import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';

import { ListComponent } from 'commons/components/list';
import { SocialMediaNetworkIconConfig } from 'commons/components/sm-icons';

import {
	CompanyListingsService,
	ListingGroupNmlsLocation,
} from '../../../../company-listings.service';

@Component({
	selector: 'listing-group-nmls-locations',
	templateUrl: './nmls-locations.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ListingGroupNmlsLocationsComponent) },
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListingGroupNmlsLocationsComponent extends ListComponent {
	static listName = ListingGroupNmlsLocationsComponent.addName('company-listing-group-nmls-locations');
	listName = ListingGroupNmlsLocationsComponent.listName;
	list: any = [
		{},
	];

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		transitionService: TransitionService,
		stateService: StateService,
		private companyListingsService: CompanyListingsService,
	) {
		super(transitionService, stateService);
		this.defaultFilters.listingGroupId = stateService.params.listingGroupId;
	}

	loadList(params) {
		this.list.$resolved = false;
		return this.companyListingsService.listingGroupNmlsLocationsList(
			this.getClearParams({
				...params,
				linked: true,
			}),
		).$promise
			.then((data: ListingGroupNmlsLocation[]) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
				this.changeDetectorRef.markForCheck();
			});
	}

	getImageClass = ({ removedFromNmls }): string[] => ([
		'list-item-image',
		!removedFromNmls ? 'nmls-active' : 'removed-from-nmls',
	]);
}
