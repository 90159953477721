import { from } from 'rxjs';
import { Component, Injector } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import { PagedListComponent } from 'commons/components/list/paged-list.component';
import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';

import { ContactService } from '../contact.service';

@Component({
	selector: 'contact-lists',
	templateUrl: './lists.component.html',
})
export class ContactListsComponent extends PagedListComponent {
	static listName = ContactListsComponent.addName('contact');
	listName = ContactListsComponent.listName;

	contact: any;
	canAdd: boolean = false;
	canRemove: boolean = false;
	add: any = {
		show: false,
		loading: false,
		form: new RealmFormGroup({
			listIds: new RealmFormControl(
				'listIds',
				{
					label: 'Lists',
				},
				[
					Validators.required,
				],
			),
		}),
	};
	lists: any = [];
	modalRef: BsModalRef;

	constructor(
		public injector: Injector,
		public transitionService: TransitionService,
		public stateService: StateService,
		public user: UserService,
		public contactService: ContactService,
		public modalService: BsModalService,
	) {
		super(transitionService, stateService);

		this.canAdd = user.profile.can('ADD_CONTACT_TO_LIST');
		this.canRemove = user.profile.can('DELETE_CONTACT_FROM_LIST');

		this.omitParams = [
			'organizationContactId',
			'containedInList',
		];

		this.defaultFilters = {
			...this.defaultFilters,
			containedInList: true,
		};

		const { contact } = this.injector.get('$scope');
		this.contact = contact;

		contact.$promise
			.then(() => {
				const { organizationContactId } = contact;

				// TODO: find a proper way to delay a list initialization to initialize all required parameters first
				this.setFilter({
					organizationContactId,
				});

				this.add.toggle = function() {
					this.show = !this.show;
					if (this.show) {
						this.availableLists = contactService.lists.get({
							organizationContactId,
							containedInList: false,
						});
						this.availableLists$ = from(this.availableLists.$promise);
					} else {
						this.form.reset();
					}
				};

				const { refreshList } = this;
				this.add.submit = function() {
					this.loading = true;
					contactService.lists.add(
						{
							organizationContactId,
						},
						{
							listIds: this.form.get('listIds').value,
						},
						() => {
							refreshList().then(
								() => this.toggle(),
							);
						},
						({ data }) => {
							this.form.setServerError(data);
						},
					).$promise
						.finally(() => {
							this.loading = false;
						});
				};
			});
	}

	loadList(queryParams) {
		this.lists.$resolved = false;

		return this.contactService.lists.get(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.lists = data;
			});
	}

	// list refresh workaround
	public refreshList = () => (
		this.loadList(this.params$.getValue())
	)

	remove({ id: listId, name: listName }) {
		const { organizationContactId } = this.contact;

		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState: {
				message: `Please confirm removing from <b>${listName}</b>.`,
				confirmText: 'Remove',
				cancelText: 'Cancel',
				onConfirm: () => {
					this.modalRef.content.resolving = true;
					this.contactService.lists.remove(
						{
							organizationContactId,
							listId,
						},
					).$promise
						.finally(() => {
							this.refreshList();
							this.modalRef.hide();
						});
				},
			},
		});
	}
}
