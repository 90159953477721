import { Component } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { OwnersListComponent } from 'tpo/contacts/owners-list.component';
import { OwnersResourceService } from 'tpo/contacts/owners-resource.service';
import { TpoAccountResourceService } from 'tpo/contacts/tpo-account-resource.service';

@Component({
	selector: 'comergence-owners-list',
	templateUrl: '../../../tpo/contacts/owners-list.component.html',
})

export class ComergenceOwnersListComponent extends OwnersListComponent {
	static listName = ComergenceOwnersListComponent.addName('cOwners');
	listName = ComergenceOwnersListComponent.listName;

	constructor(User: UserService, OwnersResource: OwnersResourceService, AccountResource: TpoAccountResourceService, modalService: BsModalService, transitionService: TransitionService, stateService: StateService) {
		super(User, OwnersResource, AccountResource, modalService, transitionService, stateService);
	}

	getAccountCompanyParams() {
		return {
			tpoId: this.stateService.params.id,
		};
	}

	getLoadListParams(queryParams) {
		return {
			...this.getClearParams(queryParams),
			tpoId: this.stateService.params.id,
		};
	}
}
