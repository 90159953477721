import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

import { OrdersService } from './orders.service';
import { OrdersListComponent } from './list';
import { OrderDetailsComponent } from './details';
import { OrderStatusModalComponent } from './details/edit-status/order-status-modal.component';

const components = [
	OrdersListComponent,
	OrderDetailsComponent,
	OrderStatusModalComponent,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PipesModule,
		DirectivesModule,
		ComponentsModule,
	],
	declarations: [
		...components,
	],
	entryComponents: [
		...components,
	],
	providers: [
		OrdersService,
	],
})

export class OrdersModule {}
