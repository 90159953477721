import { Component, forwardRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, merge, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import * as moment from 'moment';
import { mapValues, assign, map } from 'lodash';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { ListComponent, PagedListComponent } from 'commons/components/list';
import { SocialMediaPostAttachmentsComponent } from 'commons/components/sm-post';
import { ScrollService } from 'commons/services';
import { FindingsService } from 'shared/findings/findings.service';
import { AuditsService } from 'shared/social-compliance/audits';

import { FeedContext } from './feed.interface';
import { FeedService } from './feed.service';

import { AddLinkModalComponent } from './add-link-modal';
import { PostHistoryModalComponent } from './post-history-modal';
import { OwnersModalComponent } from 'tpo/social-compliance/archive/owners-modal'; // TODO: maybe move to shared

@Component({
	templateUrl: './feed.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => FeedComponent) },
	],
	providers: [
		ScrollService,
	],
})
export class FeedComponent extends PagedListComponent implements OnInit {
	static listName = FeedComponent.addName('audit-feed');
	listName = FeedComponent.listName;

	User: UserProfile;
	bsConfig: Partial<BsDatepickerConfig> = {
		dateInputFormat: 'MM/DD/YYYY',
	};
	review: any;
	list: any = [];
	count: { flaggedCount: number, totalCount: number } = null;
	feedContexts = FeedContext;
	context: FeedContext;
	modalRef: BsModalRef;
	@ViewChildren(SocialMediaPostAttachmentsComponent) attachments: QueryList<SocialMediaPostAttachmentsComponent>;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public routerGlobals: UIRouterGlobals,
		public scrollService: ScrollService,
		public userService: UserService,
		public findingsService: FindingsService,
		public auditsService: AuditsService,
		public feedService: FeedService,
		public modalService: BsModalService,
	) {
		super(transitionService, stateService);

        this.User = userService.profile;
        this.setContext();
	}

	ngOnInit(): void {
        if (!this.User?.can('TPO_SMC_VIEW_ALL') && !this.User.isComergence) return
        if (this.context === FeedContext.AUDIT) {
			const { reviewId } = this.routerGlobals.params;
			this.review = this.auditsService.audit({ reviewId });
		}
		this.configureList();

		super.ngOnInit();
	}

	setContext(): void {
		this.context = this.stateService.includes('**.:reviewId.**') ? FeedContext.AUDIT : FeedContext.GLOBAL;
		this.feedService.setContext(this.context);
	}

	configureList(): void {
		const { reviewId } = this.routerGlobals.params;

		this.omitParams.push(
			'reviewId',
			'hasTriggers',
		);

		assign(
			this.filters,
			{
				...(this.context === FeedContext.GLOBAL && { reviewedIndividual: this.feedService.reviewedIndividuals() }),
			},
		);

		assign(
			this.defaultFilters,
			{
				reviewId,
				postStartDate: null,
				postEndDate: null,
				hasTriggers: 'true',
				size: 50,
				...(this.context === FeedContext.GLOBAL && { reviewedIndividual: [] }),
			},
		);
	}

	setFilter(filters, resetPage: boolean = true) {
		const processedFilter = mapValues(filters, (value: any, key: string) => {
			switch (key) {
				case 'reviewedIndividual':
					return map(value || [], 'shortName');
				default:
					return value;
			}
		});
		super.setFilter(processedFilter, resetPage);
	}

	processParams(params) {
		return mapValues(
			this.getClearParams(params),
			(value, key) => {
				switch (key) {
					case 'postStartDate':
					case 'postEndDate':
						return value && moment(value).startOf('day').valueOf();
					default:
						return value;
				}
			},
		);
	}

	loadList(queryParams): Promise<any> {
		if (this.context === FeedContext.AUDIT) {
			this.updateCount(queryParams);
		}

		this.list.$resolved = false;

		const params = this.processParams(queryParams);
		return this.feedService.get(params).$promise.then(
				(data) => {
					this.list = data;
                    data.forEach((post) => {
                        post.$resolved = true;
                    });

					// wait attachments to be rendered
					setTimeout(() => {
						(
							this.attachments?.length ?
								forkJoin(
									this.attachments.map(({ isRendered, resolved }) => (
										merge([
											isRendered
												.pipe(
													// successful load
													filter(Boolean),
												),
											resolved,
										])
											.pipe(
												// wait load and complete
												take(1)
											)
									))
								) :
								of({})
						)
							.subscribe(() => {
								// wait attachment images to be rendered
								setTimeout(() => {
									this.scrollService.updateScrollPosition();
								}, 500);

								this.list.$resolved = true;
							});
					})
				},
				() => {
					this.list.$resolved = true;
				}
			);
	}

	updateCount(queryParams): void {
		this.feedService.count(
			this.processParams(queryParams),
		).$promise
			.then((data) => {
				this.count = data;
			});
	}

	createFinding(post): void {
		const {
			id,
			contentType: type,
			version,
			smAccountId,
			createdDate: originalPostDate,
		} = post;
		const { reviewId } = this.params;
		const params = {
			reviewId,
		};
		const body = {
			originalPostDate,
			postKey: {
				smAccountId,
				type,
				id,
				version,
			},
		};

		post.$resolved = false;
		this.findingsService.finding[this.context === FeedContext.GLOBAL ? 'createFromFeed' : 'createFromReview'](
			params,
			body
		).$promise
			.then(({ id: findingId }) => {
				this.stateService.go(
					'social-compliance.findings.:actionType.:findingId',
					{
						actionType: 'edit',
						findingId,
						tab: 'ISSUES',
					}
				);
			})
			.finally(() => {
				post.$resolved = true;
			});
	}

	reviewPost(post): void {
		// RM-26705 sometimes button is pressed couple of times
		if (!post.$resolved) {
			return;
		}

		post.$resolved = false;

		const {
			id,
			contentType: type,
			version,
			smAccountId,
		} = post;
		const { reviewId } = this.params;
		const params = {
			reviewId,
			smAccountId,
			type,
			id,
			version,
		};

		this.feedService.post.review(
			params,
			null,
		).$promise
			.then(({ reviewedDate }) => {
				assign(
					post,
					{
						reviewedDate,
						canReviewByCCM: false,
					},
				);
			})
			.finally(() => {
				post.$resolved = true;
			});
	}

	showAddLinkModal(post): void {
		const {
			webLink: link,
		} = post;
		const initialState = {
			link,
			addLink: (link: string): Promise<any> => (
				this.review.$promise
					.then(({ contactId }) => (
						this.feedService.post.addLink({
							contactId,
							link,
						}).$promise
							.then(({ link }) => {
								post.canAddLink = post.webLink !== link;
							})
					))
			),
		};

		this.modalService.show(
			AddLinkModalComponent,
			{
				initialState,
				class: 'modal-smd modal-new',
				backdrop: 'static',
			},
		);
	}

	showPostHistoryModal(post): void {
		const {
			id,
			contentType: type,
			version,
			smAccountId,
		} = post;
		const { reviewId } = this.params;
		const params = {
			...(reviewId && { reviewId }),
			smAccountId,
			type,
			id,
			version,
		};
		const initialState = {
			resource: this.feedService.post.history(params),
		};

		this.modalService.show(
			PostHistoryModalComponent,
			{
				initialState,
				class: 'modal-smd modal-new',
				backdrop: 'static',
			},
		);
	}

	showOwners(owners): void {
		const initialState = {
			list: owners,
		};

		this.modalRef = this.modalService.show(OwnersModalComponent, {
			initialState,
			class: 'modal-smd modal-new',
			backdrop: 'static',
		});
	}

	getContactData = ({ name, ...others }) => ({ contactName: name, ...others });
}
