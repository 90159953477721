import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { PipesModule } from 'commons/pipes/pipes.module';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { CompanyPagesComponent } from './company-pages.component';
import { CompanyPageService } from './company-page-resource.service';
import { LenderCompanyPagesChangeLogoModalComponent } from './change-logo-modal/lender-company-pages-change-logo-modal.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		PipesModule,
		ComponentsModule,
		DirectivesModule,
	],
	declarations: [
		CompanyPagesComponent,
        LenderCompanyPagesChangeLogoModalComponent,
	],
	providers: [
		CompanyPageService,
	],
	entryComponents: [
		CompanyPagesComponent,
        LenderCompanyPagesChangeLogoModalComponent,
	],
})
export class CompanyPagesModule {
}
