<div class="my-settings">
	<div class="gray-head">
		<h2>My Settings</h2>
	</div>
	<section class="manage-page remote-resource" [class.resolved]="userSettings?.$resolved && user?.$resolved">
		<form [formGroup]="settingsForm"
			id="settingsForm"
			(onValidSubmit)="save()"
			class="remote-resource"
			[class.resolved]="!saving"
		>
			<div class="mb20">
				<h3 class="clearfix with-buttons">
					<span class="pull-left">User Settings</span>
					<span class="pull-left">
						&nbsp;&nbsp;<button type="button" (click)="changePasswordModal()" class="btn btn-default">Change Password</button>
					</span>
					<span class="pull-right">
						<a class="btn btn-primary" *ngIf="!editable" (click)="toggleEditing(true)">
							<span class="glyphicon glyphicon-pencil"></span>Edit
						</a>

						<input type="submit" class="btn btn-primary" *ngIf="editable" tabindex="3" value="Save">

						<a class="btn btn-default" *ngIf="editable" (click)="toggleEditing(false)">
							Cancel
						</a>
					</span>
				</h3>
			</div>

			<form-errors [form]="settingsForm"></form-errors>

			<div style="overflow: hidden; height: 0;">
				<input type="text" name="fakeusernameremembered">
			</div>

			<div class="rows-striped mb30">
				<div class="row-fluid">
					<div class="col-md-6">
						<div class="form-group row" [class.has-error]="hasError('userName')">
							<label class="col-sm-3 control-label" [class.required]="editable">{{getLabel('userName')}}</label>
							<div class="col-sm-8">
								<editable-component [editable]="editable">
									<ng-template #editTemplate>
										<input type="text"
											formControlName="userName"
											class="form-control"
											tabindex="1"
										/>
									</ng-template>
									<ng-template #viewTemplate>
										<div class="control-value">
											{{userSettings?.userName}}
										</div>
									</ng-template>
								</editable-component>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group row" [class.has-error]="hasError('securityPinCode')">
							<label class="col-sm-3 control-label" [class.required]="editable">{{getLabel('securityPinCode')}}</label>
							<div class="col-sm-4">
								<editable-component [editable]="editable">
									<ng-template #editTemplate>
										<form-show-password
											[form]="settingsForm"
											control="securityPinCode"
											maxlength="4"
											tabindex="2"
										></form-show-password>
									</ng-template>
									<ng-template #viewTemplate>
										<div class="control-value">
											{{userSettings?.securityPinCode | bulletpoints}}
										</div>
									</ng-template>
								</editable-component>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>

		<lender-user-details
			*ngIf="userSettings?.$resolved && user?.$resolved"
			[user]="user"
			[settingsView]="true"
			[linkToRole]="true"
		></lender-user-details>
	</section>
</div>
