<div class="prospects">
	<div class="gray-head flex-head">
		<h2 class="col-min middle">
			Prospects
		</h2>
	</div>

    <div class="flex-row align-top">
        <div class="filters-rows flex-grow" [class.curtain]="editing">
            <div class="row-fluid filter-labels">
                <div class="col-md-a">
                    <div class="text-muted">Search</div>
                </div>
                <div class="col-md-a">
                    <span class="text-muted">Channel</span>
                </div>
                <div class="col-md-a">
                    <span class="text-muted">Assigned</span>
                </div>
                <div class="col-md-a">
                    <span class="text-muted">Status</span>
                </div>
				<div class="col-md-a"></div>
            </div>
            <div class="row-fluid filter-selectors">
                <div class="col-md-a">
                    <search-list placeholder="Company, NMLS ID or Location"></search-list>
                </div>
                <div class="col-md-a">
                    <ng-select
                        [ngModel]="params.organizationChannelId"
                        [items]="filters?.channels | async"
                        (change)="setFilter({ organizationChannelId: $event })"
                        bindValue="id"
                        bindLabel="name"
                        [clearable]="true"
                        [loading]="!(filters?.channels | async)"
                        placeholder="All Channels"
					></ng-select>
                </div>
                <div class="col-md-a">
                    <ng-select
                        [class]="'option-separator'"
                        [ngModel]="params.prospectAssigneeId"
                        [items]="filters?.assigneeOptions | async"
                        (change)="setFilter({ prospectAssigneeId: $event })"
                        bindValue="id"
                        bindLabel="name"
                        [multiple]="true"
                        [clearable]="true"
                        [loading]="!(filters?.assigneeOptions | async)"
                        placeholder="All Assignees"
					></ng-select>
                </div>
                <div class="col-md-a">
                    <ng-select
                        [ngModel]="params.prospectStatus"
                        [items]="filters?.statuses | async"
                        (change)="setFilter({ prospectStatus: $event })"
                        [multiple]="true"
                        [clearable]="true"
                        [loading]="!(filters?.statuses | async)"
                        placeholder="All Statuses"
					></ng-select>
                </div>
				<div class="col-md-a">
					<filters-toggle [opened]="moreFilters" (toggle)="toggleFilters()"></filters-toggle>
				</div>
            </div>
			<ng-container *ngIf="moreFilters">
				<div class="row-fluid filter-labels">
					<div class="col-md-a">
						<span class="text-muted">Result</span>
					</div>
					<div class="col-md-a">
						<span class="text-muted">Custom Field</span>
					</div>
					<div class="col-md-a">
						<span *ngIf="params.customFieldId" class="text-muted">Custom Field Value</span>
					</div>
					<div class="col-md-a"></div>
					<div class="col-md-a"></div>
				</div>
				<div class="row-fluid filter-selectors">
					<div class="col-md-a">
						<ng-select
							[ngModel]="params.prospectState"
							[items]="filters?.result | async"
							(change)="setFilter({ prospectState: $event })"
							bindValue="shortName"
							bindLabel="name"
							[multiple]="true"
							[clearable]="true"
							[loading]="!(filters?.result | async)"
							placeholder="All Results"
						></ng-select>
					</div>
					<div class="col-md-a">
						<ng-select #customFieldSelect
						   [ngModel]="params.customFieldId"
						   [items]="filters?.customFields | async"
						   (change)="setFilter({ customFieldId: $event, customFieldValue: null })"
						   [loading]="!(filters?.customFields | async)"
						   bindValue="id"
						   bindLabel="fieldName"
						   [multiple]="false"
						   [clearable]="true"
						   placeholder="All Custom Fields"
						></ng-select>
					</div>
					<div class="col-md-a">
						<ng-container *ngIf="customFieldSelect.selectedValues[0] as selectedItem">
							<ng-container [ngSwitch]="selectedItem?.controlTypeId" >
								<ng-container *ngSwitchCase="'YesNo'" [ngTemplateOutlet]="ngSelect"></ng-container>
								<ng-container *ngSwitchCase="'MultipleSelection'" [ngTemplateOutlet]="ngSelect"></ng-container>
								<ng-container *ngSwitchCase="'Droplist'" [ngTemplateOutlet]="ngSelect"></ng-container>

								<input
									*ngSwitchCase="'Date'" class="form-control input-date"
									bsDatepicker
									(bsValueChange)="setFilter({ customFieldValue: dateFormatter.format($event) })"
									placeholder="mm/dd/yyyy"
								>
								<div *ngSwitchDefault class="form-group no-margin has-feedback left-position">
									<input
										[ngModel]="params.customFieldValue"
										class="form-control"
										type="search"
										(input)="setCustomFieldValue($event)"
									>
									<a
										*ngIf="params.customFieldValue"
										class="form-control-feedback glyphicon glyphicon-remove"
										(click)="setFilter({ customFieldValue: null })"
									></a>
								</div>
							</ng-container>

							<ng-template #ngSelect>
								<ng-select
									[ngModel]="params.customFieldValue"
									[items]="selectedItem?.valuesList || ['Yes', 'No']"
									(change)="setFilter({ customFieldValue: $event })"
									[multiple]="true"
									[closeOnSelect]="false"
									placeholder="Please Select"
								></ng-select>
							</ng-template>
						</ng-container>
					</div>
					<div class="col-md-a"></div>
					<div class="col-md-a"></div>
				</div>
			</ng-container>
        </div>
        <div class="filters-rows">
            <div class="filter-selectors flex-row right align-bottom gap">
                <ng-container *ngIf="editing">
                    <div style="width: 240px;">
                        <div class="text-muted">Assigned</div>
                        <div [class.remote-resource]="saving">
                            <ng-select
                                [(ngModel)]="selectedAssignee"
                                [items]="channelAssigneeOptions | async"
                                bindValue="id"
                                bindLabel="name"
                                [disabled]="!checkedProspects.length"
                                [clearable]="true"
                                [loading]="!(channelAssigneeOptions | async)"
                                placeholder="Choose Prospect Assigned">
                            </ng-select>
                        </div>
                    </div>

                    <button (click)="cancel()" type="button" class="btn-default btn">
                        Cancel
                    </button>

                    <button
                        [disabled]="!selectedAssignee || !checkedProspects.length"
                        type="button"
                        class="btn btn-primary"
                        (click)="saveAssignment()"
                    >
                        Save
                    </button>
                </ng-container>

                <ng-container *ngIf="!editing">
                    <div class="dropdown" dropdown [insideClick]="true">
                        <div>
                            <div>&nbsp;</div>
                            <a class="dropdown-toggle btn btn-tertiary btn-icon" dropdownToggle tooltip="Manage Columns" placement="top">
                                <span class="c-icon c-icon-list-bullet"></span>
                            </a>
                        </div>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                            <li *ngFor="let column of columnValues()">
                                <a class="link-option" (click)="toggleColumn(column.id)">
                                <span class="checkbox-holder">
                                    <input type="checkbox" [checked]="column.enabled">
                                    {{column.name}}
                                </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <button
                        *ngIf="User.can('EDIT_AA_ASSIGNMENT')"
                        (click)="changeAssignment()"
                        type="button"
                        class="btn btn-primary pull-right">Bulk Assignment
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
	<div class="rows-bordered remote-resource" [class.resolved]="!resolving">
		<div class="row-fluid header">
			<span class="col-f-40" *ngIf="editing"></span>
			<span class="col-sm-4">
				<b>Name</b>
			</span>
			<span class="col-sm-2">
				<b>Channel</b>
			</span>
			<span class="col-sm-2" isColumnEnabled columnName="locationName">
				<b>Location</b>
			</span>
			<span class="col-sm-2" isColumnEnabled columnName="prospectStatus">
				<b>Status</b>
			</span>
			<span class="col-sm-2" isColumnEnabled columnName="assigneeName">
				<b>Assigned</b>
			</span>
            <span class="col-sm-2 text-right" isColumnEnabled columnName="totalVolume">
				<b>Total Vol.</b>
			</span>
            <span class="col-sm-2 two-liners text-right" isColumnEnabled columnName="individualNmlsCount">
				<b>Ind. NMLS</b>
			</span>
            <span class="col-sm-2 two-liners text-right" isColumnEnabled columnName="brokerVolume">
				<b>Broker Vol.</b>
			</span>
            <span class="col-sm-2 two-liners text-right" isColumnEnabled columnName="soldVolume">
				<b>Sold. Vol.</b>
			</span>
			<span class="col-f-100"></span>
		</div>
		<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied && !editing">
			<span class="col-sm-12 va-m text-center">
				Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results
			</span>
		</div>
		<div class="row-fluid" *ngFor="let item of list | async">
			<div class="col-f-40 va-m" *ngIf="editing">
				<input
					type="checkbox"
					[(ngModel)]="item.checked"
				>
			</div>
			<span class="col-sm-4 col-w-logo control-value">
				<div class="logo-image" *ngIf="item.tpoId"></div>
				<a class="text-bigger" uiSref=".:id" [uiParams]="{ id: item.id }">{{item.locationCompanyName}}</a>
				<div>
					<span class="text-light">NMLS ID: {{item.locationNmlsId}}</span>							&nbsp;
					<span class="label"
						  [ngClass]="(item.locationType == 'HQ' ? 'label-default' : 'label-default-wired')">
						{{item.locationType == 'HQ' ? 'Headquarters' : 'Branch'}}
					</span>
				</div>
			</span>
			<span class="col-sm-2 control-value">
				{{item.channelName || '&ndash;'}}
			</span>
			<span class="col-sm-2  control-value" isColumnEnabled columnName="locationName">
				{{item.locationName}}
			</span>
			<span class="col-sm-2" isColumnEnabled columnName="prospectStatus">
                <cell-select class="inline-edit"
                             [editable]="item.editable && item.editableStatus && !editing"
                             (open)="openStatus($event, item)"
                             (save)="saveStatus($event, item)"
                             [config]="statusConfig">
                    {{item.prospectStatus || '&ndash;'}}
                </cell-select>
			</span>
			<span class="col-sm-2" isColumnEnabled columnName="assigneeName">
                <cell-select class="inline-edit"
                             [editable]="(item.editable && item.editableStatus && (User.can('ASSIGN_PROSPECT') || item?.assigneeUserId == User.id)) && !editing"
                             (open)="openAssigned($event, item)"
                             (save)="saveAssigned($event, item)"
                             [config]="assigneeConfig">
                    {{item.assigneeName || 'Unassigned'}}
                </cell-select>

			</span>
            <span class="col-sm-2 control-value text-right text-nowrap" isColumnEnabled columnName="totalVolume">
				{{(item.totalVolume | currency : 'USD' : '$' : '1.0-0') || '&ndash;'}}
			</span>
            <span class="col-sm-2 control-value text-right" isColumnEnabled columnName="individualNmlsCount">
                {{item.individualNmlsCount || '&ndash;'}}
			</span>
            <span class="col-sm-2 control-value text-right text-nowrap" isColumnEnabled columnName="brokerVolume">
                {{(item.brokerVolume | currency : 'USD' : '$' : '1.0-0') || '&ndash;'}}
			</span>
            <span class="col-sm-2 control-value text-right text-nowrap" isColumnEnabled columnName="soldVolume">
                {{(item.soldVolume | currency : 'USD' : '$' : '1.0-0') || '&ndash;'}}
			</span>
			<span class="col-f-100 text-right button-holder">
				<a
					class="btn btn-default"
					*ngIf="item.tpoId && item.channelsToInviteIds?.length"
					uiSref="client.:id.account.channels"
					target="_blank"
					[uiParams]="{ id: item.tpoId }"
				>
					Invite
				</a>
			</span>
		</div>
		<div class="row-fluid" *ngIf="!resolving && !(list | async)?.length && filtersApplied">
			<span class="col-sm-12 text-muted text-center text-bigger text-weak">No matches found</span>
		</div>
		<div class="row-fluid" *ngIf="!resolving && !(list | async)?.length && !filtersApplied">
			<div class="col-md-12 text-center text-bigger text-muted">No records added</div>
		</div>
		<br>
		<div [class.curtain]="editing">
			<paginator [sizes]="[10, 25, 50]"></paginator>
		</div>
	</div>
</div>
