import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { PasswordPolicyComponent } from './password-policy.component';
import { PasswordPolicyService } from './password-policy.service';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		ComponentsModule,
		DirectivesModule,
	],
	declarations: [
		PasswordPolicyComponent,
	],
	providers: [
		PasswordPolicyService,
	],
	entryComponents: [
		PasswordPolicyComponent,
	],
})

export class PasswordPolicyModule {}
