import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list';
import { ApplicationsResourceService } from '../application.service';
import { NotifyOwnershipVerificationModalComponent } from '../modals/notify-ownership-verification';
import { TpoDocumentListComponent } from 'tpo/documents';
import { hasContactPermission } from 'tpo/contacts/contact-permissions';

const blockerStatuses = new Set();
blockerStatuses.add('DR');
blockerStatuses.add('NS');

@Component({
	templateUrl: './applications-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => PendingApplicationsListComponent) },
	],
	selector: 'pending-applications',
})
export class PendingApplicationsListComponent extends ListComponent {
	static listName = PendingApplicationsListComponent.addName('pending-applications');
	listName = PendingApplicationsListComponent.listName;

	@Input() hasOwnLoader = true;
	@Output('onLoaded') _onLoaded = new EventEmitter<boolean>();

    hasContactPermission = hasContactPermission;

	User: any;
	list: any = [];

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		private modalService: BsModalService,
		private applicationsService: ApplicationsResourceService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;
		this.defaultFilters.approvalStatus = [ 'PENDING', 'PENDING_RENEWAL' ].join(',');
	}

	isBlocked(status): boolean {
		return blockerStatuses.has(status);
	}

	notifyUnverifiedOwners(channelId?: number) {
		const initialState = {
			channelId,
		};
		this.modalService.show(
			NotifyOwnershipVerificationModalComponent,
			{
				initialState,
				class: 'modal-smd modal-new verify-ownership-modal',
			}
		);
	}

	loadList(queryParams): void {
		this._setLoaded(false);
		return this.applicationsService.getList(
			this.getClearParams(queryParams),
			(data) => {
				this.list = data.sort((a, b) => {
					const statusA = blockerStatuses.has(a.applicationStatus.id) ? 1 : -1;
					const statusB = blockerStatuses.has(b.applicationStatus.id) ? 1 : -1;
					if ( statusA == statusB ) return b.createdDate - a.createdDate;
					return statusB;
				}).map(application => {
					const {
						lender: { id: lenderId },
						channel: { id: channelId },
					} = application;
					return {
						...application,
						docListParams: {
							[TpoDocumentListComponent.listName]: {
								lenderId,
								channelId: [channelId],
							},
						},
					}
				});
			}
		).$promise
			.finally(() => {
				this._setLoaded(true);
			});
	}

	_setLoaded(isLoaded: boolean): void {
		this.list.$resolved = isLoaded;

		this._onLoaded.emit(isLoaded);
	}

	getSource(application): string {
		return `/api/rest/lenders/${application.lender.id}/logo`;
	}
}
