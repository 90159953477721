import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CompanyPageService } from '../company-page-resource.service';


@Component({
	templateUrl: './lender-company-pages-change-logo-modal.component.html',
})
export class LenderCompanyPagesChangeLogoModalComponent implements AfterViewInit, OnDestroy {
    private readonly image = new Image();

    @ViewChild('modalHeader', { read: ElementRef }) private modalHeader;
    @ViewChild('wholeImage', { read: ElementRef }) private wholeImage;
    @ViewChild('croppedImage', { read: ElementRef }) private canvas: ElementRef<HTMLCanvasElement>;

    @Input() public selectedImage: string;
    @Input() public saveCallback: () => any;

    private rootElement: HTMLElement;

    private context: CanvasRenderingContext2D;

    public isSaving = false;

    public imagePos = null;

    public resultSize = 256;
    public sourceSize = 260;
    public zone = {
        x: 0,
        y: 0,
        size: this.sourceSize,
    };

    // Draggable areas
    public startCoords = {
        x: 0,
        y: 0,
    };
    public startZone;
    public minSize = 20;

    private imageLoadUnlistener: () => void;
    private imageErrorUnlistener: () => void;
    private rootElementMouseMoveUnlistener: () => void;
    private rootElementMouseUpUnlistener: () => void;

    public constructor(
            private readonly renderer2: Renderer2,
            private readonly companyPageService: CompanyPageService,
            private readonly modalRef: BsModalRef) {
    }

    // Override
    public ngAfterViewInit(): void {
        this.rootElement = document.documentElement;

        this.context = this.canvas.nativeElement.getContext('2d');

        const that = this;
        this.imageLoadUnlistener = this.renderer2.listen(this.image, 'load', () => {
            that.imagePos = {
                x: that.wholeImage.nativeElement.offsetLeft,
                y: that.wholeImage.nativeElement.offsetTop,
            };

            that.redrawCropped();
        });
        this.imageErrorUnlistener = this.renderer2.listen(this.image, 'error', () => {
            that.hide();
            alert('Unsupported image type.');
        });

        this.image.src = this.selectedImage;

        this.rootElementMouseUpUnlistener = this.renderer2.listen(
            this.rootElement,
            'mouseup',
            () => this.clearRootElementMouseMoveListener());
    }

    // Override
    public ngOnDestroy(): void {
        if (this.imageLoadUnlistener) { this.imageLoadUnlistener(); }
        if (this.imageErrorUnlistener) { this.imageErrorUnlistener(); }
        if (this.rootElementMouseUpUnlistener) { this.rootElementMouseUpUnlistener(); }
        
        this.clearRootElementMouseMoveListener();
    }

    public redrawCropped() {
        if (!this.imagePos) {
            return;
        }

        const maxRelSide = Math.max(this.image.width, this.image.height, this.sourceSize);
        const imgRelative = {
            x: (this.imagePos.x / this.sourceSize),
            y: (this.imagePos.y / this.sourceSize),
            w: (this.image.width / maxRelSide),
            h: (this.image.height / maxRelSide),
        };

        const cropRelative = {
            x: (this.zone.x / this.sourceSize),
            y: (this.zone.y / this.sourceSize),
            s: (this.zone.size / this.sourceSize),
        };

        this.context.fillStyle = '#fff';
        this.context.fillRect(0, 0, this.resultSize, this.resultSize);

        const sourceX = (maxRelSide * Math.min(Math.max(0, cropRelative.x - imgRelative.x), Math.max(cropRelative.x, imgRelative.x)));
        const sourceY = (maxRelSide * Math.min(Math.max(0, cropRelative.y - imgRelative.y), Math.max(cropRelative.y, imgRelative.y)));
        const sourceWidth = (maxRelSide * Math.max(0, Math.min(cropRelative.x + cropRelative.s, imgRelative.x + imgRelative.w) - Math.max(cropRelative.x, imgRelative.x)));
        const sourceHeight = (maxRelSide * Math.max(0, Math.min(cropRelative.y + cropRelative.s, imgRelative.y + imgRelative.h) - Math.max(cropRelative.y, imgRelative.y)));
        const destinationX = (this.resultSize * Math.min(Math.max(0, imgRelative.x - cropRelative.x)) / cropRelative.s);
        const destinationY = (this.resultSize * Math.min(Math.max(0, imgRelative.y - cropRelative.y)) / cropRelative.s);
        const destinationWidth = (this.resultSize * Math.max(0, Math.min(cropRelative.x + cropRelative.s, imgRelative.x + imgRelative.w) - Math.max(cropRelative.x, imgRelative.x)) / cropRelative.s);
        const destinationHeight = (this.resultSize * Math.max(0, Math.min(cropRelative.y + cropRelative.s, imgRelative.y + imgRelative.h) - Math.max(cropRelative.y, imgRelative.y)) / cropRelative.s);
        this.context.drawImage(
            this.wholeImage.nativeElement,
            sourceX,
            sourceY,
            sourceWidth,
            sourceHeight,
            destinationX,
            destinationY,
            destinationWidth,
            destinationHeight
        );
    }

    public async save(): Promise<void> {
        const imgData = this.canvas.nativeElement.toDataURL('image/jpeg', 0.9);
        const payload = imgData.substr(imgData.indexOf(',') + 1);

        this.isSaving = true;
        await this.companyPageService.setLogo(payload).$promise;
        this.isSaving = false;

        this.hide();

        if (this.saveCallback) {
            this.saveCallback();
        }
    }

    public hide(): void {
        this.modalRef?.hide();
    }

    public initDrag(e) {
        this.startCoords.x = (this.rootElement.scrollLeft + e.clientX);
        this.startCoords.y = (this.rootElement.scrollTop + e.clientY);
        this.startZone = { ...this.zone };
    }

    public sizeDragMouseDown(e) {
        this.initDrag(e);
        if (e.which === 1) { // left button drag
            this.rootElementMouseMoveUnlistener = this.renderer2.listen(this.rootElement, 'mousemove', (e) => this.dragSize(e));
        }

        e.preventDefault();
        e.stopPropagation();
    }

    public cropZoneMouseDown(e) {
        this.initDrag(e);
        if (e.which === 1) { //left button drag
            this.rootElementMouseMoveUnlistener = this.renderer2.listen(this.rootElement, 'mousemove', (e) => this.dragPos(e));
        }

        e.preventDefault();
        e.stopPropagation();
    }

    private dragSize(e) {
        if (e.which === 1) {
            const delta = this.getDelta(e);
            const value = (this.startZone.size + Math.round((delta.x + delta.y) / 2));
            const max = (this.sourceSize - Math.max(this.startZone.x, this.startZone.y));
            this.zone.size = this.limitMaxMin(value, max, this.minSize);

            this.redrawCropped();
        } else {
            this.clearRootElementMouseMoveListener();
        }

        e.preventDefault();
        e.stopPropagation();
    }

    private dragPos(e) {
        if (e.which === 1) {
            const delta = this.getDelta(e);

            const xValue = (this.startZone.x + delta.x);
            const yValue = (this.startZone.y + delta.y);
            const maxValue = (this.sourceSize - this.startZone.size);
            this.zone.x = this.limitMaxMin(xValue, maxValue, 0);
            this.zone.y = this.limitMaxMin(yValue, maxValue, 0);

            this.redrawCropped();
        } else {
            this.clearRootElementMouseMoveListener();
        }

        e.preventDefault();
        e.stopPropagation();
    }

    private limitMaxMin(value, max, min): number {
        return Math.max(min, Math.min(value, max));
    }

    private getDelta(e) {
        return {
            x: (this.rootElement.scrollLeft + e.clientX - this.startCoords.x),
            y: (this.rootElement.scrollTop + e.clientY - this.startCoords.y),
        };
    }

    private clearRootElementMouseMoveListener(): void {
        if (this.rootElementMouseMoveUnlistener) {
            this.rootElementMouseMoveUnlistener();
            this.rootElementMouseMoveUnlistener = null;
        }
    }
}