import { sortBy } from 'lodash';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';

import { NgResourceArray } from 'commons/interfaces';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';

import { TpoDocumentsService } from 'tpo/documents/documents.service';
import { RequiredDocumentsPartner } from 'tpo/documents/documents.interface';
import { TpoDocumentListComponent } from 'tpo/documents';

@Component({
	selector: 'required-documents-partners',
	templateUrl: './required-documents-partners-list.component.html',
})
export class RequiredDocumentsPartnersListComponent extends ListComponent {
	static listName = RequiredDocumentsPartnersListComponent.addName('requestedDocuments');
	listName = RequiredDocumentsPartnersListComponent.listName;

	@Input() hasOwnLoader = true;
	@Output('onLoaded') _onLoaded = new EventEmitter<boolean>();

	User: UserProfile;
	list: NgResourceArray<RequiredDocumentsPartner & { link?: string }> = [];

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public userService: UserService,
		public documentsService: TpoDocumentsService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;

		this.configureList();
	}

	configureList(): void {
		const {
			id: tpoId,
		} = this.stateService.params;

		this.defaultFilters = {
			tpoId,
		};

		this.omitParams = [
			...this.omitParams,
			'tpoId',
		];
	}

	loadList(queryParams) {
		this._setLoaded(false);
		return this.documentsService.getRequiredDocumentsPartners(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.list = sortBy(data, (partner) => partner.name.toLowerCase()).map((partner) => {
					// prepare link to documents on data fetch
					return {
						...partner,
						link: this.stateService.href('documents.list', {
							[TpoDocumentListComponent.listName]: {
								lenderId: partner.id,
							},
						}),
					};
				});
			})
			.finally(() => {
				this._setLoaded(true);
			});
	}

	_setLoaded(isLoaded: boolean): void {
		this.list.$resolved = isLoaded;

		this._onLoaded.emit(isLoaded);
	}
}
