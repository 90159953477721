import { Injector, Injectable } from '@angular/core';


declare let apiPath: string;

@Injectable()
export class PasswordService {
	private readonly resource: any;

	public constructor(public injector: Injector) {
		const $resource = injector.get('$injector').get('$resource');
		const path: string = `${apiPath}/users/:id/password`;

		this.resource = $resource(path, { id: '@id' }, {
			update: {method: 'put'},
		});
	}

	public update(...args) {
        return this.resource.update(...args);
    }
}
