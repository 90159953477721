import { Component, forwardRef } from '@angular/core';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ListComponent } from 'commons/components/list';
import { HistoryDocumentListComponent } from 'shared/documents';

import { RequestDocumentHistoryModalComponent } from '../../common';
import { ClientHistoryDocument } from '../../client-documents.interface';
import { ClientDocumentsService } from '../../client-documents.service';
import { WaiveDocumentHistoryModalComponent } from '../../common/waive-document-history-modal/waive-document-history-modal.component';
import { UserService } from 'angularjs-providers/user.provider';

@Component({
	templateUrl: '../../common/history/document-history.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ClientDocumentHistoryComponent) },
	],
})
export class ClientDocumentHistoryComponent extends HistoryDocumentListComponent<ClientHistoryDocument> {
	static listName = ClientDocumentHistoryComponent.addName('clientDocumentHistory');
	listName = ClientDocumentHistoryComponent.listName;
	modalRef: BsModalRef;

	listResource = this.documentsService.getDocumentHistory;

	configureList = (): void => {
		const {
			id: tpoId,
			documentId,
		} = this.uiRouterGlobals.params;
		Object.assign(
			this.defaultFilters,
			{
				tpoId,
				documentId,
			},
		);

		this.omitParams.push('tpoId', 'documentId');
	};

	getDownloadLink = ({ historyId }: ClientHistoryDocument): string => {
		const {
			id: tpoId,
			documentId,
		} = this.uiRouterGlobals.params;
		return this.documentsService.getDownloadLink({
			tpoId,
			documentId,
			historyId,
		});
	};

	showRequestHistoryModal({ historyId }: ClientHistoryDocument): void {
		const {
			id: tpoId,
			documentId,
		} = this.uiRouterGlobals.params;
		this.modalRef = this.modalService.show(
			RequestDocumentHistoryModalComponent,
			{
				initialState: {
					snapshotData: this.documentsService.getDocumentRequestsHistory({
						tpoId,
						documentId,
						historyId,
					}),
				},
				class: 'modal-new modal-smd request-history-modal',
			},
		);
	}

	showWaiveHistoryModal({ historyId }: ClientHistoryDocument): void {
		const {
			id: tpoId,
			documentId,
		} = this.uiRouterGlobals.params;
		this.modalRef = this.modalService.show(
			WaiveDocumentHistoryModalComponent,
			{
				initialState: {
					snapshotData: this.documentsService.getDocumentWaiveHistory({
						tpoId,
						documentId,
						historyId,
					}),
				},
				class: 'modal-new modal-smd request-history-modal',
			},
		);
	}

    async markDocumentReviewed(document: ClientHistoryDocument) {
        const lenderId = this.user.profile.organization.id;
        const tpoId = this.params.tpoId;
        const documentId = document.documentId;
        const historyId = document.historyId;
        try {
            this.resolving = true;
            await this.documentsService.markHistoricalDocumentAsReviewed(lenderId, tpoId, documentId, historyId).toPromise();
            await this.loadList(this.params);
        } finally {
            this.resolving = false;
        }
    }

	constructor(
		private uiRouterGlobals: UIRouterGlobals,
		transitionService: TransitionService,
		stateService: StateService,
		private documentsService: ClientDocumentsService,
		public modalService: BsModalService,
        public user: UserService,
	) {
		super(transitionService, stateService);
        this.canManageDocuments = user.profile.can('MANAGE_CLIENT_DOCUMENTS');
        this.isLender = user.profile.isLender;
	}
}
