import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent, PagedListComponent } from 'commons/components/list';

import { OrdersService } from '../orders.service';
import { OrderStatusModalComponent } from '.';
import { statusesConfig } from '../../statusesConfig';

@Component({
	templateUrl: './order-details.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => OrderDetailsComponent) },
	],
	preserveWhitespaces: true,
})
export class OrderDetailsComponent extends PagedListComponent implements OnInit {
	static listName = OrderDetailsComponent.addName('orderDetails');
	listName = OrderDetailsComponent.listName;

	User: any;
	helpLink = '/help/authenticated/tpo/smc/order-details';
	bsConfig: Partial<BsDatepickerConfig> = {
		dateInputFormat: 'MM/DD/YYYY',
	};
	statusesConfig = statusesConfig;
	list: any = [];
	order: any = {};

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public userService: UserService,
		public ordersService: OrdersService,
		public modalService: BsModalService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;
	}

	ngOnInit(): void {
		const { id: orderId } = this.stateService.params;

		this.defaultFilters = {
			...this.defaultFilters,
			orderId,
		}

		super.ngOnInit();

		this.order = this.ordersService.get({ orderId });
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.ordersService.reviews(queryParams).$promise
			.then((data) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}

	editStatus(): void {
		const { id: orderId } = this.stateService.params;
		const { order } = this;
		const initialState = {
			order,
			statuses: this.ordersService.getStatus({ orderId }),
			update: (status: any): Promise<any> => (
				this.ordersService.saveStatus(
					{ orderId },
					status,
				).$promise
					.then((data) => {
						this.order = data;
					})
			),
		};
		this.modalService.show(
			OrderStatusModalComponent,
			{
				initialState,
				class: 'modal-smd modal-new',
			}
		);
	}
}
