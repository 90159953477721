<div class="gap-l" *ngIf="section">
    <div class="col-sm-5">{{sectionLabel}}</div>
    <div class="col-sm-5 text-medium">
        <span class="label label-danger-wired" *ngIf="section.expired; else notExpired">Review Required</span>
    </div>
    <div class="col-sm-2">{{(section.reviewDate | date: 'MM/dd/yyyy') || '&ndash;'}}</div>
    <div class="col-sm-5 text-medium">
        <ng-template #notExpired> <span class="label label-success-wired" >Completed</span></ng-template>
    </div>
</div>
