import { Directive, OnDestroy, OnInit } from '@angular/core';
import { isEqual, debounce, omit, omitBy, mapValues, trim, isEmpty, pick } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TransitionService, StateService } from '@uirouter/core';

import { jsonWithDate, jsonWithIntdate } from 'custom-url-params';

import { ListInterface } from './list.interface';
import { shrinkListName } from './helpers/shrink-list-name';
import { getRecord, addRecord, hasRecord, dumpRecords } from './helpers/used-names';

const getDataDecoder = (typeName) => {
	if (!typeName) {
		return null;
	}

	if (typeName === jsonWithDate.name) {
		return jsonWithDate.config.normalizeDecodeData;
	}

	if (typeName === jsonWithIntdate.name) {
		return jsonWithIntdate.config.normalizeDecodeData;
	}

	return null;
};

@Directive()
export abstract class ListComponent implements ListInterface, OnInit, OnDestroy {
	static listName;

	// TODO: think about typescript mapped types to implement same logic
	// i.e. list name should be unique in the app for some list class
	// let's stick to this approach for now,
	// since in development phase new list should trigger runtime name errors
	// and it is extremely hard to miss it
	static addName(name) {
		// NOTICE: this is refer to class itself, not entity
		const className = this.name;
		if (!name) {
			throw new Error(`name property should be not an empty string in ${className}`);
		}

		const shortName = shrinkListName(name);
		const existingRecord = getRecord(shortName);

		if (hasRecord(shortName) && existingRecord.className !== className) {
			throw new Error(`Static property listName='${name}' and shortened '${shortName}' already defined in ${existingRecord.className}. Please, add another meaningfull name`);
		}

		// if everything is ok, set new name(or set existing again)
		addRecord(shortName, { className, listName: name });

		return shortName;
	}

	static logListNames() {
		// tslint:disable-next-line
		console.log(dumpRecords());
	}

	abstract listName;

	// observable filters
	params$: BehaviorSubject<any> = new BehaviorSubject({});
	// just map from observable to static object for UI render
	params: any = {};
	noMatches: boolean = true;
	filtersApplied: boolean = false;
	defaultFilters: any = {
		sort: '',
		q: '',
	};
	globalParams: any[] = [];
	omitParams: any = ['sort'];
	// omit on reset
	omitResetParams: any = ['sort'];
	// this allow to skip some params in url
	omitLocationParams: string[] = [];
	filters: any = {};
	// if component should use location params
	useLocation: boolean = true;
	public _paramsSub: Subscription;
	// UI-Router defines it as Function
	// tslint:disable-next-line:ban-types
	public _locationDereg: Function;
	public _timer: any;

	constructor(public transitionService: TransitionService, public stateService: StateService) {
	}

	ngOnInit() {
		// prevent trigger search on each keyup
		this.setSearch = debounce(this.setSearch, 1000);

		// let params settle down before making a request
		this.loadList = debounce(this.loadList, 10);

		if (this.useLocation) {
			let amountOfLoopsPerSecond = 0;
			this._timer = setInterval(() => {
				amountOfLoopsPerSecond = 0;
			}, 1000);

			this._locationDereg = this.transitionService.onRetain({retained: this.stateService.current.name}, (transition) => {
				// In case wea re going to substate, don't cancel transition
				if (this.stateService.current.name !== transition.targetState().name()) {
					return true;
				}

				const namespacedParams = transition.targetState().params();
				const newParams = this.getClearParams(this.getCombinedParams(namespacedParams));
				const nextParams = {...this.defaultFilters, ...newParams };

				// do not allow forever loop
				if (!isEqual(nextParams, this.params$.getValue()) && amountOfLoopsPerSecond < 5) {
					amountOfLoopsPerSecond++;
					this.params$.next(nextParams);
				}
			});

			const namedParams = this.stateService.params;
			const initRawParams = {
				...this.defaultFilters,
				...this.getClearParams(this.getCombinedParams(namedParams)),
			};

			const paramsConfig = this.stateService.current.params;
			const decoder = getDataDecoder(paramsConfig && paramsConfig[this.listName] && paramsConfig[this.listName].type);

			// sometimes data comes in wrong format, so we are parsing it in place
			// most problems happens with date formats
			// 1) since it can have different structure after serialization/deserialization
			// 2) also some url can change before params parsed by router
			// so explicit parse would help with date errors
			// 3) our HistoryLogService can return encoded data from session storage
			// so here we decode it back
			const initParams = decoder ? decoder(initRawParams) : initRawParams;

			// NOTICE: previously we merged all state params here
			// so if your List rely on this hidden logic, please, move missing params to filters
			// right now only filters become observable in the List
			this.params$.next(initParams);
		} else {
			this.params$.next({...this.defaultFilters});
		}

		this._paramsSub = this.params$.subscribe((queryParams) => {
			this.filtersApplied = this.isSomeFiltersApplied(queryParams);

			this.params = {...queryParams};

			if (this.useLocation) {
				setTimeout(() => {
					const params = {
						...mapValues(this.defaultFilters, () => ('')),
						...this.getNonDefaultParams(queryParams),
					};

					const urlParams = this.getLocationParams(params);

					const resultParams = {
						...this.stateService.params,
						...urlParams,
					};

					this.stateService.transitionTo(this.stateService.current.name, resultParams, {inherit: false});
				}, 10);
			}

			this.loadList({...queryParams});
		});
	}

	isSomeFiltersApplied = (queryParams) => {
		return !isEqual(omit(queryParams, this.omitParams), omit(this.defaultFilters, this.omitParams));
	}

	getLocationParams = (params) => {
		const effectiveUrlParams = omitBy(params, (value) => (value === ''));
		const resultParams = omit(effectiveUrlParams, this.omitLocationParams, this.globalParams);

		return {
			[this.listName]: isEmpty(resultParams) ? null : resultParams,
			...this.getGlobalParams(effectiveUrlParams),
		};
	}

	ngOnDestroy() {
		this._paramsSub && this._paramsSub.unsubscribe();

		if (this.useLocation) {
			this._locationDereg && this._locationDereg();
		}

		if (this._timer) {
			clearInterval(this._timer);
		}
	}

	getCombinedParams(params) {
		return {
			...this.getGlobalParams(params),
			...params[this.listName] || {},
		}
	}

	getGlobalParams(params) {
		return pick(params, this.globalParams);
	}

	getClearParams(queryParams) {
		return omitBy(omit(queryParams, '#', '$inherit'), (value) => (!value));
	}

	getNonDefaultParams(queryParams) {
		return omitBy(omit(queryParams, '#', '$inherit'), (value, key) => (isEqual(value, this.defaultFilters[key])));
	}

	abstract loadList(queryParams: any);

	resetFilters() {
		this.params$.next({...this.params, ...omit(this.defaultFilters, this.omitResetParams)});
	}

	resetSearch() {
		const {q} = this.params$.getValue();

		if (!q) {
			return;
		}

		this.params$.next({...this.params$.getValue(), q: this.defaultFilters.q});
	}

	resetSort(): void {
		this.params$.next({...this.params$.getValue(), sort: ''});
	}

	setSearch($event) {
		const q = trim(`${$event.target.value}`);
		const currentParams = this.params$.getValue();
		if (currentParams.q === q) {
			// do not search on same input
			return;
		}

		this.params$.next({...this.params$.getValue(), q });
	}

	setFilter(filter) {
		this.params$.next({...this.params$.getValue(), ...filter});
	}

	setSort(name) {
		const {sort} = this.params$.getValue();
		const [sortName, direction] = `${sort}`.split(',');

		// if no sorting or new sort column -> set column and asc
		if (!sortName || name !== sortName) {
			this.params$.next({...this.params$.getValue(), sort: `${name},asc`});
			return;
		}

		// on second click change to direction to desc
		if (direction === 'asc') {
			this.params$.next({...this.params$.getValue(), sort: `${name},desc`});
			return;
		}

		// third click should reset sorting
		this.params$.next({...this.params$.getValue(), sort: ''});
	}

	getSortClass(name) {
		if (!this.params$) {
			return 'sortable';
		}

		const {sort} = this.params$.getValue();
		const [sortName, direction] = `${sort}`.split(',');

		if (!sortName || name !== sortName) {
			return 'sortable';
		}

		return `sortable ${direction}`;
	}
}
