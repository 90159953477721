import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';

import { NgResourceArray } from 'commons/interfaces';
import {omit, omitBy } from 'lodash';

import { TpoPartnersService } from '../partners.service';
import { UserService } from 'angularjs-providers/user.provider';
import { Partner } from '../partners.interface';
import { LenderChannel, ChannelsStatus } from '../lender-channel.interface';


@Component({
    templateUrl: './tpo-partners-details.component.html',
})
export class TpoPartnersDetailsComponent implements OnInit {
    lenderId: string;
    channelsContact: NgResourceArray<LenderChannel> = [];
    appHistory: NgResourceArray<Partner> = [];
    companyPage: NgResourceArray<Partner> = [];
    channelsStatus: ChannelsStatus[] = [];

    resolved: boolean = false;

    constructor(
        public stateService: StateService,
        private readonly userService: UserService,
        private readonly tpoPartnersService: TpoPartnersService
    ) {}

    async ngOnInit(): Promise<void> {
        this.lenderId = this.stateService.params.id;

        const queryParams: any = {};
        queryParams.channelId = this.userService.profile.organization.id;
        queryParams.lenderId = this.lenderId;

        this.resolved = false;

        [ this.companyPage, this.channelsContact, this.appHistory ] = await Promise.all([
            this.tpoPartnersService.getCompanyPage(this.getClearParams(queryParams)).$promise,
            this.tpoPartnersService.getLenderChannels(this.getClearParams(queryParams)).$promise,
            this.tpoPartnersService.getApplicationHistory(this.getClearParams(queryParams)).$promise,
        ]);

        this.channelsStatusChange();

        this.resolved = true;
    }

    channelsStatusChange(): void {
        this.channelsStatus = this.channelsContact.map((item: LenderChannel) => {
            return {
                isPendingRenewal: item.state == 'PENDING' && item.status && !!~['PR'].indexOf(item.status && item.status.id),
                isPending: item.state == 'PENDING' && item.status && !!~['P'].indexOf(item.status && item.status.id),
                isActive: item.state == 'ACTIVE' && item.status && !!~['A', 'I', 'S', 'W'].indexOf(item.status && item.status.id),
                isSuspended: item.state == 'SUSPENDED' && item.status && !!~['RS'].indexOf(item.status && item.status.id),
            }
        });
    }

    getClearParams(queryParams) {
		return omitBy(omit(queryParams, '#', '$inherit'), (value) => (!value));
	}
}
