import { Component, Input } from '@angular/core';

@Component({
    selector: 'tpo-partners-company-page',
    templateUrl: './tpo-partners-company-page.component.html',
})
export class TpoPartnersCompanyPageComponent {
    @Input() companyPage;
    @Input() stateService;
}
