import { Component, Input, OnDestroy } from '@angular/core';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ValidationErrors, Validators } from '@angular/forms';
import { PhoneValidator } from 'commons/validators';
import { RegistrationService } from 'login/registration/registration.service';
import { StateService } from '@uirouter/core';
import { trim } from 'lodash';
import {
    CompanyInformation,
    EmailCheckResult,
    RegistrationPassword,
} from 'login/registration/registration-interface.interface';

declare let window: Window;

@Component({
    templateUrl: 'registration.component.html',
})
export class RegistrationComponent implements OnDestroy {
    @Input() public recaptcha: any;
    // @Input() public recaptcha: ReCaptchaV2.ReCaptcha;
    public companyInformation: CompanyInformation;
    public onPageOne: boolean = true;
    public passwordInformation: RegistrationPassword;
    public nmlsHasError: boolean = false;
    public emailHasError: boolean = false;

    constructor(
        protected registrationService: RegistrationService,
        public stateService: StateService,
    ) {
        const captchaEl = this.getRecaptchaElement();
        if (captchaEl) {
            captchaEl.style.display = 'block';
        }
    }

    registrationForm = new RealmFormGroup({
        firstName: new RealmFormControl(
            'firstName',
            {
                label: 'First Name',
            },
            Validators.required,
        ),
        lastName: new RealmFormControl(
            'lastName',
            {
                label: 'Last Name',
            },
            Validators.required,
        ),
        email: new RealmFormControl(
            'email',
            {
                label: 'Email',
            },
            [
                Validators.required,
                Validators.email,
            ],
        ),
        phone: new RealmFormControl(
            'phone',
            {
                label: 'Phone',
            },
            [
                Validators.required,
                PhoneValidator(),
            ],
        ),
        nmlsId: new RealmFormControl(
            'nmlsId',
            {
                updateOn: 'change',
                label: 'Company NMLS ID',
            },
            Validators.required,
        ),
        confirmEmail: new RealmFormControl(
            'confirmEmail',
            {
                label: 'Confirm Your Email',
            },
            [
                Validators.required,
                Validators.email,
            ],
        ),
        companyName: new RealmFormControl(
            'companyName',
            {
                label: 'Confirm Company Name',
                updateOn: 'change',
            },
            Validators.required,
        ),
    },
        { validators: [ () => this.emailMustMatchValidator(), () => this.companyNameMustMatchValidator() ] },
    );

    async validateEmail(email) {
        const enteredEmail: string = trim(email.target.value);
        if (this.registrationForm.serverError) {
            this.registrationForm.serverError = '';
            this.emailHasError = false;
        }
        if (enteredEmail && this.registrationForm.get('email').valid) {
            try {
                const emailCheckResult: EmailCheckResult = await this.registrationService.validateEmail(enteredEmail).toPromise();
                if (!emailCheckResult.emailIsAvailable) {
                    this.registrationForm.setServerError({ message: 'Email is already in use' });
                    this.emailHasError = true;
                }
            } catch({ error: { message }, message: httpError }) {
                this.registrationForm.setServerError({ message: message });
            } finally {
                this.registrationForm.get('confirmEmail').updateValueAndValidity();
            }
        }
    }

    clearCompanyName(): void {
        this.registrationForm.controls.companyName.reset();
    }

    async validateNMLSId(rawNmlsId: string) {
        const enteredNmlsId: number = Number(rawNmlsId);
        let companyInfo: CompanyInformation = null;

        if (this.registrationForm.serverError) {
            this.registrationForm.serverError = '';
            this.nmlsHasError = false;
        }

        if (enteredNmlsId) {
            try {
                const token = await this.getCaptchaToken();
                companyInfo = await this.registrationService.validateNmlsId(enteredNmlsId, token).toPromise();
            } catch (error) {
                this.nmlsHasError = true;
                companyInfo = null;
                const message: string = (error?.error?.message || error?.message || error);
                if (!message) {
                    return;
                }
                this.registrationForm.setServerError({ message: message });
            }
        }
        this.companyInformation = companyInfo;
        this.registrationForm.get('companyName').reset();
    }

    getCaptchaToken = () => {
        return new Promise<string>((resolve, reject) => {
            this.recaptcha.ready(() => {
                const siteKey = window.env['application_re-captcha_site-key'];
                this.recaptcha.execute(siteKey, { action: 'get_nmls_details' }).then(
                    (token) => {
                        resolve(token);
                    },
                    () => {
                        reject();
                    },
                );
            })
        });
    }

    goNext() {
        this.onPageOne = false;
    }

    goBack(returnedPasswordValues: RegistrationPassword) {
        this.onPageOne = true;
        this.passwordInformation = returnedPasswordValues;
    }

    cancelRegistration() {
        this.registrationForm.reset();
        this.stateService.go('login');
    }

    companyNameMustMatchValidator(): ValidationErrors {
        if (!this.registrationForm?.controls?.companyName || !this.registrationForm?.controls?.companyName.dirty) {
            return null;
        }

        if (!this.companyInformation) {
            const validatorError: ValidationErrors = {
                error: true,
            };
            this.registrationForm.controls['companyName'].markAsTouched();
            this.registrationForm.controls['companyName'].setErrors({ error : true });

            return validatorError;
        } else {
            let validatorError: ValidationErrors;
            if (this.registrationForm.controls['companyName'].value) {
                const invalidName = this.registrationForm.controls['companyName'].value.toLowerCase() !== this.companyInformation.companyName.toLowerCase();
                if (invalidName) {
                    validatorError = { error: true };
                    this.registrationForm.controls['companyName'].markAsTouched();
                    this.registrationForm.controls['companyName'].setErrors({ error : true });
                }

                return invalidName ? validatorError : null;
            }
        }
    }

    emailMustMatchValidator(): ValidationErrors {
        if (!this.registrationForm) {
            return  null;
        } else {
            let validatorError: ValidationErrors;
            if (this.registrationForm.controls['confirmEmail'].value && this.registrationForm.controls['email'].value) {
                const invalidEmail = (this.registrationForm.controls['confirmEmail'].value !== this.registrationForm.controls['email'].value);
                if (invalidEmail) {
                    validatorError = { error: true };
                    this.registrationForm.controls['confirmEmail'].markAsTouched();
                    this.registrationForm.controls['confirmEmail'].setErrors({ error : true });
                }
                return invalidEmail ? validatorError: null ;
            }
        }
    }

    disablePaste($event) {
        $event.preventDefault();
    }

    getRecaptchaElement = (): HTMLElement => window.document.querySelector('.grecaptcha-badge');

    ngOnDestroy() {
        this.getRecaptchaElement().style.display = 'none';
    }
}
