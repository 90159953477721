import { Component, Input, Output, HostListener, forwardRef, EventEmitter, ɵmarkDirty } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { pick, defaults } from 'lodash';

const DefaultValues = {true: true, false: false};
const ComponentDefinition = {
	selector: 'toggle',
};

export const TOGGLE_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => ToggleComponent),
	multi: true,
};

const noop = () => {
};

@Component({
	templateUrl: './toggle.component.html',
	selector: `${ComponentDefinition.selector}[ngModel],${ComponentDefinition.selector}[formControl],${ComponentDefinition.selector}[formControlName],${ComponentDefinition.selector}[value]`,
	providers: [TOGGLE_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class ToggleComponent implements ControlValueAccessor {

	@Input('values')
	set _values(inputValue: any) {
		const newValue = pick(inputValue, ['true', 'false']);
		this.values = defaults(newValue, DefaultValues);
	}

	@Input('value')
	// get accessor
	get value(): any {
		return this.innerValue;
	}

	// set accessor including call the onchange callback
	set value(v: any) {
		if (v !== this.innerValue) {
			this.writeValue(v);
			this.onChangeCallback(v);
		}
	}
	public values: {true, false} = DefaultValues;

	@Input() loading?: boolean = false;
	@Input() disabled?: boolean = false;

	@Output('toggle') toggle: EventEmitter<any> = new EventEmitter();

	state: boolean = null;
	// The internal data model
	public innerValue: any = '';

	// Placeholders for the callbacks which are later provided by the Control Value Accessor
	public onTouchedCallback: () => void = noop;
	public onChangeCallback: (_: any) => void = noop;

	@HostListener('click', ['$event']) onClick($event: Event) {
		if (this.disabled || this.loading) { return; }
		if (this.toggle.observers.length) {
			this.toggle.next($event);
		} else {
			this.toggleValue();
		}
	}

	toggleValue() {
		this.state = ! this.state;
		this.value = this.values[String(this.state)];
	}

	// Set touched on blur
	onBlur() {
		this.onTouchedCallback();
	}

	// From ControlValueAccessor interface
	writeValue(value: any) {
		if (value !== this.innerValue) {
			this.innerValue = value;
			this.setState(value);
            ɵmarkDirty(this);
		}
	}

	setState(value: any) {
		if (this.values.true === value) {
			this.state = true;
		} else if (this.values.false === value) {
			this.state = false;
		} else {
			this.state = null;
		}
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}
}
