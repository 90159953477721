import { UrlPipe } from './url.pipe';
import { AddressPipe } from './address.pipe';
import { TelPipe } from './tel.pipe';
import { FilterPipe } from './filter.pipe';
import { OrderByPipe } from './order-by.pipe';
import { ArrayFilterPipe } from './array-filter.pipe';
import { HumanSizePipe } from './human-size.pipe';
import { TruncatePipe } from './truncate.pipe';
import { NumbersRangePipe } from './numbers-range.pipe';
import { BulletpointsPipe } from './bulletpoints.pipe';
import { UrlSanitizer } from './url-sanitizer.pipe';
import { SanitizeHtml } from './sanitize-html.pipe';
import { ArrayUniqueByPipe } from './array-uniq-by.pipe';
import { HrefReadyPipe } from './hrefReady.pipe';
import { TextToUrlPipe } from './textToUrl.pipe';
import { EntityNamesPipe } from 'commons/pipes/entity-names.pipe';

export default [
	UrlPipe,
	AddressPipe,
	TelPipe,
	FilterPipe,
	OrderByPipe,
	ArrayFilterPipe,
	HumanSizePipe,
	TruncatePipe,
	NumbersRangePipe,
	BulletpointsPipe,
	UrlSanitizer,
	SanitizeHtml,
	ArrayUniqueByPipe,
	HrefReadyPipe,
	TextToUrlPipe,
    EntityNamesPipe,
];
