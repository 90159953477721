import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserProfile } from 'angularjs-providers/user.provider';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { PasswordPolicyService } from 'shared/password-policy/password-policy.service';
import { PasswordService } from 'shared/services/password.service';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { PasswordPolicy } from 'shared/models/PasswordPolicy';


@Component({
    templateUrl: './change-own-password-modal.component.html',
})
export class ChangeOwnPasswordModalComponent implements OnInit, OnDestroy {
    public passwordPolicy: PasswordPolicy = {};
    public resolving = false;
    @Input() public userProfile: UserProfile;
    private userProfileUnsubscribe$: Subject<void> = new Subject();

    public readonly currentPassword: RealmFormControl = new RealmFormControl(
        'currentPassword',
        { label: 'Enter Current Password' },
        Validators.compose([
            Validators.required,
        ]),
    );
    public readonly newPassword: RealmFormControl = new RealmFormControl(
        'newPassword',
        { label: 'Enter New Password' },
        Validators.compose([
            Validators.required,
            Validators.maxLength(20),
			ChangeOwnPasswordModalComponent.passwordsMatch,
        ]),
    );
    public readonly passwordConfirm: RealmFormControl = new RealmFormControl(
        'passwordConfirm',
        { label: 'Re-enter New Password' },
        Validators.compose([
            Validators.required,
            Validators.maxLength(20),
            ChangeOwnPasswordModalComponent.passwordsMatch,
        ]),
    );
    public readonly passwordForm: RealmFormGroup = new RealmFormGroup({
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
        passwordConfirm: this.passwordConfirm,
    });

    public isUpdatingPassword: boolean = false;

    constructor(
        private readonly passwordPolicyService: PasswordPolicyService,
        private readonly passwordService: PasswordService,
        public readonly modalRef: BsModalRef,
        private _cd: ChangeDetectorRef,
    ) {
    }

    // Override
    public ngOnInit(): void {
        this.passwordPolicyService.get((passwordPolicy: PasswordPolicy) => this.passwordPolicyResolved(passwordPolicy));
    }

    // Override
    public ngOnDestroy(): void {
        this.userProfileUnsubscribe$.next();
        this.userProfileUnsubscribe$.complete();
    }

    public async save(): Promise<void> {
        this.isUpdatingPassword = true;

        const formData = {
            currentPassword: this.passwordForm.get('currentPassword').value,
            newPassword: this.passwordForm.get('newPassword').value,
        };

        await this.passwordService.update(
            { id: this.userProfile.id },
            formData,
            () => {
                this.isUpdatingPassword = false;
                this.hide();
            },
            (response) => {
                this.isUpdatingPassword = false;

                this.passwordForm.setServerError({
                    message: response?.data?.message,
                });
            },
        ).$promise;
        this._cd.markForCheck();
    }

    public hide(): void {
        this.modalRef.hide();
    }

    private passwordPolicyResolved(passwordPolicy: PasswordPolicy): void {
        this.passwordPolicy = passwordPolicy;
        this._cd.markForCheck();
    }

    public static passwordsMatch(control: AbstractControl): ValidationErrors | null {
        if (control.parent) {
            const newPasswordControl: AbstractControl = control.parent.get('newPassword');
            const passwordConfirmControl: AbstractControl = control.parent.get('passwordConfirm');

            if (newPasswordControl?.value && passwordConfirmControl?.value) {
                if (newPasswordControl.value !== passwordConfirmControl.value) {
                    const message: string = 'Passwords do not match';

                    return {
                        newPassword: {
                            value: newPasswordControl.value,
                            message,
                        },
                        passwordConfirm: {
                            value: passwordConfirmControl.value,
                            message,
                        },
                    };
                }
            }
        }

        return null;
    }
}
