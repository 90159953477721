import { Component } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { RealmStateDeclaration } from 'routes/sharedRoutes';

@Component({
    selector: 'global-footer',
    templateUrl: './footer.component.html',
})
export class FooterComponent {
    isFree = true;
    constructor(
        { success$ }: UIRouterGlobals,
    ) {
        // no need to unsubscribe since it dies with app
        success$.subscribe((t) => {
            const { free = false } = t.targetState().state() as RealmStateDeclaration;
            this.isFree = free;
        });
    }
}
