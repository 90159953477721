<form [formGroup]="investorInformationForm" class="col-md-12 remote-resource" (onValidSubmit)="doSave()" [class.resolved]="!resolving">
    <div class="row-fluid">
        <div class="form-group col-md-a" has-error>
            <div [isEditable]="isEditing">
                <div view class="control-value">{{item.investorName || '&ndash;'}}</div>
                <input edit type="text" maxlength="99" name="investorName" formControlName="investorName" class="form-control"/>
            </div>
        </div>
        <div class="form-group col-md-a" has-error>
            <div [isEditable]="isEditing">
                <div view class="control-value">{{ item.phone || '&ndash;'| tel }}</div>
                <input edit phoneFormatter type="text" name="phone" formControlName="phone" class="form-control" />
            </div>
        </div>
        <div class="form-group col-md-a" has-error>
            <div [isEditable]="isEditing">
                <div view class="control-value">{{item.contactName || '&ndash;'}}</div>
                <input edit type="text" maxlength="100" name="contactName" formControlName="contactName" class="form-control" />
            </div>
        </div>
        <div class="col-f-280">
            <div class="row-fluid">
                <div class="col-md-a">
                    <div [isEditable]="isEditing" class="form-group" has-error>
                        <div view class="control-value">
                            {{item.relationshipStartDate ? (item.relationshipStartDate | date:labelDateFormat) : '&ndash;'}}
                        </div>
                        <input edit
                               formControlName="relationshipStartDate"
                               class="form-control"
                               bsDatepicker
                               [bsConfig]="{ dateInputFormat: dateFormat }"
                        />
                    </div>
                </div>
                <div class="col-md-a">
                    <div [isEditable]="isEditing" class="form-group" has-error>
                        <div view class="control-value">
                            {{item.relationshipEndDate ? (item.relationshipEndDate | date:labelDateFormat) : 'Present'}}
                        </div>
                        <input edit
                               formControlName="relationshipEndDate"
                               class="form-control"
                               bsDatepicker
                               [bsConfig]="{ dateInputFormat: dateFormat }"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-f-100 text-right va-m button-holder">
            <ng-container [ngSwitch]="isEditing">
                <ng-container *ngSwitchDefault>
                    <a *ngIf="userCanAddEdit" class="btn btn-icon btn-tertiary" (click)="doEdit()"><span class="glyphicon glyphicon-pencil"></span></a>
                </ng-container>
                <ng-container *ngSwitchCase="true">
                    <button class="btn btn-icon btn-tertiary" type="submit"><span class="glyphicon glyphicon-ok"></span></button>
                    <a class="btn btn-icon btn-tertiary" (click)="doCancel()"><span class="glyphicon glyphicon-remove"></span></a>
                </ng-container>
            </ng-container>
        </div>
    </div>
</form>
