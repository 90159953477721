import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ForgotEntityType, RegistrationService } from 'login/registration/registration.service';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

@Component({
    templateUrl: './forgot-modal.component.html',
})
export class ForgotModalComponent {
    entity: ForgotEntityType;
    resolved = true;

    get entityName(): string {
        if (this.entity == 'password')
            return 'Password';

        if (this.entity == 'username')
            return 'User Name';

        return '';
    }

    form = new RealmFormGroup({
            email: new RealmFormControl(
                'email',
                {
                    label: 'Email',
                },
                [
                    Validators.required,
                    Validators.email,
                ],
            ),
        },
    );

    constructor(
        readonly modalRef: BsModalRef,
        private readonly registrationService: RegistrationService,
        private readonly notificationsService: GlobalNotificationsService,
    ) {
    }

    async save() {
        this.resolved = false;
        try {
            await this.registrationService.forgot(this.entity, this.form.value).toPromise();
            this.notificationsService.add({
                type: GlobalNotificationType.POSITIVE,
                message: 'Email successfully sent.',
            });
            this.modalRef.hide();
        } catch ({ error: { message } }) {
            this.notificationsService.add({
                type: GlobalNotificationType.ERROR,
                message,
            });
    }
        this.resolved = true;
    }
}
