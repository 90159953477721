import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { UserService } from 'angularjs-providers/user.provider';
import { UnderwritingInterface } from 'tpo/account/underwriting/underwriting.interface';
import { UnderwritingService } from 'tpo/account/underwriting/underwriting.service';
import { SectionReviewComponent } from 'tpo/account/section-review/section-review.component';
import { PROFILE_SECTION } from 'tpo/account/section-review/section-review.service';


@Component({
    selector: 'underwriting',
    templateUrl: 'underwriting.component.html',
})
export class UnderwritingComponent implements OnInit {
    @Input() public lenderInfo: AccountInformation;
    @ViewChild(SectionReviewComponent, { static: true }) sectionReview: SectionReviewComponent;
    public readonly sectionType = PROFILE_SECTION.UNDERWRITING;
    public underwritingList: UnderwritingInterface[];

    public resolving: boolean;
    public userCanAddEdit: boolean;
    public listContainsUnderwritingServices: boolean;
    public listContainsProcessingServices: boolean;

    constructor(
        private underwritingService: UnderwritingService,
        private readonly userService: UserService,

    ) {
        this.userCanAddEdit = this.userService.profile.isTpo;
    }

    async ngOnInit(): Promise<void> {
        this.underwritingList = await this.underwritingService.getServices(this.lenderInfo.identifier.tpoId).toPromise();
        this.resolving = true;
    }

    addService(type: string) {
        this.underwritingList.push({
            id: null,
            phone: null,
            relationshipStartDate: null,
            relationshipEndDate: null,
            companyName: null,
            contactName: null,
            email: null,
            type: type,
        });
    }

    cancelNewService(service: UnderwritingInterface): void {
        if (!service.id) {
            const serviceIndex: number = this.underwritingList.indexOf(service);
            if (serviceIndex != -1) {
                this.underwritingList.splice(serviceIndex, 1);
            }
        }
    }

    filterByServiceType(type: string) {
        const filteredList = this.underwritingList?.filter( service => service.type == type );
        if (type == 'processing') {
            this.listContainsProcessingServices = (filteredList?.length > 0)
        }
        if (type == 'underwriting') {
            this.listContainsUnderwritingServices = (filteredList?.length > 0)
        }
        return filteredList;
    }
}

