import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { ComponentsModule } from 'commons/components/components.module';

import { ExampleComponentsComponent } from './__components.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		ComponentsModule,
	],
	declarations: [
		ExampleComponentsComponent,
	],
	entryComponents: [
		ExampleComponentsComponent,
	],
})
export class ExampleComponentsModule { }
