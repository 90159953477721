import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Optional } from '@angular/core';
import { UIRouterGlobals, UIRouter } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { PagedData } from 'commons/services/http';
import { ListComponent } from 'commons/components/list';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ConfirmModalComponent } from 'commons/components/modals';

import { TpoContactDetailsComponent } from 'tpo/people/contacts/details/tpo-contact-details.component';
import { InvestorContactDetailsComponent } from 'lender/new-contacts/details/investor-contact-details.component';
import { CcmContactDetailsComponent } from 'comergence/new-contacts/details/ccm-contact-details.component';
import { NewContactSocialAccountsService } from '../social-accounts.service';

import { FullTpoContact } from 'shared/new-contacts/contacts.interface';
import { SocialAccountLink } from 'tpo/social-accounts/company/link.class';
import { SharedContactDetailsComponent } from 'shared/new-contacts/shared-contact-details.component';

@Component({
    templateUrl: './removed-social-accounts.component.html',
    viewProviders: [
        { provide: NewPagedListComponent, useExisting: forwardRef(() => NewContactRemovedSocialAccountsComponent) },
        // FIXME: required by SearchListComponent
        { provide: ListComponent, useExisting: forwardRef(() => NewContactRemovedSocialAccountsComponent) },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewContactRemovedSocialAccountsComponent extends NewPagedListComponent<SocialAccountLink> {
    static listName = 'removedSocialAccountsComponent';

    globalParams;
    user: UserProfile;
    socialMediaServiceParams: {
        mediaType: string;
        entityIdType: string;
        entityId: string | number;
    };

    canRestore: boolean;
    modalRef: BsModalRef
    private details: SharedContactDetailsComponent;

    constructor(
        private cd: ChangeDetectorRef,
        router: UIRouter,
        { params }: UIRouterGlobals,
        { profile }: UserService,
        @Optional() tpoDetails: TpoContactDetailsComponent,
        @Optional() investorDetails: InvestorContactDetailsComponent,
        @Optional() ccmDetails: CcmContactDetailsComponent,
        private contactSocialAccountsService: NewContactSocialAccountsService,
        private modalService: BsModalService,
    ) {
        super(router);

        this.globalParams = params;
        this.user = profile;
        this.details = ccmDetails || investorDetails || tpoDetails;
    }

    ngOnInit(): void {
        this.details.contact.pipe(
            takeUntil(this.destroy$),
        ).subscribe(({ contactId }: FullTpoContact) => {
            const tpoId = this.user.isTpo ? this.user.organization.id : this.globalParams.id;
            const { contactId: currentUserContactId } = this.user;
            const isCurrentUser = currentUserContactId === Number(contactId);

            this.canRestore = (
                isCurrentUser
                || this.user.can('CCM_EDIT_SOCIAL_MEDIA_CONTACTS')
            );

            Object.assign(
                this.defaultParams,
                {
                    tpoId,
                    contactId,
                    includePublisherStatus: this.user.isTpo,
                    includePublisherCount: this.user.isTpo || this.user.isComergence,
                },
            );

            this.socialMediaServiceParams = {
                mediaType: 'social-media',
                entityIdType: 'CONTACTID',
                entityId: contactId,
            };

            super.ngOnInit();
            this.cd.markForCheck();
        });
    }

    loadList(params: ListParams): Observable<PagedData<SocialAccountLink[]>> {
        return this.contactSocialAccountsService.getRemovedSocialAccounts(params);
    }

    restore(link: SocialAccountLink): void {
        const initialState = {
            title: 'Account Restore',
            confirmText: 'Confirm',
            cancelText: 'Cancel',
            message: 'You are about to restore a media account. Are you sure you want to proceed?',
            onConfirm: async (): Promise<void> => {
                this.modalRef.content.resolving = true;
                try {
                    await this.contactSocialAccountsService.restoreLink({
                        ...this.socialMediaServiceParams,
                        mediaLinkId: link.data.linkId,
                    }).toPromise();
                    this.updateList();
                    this.modalRef.hide();
                } catch ({ data }) {
                    this.modalRef.content.errorText = data;
                }
                this.modalRef.content.resolving = false;
                this.cd.detectChanges();
            },
        };

        this.modalRef = this.modalService.show(ConfirmModalComponent, {
            initialState,
            class: 'modal-smd modal-new modal-restore-link',
        });
    }
}
