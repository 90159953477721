import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { TwoFactorAuthentication } from './2FA.component';
import { TwoFactorAuthenticationService } from './2FA.service';

@NgModule({
	imports: [
		CommonModule,
		DirectivesModule,
		ComponentsModule,
		ReactiveFormsModule,
	],
	declarations: [
		TwoFactorAuthentication,
	],
	entryComponents: [
		TwoFactorAuthentication,
	],
	providers: [
		TwoFactorAuthenticationService,
	],
})
export class TwoFactorAuthenticationModule {}
