import { Injectable, Injector } from '@angular/core';

import { UserService } from '../../angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class CompanyPageService {
	resource: any;
    setLogoResource: any;

	constructor(public injector: Injector, public User: UserService) {
        const $injector = injector.get('$injector');
		const $resource = $injector.get('$resource');
		const MemoResource = $injector.get('MemoResource');

		const path = `${apiPath}/lenders/${User.profile.organization.id}/company-page-info`;
		this.resource = $resource(path, {}, {
			get: {method: 'get'},
			save: {method: 'put'},
		});
        this.setLogoResource = MemoResource(
            `${apiPath}/lenders/:lenderId/company-page-info`,
            {
                lenderId: User.profile.organization.id,
            },
            {
				setLogo: {
                    method: 'put',
                    url: `${apiPath}/lenders/:lenderId/logo/jpg`,
                    // transformRequest: function(data) { return data; },
                },
			});
	}

	get = (...args) => this.resource.get(...args);
	save = (...args) => this.resource.save(...args);
	setLogo = (...args) => this.setLogoResource.setLogo(...args);
}
