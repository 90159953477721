<div class="audit-information remote-resource" [class.resolved]="review.$resolved">
	<div>
		<div class="alert alert-warning text-center" role="alert" *ngIf="review.$resolved && review.reviewStatus != 'C' && User.isTpo">
			Comergence will notify you when this audit is ready for your review.
		</div>
	</div>
	<div class="rows-striped">
		<div class="row-fluid">
			<div class="col-md-5">
				<div class="form-group row">
					<label class="col-sm-3 control-label">Name</label>
					<div class="col-sm-9">
						<span class="control-value">
							<contact-link [data]="contact"></contact-link>
						</span>
					</div>
				</div>
			</div>
			<div class="col-md-5">
				<div class="form-group row">
					<label class="col-sm-4 control-label">Review Status</label>
					<div class="col-sm-8">
						<span class="control-value">
							<span class="text-bigger" *ngIf="review.$resolved">
								<span class="label" [ngClass]="'label-' + reviewStatuses[review.reviewStatus]?.color">
									{{reviewStatuses[review.reviewStatus]?.title}}
								</span>
							</span>
							<span class="text-muted">{{!review.reviewStatusDate ? '&ndash;' : review.reviewStatusDate | date : 'MM/dd/yyyy'}}</span>&nbsp;&nbsp;
							<a *ngIf="User.isComergence && review.reviewStatus != 'C' && review.auditStatus?.condition != 'C'" (click)="editReviewStatus()">Edit</a>
						</span>
					</div>
				</div>
			</div>
			<div class="col-md-1"></div>
		</div>
		<div class="row-fluid">
			<div class="col-md-5">
				<div class="form-group row">
					<label class="col-sm-3 control-label">NMLS ID</label>
					<div class="col-sm-9">
						<span class="control-value">
							<a *ngIf="User.isTpo" uiSref="account.licenses.nmls.:nmlsId" [uiParams]="{nmlsId:review.nmlsId}" target="_blank">{{review.nmlsId}}</a>
							<a *ngIf="User.isComergence" uiSref="tpo.:id.account.licenses.nmls.:nmlsId" [uiParams]="{nmlsId:review.nmlsId, id: review.tpoId}" target="_blank">{{review.nmlsId}}</a>
							<span *ngIf="!review.nmlsId">&ndash;</span>
						</span>
					</div>
				</div>
			</div>
			<div class="col-md-5">
				<div class="form-group row">
					<label class="col-sm-4 control-label">Audit Status</label>
					<div class="col-sm-8">
						<span class="control-value">
							{{review.auditStatus?.status}}
							<span class="text-muted">{{review.auditStatusDate | date : 'MM/dd/yyyy'}}</span>
							&nbsp;&nbsp;<a *ngIf="!User.isComergence && (review.auditStatus?.condition != 'C') && User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE')" (click)="editAuditStatus()">Edit</a>
						</span>
					</div>
				</div>
			</div>
			<div class="col-md-1"></div>
		</div>
		<div class="row-fluid">
			<div class="col-md-5">
				<div class="form-group row">
					<label class="col-sm-3 control-label">Order ID</label>
					<div class="col-sm-9">
						<span class="control-value">
							<a uiSref="social-compliance.orders.:id" [uiParams]="{id: review.orderId}" target="_blank">{{review.orderId}}</a>
						</span>
					</div>
				</div>
			</div>
			<div class="col-md-5">
				<div class="form-group row">
					<label class="col-sm-4 control-label">Assigned</label>
					<div class="col-sm-8">
						<span class="control-value">
							{{review.assigned?.name}} &nbsp;&nbsp;
							<a *ngIf="canEditAssignee" (click)="editReviewAssigned()">Edit</a>
						</span>
					</div>
				</div>
			</div>
			<div class="col-md-1"></div>
		</div>
	</div>

	<section *ngIf="review.$resolved">
		<audit-findings [review]="review"></audit-findings>
	</section>
</div>

<audit-supporting-documents [review]="review"></audit-supporting-documents>

<section class="remote-resource" [class.resolved]="commentsComponent.resolved">
	<h3>Comments</h3>
	<comments-filter #switcher
					 [isMobileUser]="review.isMobileUser"
					 (onChange)="commentsComponent.loadList(commentsComponent.params)">
		<comments #commentsComponent
				  [resource]="getCommentResource(switcher.activeSwitcher?.name)"
				  [showLoader]="false"
				  [entityName]="switcher.activeSwitcher?.name"
				  [permissions]="{ canAdd: User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE')
									|| (User.isComergence && User.can('CCM_SMC_VIEW_ALL')) }"
				  [addForm]="commentsForm">

			<ng-template #formTemplate
						 let-addForm="addForm"
						 let-add="methods.add"
						 let-entityName="entityName"
						 let-editing="editing"
						 let-submitted="submitted"
						 let-setEditMode="methods.setEditMode">
				<form [formGroup]="addForm" id="addCommentForm" class="add-comment-form"
					  (onValidSubmit)="add()" autocomplete="off" role="presentation">
					<div class="mb15 remote-resource" [class.resolved]="!submitted">
						<div class="form-group full-width">
							<textarea class="form-control no-resize"
								formControlName="comment"
								rows="{{editing ? 5 : 1}}"
								maxlength="2000"
								placeholder="Add your {{entityName | lowercase}} here"
								(focus)="setEditMode(true)">
							</textarea>
						</div>

						<div class="btn-group-lg" *ngIf="editing">
							<button type="submit" class="btn btn-primary"
									[disabled]="submitted || !addForm.value.comment">
								Add {{entityName | titlecase}}
							</button>
							<button type="button" class="btn btn-default" (click)="setEditMode(false)">
								Cancel
							</button>
						</div>
					</div>
				</form>
			</ng-template>

			<ng-template #commentTemplate let-comment="comment" let-entityName="entityName">
				<div>
					<div class="horizontal-flex-group">
						<div class="full-width">
							<b *ngIf="commentsComponent.resolved">
								{{entityName === 'message' ? comment.createdBy.fullName : comment.createdBy}}
							</b>
							<i *ngIf="entityName === 'message' && comment.createdBy.isAccountOwner"
							   class="owner-icon c-icon c-icon-profile-image"
							   tooltip="Account Owner">
							</i>
						</div>
						<div class="text-light">
							{{comment.createdDate | date:'MM/dd/yyyy HH:mm'}}
						</div>
					</div>
					<p class="comment">{{comment[entityName]}}</p>
				</div>
			</ng-template>

		</comments>
	</comments-filter>
</section>
