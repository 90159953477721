import { Component, ɵmarkDirty } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';
import { HistoryLogService } from 'angularjs-providers/history-log-service.provider';

import { ReviewsService } from 'shared/reviews/reviews.service';
import { ContactService } from 'shared/contact/contact.service';
import { FindingsService } from 'shared/findings/findings.service';

import { NewFindingComponent } from '../common';

@Component({
	templateUrl: '../common/new-finding.component.html',
})
export class NewContactFindingComponent extends NewFindingComponent {
	constructor(
		public sanitizer: DomSanitizer,
		public stateService: StateService,
		public historyLogService: HistoryLogService,
		public userService: UserService,
		public reviewsService: ReviewsService,
		public contactService: ContactService,
		public findingsService: FindingsService,
	) {
		super(
			sanitizer,
			stateService,
			historyLogService,
			userService,
			reviewsService,
			contactService,
			findingsService,
		);
	}

	get params() {
		const { contactId } = this.stateService.params;
		return {
			contactId,
		};
	}

	async getContact() {
		return this.contactService.contact.get(
			this.params,
		).$promise;
	}

	async init() {
		this.resolved = false;

		const contact = await this.getContact();
		this.contactName = contact.fullName;
		this.mediaLinks = await this.getMediaLinks(contact);

		this.resolved = true;
        ɵmarkDirty(this);
	}

	create(finding): Promise<any> {
		return this.findingsService.finding.createFromContact(
			this.params,
			finding,
		).$promise;
	}

	cancel() {
		// find a state that does not contain findingId and is not fullPage
		const backState = this.historyLogService.findBackState([ '**.new-finding.**' ], false);

        if (backState) {
            return this.historyLogService.goBackTo(backState);
        }

		window.history.back();
	}
}
