import { Component, forwardRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { TransitionService, StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';
import { PagedListComponent } from 'commons/components/list/paged-list.component';
import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';

import { CampaignsResourceService } from '../../campaigns-resource.service';

interface Template {
	id: number;
	summary: string;
	tags: string[];
	updatedBy: string;
	updatedDate: number;
}

@Component({
	templateUrl: './campaign-templates.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => CampaignTemplatesComponent) },
	],
})
export class CampaignTemplatesComponent extends PagedListComponent {
	static listName = CampaignTemplatesComponent.addName('campaignTemplates');
	listName = CampaignTemplatesComponent.listName;

	canManageCampaigns: boolean;
	canManageTemplates: boolean;
	templates: any = []; // Array<Template> resource
	addTemplate: any = {};
	removeTemplate: any = {};

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public user: UserService,
		public campaignsResource: CampaignsResourceService,
	) {

		super(transitionService, stateService);

		this.canManageCampaigns = user.profile.can('TPO_PB_MANAGE_CAMPAIGNS');
		this.canManageTemplates = user.profile.can('TPO_PB_MANAGE_TEMPLATES');

		const { campaignId } = stateService.params;
		const params = { campaignId };

		// Templates list
		this.defaultFilters = {
			...this.defaultFilters,
			campaignId,
			size: 25,
		};

		this.omitParams.push('campaignId');

		// deep methods will have another this
		const { refreshList } = this;

		// Add templates
		this.addTemplate = {
			show: false,
			resolved: true,
			templates: [], // Array<Template> resource
			form: new RealmFormGroup({
				templateIds: new RealmFormControl(
					'templateIds',
					{
						label: 'templates',
					},
					Validators.required,
				),
			}),
			submit() {
				this.resolved = false;
				campaignsResource.campaignTemplates.add(
					params,
					this.form.getRawValue().templateIds,
				).$promise
					.then(
						() => refreshList().then(
							() => this.toggle(),
						),
						({ data }) => this.form.setServerError(data),
					)
					.finally(
						() => this.resolved = true,
					);
			},
			toggle() {
				this.show = !this.show;
				if (this.show) {
					this.templates.$resolved = false;
					// do this way to trigger ng-select change detection
					campaignsResource.availableTemplates.get(params).$promise
						.then((data) => this.templates = data);
				} else {
					this.form.reset();
				}
			},
			new() {
				stateService.go('social-compliance.publisher.library.templates.new', { campaignId });
			},
		};

		// Remove template
		this.removeTemplate = {
			resolved: true,
			reset() {
				Object.assign(this, {
					id: undefined,
					allowed: undefined,
					message: undefined,
				});
			},
			form: new RealmFormGroup({}),
			errorHandler(data) {
				this.form.setServerError(data);
			},
			request(templateId) {
				this.id = templateId;
				this.resolved = false;
				campaignsResource.campaignTemplates.removalRequest({
					...params,
					templateId,
				}).$promise
					.then(
						({ allowed, message }) => Object.assign(this, { allowed, message }),
						({ data }) => this.errorHandler(data),
					)
					.finally(
						() => this.resolved = true,
					);
			},
			submit() {
				this.resolved = false;
				campaignsResource.campaignTemplates.remove({
					...params,
					templateId: this.id,
				}).$promise
					.then(
						() => refreshList().then(
							() => this.reset(),
						),
						({ data }) => this.errorHandler(data),
					)
					.finally(
						() => this.resolved = true,
					);
			},
		};
	}

	loadList(queryParams) {
		this.templates.$resolved = false;
		return this.campaignsResource.campaignTemplates.get(
			this.getClearParams(queryParams),
		).$promise
			.then((data: Template[]) => {
				this.templates = data;
				this.noMatches = !data.length && (this.filtersApplied || queryParams.q);
			});
	}

	// list refresh workaround
	public refreshList = () => (
		this.loadList(this.params$.getValue())
	)
}
