import { Directive, OnInit } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';

import { NgResourceArray } from 'commons/interfaces';
import { PagedListComponent } from 'commons/components/list';

import { TpoPartnersService } from '../partners.service';
import { UserService } from 'angularjs-providers/user.provider';
import { Partner } from '../partners.interface';


@Directive()
export abstract class TpoPartnersListComponent extends PagedListComponent implements OnInit {
	static listName = TpoPartnersListComponent.addName('TpoPartnersListComponent');
	listName = TpoPartnersListComponent.listName;
	list: NgResourceArray<Partner> = [];

    protected abstract type: ('all' | 'my');

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		private readonly userService: UserService,
		private readonly tpoPartnersService: TpoPartnersService
	) {
		super(transitionService, stateService);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	setFilter(filters, resetPage: boolean = true): void {
		const pageParams = resetPage ? {
			page: 0,
			q: filters.target.value,
		} : {};
		this.params$.next({ ...this.params$.getValue(), ...pageParams });
	}

	async loadList(queryParams) {
        if (this.type === 'my') {
            queryParams.tpoId = this.userService.profile.organization.id;
        }

		this.list.$resolved = false;
		try {
			this.list = await this.tpoPartnersService.getLenders(
				this.getClearParams(queryParams),
			).$promise;
		} catch (e) {}
		this.list.$resolved = true;
	}
}
