import { TransitionService, StateService } from '@uirouter/core';

import { PagedListComponent } from 'commons/components/list';
import { NgResourceArray } from 'commons/interfaces';

import { TasksService, Remediation } from '../tasks.service';

export abstract class TaskListBaseComponent extends PagedListComponent {
	static listName = TaskListBaseComponent.addName('compliance-task-list');
	listName = TaskListBaseComponent.listName;

	list: NgResourceArray<Remediation> = [];

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		public tasksService: TasksService,
	) {
		super(transitionService, stateService);
	}

	loadList(params) {
		this.list.$resolved = false;
		return this.tasksService.list(
			this.getClearParams(params),
		).$promise
			.then((data) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}
}
