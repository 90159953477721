import { Validators } from '@angular/forms';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import { MaxLengthValidator, PhoneValidator } from 'commons/validators';

const LenderUserDetailsForm = new RealmFormGroup({
	userName: new RealmFormControl(
		'userName',
		{label: 'Username'},
		Validators.compose([ Validators.required, MaxLengthValidator(100) ]),
	),
	systemRoleId: new RealmFormControl(
		'systemRoleId',
		{label: 'System Role'},
		Validators.required,
	),
	firstName: new RealmFormControl(
		'firstName',
		{label: 'First Name'},
		Validators.compose([ Validators.required, MaxLengthValidator(50) ]),
	),
	lastName: new RealmFormControl(
		'lastName',
		{label: 'Last Name'},
		Validators.compose([ Validators.required, MaxLengthValidator(50) ]),
	),
	email: new RealmFormControl(
		'email',
		{label: 'Email'},
		Validators.compose([ Validators.required, MaxLengthValidator(100), Validators.email ]),
	),
	phone: new RealmFormControl(
		'phone',
		{label: 'Phone'},
		Validators.compose([ Validators.required, MaxLengthValidator(10), PhoneValidator() ]),
	),
	address1: new RealmFormControl(
		'address1',
		{label: 'Address 1'},
		Validators.compose([ Validators.required, MaxLengthValidator(100) ]),
	),
	address2: new RealmFormControl(
		'address2',
		{label: 'Address 2'},
		MaxLengthValidator(100),
	),
	city: new RealmFormControl(
		'city',
		{label: 'City'},
		Validators.compose([ Validators.required, MaxLengthValidator(100) ]),
	),
	state: new RealmFormControl(
		'state',
		{label: 'State'},
		Validators.required,
	),
	zip: new RealmFormControl(
		'city',
		{label: 'Zip'},
		Validators.compose([ Validators.required, MaxLengthValidator(20) ]),
	),
	reportsToUserId: new RealmFormControl(
		'reportsToUserId',
		{label: 'Reports To'},
	),
	reportingGroups: new RealmFormControl(
        'reportingGroups', { label: 'Report Access' }
	),
	isReportingManager: new RealmFormControl(
		'isReportingManager',
		{label: 'Reporting Manager'},
	),
	isAccountAssigned: new RealmFormControl(
		'isAccountAssigned',
		{label: 'Account Assignment'},
	),
	isFullVisibilityAllowed: new RealmFormControl(
		'isFullVisibilityAllowed',
		{label: 'Allow Full Visibility'},
	),
});

export { LenderUserDetailsForm };
