import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import {
	ListingGroupImageComponent,
	CompanyListingGroupsComponent,
	CompanyListingGroupDetailsComponent,
	CompanyListingGroupDetailsOverviewComponent,
	ListingGroupNmlsLocationsComponent,
	ListingGroupListingsComponent,
	ListingGroupListingAddComponent,
	MoveListingModalComponent,
	RenameListingGroupModalComponent,
	ListingLinkConfirmModalComponent,
	CompanyListingGroupDetailsSettingsComponent,
	CompanyListingGroupAlertsComponent,
	CompanyListingGroupOpenAlertsComponent,
	CompanyListingGroupResolvedAlertsComponent,
	ListingGroupListingDetailsComponent,
	CompanyAlertedListingsComponent,
} from './';

const components = [
	CompanyListingGroupsComponent,
	CompanyListingGroupDetailsComponent,
	CompanyListingGroupDetailsOverviewComponent,
	CompanyListingGroupDetailsSettingsComponent,
	CompanyListingGroupAlertsComponent,
	CompanyListingGroupOpenAlertsComponent,
	CompanyListingGroupResolvedAlertsComponent,
	ListingGroupListingDetailsComponent,
	MoveListingModalComponent,
	RenameListingGroupModalComponent,
	ListingLinkConfirmModalComponent,
	CompanyAlertedListingsComponent,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BsDropdownModule,
		ComponentsModule,
		DirectivesModule,
		PipesModule,
	],
	declarations: [
		ListingGroupImageComponent,
		ListingGroupNmlsLocationsComponent,
		ListingGroupListingsComponent,
		ListingGroupListingAddComponent,
		...components,
	],
	entryComponents: [
		...components,
	],
})
export class CompanyListingsModule {}
