<div class="social-compliance-monitoring-settings-company remote-resource" [class.resolved]="isResolved()">
	<div class="rows-bordered">
		<div class="row-fluid header">
			<div class="col-sm-4" [ngClass]="getSortClass('name')" (click)="setSort('name')">
				<b>Account</b>
			</div>
			<div class="col-sm-5"></div>
			<div class="col-sm-a" [ngClass]="getSortClass('monitoringStatus')" (click)="setSort('monitoringStatus')">
				<b>Alert</b>
			</div>
		</div>

		<div class="row-fluid" *ngFor="let company of list">
			<div class="col-sm-4 va-m">
				<div class="company">
					<div class="icon-container">
						<sm-icon [config]="company"></sm-icon>
					</div>
					<div class="link-container">
						<ng-container *ngIf="company.networkCode !== 'RS' && company.link; then linkAnchor; else linkName"></ng-container>
						<ng-template #linkAnchor>
							<a [href]="company.link" target="_blank">{{company.name}}</a>
						</ng-template>
						<ng-template #linkName>
							{{company.name}}
						</ng-template>
					</div>
				</div>
			</div>
			<div class="col-sm-5 va-m">
				<monitoring-status [status]="company.lastConnectionStatus"></monitoring-status>
			</div>
			<div class="col-sm-a va-m">
                <ng-container [ngSwitch]="User.hasFeature(PACKAGE_FEATURE.COMPLIANCE)">
                    <ng-container *ngSwitchCase="false">
                        <toggle
                            [ngModel]="company.monitoringStatus"
                            [values]="{true: 'on', false: 'off'}"
                            (toggle)="goToSubscribe()"
                        ></toggle>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <toggle
                            [disabled]="!User.can('TPO_SMC_ORDER_MONITORING')"
                            [ngModel]="company.monitoringStatus"
                            [values]="{true: 'on', false: 'off'}"
                            (toggle)="toggleCompanyMonitoring(company)"
                            [tooltip]="User.can('TPO_SMC_ORDER_MONITORING') ? '' : 'You don\'t have permissions'"
                            [loading]="company.$resolving"
                        ></toggle>
                    </ng-container>
                </ng-container>
			</div>
		</div>

		<div class="row-fluid" *ngIf="!list.length">
			<div class="col-sm-12 text-center text-bigger text-muted">No accounts connected</div>
		</div>
	</div>
	<br>
	<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
</div>
