import { Component, Input, OnInit } from '@angular/core';

import { NgResource, NgResourceArray } from 'commons/interfaces';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LenderChannel, ChannelsStatus, LenderChannelContactInfo } from 'tpo/partners/lender-channel.interface';
import { TpoPartnersService } from 'tpo/partners/partners.service';
import { TpoInfoModalComponent } from './tpo-info-modal/tpo-info-modal.component';


@Component({
    selector: 'tpo-partners-channel',
    templateUrl: './tpo-partners-channel.component.html',
})
export class TpoPartnersChannelComponent implements OnInit {
    @Input() lenderId: string;
    @Input() channelsContact: NgResourceArray<LenderChannel>;
    @Input() channelsStatus: ChannelsStatus[];

    private modalRef: BsModalRef;

    public constructor(
            private readonly partnersService: TpoPartnersService,
            private readonly modalService: BsModalService) {
    }
    
    ngOnInit(): void {
        this.channelsContact.$resolved = true;
    }

    public contactUs(lenderChannel: LenderChannel): void {
        const lenderChannelContactInfo: NgResource<LenderChannelContactInfo> =
            this.partnersService.getLenderChannelContactInfo({ lenderId: this.lenderId, channelId: lenderChannel.id });
        
        const initialState = {
            lenderChannelContactInfo: lenderChannelContactInfo,
            channel: lenderChannel,
        };

        this.modalRef = this.modalService.show(TpoInfoModalComponent, {
            initialState: initialState,
            class: 'modal-smd modal-new',
            backdrop: 'static',
        });
    }
}
