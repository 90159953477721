import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { SideMenuContainerComponent } from 'commons/components/navigation/menu/side/side-menu-container.component';
import { UIRouterGlobals } from '@uirouter/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { combineLatest, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountInformation, AccountInformationService } from 'shared/account/company-information/account-information.service';

@Component({
	selector: 'ccm-client-menu',
	templateUrl: '../../../commons/components/navigation/menu/side/side-menu-container.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CCMClientMenuComponent extends SideMenuContainerComponent {
    @Input() lenderInfo: AccountInformation;
	protected user$: Observable<UserProfile>;
	protected user: UserProfile;

	constructor(
		{ profile$, profile }: UserService,
		changeDetector: ChangeDetectorRef,
		router: UIRouterGlobals,
    ) {
		super(changeDetector, router);
		this.user$ = profile$.asObservable();
		this.user = profile;
	}

	ngOnInit() {
		super.ngOnInit();
        this.menuUpdate$ = combineLatest([this.user$, this.router.success$]).pipe(
            takeUntil(this.destroyed$),
        );
		combineLatest([this.user$]).pipe(
			takeUntil(this.destroyed$),
		).subscribe(this.populateMenu);
	}

	populateMenu = ([User]: [UserProfile]) => {
        const { isRegistered } = this.lenderInfo;
        const can = (...permissions: string[]) => permissions.some(permission => User.can(permission));

		const clientRoute = isRegistered ? 'tpo.:id' : 'nmls.client.:id';
		const accountRoute = `${clientRoute}.account`;
		const relationshipRoute = `${clientRoute}.relationships`;
		const reviewsRoute = `${clientRoute}`;

		this.menuItems = [
			{
				title: 'Account',
				link: {
					sref: `${accountRoute}.information`,
				},
				items: [
					{ title: 'Company Information', link: `${accountRoute}.information` },
					{ title: 'DBA', link: `${accountRoute}.dba` },
					{ title: 'Federal Registration', link: `${accountRoute}.federal-registration` },
					{ title: 'Licenses', link: `${accountRoute}.licenses` },
					{ title: 'Locations', link: `${accountRoute}.locations` },
				],
			},

			...(isRegistered ? [
				{
					title: 'Account Information',
					link: `${relationshipRoute}.affiliates`,
					items: [
						{ title: 'Affiliates', link: `${relationshipRoute}.affiliates` },
						{ title: 'Agency', link: `${relationshipRoute}.agency` },
						{ title: 'Insurance & Bonds', link: `${relationshipRoute}.insuranceBonds` },
						{ title: 'Investors', link: `${relationshipRoute}.investors` },
                        { title: 'Technology & Operations', link: `${relationshipRoute}.tech-ops` },
						{ title: 'Underwriting', link: `${relationshipRoute}.underwriting` },
						{ title: 'Warehouse', link: `${relationshipRoute}.warehouse` },
					],
				},
			] : []),

            ...(isRegistered ? [{
                title: 'Billing',
                items: [
                    {
                        title: 'Social Media',
                        link: `${clientRoute}.billing.social-compliance`,
                    },
                ],
            }] : []),

            ...(isRegistered ? [{
                title: 'Contacts',
                link: `${clientRoute}.contacts`,
            }] : []),

            ...(isRegistered && can('CCM_VIEW_REVIEWS') ? [{
                title: 'Due Diligence',
                items: [
                    {
                        title: 'Scorecard',
                        link: `${reviewsRoute}.scorecard.summary`,
                    },
                ],
            }] : []),

            {
				title: 'Production',
				link: `${clientRoute}.production`,
			},

            {
                title: 'Social Accounts',
                link: {
                    sref: `${clientRoute}.social-accounts.company`,
                    params: { linkListType: 'social' },
                },
            },
		];
		this.changeDetector.markForCheck();
	}
}
