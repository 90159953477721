import { ValidatorFn, AbstractControlOptions, AsyncValidatorFn, FormArray } from '@angular/forms';

import { RealmFormControl } from './form-control.hoc';
import { RealmFormGroup } from 'commons/forms/form-group.hoc';

interface ServerError {
	name?: string;
	description?: string;
}

interface ServerErrorResponse {
	message?: string;
	errorDetails?: ServerError[];
}

// TODO: add recurly from server-validation.js
class RealmFormArray extends FormArray {
	serverError: string = '';
	label: string;

	get showError() {
		return this.touched && this.invalid;
	}

	constructor(controls: Array<RealmFormControl | RealmFormArray | RealmFormGroup>, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
		super(controls, validatorOrOpts, asyncValidator);
	}

	setServerError(response: ServerErrorResponse) {
		if (response.message) {
			if (response.errorDetails && response.errorDetails.length > 0) {
				for (const i in response.errorDetails) {
					if (response.errorDetails.hasOwnProperty(i)) {
						const fieldName = response.errorDetails[i].name;
						const field = this.get(fieldName) as RealmFormControl;

						if (!field) {
							// this is strange, no?
							this.serverError = response.message;
							continue;
						}

						field.serverError = response.errorDetails[i].description;
						field.patchValue(field.value);
						field.markAsTouched();
						field.updateValueAndValidity();
					}
				}
				return;
			}

			this.serverError = response.message;
			return;
		}

		for (const fieldName in response) {
			if (response.hasOwnProperty(fieldName)) {
				const field = this.controls[fieldName] as RealmFormControl;

				if (!field) {
					continue;
				}

				field.serverError = response[fieldName];
				field.patchValue(field.value);
				field.markAsTouched();
				field.updateValueAndValidity();
			}
		}
	}

}

export { RealmFormArray };
