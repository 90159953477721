import { OnDestroy, OnInit, Directive } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import { NotificationModalComponent } from 'commons/components/modals';
import { PagedListComponent } from 'commons/components/list/paged-list.component';

import { MonitoringSettingsService } from '../monitoring-settings.service';
import { PACKAGE_FEATURE } from 'tpo/social-media/sm-package.service';

@Directive()
export abstract class MonitoringListComponent extends PagedListComponent implements OnInit, OnDestroy {
	public User: UserProfile;
	public modalRef: BsModalRef;
	public list: any = [];
	public monitoringSettings: any = {
		enabledLinks: {
			count: 0,
		},
	};
	public allowedConnectionStatuses: string[] = ['FB', 'IG', 'LI', 'TW', 'GP', 'YT', 'TM', 'GMB'];
    public PACKAGE_FEATURE = PACKAGE_FEATURE;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public userService: UserService,
		public modalService: BsModalService,
		public monitoringSettingsService: MonitoringSettingsService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;
	}

	abstract loadList(queryParams: any);

	ngOnInit(): void {
		super.ngOnInit();
		this.getMonitoringSettings();
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
		this.clearMonitoringSettings();
	}

	getMonitoringSettings(): void {
		this.monitoringSettings.settings = this.monitoringSettingsService.getSettings();
		this.monitoringSettings.billing = this.monitoringSettingsService.getBilling();
	}

	clearMonitoringSettings(): void {
		this.monitoringSettingsService.clearSettings();
		this.monitoringSettingsService.clearBilling();
	}

	isResolved = (): boolean => (
		this.list.$resolved &&
		this.monitoringSettings.settings.$resolved &&
		this.monitoringSettings.billing.$resolved
	)

	showAccessDeniedModal = (): void => {
		this.modalRef = this.modalService.show(NotificationModalComponent, {
			initialState: {
				title: 'Sorry! Access Denied',
				confirmText: 'Ok',
				notification: 'Please contact Customer Service.',
				onConfirm: () => {
					this.modalRef.hide();
				},
			},
			class: 'modal-smd modal-new',
		});
	}

	verifyMonitoringAccess = (): Promise<void> => {
		if (this.monitoringSettings.billing.enabled) {
			if (this.monitoringSettingsService.isActive()) {
				return Promise.resolve();
			} else {
				this.stateService.go('social-compliance.monitoring-settings.settings');
			}
		} else {
			this.showAccessDeniedModal();
		}

		return Promise.reject();
	}

	updateLinksConnectionStatus = (data: any[]): void => {
		const isRSS = ({ isManual, networkCode }): boolean => (
			isManual || !this.allowedConnectionStatuses.includes(networkCode)
		);

		data.forEach((item): void => {
			if (isRSS(item)) {
				item.linkStatus = 'RSS';
			}
		});
	}

    public goToSubscribe(): void {
        this.stateService.go('social-compliance.subscription');
    }
}
