<form-errors
	[form]="forms[index]"
	*ngFor="let item of list; index as index"
></form-errors>

<div class="rows-bordered remote-resource" [class.resolved]="list.$resolved">
	<div class="row-fluid header">
		<div class="col-sm-3"><b>Listing</b></div>
		<div class="col-sm-a"><b>Field</b></div>
		<div class="col-sm-a"><b>Current Value</b></div>
		<div class="col-sm-a"><b>Previous Value</b></div>
		<div class="col-sm-a"><b>Group Value</b></div>
		<div class="col-sm-2"></div>
	</div>

	<form
		class="row-fluid"
		[formGroup]="forms[index]"
		(onValidSubmit)="confirm(item, forms[index])"
		*ngFor="let item of list; index as index"
	>
		<div class="col-sm-3 va-m">
			<div class="flex-row gap">
				<sm-network-icon
					*ngIf="item.listingNetworkCode"
					[config]="{ networkCode: item.listingNetworkCode }"
					[minified]="true"
				></sm-network-icon>&nbsp;
				<a
					uiSref="listings.company.:listingGroupId.:listingDetailsId"
					[uiParams]="{ listingGroupId: item.listingGroupId, listingDetailsId: item.listingId }"
					class="flex"
				>{{item.listingName}}</a>
			</div>
		</div>
		<div class="col-sm-a va-m">{{item.alertField}}</div>
		<div class="col-sm-a va-m">
			<label class="control-label text-default flex-row gap">
				<input
					type="radio"
					formControlName="resolveType"
					value="CURRENT"
					*ngIf="User.can('TPO_SMC_MANAGE_DIGITAL_MEDIA')"
				>
				<span class="flex">{{item.listingValue || '&ndash;'}}</span>
			</label>
		</div>
		<div class="col-sm-a va-m">
			<label class="control-label text-default flex-row gap">
				<input
					type="radio"
					formControlName="resolveType"
					value="PREVIOUS"
					*ngIf="User.can('TPO_SMC_MANAGE_DIGITAL_MEDIA')"
				>
				<span class="flex">{{item.previousValue || '&ndash;'}}</span>
			</label>
		</div>
		<div class="col-sm-a va-m">
			<label class="control-label text-default flex-row gap">
				<input
					type="radio"
					formControlName="resolveType"
					value="GROUP"
					*ngIf="User.can('TPO_SMC_MANAGE_DIGITAL_MEDIA')"
				>
				<span class="flex">{{item.listingGroupValue || '&ndash;'}}</span>
			</label>
		</div>
		<div class="col-sm-2 text-right va-m">
			<button
				type="submit"
				class="btn btn-primary"
				[disabled]="forms[index].invalid"
				*ngIf="User.can('TPO_SMC_MANAGE_DIGITAL_MEDIA')"
			>
				Confirm
			</button>
		</div>
	</form>

	<div class="row-fluid" *ngIf="list.$resolved && !list.length">
		<div class="col-sm-12 text-center va-m text-bigger text-muted">No alerts</div>
	</div>
</div>

<br/>

<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
