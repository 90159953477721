import { NgModule } from '@angular/core';
import { ComponentsModule } from 'commons/components/components.module';
import { CustomerListComponent } from './customer-list.component';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from 'commons/directives/directives.module';

@NgModule(
    {
    imports: [
        ComponentsModule,
        CommonModule,
        DirectivesModule,
    ],
    declarations: [
        CustomerListComponent,

    ],
    providers: [

    ],
    entryComponents: [

    ],
}
)
export class CustomerModule {

}
