import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

// Components
import { AttachmentsComponent } from './attachments.component';
import { TpoPublisherAttachmentService } from './tpo-publisher-attachment.service';
import { EditThumbnailModalComponent } from './edit-thumbnail-modal';

@NgModule({
	imports: [
		CommonModule,
		DirectivesModule,
		PipesModule,
		ComponentsModule,
	],
	declarations: [
		AttachmentsComponent,
		EditThumbnailModalComponent,
	],
	providers: [TpoPublisherAttachmentService],
	exports: [
		AttachmentsComponent,
	],
	entryComponents: [
		EditThumbnailModalComponent,
	],
})
export class AttachmentsModule {}
