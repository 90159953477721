<div class="partners companies">
	<div class="gray-head flex-head">
		<h2>Partners</h2>
		<ul class="tab-menu col-min">
			<li
				*ngFor="let tab of tabs"
				uiSrefActive="active"
			>
				<a uiSref="{{ tab.state }}" [uiParams]="tab.params">
					<b>{{ tab.title }}</b>
				</a>
			</li>
		</ul>	
	</div>
	<ui-view></ui-view>		
</div>

