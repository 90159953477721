import { forEach } from 'lodash';
import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { UserService as UService } from 'angularjs-providers/user.provider';
import { TimezonesService } from 'commons/services/compatibility/timezones.service';

import { ListComponent } from 'commons/components/list/list.component';
import { PagedListComponent } from 'commons/components/list/paged-list.component';

import { CampaignsResourceService } from '../../campaigns-resource.service';
import { PostsTypeConfig, PostsTypeMap } from '../../../posts-type-list';

interface Post {
	id: number;
	text: string;
	sentDate: number;
	updatedDate: number;
	updatedBy: string;
	scheduledDate: any;
	scheduledZoneTime?: any;
	timezone: any;
	hasImagePreview: boolean;
	imagePreviewLink: string;
	isVideoThumbnail: boolean;
}

interface PostResource extends Array<Post> {
	$promise?: Promise<Post[]>;
	$resolved?: boolean;
}

@Component({
	templateUrl: './campaign-folder.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => CampaignFolderComponent) },
	],
})
export class CampaignFolderComponent extends PagedListComponent {
	static listName = CampaignFolderComponent.addName('campaignFolder');
	listName = CampaignFolderComponent.listName;

	User: any;
	tpoId: any;
	list: PostResource = [];
	postTypesConfig: PostsTypeMap = PostsTypeConfig;
	postType: any = {};

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public campaignsResource: CampaignsResourceService,
		public UserService: UService,
		public timezonesService: TimezonesService,
	) {
		super(transitionService, stateService);

		this.defaultFilters = {
			...this.defaultFilters,
			size: 10,
		};

		this.User = UserService.profile;
		this.tpoId = this.User.organization.id;

		const { folder: folderId } = stateService.params;
		this.postType = this.postTypesConfig.get(folderId);
	}

	loadList(queryParams) {
		this.list.$resolved = false;

		const params = this.getLoadListParams(queryParams);
		return this.campaignsResource.campaignMediaPosts.get(params).$promise
			.then((data) => {
				forEach(data, (item) => {
					if (item.scheduledDate) {
						const timezoneName = this.timezonesService.getZoneAbbr(item.timezone);
						const timezoneTime = this.timezonesService.convertTimezoneToUTC(item.scheduledDate, item.timezone, 'MM/DD/YYYY, hh:mm A');
						item.scheduledZoneTime = `${ timezoneTime } ${ timezoneName }`;
					}
				});

				this.list = data;
				this.noMatches = !data.length && (this.filtersApplied || queryParams.q);
			});
	}

	getLoadListParams(queryParams) {
		return {
			...this.getClearParams(queryParams),
			status: this.postType.resourceId,
			campaignId: this.stateService.params.campaignId,
		};
	}

	back() {
		window.history.back();
	}
}
