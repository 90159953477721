import { Component, forwardRef } from '@angular/core';

import { ListComponent } from 'commons/components/list';

import { TpoPartnersListComponent } from '.';


@Component({
	templateUrl: './tpo-partners-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => TpoAllPartnersListComponent) },
	],
})
export class TpoAllPartnersListComponent extends TpoPartnersListComponent {
	static listName = TpoAllPartnersListComponent.addName('TpoAllPartnersListComponent');
	listName = TpoAllPartnersListComponent.listName;

    protected type: ('all' | 'my') = 'all';
}
