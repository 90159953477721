import { Component, OnInit } from '@angular/core';
import { RealmFormArray, RealmFormControl, RealmFormGroup } from 'commons/forms';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import * as moment from 'moment';
import { shareReplay, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonValue, CommonValuesService } from 'shared/services/common-values.service';
import {
    AffiliateBusinessTypeInterface,
    AffiliateInterface,
    AffiliateOwnershipInterface,
} from 'tpo/account/affiliates/affiliate.interface';
import { AffiliatesService } from 'tpo/account/affiliates/affiliates.service';
import { StateService, UIRouterGlobals } from '@uirouter/core';

@Component({
    templateUrl: 'affiliates-add-edit-view.component.html',
})
export class AffiliatesAddEditViewComponent implements OnInit {

    user: UserProfile;
    editing: boolean;
    new: boolean;
    resolved: boolean;
    userIsInvestor: boolean;

    tpoId: number;
    affiliateId: number;
    affiliate: Partial<AffiliateInterface>;
    companyName: string;

    labelDateFormat: string = 'MM/dd/YYYY';
    modelDateFormat: string = 'MM/DD/YYYY';

    states: Observable<CommonValue[]>;
    loadingValue = {
        states: true,
    };
    businessTypes: AffiliateBusinessTypeInterface[];

    affiliateOwnerships: RealmFormArray = new RealmFormArray([]);

    affiliateForm: RealmFormGroup = new RealmFormGroup({
        businessType: new RealmFormControl(
            'businessType',
            {
                label: 'Business Type',
            },
            Validators.required,
        ),
        entityName: new RealmFormControl(
            'entityName',
            {
                label: 'Entity Name',
            },
            Validators.required,
        ),
        address1: new RealmFormControl(
            'address1',
            {
                label: 'Address 1',
            },
            Validators.required,
        ),
        startDate: new RealmFormControl(
            'startDate',
            {
                label: 'Affiliation Started',
            },
            Validators.required,
        ),
        address2: new RealmFormControl(
            'address2',
            {
                label: 'Address 2',
            },
        ),
        endDate: new RealmFormControl(
            'endDate',
            {
                label: 'Affiliation End',
            },
        ),
        city: new RealmFormControl(
            'city',
            {
                label: 'City',
            },
            Validators.required
        ),
        stateOfIncorporate: new RealmFormControl(
            'stateOfIncorporate',
            {
                label: 'State of Incorporation',
            },
            Validators.required,
        ),
        state: new RealmFormControl(
            'state',
            {
                label: 'State',
            },
            Validators.required,
        ),
        operatingStates: new RealmFormControl(
            'operatingStates',
            {
                label: 'Operating States',
            },
            Validators.required,
        ),
        zip: new RealmFormControl(
            'zip',
            {
                label: 'Zip',
            },
            Validators.required,
        ),
        affiliateOwnerships: this.affiliateOwnerships,
    },
    { validators: [this.relationshipDatesValidator, this.ownershipPercentagesValidator] }
    );

    constructor(
        private affiliateService: AffiliatesService,
        private userService: UserService,
        private commonValues: CommonValuesService,
        private stateService: StateService,
        private router: UIRouterGlobals,
    ) {
        this.user = userService.profile;
        this.userIsInvestor = this.user.isTpo;
        this.states = commonValues.getStates()
            .pipe(
                tap(() => this.loadingValue.states = false),
                shareReplay(1),
            );
    }

    ngOnInit() {
        if(this.user.isTpo) {
            this.tpoId = this.user.organization.id;
        } else {
            this.tpoId = this.router.params.id;
        }
        this.new = this.stateService.is('account.relationships.affiliates.new');
        if(!this.new) {
            this.affiliateId = this.router.params.affiliateId;
            this.loadAffiliateAndPopulateForm();
        }
        else {
            this.companyName = 'Add Affiliate';
            this.loadBusinessTypes();
            this.editing = true;
            this.affiliate = {};
            this.resolved = true;
        }
    }

    async loadAffiliateAndPopulateForm(): Promise<void> {
        this.resolved = false;
        try {
            this.affiliate = await this.affiliateService.getAffiliate(this.tpoId, this.affiliateId).toPromise();
            this.patchToForm();
            this.companyName = this.affiliate.entityName;
        } catch (e) {
            this.affiliateForm.setServerError(e);
        } finally {
            this.resolved = true;
        }
    }

    async loadBusinessTypes(): Promise<void> {
        this.resolved = false;
        try {
            this.businessTypes = await this.affiliateService.getBusinessType().toPromise();
        } catch (e) {
            this.affiliateForm.setServerError(e);
        } finally {
            this.resolved = true;
        }
    }

    patchToForm(): void {
        this.affiliateForm.reset();
        this.affiliateOwnerships.clear();
        this.affiliateForm.patchValue(this.affiliate);
        this.affiliateForm.patchValue({startDate: this.convertDateStringToDate(this.affiliate.startDate)});
        this.affiliateForm.patchValue({endDate: this.convertDateStringToDate(this.affiliate.endDate)});
        this.affiliate.affiliateOwnerships.forEach( (owner) => {
            const newRow = this.createOwnershipRow();
            newRow.patchValue(owner);
            this.affiliateOwnerships.push(newRow);
        });
    }

    relationshipDatesValidator(formGroup: FormGroup): ValidationErrors {
        const datesAreNotValid = ((formGroup.controls['endDate'].value && formGroup.controls['startDate'].value) && (formGroup.controls['endDate'].value < formGroup.controls['startDate'].value));
        const validatorError: ValidationErrors = { error: true };
        if(datesAreNotValid) {
            formGroup.controls['endDate'].markAsTouched();
            formGroup.controls['endDate'].setErrors({ error : true });
        }
        return datesAreNotValid ? validatorError : null;
    }

    ownershipPercentagesValidator(formGroup: FormGroup): ValidationErrors {
        const ownerships = formGroup.value['affiliateOwnerships'];
        let totalPercentage = 0;
        if(ownerships.length > 1) {
            ownerships.forEach(owner => totalPercentage += owner.ownershipPercentage);
        }
        if(ownerships.length == 1) {
            totalPercentage = ownerships[0].ownershipPercentage;
        }

        if(totalPercentage > 100){
            const affiliateOwnershipsForm = formGroup.controls['affiliateOwnerships'] as FormGroup;
            for(let i = 0 ; i < ownerships.length ; i++) {
                const formControls = affiliateOwnershipsForm.controls[i] as FormGroup;
                formControls.controls['ownershipPercentage'].markAsTouched();
                formControls.controls['ownershipPercentage'].setErrors({ error: true });
            }
        }

        return (totalPercentage > 100) ? { error: { message: '<b>Ownership %</b> of all owners (Company and Individual) put together cannot be greater than 100% ' } } : null;
    }

    createOwnershipRow(): RealmFormGroup {
        return new RealmFormGroup({
            id: new RealmFormControl(
                'id',
                {
                    label: 'id',
                },
            ),
            ownershipFirstName: new RealmFormControl(
                'ownershipFirstName',
                {
                    label: 'Name',
                },
                Validators.required,
            ),
            ownershipLastName: new RealmFormControl(
                'ownershipLastName',
                {
                    label: 'Last Name',
                },
            ),
            ownershipPercentage: new RealmFormControl(
                'ownershipPercentage',
                {
                    label: 'Ownership %',
                },
                Validators.required
            ),
            ownershipTypeID: new RealmFormControl(
                'ownershipTypeId',
                {
                    label: 'Ownership Type ID',
                },
            ),
        })
    }

    addIndividualOwner(): void {
        const newOwnership: Partial<AffiliateOwnershipInterface> = {
            ownershipTypeID: 'ID',
        }
        const newRow = this.createOwnershipRow();
        newRow.patchValue(newOwnership);
        this.affiliateOwnerships.push(newRow);
    }

    addCompanyOwner(): void {
        const newOwnership: Partial<AffiliateOwnershipInterface> = {
            ownershipTypeID: 'CM',
        }
        const newRow = this.createOwnershipRow();
        newRow.patchValue(newOwnership);
        this.affiliateOwnerships.push(newRow);
    }

    removeOwner(index: number) {
        this.affiliateOwnerships.removeAt(index);
    }

    enableEditing(): void {
        this.editing = true;
        this.loadBusinessTypes();
    }

    convertDateStringToDate(dateString) {
        if(dateString) {
            return moment(dateString).toDate();
        }
        return null;
    }

    formatAffiliate(newAffiliate: AffiliateInterface): AffiliateInterface {
        if(newAffiliate.startDate) {
            newAffiliate.startDate = moment(newAffiliate.startDate).unix() * 1000;
        }
        if(newAffiliate.endDate) {
            newAffiliate.endDate = moment(newAffiliate.endDate).unix() * 1000;
        }
        return newAffiliate;
    }


    cancel(): void {
        this.editing = false;
        if(this.new) {
            this.stateService.go('account.relationships.affiliates');
            return;
        }
        this.patchToForm();
    }

    async save(): Promise<void> {
        this.editing = false;
        this.resolved = false;
        const newAffiliate = this.formatAffiliate(this.affiliateForm.value);
        if(this.new) {
            try {
                await this.affiliateService.createAffiliate(this.tpoId, newAffiliate).toPromise();
                this.stateService.go('account.relationships.affiliates');
            } catch(e) {
                this.affiliateForm.setServerError(e);
            } finally {
                this.resolved = true;
            }
            return;
        }
        try {
            this.affiliate = await this.affiliateService.updateAffiliate(this.affiliateId, newAffiliate).toPromise();
            this.patchToForm();
        } catch(e) {
            this.affiliateForm.setServerError(e);
        } finally {
            this.resolved = true;
        }
    }

}
