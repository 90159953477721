import { Component, HostBinding, HostListener, OnInit, OnDestroy, ElementRef, ɵdetectChanges } from '@angular/core';
import { StateService } from '@uirouter/core';
import { merge, isArray, sortBy } from 'lodash';
import { ValidatorFn, ValidationErrors } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { UserService } from 'angularjs-providers/user.provider';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ConfirmModalComponent } from 'commons/components/modals';
import { DropdownRequiredValidator } from 'commons/validators';

import { FindingModelService } from '../../finding-model.service';
import { FindingsService } from '../../../findings.service';
import { IssueListComponent } from '..';
import { FindingComponent } from '../..';

const mockedObservations = [
	{ id: 3, observation: 'Ad/Post may be discriminatory' },
	{ id: 243, observation: 'If the ad/communication requests the applicant information pertaining to applicants child bearing or child rearing stage' },
	{ id: 292, observation: 'The ad/ communication post mentions status of credit transaction with reference to the skin color/religion/nationality/sexual orientation/marital status/age of a client or  of consumers in general ' },
	{ id: 308, observation: 'The ad/post fails to provide explanation of adverse action for loan denial' },
	{ id: 323, observation: 'The ad/post mentions any pre-screening tactics likely to discourage potential applicants on prohibited basis' },
	{ id: 324, observation: 'The ad/post mentions any statement which would discourage borrowers on a prohibited basis' },
	{ id: 326, observation: 'The ad/post mentions status of credit transaction/ application of credit/ application for extension of credit with reference to the race of a client or race of consumers in general ' },
	{ id: 328, observation: 'The ad/post mentions that the creditor has denied credit in whole or in part based on the country an applicant is from' },
	{ id: 329, observation: 'The ad/post mentions that the creditor has refused to grant an individual account to a creditworthy applicant on a prohibited basis' },
	{ id: 330, observation: 'The ad/post mentions that the creditor refuses to allow an applicant to open or maintain an account in a birth-given first name and a surname that is the applicant’s birth-given surname, the spouse’s surname, or a combined surname?' },
	{ id: 336, observation: 'The ad/post provides explanation of adverse action for loan denial being race, ethnicity, religion, nationality, sex' },
	{ id: 337, observation: 'The ad/post requests information about an applicant\'s spouse or former spouse (except where the non-applicant spouse is a joint obligor on the account/except where the non-applicant spouse is contractually liable on the account) ' },
	{ id: 338, observation: 'The ad/post requests prohibited information from the applicant.' },
	{ id: 339, observation: 'The ad/post requests prohibited information in connection with a self-test being conducted by the creditor' },
	{ id: 340, observation: 'The ad/post requests prohibited information in connection with determining If an applicant’s eligibility for special purpose credit programs' },
	{ id: 341, observation: 'The ad/post requests prohibited information in connection with monitoring purposes in relation to credit secured by real estate ( HAMP)' },
	{ id: 342, observation: 'The ad/post requests the applicant information pertaining to receiving alimony, child support for determination of credit worthiness' },
	{ id: 350, observation: 'The ad/post uses terms such as scripts/ rate quotes to discourage applicants from applying for credit on a prohibited basis' },
];

const RequiredArray: ValidatorFn = (control: RealmFormControl): ValidationErrors | null => {
	return !isArray(control.value) || control.value.length === 0 ? { required: true } : null;
};

@Component({
	templateUrl: './issue-edit.component.html',
})
export class IssueEditModalComponent implements OnInit, OnDestroy {
	User: any;
	get canManage(): boolean {
		return this.issueList.canManage;
	}
	get isNew(): boolean {
		return !this.issue.id;
	}
	public values: { observations: any, groups: number } = {
		groups: null,
		observations: [],
	};
	public initialized: boolean = false;

	issueForm = new RealmFormGroup({
		groupType: new RealmFormControl(
			'groupType',
			{
				label: 'Group',
				updateOn: 'change',
			},
		),
		number: new RealmFormControl('number', { label: 'Number' }),
		screenPosX: new RealmFormControl('screenPosX', { label: 'X' }),
		screenPosY: new RealmFormControl('screenPosY', { label: 'Y' }),
		observations: new RealmFormControl(
			'observations',
			{
				label: 'Observation',
				updateOn: 'change',
				value: [],
			},
			DropdownRequiredValidator,
		),
		description: new RealmFormControl(
			'description',
			{ label: 'Description' },
		),
	});

	issueList: IssueListComponent;
	findingComponent: FindingComponent;
	issue: any;
	reviewId: number;
	public subModalRef: BsModalRef;
	public resolved: boolean = true;
	public expanded: boolean = false;
	public search$: Subject<string> = new Subject<string>();
	public keyUp$ = new Subject<string>();
	public search: string = '';

	@HostBinding('class.is-editing') isEditing: boolean = false;
	@HostBinding('class.active') isFocused: boolean = false;

	constructor(
		public element: ElementRef,
		public modalRef: BsModalRef,
		public stateService: StateService,
		public userService: UserService,
		public modalService: BsModalService,
		public findingsService: FindingsService,
		public findingModelService: FindingModelService,
	) {
		this.User = userService.profile;

		this.keyUp$.pipe(debounceTime(1000)).subscribe((search) => {
			this.search$.next(search);
		});
	}

	@HostListener('click') focusIssue() {
		if (!this.findingModelService.isEditingIssue && !this.isFocused) {
			this.findingComponent.highlightIssue(this.issue);
		}
	}

	ngOnInit(): void {
		this.findingComponent = this.issueList.findingComponent;
		this.findingComponent.editIssue(this.issue);
		this.resetForm();
		this.resolved = !this.isNew;

		// New Issue Case
		if (this.isNew) {
			this.findingsService.issues.nextNumber(this.getParams(), ({ number: num }) => {
				this.issue.number = num;
				this.issueForm.patchValue({ number: num });
				this.resolved = true;
			});
		}
		this.setDescriptionFocus(false);

		this.findingComponent.finding.$promise
			.then((finding: any) => {
				this.reviewId = finding.reviewId;
				this.loadGroupTypes();
				this.initialized = true;
			});

		this.search$.subscribe((search) => {
			this.search = search;
			this.loadObservations();
		});
	}

	resetForm(): void {
		const { observations = [] }  = this.issue;
		this.issueForm.reset({
			...this.issue,
			observations: observations.map((item) => ({...item})),
		});
	}

	setDescriptionFocus(focused: boolean): void {
		const { description } = this.issueForm.value;
		this.expanded = focused || (description && description.length > 0);
	}

	ngOnDestroy(): void {
		if (this.subModalRef) {
			this.subModalRef.hide();
		}
	}

	loadGroupTypes() {
		this.values.groups = this.findingsService.types.groups();
        this.values.observations = [...(this.issue.observations || [])];
        this.values.observations.$resolved = true;
        Object.defineProperty(this.values.observations, '$promise', { enumerable: false, value: Promise.resolve(this.values.observations) });
	}

	cancelEditing() {
		this.modalRef.hide();
		this.findingComponent.cancelEdit();
		if (!this.issue.id) {
			this.removeSelfFromList();
		}
	}

	removeSelfFromList() {
		this.findingComponent.removeIssue(this.issue);
	}

	loadObservations(): void {
		const { reviewId } = this;
		const q = this.search || null;
		const { findingId } = this.stateService.params;
		const { groupType } = this.issueForm.value;

		if (q || groupType) {
			this.values.observations.$resolved = false;

			this.findingsService.types.enabledObservations({ groupId: groupType, findingId, reviewId, q }, (data) => {
				this.values.observations = data;
			}, /* error */() => {
				this.values.observations.$resolved = true;
			});
		} else {
			const selectedObservations = sortBy(this.issueForm.get('observations').value, 'observation');
            this.values.observations = [...selectedObservations];
            this.values.observations.$resolved = true;
            Object.defineProperty(this.values.observations, '$promise', { enumerable: false, value: Promise.resolve(this.values.observations) });
		}
	}

	save() {
		this.resolved = false;
		this.findingsService.issues[this.isNew ? 'create' : 'update'](this.getParams(), this.issueForm.getRawValue())
			.$promise
			.then((issue) => {
				if (this.isNew) {
					this.findingModelService.isEditingIssue = false;
				}
				merge(this.issue, {observations: null}, issue);
				this.findingComponent.cancelEdit();
			})
			.catch(({ data: response }) => {
				this.issueForm.setServerError(response);
			})
			.finally(() => {
				this.resolved = true;
				this.modalRef.hide();
			});
	}

	getParams() {
		return {
		...(this.User.isTpo ? { tpoId: this.User.organization.id } : {}),
			findingId: this.stateService.params.findingId,
			issueId: this.issue.id,
		};
	}

	confirmRemove(): void {
		const initialState = {
			title: 'Remove Issue',
			message: this.User.isComergence ?
				'Are you sure you want to remove issue?' :
				'<div class="alert alert-danger text-center">Caution!</div>' +
				'If you remove this issue from the finding it will also be removed from any task.',
			confirmText: 'Remove',
			onConfirm: () => {
				const modal = this.subModalRef.content;
				modal.resolving = true;
				this.findingsService.issues.remove(this.getParams())
					.$promise
					.then((issue) => {
						this.subModalRef.hide();
						this.modalRef.hide();
						this.removeSelfFromList();
						this.findingComponent.cancelEdit();
					})
					.finally(() => {
						modal.resolving = false;
					});
			},
			onCancel: () => {
				this.subModalRef.hide();
			},
		};

		this.subModalRef = this.modalService.show(ConfirmModalComponent, { initialState, class: 'modal-smd modal-new' });
	}
}
