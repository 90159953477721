import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { RecurlyModule } from 'commons/recurly';
import { PipesModule } from 'commons/pipes/pipes.module';

// Services
import { SocialMediaResourceService } from './social-media-resource.service';
import { MonitoringSettingsService } from './monitoring-settings.service';

// Components
import { MonitoringStatusComponent } from './common';
import { MonitoringSettingsComponent } from './monitoring-settings.component';
import { CompanyListComponent } from './company';
import { IndividualsListComponent } from './individuals';
import { SettingsComponent } from './settings';
import { TpoStatusModule } from 'tpo/status';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BsDropdownModule.forRoot(),
		DirectivesModule,
		ComponentsModule,
		RecurlyModule,
		PipesModule,
        TpoStatusModule,
	],
	declarations: [
		MonitoringStatusComponent,
		MonitoringSettingsComponent,
		CompanyListComponent,
		IndividualsListComponent,
		SettingsComponent,
	],
	providers: [
		SocialMediaResourceService,
		MonitoringSettingsService,
	],
	entryComponents: [
		MonitoringStatusComponent,
		MonitoringSettingsComponent,
		CompanyListComponent,
		IndividualsListComponent,
		SettingsComponent,
	],
})
export class MonitoringSettingsModule {}
