import { Component, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { StateService, UIRouterGlobals } from '@uirouter/core';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';

import { UserService, UserProfile, Credentials } from 'angularjs-providers/user.provider';

import { TwoFactorAuthenticationService } from './2FA.service';
import { LoginParams } from './2FA.interface';

@Component({
	templateUrl: './2FA.component.html',
})
export class TwoFactorAuthentication {
	User: UserProfile;
	loginParams: LoginParams;
	errorCode: 'NOT_PAIRED' | 'OTP_REQUIRED';
	credentials: Credentials;
	qrCodeImg: SafeUrl;
	secret: string;
	loading: boolean;
	step = 1;
	otpLength = 6;
	validityTime: Date = window.env['two-factor-auth_email_ttlMillis'];

	otpControl = new RealmFormControl(
		'otp',
		{ label: 'Code' },
	);
	otpForm = new RealmFormGroup({
		otpControl: this.otpControl,
	});
	isResending: boolean;
	isNotificationSet: boolean;

	private otpElement: ElementRef;
	@ViewChild('otpElement') set _otpElement(_otpElement: ElementRef) {
		if (this.step === 3) {
			this.otpElement = _otpElement;
		}
	}

	constructor(
		public stateService: StateService,
		public userService: UserService,
		public TFAService: TwoFactorAuthenticationService,
		{ params: { credentials, data: { errorCode, errorDetails } } }: UIRouterGlobals,
		private domSanitizer: DomSanitizer,
	) {
		this.User = userService.profile;

		this.errorCode = errorCode;
		this.credentials = credentials;
		this.loginParams = errorDetails.reduce(
			(acc, { name, value }) => ({ ...acc, [name]: value }),
			{},
		);

		if (errorCode === 'OTP_REQUIRED') {
			this.goToOTPStep();
		}
	}

	goBackToLogin(): void {
		this.stateService.go('login');
	}

	async goToQRCodeStep(): Promise<void> {
		this.loading = true;
		try {
			const { qrCodeImg, secret } = await this.TFAService.pair(
				this.credentials.username,
				this.loginParams.accessToken,
			).$promise;

			this.qrCodeImg = this.domSanitizer.bypassSecurityTrustUrl(`data:image/png;base64,${qrCodeImg}`);
			this.secret = secret;
			this.step = 2;

			this.loading = false;
		} catch (e) {
			this.loading = false;
		}
	}

	goToOTPStep(): void {
		this.step = 3;
		requestAnimationFrame(() => {
			this.otpElement?.nativeElement?.focus()
		});
	}

	submitOtp(): void {
		this.otpForm.serverError = null;
		this.loading = true;
		this.credentials.otp = this.otpControl.value;
		this.userService.login(
			this.credentials,
			() => {
				this.stateService.transitionTo('2FA', { isSuccess2FA: true }); // tell the realm-content-class.js to show the loader until authentication is complete
				this.loading = false;
			},
			({ data: { errorCode } }) => {
				this.loading = false;
				const error = {
					message: '',
				};

				switch (errorCode) {
					case 'INVALID_OTP':
						error.message = 'The code entered is no longer valid, please check your authenticator and try again.';
						break;
					default:
						error.message = 'Please contact your administrator.'; // Here we lock the account on a back-end side
						break;
				}

				this.otpForm.setServerError(error);
				this.otpForm.updateValueAndValidity();
			});
	}

	onInput(inputElement: HTMLInputElement): void {
		inputElement.value = inputElement.value
			.replace(/[^0-9.]/g, '')
			.replace(/(\..*)\./g, '$1');
	}

	goBackToQRCodeStep(): void {
		this.otpControl.reset();
		this.step = 2;
	}

	async resendEmail(): Promise<void> {
		this.isResending = true;
		try {
			await this.TFAService.resendEmail(this.credentials.username, this.loginParams.accessToken).$promise;
			this.isNotificationSet = true;
			setTimeout(() => {
				this.isNotificationSet = false;
			}, 3000);
		} catch (error) {

			this.otpForm.setServerError(error?.data || error);
		}
        this.isResending = false;
	}
}
