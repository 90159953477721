import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { AuditChecklistModule } from 'shared/reviews/$reviewId/checklist/checklist.module';

import { IssuesListComponent } from './common';
import { ChecklistSettingsComponent } from './checklist-settings.component';
import { ChecklistSettingsRegulationsComponent } from './regulations';
import { ChecklistSettingsStateChecksComponent } from './state-checks';

import { ChecklistSettingsService } from './checklist-settings.service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ComponentsModule,
		DirectivesModule,
		AuditChecklistModule,
	],
	declarations: [
		IssuesListComponent,
		ChecklistSettingsComponent,
		ChecklistSettingsRegulationsComponent,
		ChecklistSettingsStateChecksComponent,
	],
	entryComponents: [
		ChecklistSettingsComponent,
		ChecklistSettingsRegulationsComponent,
		ChecklistSettingsStateChecksComponent,
	],
	providers: [
		ChecklistSettingsService,
	],
})
export class ChecklistSettingsModule {}
