<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="hide()">&times;</button>
	<h4 class="modal-title text-center">Change Password</h4>
</div>
<div class="modal-body remote-resource" [ngClass]="{ resolved: !isUpdatingPassword && passwordPolicy?.$resolved }">
    <form [formGroup]="passwordForm" name="passwordForm" id="passwordForm" (onValidSubmit)="save()" class="form-horizontal clearfix">
        <form-errors [form]="passwordForm"></form-errors>

        <div class="col-sm-8 strip-horizontal-padding">
            <modal-password-field [form]="passwordForm" [inputLabel]="passwordForm.getFieldLabel('currentPassword')" controlName="currentPassword">
            </modal-password-field>

            <modal-password-field [form]="passwordForm" [inputLabel]="passwordForm.getFieldLabel('newPassword')" controlName="newPassword">
            </modal-password-field>

            <modal-password-field [form]="passwordForm" [inputLabel]="passwordForm.getFieldLabel('passwordConfirm')" controlName="passwordConfirm">
            </modal-password-field>
        </div>
        <div class="col-sm-4 strip-horizontal-padding">
            <password-requirements [config]="passwordPolicy" [passwordControl]="newPassword"></password-requirements>
        </div>
    </form>
</div>
<div class="modal-footer" [class.curtain]="resolving">
    <div class="btn-group-lg">
        <button class="btn btn-primary" type="submit" form="passwordForm">Save</button>
        <button type="button" class="btn btn-default" (click)="hide()">Cancel</button>
    </div>
</div>
