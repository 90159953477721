import { Component, forwardRef } from '@angular/core';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';

import { ListComponent } from 'commons/components/list';
import { HistoryDocumentListComponent } from 'shared/documents';

import { TpoHistoryDocument } from '../../documents.interface';
import { TpoDocumentsService } from '../../documents.service';
import { ClientHistoryDocument } from 'lender/clients/$id/documents/client-documents.interface';
import { WaiveDocumentHistoryModalComponent } from 'lender/clients/$id/documents/common/waive-document-history-modal/waive-document-history-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: '../../../../shared/documents/document-history/document-history.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => TpoDocumentHistoryComponent) },
	],
})
export class TpoDocumentHistoryComponent extends HistoryDocumentListComponent<TpoHistoryDocument> {
	static listName = TpoDocumentHistoryComponent.addName('tpoDocumentHistory');
	listName = TpoDocumentHistoryComponent.listName;

	listResource = this.uiRouterGlobals.params.documentId
		? this.documentsService.getDocumentHistory
		: this.documentsService.getFromLibraryDocumentHistory;

	modalRef: BsModalRef;

	configureList = (): void => {
		if (this.uiRouterGlobals.params.documentId) {
			this.globalParams.push('documentId');
			this.omitParams.push('documentId');
		} else {
			this.globalParams.push('libraryId');
			this.omitParams.push('libraryId');
		}
	};

	showWaiveHistoryModal({ historyId, documentId }: ClientHistoryDocument): void {
		this.modalRef = this.modalService.show(
			WaiveDocumentHistoryModalComponent,
			{
				initialState: {
					snapshotData: this.documentsService.getDocumentWaiveHistory({
						documentId,
						historyId,
					}),
				},
				class: 'modal-new modal-smd request-history-modal',
			},
		);
	}

	constructor(
		private uiRouterGlobals: UIRouterGlobals,
		transitionService: TransitionService,
		stateService: StateService,
		private documentsService: TpoDocumentsService,
		public modalService: BsModalService,
	) {
		super(transitionService, stateService);
	}
}
