import { Component, Input } from '@angular/core';
import { ApplicationSummary } from 'lender/applications/application-summary.interface';


@Component({
    selector: 'application-summary-profile-information',
    templateUrl: 'application-summary-profile-information.component.html'})
export class ApplicationSummaryProfileInformationComponent {
    @Input() summary: ApplicationSummary;

    constructor() {}
}
