import { cloneDeep } from 'lodash';
import { Component, ChangeDetectorRef, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { CommonPartialScheduleComponent } from '../common-partial.component';
import { MonitoringScheduleService, SelectionPoolContactType } from '../../schedule.service';
import { SelectionPoolFileModalComponent, PoolData } from '../audit-schedule/selection-pool-file-modal';
import { SelectionPoolResetModalComponent } from 'tpo/social-compliance/schedule/partials/audit-schedule/reset-modal/reset-modal.component';
import { ScheduleManageContactsComponent } from 'tpo/social-compliance/schedule/partials/audit-schedule/manage-contacts/manage-contacts.component';
import { GlobalNotificationsService,  GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

@Component({
    selector: 'tpo-schedule-selection-pool',
    templateUrl: './selection-pool.component.html',
})
export class TpoScheduleSelectionPoolComponent extends CommonPartialScheduleComponent implements OnChanges {
    form = new RealmFormGroup({
        includeAllNmlsContacts: new RealmFormControl(
            'includeAllNmlsContacts',
            {
                label: 'Include All NMLS IDs',
                updateOn: 'change',
            },
        ),
        includeNonNmlsContacts: new RealmFormControl(
            'includeNonNmlsContacts',
            {
                label: 'Include Contacts with no NMLS',
                updateOn: 'change',
            },
        ),
    });
    User: UserProfile;
    modalRef: BsModalRef;
    mDateFormat: string = 'MM/DD/YYYY';

    @Input() loading: boolean;
    @Output() loadingChange = new EventEmitter<boolean>();

    constructor(
        userService: UserService,
        public scheduleService: MonitoringScheduleService,
        public modalService: BsModalService,
        protected cd: ChangeDetectorRef,
        private readonly globalNotificationsService: GlobalNotificationsService,
    ) {
        super(userService);
        this.User = userService.profile;
    }

    ngOnChanges({ settings }: SimpleChanges): void {
        if (settings?.currentValue) {
            this.resetForm();
        }
    }

    resetForm(): void {
        this.form.reset(this.settings.auditSchedule.selectionPool);
    }

    toEdit(): void {
        // Redirect to subscription if not enabled
        if (!this.redirectIfNoCompliance()) return;

        this.editing = true;
        this.resetForm();
    }

    cancelEdit(): void {
        this.editing = false;
        this.resetForm();
    }

    async formSubmit(): Promise<void> {
        const selectionPool = this.form.value;
        this.loading = true;

        const settings = await this.scheduleService.update({
            ...this.settings,
            auditSchedule: { ...this.settings.auditSchedule, selectionPool },
        }).toPromise();

        this.updateSettings(settings);
        this.priceUpdate.next(cloneDeep(this.settings));
        const formattedDate = moment(settings.auditSchedule.auditScheduleSettings.nextOrderDate).format(this.mDateFormat);

        this.notifyEmitter.emit({ message: `Updated settings will be applied to the next order on ${formattedDate}` });
        this.cancelEdit();
        this.loading = false;
    }

    openFileModal() {
        // Redirect to subscription if not enabled
        if (!this.redirectIfNoCompliance()) return;

        const { firstName, lastName } = this.User;
        this.modalRef = this.modalService.show(SelectionPoolFileModalComponent, {
            initialState: {
                onSave: async (poolData: PoolData) => {
                    this.modalRef.content.resolving = true;
                    try {
                        const settings = await this.scheduleService.uploadSelectionPoolFile(poolData).toPromise();
                        this.updateSettings(settings);
                        this.priceUpdate.next(settings);
                        this.globalNotificationsService.add ({
                            type: GlobalNotificationType.POSITIVE,
                            message: `Selection pool updated`,
                        });
                        this.modalRef.hide();
                    } catch (e) {
                        this.modalRef.content.form.setServerError(e.data || e);
                    }
                    this.modalRef.content.resolving = false;
                },
            },
            class: 'modal-smd modal-new',
        });
    }

    manageIndividuals() {
        // Redirect to subscription if not enabled
        if (!this.redirectIfNoCompliance()) return;

        this.modalRef = this.modalService.show(ScheduleManageContactsComponent, {
            initialState: {
                updateCount: (settings) => {
                    this.updateSettings(settings);
                    this.priceUpdate.next(settings);
                },
            },
            class: 'modal-lg modal-new',
        });
    }

    resetSelectionPool(isNmls: boolean) {
        const type = isNmls ? SelectionPoolContactType.NMLS : SelectionPoolContactType.NON_NMLS;
        const { includeAllNmlsContacts, includeNonNmlsContacts } = this.settings.auditSchedule.selectionPool;

        this.modalRef = this.modalService.show(SelectionPoolResetModalComponent, {
            initialState: {
                all: isNmls ? includeAllNmlsContacts : includeNonNmlsContacts,
                doRequest: async () => {
                    const settings = await this.scheduleService.resetSelectionPool(type).toPromise();
                    this.updateSettings(settings);
                    this.priceUpdate.next(settings);
                },
            },
            class: 'modal-smd modal-new',
        });
    }
}
