import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { InvestorMarketingStatusComponent } from 'comergence/customers/investor-marketing-service/investor-marketing-status.component';
import { InvestorMarketingVisibilityComponent } from 'comergence/customers/investor-marketing-service/investor-marketing-visibility.component';
import { InvestorMarketingServiceHistoryComponent } from 'comergence/customers/investor-marketing-service/investor-marketing-service-history.component';

@NgModule(
    {
        imports: [
            CommonModule,
            UIRouterModule,
            FormsModule,
            NgSelectModule,
            ReactiveFormsModule,
            ComponentsModule,
            DirectivesModule,
        ],
        declarations: [
            InvestorMarketingServiceHistoryComponent,
            InvestorMarketingStatusComponent,
            InvestorMarketingVisibilityComponent,
        ],
        providers: [
        ],
        entryComponents: [
        ],
    }
)
export class InvestorMarketingModule {

}
