<div class="lender-page remote-resource" [class.resolved]="resolved">
	<div class="big-line">
		<div class="row back-partners">
			<div class="col-sm-2 back-link">
				<a uiSref="partners.{{ stateService.params.type }}">
					<span class="glyphicon glyphicon-chevron-left"></span>Back to {{stateService.params.type == 'my' ?
					'My
					Partners': 'All Companies' }}</a>
			</div>
		</div>
	</div>
	<!-- Company Page section -->
	<tpo-partners-company-page
		[companyPage]="companyPage"
		[stateService]="stateService"
	></tpo-partners-company-page>
	
	<!-- Channel Section -->
	<tpo-partners-channel
		[lenderId]="lenderId"
		[channelsContact]="channelsContact"
		[channelsStatus]="channelsStatus"
	></tpo-partners-channel>
	
	<!-- Application History Section -->
	<tpo-partners-application-history
		[appHistory]="appHistory"
	></tpo-partners-application-history>
</div>