<a uiSref="partners.{{ type }}.:id" [uiParams]="{ id: item.id }">
    <div class="lender partners-lender">
        <div class="lender-logo">
            <div class="lender-logo-inner">
                <img class="img-rounded img-responsive" src="{{ '/api/rest/lenders/' + item.id + '/logo' }}"  alt="{{ item.name }}" />
            </div>
        </div>
        <div class="lender-description">
            <div class="lender-description-inner">
                <a class="text-bigger brand-title">{{ item.name }}</a>
                <br>

                <span class="text-light">
                    {{ ([ item.address1, item.address2, item.suite, item.city, item.state, item.zip ] | arrayFilter).join(', ') }}
                </span>
                <div class="description">
                    {{ item.description }}
                </div>
            </div>
        </div>
    </div>
</a>
