<div class="social-media-campaigns">
	<div class="flex-head">
		<ul class="breadcrumbs">
			<li>
				<a (click)="back()">
					<span class="glyphicon glyphicon-chevron-left"></span>
					Back
				</a>
			</li>
		</ul>
		<h2>
			{{postType.title}}
		</h2>
	</div>
	<section class="rows-bordered" [ngClass]="{ 'remote-resource': list, 'resolved': list.$resolved }">
		<div class="row-fluid header">
			<div class="col-md-5">
				<search-list placeholder="Search"></search-list>
			</div>
			<div *ngFor="let column of postType.columns" class="col-md-a">
				<b class="two-liners">{{ column.title }}</b>
			</div>
			<div class="col-md-a"></div>
		</div>
		<div class="row-fluid" *ngFor="let item of list">
			<div class="col-md-5 va-m text-overflow-wrap">
				<div class="preview">
					<div class="image-holder" [class.attachment-video]="item.isVideoThumbnail"
						 [ngSwitch]="item.hasImagePreview">
						<img *ngSwitchCase="true"
							 srcTest="{{item.imagePreviewLink}}">
						<i *ngSwitchCase="false" class="c-icon c-icon-user-rounded"></i>
					</div>
					<div class="text-overflow-wrap" *ngIf="item.text">
						<span [innerHTML]="item.text"></span>
					</div>
				</div>
			</div>
			<div *ngFor="let column of postType.columns" class="va-m col-md-a">
				<div [ngSwitch]="column.id">
					<!-- Draft Date -->
					<div *ngSwitchCase="'updatedDate'">
						{{ item.updatedDate | date:'MM/dd/yyyy, hh:mm a' }}
					</div>
					<!-- Scheduled Date -->
					<div *ngSwitchCase="'scheduledDate'">
						{{ item.scheduledZoneTime }}
					</div>
					<!-- Posted Date -->
					<div *ngSwitchCase="'postedDate'">
						{{ item.sentDate | date:'MM/dd/yyyy, hh:mm a' }}
					</div>
					<!-- Updated By -->
					<div *ngSwitchCase="'updatedBy'">
						{{ item.updatedBy }}
					</div>
				</div>
			</div>
			<div class="col-md-a va-m text-center">
				<a uiSref="social-compliance.publisher.posts.:postType.:postId" [uiParams]="{ postType: postType.id,  postId: item.id }" class="btn btn-default">
					View
				</a>
			</div>
		</div>
		<div class="row-fluid" *ngIf="noMatches">
			<div class="col-md-12 text-center text-bigger text-muted">No matches found</div>
		</div>
		<div class="row-fluid" *ngIf="!list.length && !noMatches">
			<div class="col-md-12 text-center text-bigger text-muted">No posts</div>
		</div>
		<br>
		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</section>
</div>
