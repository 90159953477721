import { Component, OnInit, forwardRef } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { map, mapValues } from 'lodash';

import { Mixin } from 'utils/mixin.decorator';

import { UserService } from 'angularjs-providers/user.provider';

import { ColumnsManagerMixin, ExportMixin } from 'commons/mixins';
import { ListComponent } from 'commons/components/list/list.component';

import {
    ApplicationStatus,
    ApplicationStatusTransitions,
    LenderApplication,
    LenderApplicationsResourceService,
} from './applications.service';

import { ApplicationStatusColors } from './application-status-colors';
import { RelationStatusColors } from '../relation-status-colors';
import { ApplicationTypes } from './application-types';
import { columnsConfig } from './columns-config';
import { ApplicationExpirationDateModalComponent } from '.';
import { Entity } from 'tpo/documents/documents.interface';
import { ApplicationChangeStatusModalComponent } from 'lender/applications/app-status-modal/app-status.modal';
import { CellSelectComponent, CellSelectConfig } from 'commons/components/cell-select/cell-select.component';
import { Observable } from 'rxjs';
import { IntDateProcessor, ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { PagedData } from 'commons/services/http';

@Mixin([ ColumnsManagerMixin, ExportMixin ])
@Component({
	templateUrl: './applications-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ApplicationsListComponent) },
		{ provide: NewPagedListComponent, useExisting: forwardRef(() => ApplicationsListComponent) },
	],
})
export class ApplicationsListComponent extends NewPagedListComponent<LenderApplication> implements OnInit, ColumnsManagerMixin, ExportMixin {
	static listName = 'applications';

	applicationStatusColors = ApplicationStatusColors;
	relationStatusColors = RelationStatusColors;

	modalRef: BsModalRef;

	// ColumnsManagerMixin
	// GOTCHA: objects and arrays should have default value to properly work with mixins
	StorageService;
	localStorageName = 'LenderApplicationsCols';
	columns = new Map();
	columns$;
    columnValues: () => [];
	prepareColumns;
	toggleColumn;
	getColumnVisibility;

	// ExportMixin
	url: any;
	export: any;
    lenderId: number;

	moreFilters: boolean = false;
    hasStatusPermission: boolean = false;
    appStatuses: ApplicationStatusTransitions;

    filters = {
        applicationType: ApplicationTypes,
        applicationStatus: this.service.filters.applicationStatus(),
        optionalStatus: this.service.filters.optionalStatus(),
        channelName: this.service.filters.channelName(),
        accountExecutive: this.service.filters.accountExecutive(),
        alternativeAssignee: this.service.filters.alternativeAssignee(),
    };

	constructor(
		router: UIRouter,
		public User: UserService,
		public service: LenderApplicationsResourceService,
		public modalService: BsModalService,
	) {
		super(router);
        this.hasStatusPermission = this.User.profile.can('EDIT_STATUS');

		this.prepareFilters();
		this.readColumnsConfig();
        this.loadAppStatuses();
	}

	prepareFilters() {
        Object.assign(
            this.defaultParams,
            {
                applicationType: [],
                applicationStatus: [],
                optionalStatus: [],
                channelName: [],
                accountExecutive: [],
                alternativeAssignee: [],
                createdAfter: null,
                createdBefore: null,
            }
        );

        Object.assign(this.groupedParamProcessors, {
            createdAfter: IntDateProcessor,
            createdBefore: IntDateProcessor,
        });
	}

    async loadAppStatuses() {
        this.appStatuses = await this.service.getApplicationStatusTransitions().toPromise();
    }

    updateOptionalFilter(item, newStatus) {
        this.filters.optionalStatus = this.service.filters.optionalStatus();
        item.optionalStatus = newStatus;
    }

	readColumnsConfig() {
		this.prepareColumns(columnsConfig);
	}

	ngOnInit() {
		super.ngOnInit();

		if (this.User.profile.isLender) {
            this.lenderId = this.User.profile.organization.id;
			this.url = `/api/download/lenders/${this.lenderId}/applications/export`;
		}
	}

	setFilter(filter, resetPage: boolean = true) {
		const processedFilter = mapValues(filter, (value: any, key: string) => (
			!this.filters[key]
				? value
				: map(value || [], 'id')
		));

		super.setFilter(processedFilter, resetPage);
	}

	toggleFilters() {
		this.moreFilters = !this.moreFilters;
	}

	getExportParams = () => {
		return this.encodeGroupedParams(this.params);
	}

    loadList(params: ListParams): Observable<PagedData<LenderApplication[]>> {
        return this.service.getList(params);
    }

	editExpirationDate(application): void {
		const initialState = {
			application,
			onConfirm: (): void => {
				this.updateList();
			},
		};

		this.modalRef = this.modalService.show(ApplicationExpirationDateModalComponent, {
			initialState,
			class: 'modal-smd modal-new',
		});
	}

    setAppStatus(application: LenderApplication, status: ApplicationStatus) {
        const { id: statusCode } = status;
        const initialState: Partial<ApplicationChangeStatusModalComponent> = {
            applicationSummary: application,
            statusCode,
            updateApplication: (newApplication: LenderApplication) => {
                Object.assign(application, newApplication);
            },
        };

        this.modalRef = this.modalService.show(ApplicationChangeStatusModalComponent, {
            initialState,
            class: 'modal-smd modal-new',
        });
    }

    altAssignedConfig: CellSelectConfig = {
        bindValue: null,
        bindLabel: 'name',
        title: 'Change Assigned',
        label: 'Assignment',
        message: '<p>Please choose new Assignment to set for the selected application</p>',
    };

	openAltAssigned(component: CellSelectComponent, item: LenderApplication) {
        const { alternativeAssignee } = item;
        const values$ = this.service.getAppAlternativeAssignee(item.applicationId);
        component.open(values$, alternativeAssignee);
    }

    async saveAltAssigned([component, alternativeAssignee]: [CellSelectComponent, Entity], item: LenderApplication) {
        try {
            component.setResolving(true);
            await this.service.setAssignedStatus(item.applicationId, alternativeAssignee).toPromise();
            this.filters.alternativeAssignee = this.service.filters.alternativeAssignee();
            item.alternativeAssignee = alternativeAssignee;
            component.setResolving(false);
            component.close();
        } catch ({ error }) {
            component.setError(error);
            component.setResolving(false);
        }
    }
}
