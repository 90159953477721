<div class="company-page-edit">
	<div class="yellow-head text-center text-bigger">
		Here you can customize your Company Information Page which will show
		to clients
	</div>
	<div class="company-page">
		<form [formGroup]="companyPageForm" class="remote-resource" (onValidSubmit)="save()" [ngClass]="{resolved: companyPageResource.$resolved}">
			<form-errors [form]="companyPageForm"></form-errors>

			<div class="clearfix">
				<div class="pull-right" [class.hidden]="!User?.profile?.can('EDIT_INFORMATION_PAGE')">
					<a class="btn btn-primary" [class.hidden]="editing" (click)="showForm()">Edit</a>
					<button type="submit" class="btn btn-primary" [class.hidden]="!editing" [disabled]="submitted">Save</button>
					<a class="btn btn-default" [class.hidden]="!editing" (click)="reset()">Cancel</a>
				</div>
			</div>
			<div class="row-fluid">
				<div class="col-sm-3">
					<div class="logo">
						<img [src]="getImageURL()" />
					</div>
					<label class="logo-btn" *ngIf="User?.profile?.can('EDIT_INFORMATION_PAGE')">
						<input id="selectImage" type="file" name="img" onclick="value = null" (change)="showUploadModal($event.target.files)" accept="image/*">
						Change Logo
					</label>
				</div>
				<div class="col-sm-9">
					<div class="form-group" [ngClass]="{ 'has-error': editing && companyPageForm.get('displayName').invalid }">
						<label class="control-label" [ngStyle]="{opacity: editing ? '1' : '0'}">{{companyPageForm.getFieldLabel('displayName')}}</label>
						<editable-component [editable]="editing">
							<ng-template #editTemplate>
								<input type="text" name="displayName" formControlName="displayName" class="form-control" maxlength="100" tabindex="1" />
							</ng-template>
							<ng-template #viewTemplate>
								<h3>{{companyPageData.displayName}}</h3>
							</ng-template>
						</editable-component>
					</div>
					<div class="form-group no-margin" [ngClass]="{ 'has-error': editing && companyPageForm.get('description').invalid }">
						<editable-component [editable]="editing">
							<ng-template #editTemplate>
								<label class="control-label">{{companyPageForm.getFieldLabel('description')}}</label>

								<textarea name="description" formControlName="description" class="form-control resize-vertical"
								 rows="4" maxlength="{{maxDescriptionLength}}" tabindex="2"></textarea>

								<span class="text-smaller text-muted">Characters left: {{charsLeft()}}</span>
							</ng-template>
							<ng-template #viewTemplate>
								<span>{{companyPageData.description}}</span>
							</ng-template>
						</editable-component>
					</div>
				</div>
			</div>

			<div class="row-fluid prop-blocks form-group">
				<div class="col-sm-4 prop-block" [ngClass]="{ 'has-error': editing && companyPageForm.get('contactName').invalid, hidden: !editing && !companyPageData.contactName }">
					<div class="text-block">
						<editable-component [editable]="editing">
							<ng-template #editTemplate>
								<input type="text" name="contactName" formControlName="contactName" class="form-control" maxlength="100" tabindex="3" />
							</ng-template>
							<ng-template #viewTemplate>
								<h5>{{companyPageData.contactName}}</h5>
							</ng-template>
						</editable-component>
						<label class="control-label">{{companyPageForm.getFieldLabel('contactName')}}</label>
						<span class="glyphicon glyphicon-user text-light"></span>
					</div>
				</div>
				<div class="col-sm-4 prop-block form-group" [ngClass]="{ 'has-error': editing && companyPageForm.get('webSite').invalid, hidden: !editing && !companyPageData.webSite }">
					<div class="text-block">
						<editable-component [editable]="editing">
							<ng-template #editTemplate>
								<input type="text" name="webSite" formControlName="webSite" class="form-control" maxlength="100" tabindex="4" />
								<label class="control-label">{{companyPageForm.getFieldLabel('webSite')}}</label>
							</ng-template>
							<ng-template #viewTemplate>
								<h5 class="text-bigger">
									<a [href]="companyPageData.webSite | url" target="_blank">Open Website</a>
								</h5>
								<label class="control-label">{{companyPageData.webSite | url}}</label>
							</ng-template>
						</editable-component>
						<span class="glyphicon glyphicon-globe text-light"></span>
					</div>
				</div>
				<div class="col-sm-4 prop-block form-group" [ngClass]="{ 'has-error': editing && companyPageForm.get('phone').invalid, hidden: !editing && !companyPageData.phone }">
					<div class="text-block">
						<editable-component [editable]="editing">
							<ng-template #editTemplate>
								<input phoneFormatter type="text" name="phone" formControlName="phone" class="form-control" tabindex="5" />
							</ng-template>
							<ng-template #viewTemplate>
								<h5>{{companyPageData.phone || '' | tel}}</h5>
							</ng-template>
						</editable-component>
						<label class="control-label">{{companyPageForm.getFieldLabel('phone')}}</label>
						<span class="glyphicon glyphicon-earphone text-light"></span>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
