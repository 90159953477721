import { Component, EventEmitter, HostBinding, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { APPROVAL_TYPE, ShareRequest, TpoShareRequestsService } from './tpo-share-requests.service';
import { RequestReviewModalComponent } from './request-review-modal/request-review.modal.component';

@Component({
    selector: 'tpo-home-share-requests',
    templateUrl: './tpo-share-requests.component.html',
    host: {
        'class': 'remote-resource',
    },
})
export class TpoShareRequestsComponent implements OnDestroy {
    @Output('onLoaded') _onLoaded = new EventEmitter<boolean>();
    @HostBinding('class.resolved') resolved = false;
    refresh$ = new Subject();
    modalRef: BsModalRef;
    requests$ = this.refresh$.pipe(
        startWith(true),
        tap(() => this.resolved = false),
        switchMap(() => this.tpoShareRequestsService.getShareRequests()),
        shareReplay(1),
        tap(() => {
            this.resolved = true;
            this._onLoaded.emit(true);
        }),
    );
    APPROVAL_TYPE = APPROVAL_TYPE;

    constructor(
        private readonly tpoShareRequestsService: TpoShareRequestsService,
        private readonly modalService: BsModalService,
    ) {}

    ngOnDestroy() {
        this.refresh$.complete();
    }

    reviewRequest({ id, datMatchItemId, approvalType }: ShareRequest) {
        const shareRequestId = approvalType === APPROVAL_TYPE.CLIENT_SHARE ? id : datMatchItemId;
        const initialState: Partial<RequestReviewModalComponent> = {
            shareRequestId,
            approvalType,
            onFinalize: () => this.refresh$.next(),
        };
        this.modalRef = this.modalService.show(
            RequestReviewModalComponent, {
                class: 'modal-new modal-smd',
                initialState,
            }
        );
    }
}
