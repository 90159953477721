import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UserService } from 'angularjs-providers/user.provider';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PasswordChangeModalComponent } from 'login/password-change-modal/password-change-modal.component';
import { ForgotEntityType } from 'login/registration/registration.service';
import { ForgotModalComponent } from 'login/forgot-modal/forgot-modal.component';

@Component({
    templateUrl: './login.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
    resolved = true;
    readonly cookieEnabled = navigator.cookieEnabled;
    loginForm = new RealmFormGroup({
        username: new RealmFormControl('username', { label: 'Username' }, Validators.required),
        password: new RealmFormControl('password', { label: 'Password' }, Validators.required),
    });
    protected modalRef: BsModalRef;

    constructor(
        private readonly userService: UserService,
        private readonly cdr: ChangeDetectorRef,
        private readonly modalService: BsModalService,
    ) {
    }

    submit() {
        if (!this.cookieEnabled) return;
        this.resolved = false;
        this.userService.login(this.loginForm.value, this.onSuccesfullLogin, this.onLoginFail);
    }

    onSuccesfullLogin = () => {
        this.resolved = true;
        this.cdr.markForCheck();
    };

    onLoginFail = ({ data, status }) => {
        const { message, errorDetails } = data || {};
        const isReset = message == 'Password reset required';
        this.resolved = true;


        if (status === 401 && message == 'Credentials expired' || isReset) {
            this.cdr.markForCheck();
            const token = errorDetails && errorDetails[0]?.value || '';


            return this.openPasswordChangeModal(token, isReset);
        }

        this.loginForm.setServerError(data);
        this.cdr.markForCheck();
    };

    openPasswordChangeModal = (resetToken: string, isReset: boolean) => {
        const initialState: Partial<PasswordChangeModalComponent> = {
            ...(isReset ? { passwordNotification: 'You logged in with temporary password, please set new password for security reason.' } : {}),
            onReset: () => {
                this.loginForm.reset();
            },
            resetToken,
        };
        this.modalRef = this.modalService.show(PasswordChangeModalComponent, {
            initialState,
            class: 'modal-new',
        });
    }

    onPasswordBlur = () => {
        // Trigger validation on blur
        // Walkaround for not triggering statusChanges observable on type=password field.
        this.loginForm.get('password').updateValueAndValidity();
    };


    showForgotModal(entity: ForgotEntityType) {
        const initialState: Partial<ForgotModalComponent> = { entity };
        this.modalRef = this.modalService.show(ForgotModalComponent, {
            initialState,
            class: 'modal-new modal-cmd',
        });
    }

    forgotPassword() {
        this.showForgotModal('password');
    }

    forgotUserName() {
        this.showForgotModal('username');
    }
}
