<section class="social-compliance-monitoring-settings-individuals remote-resource" [class.resolved]="isResolved()">
	<div class="filters-rows">
		<div class="row-fluid filter-labels">
			<div class="col-sm-3">
				<span class="text-muted">Search</span>
			</div>
			<div class="col-sm-a"></div>
		</div>
		<div class="row-fluid filter-selectors">
			<div class="col-sm-3">
				<search-list placeholder="Name or NMLS ID"></search-list>
			</div>
			<div class="col-sm-a"></div>
		</div>
	</div>
	<div class="rows-bordered">
		<div class="row-fluid header">
			<div class="col-sm-3" [ngClass]="getSortClass('fullName')" (click)="setSort('fullName')">
				<b>Name</b>
			</div>
			<div class="col-sm-3">
				<b>Accounts</b>
			</div>
			<div class="col-sm-2" [ngClass]="getSortClass('monitoringStatus')"  (click)="setSort('monitoringStatus')">
				<b>Alert</b>
			</div>
		</div>

		<ng-container *ngFor="let contact of list">
			<div class="row-fluid"
				 [class.expandable]="contact.linksCount"
				 [attr.aria-controls]="getAccountsListId(contact)"
				 [attr.aria-expanded]="isAccountsListExpanded(contact)"
				 (click)="contact.linksCount && toggleAccountsList(contact)"
			>
				<div class="col-sm-3 va-m">
					<div class="contact">
						<div class="expandable-icon" *ngIf="contact.linksCount > 0"></div>
						<div class="link-container">
							<a uiSref="people.contacts.:contactId.information" [uiParams]="{ contactId: contact.contactId }" target="_blank" (click)="stopPropagation($event)">{{contact.fullName}}</a>
							<i class="c-icon c-icon-phone" tooltip="Mobile User" placement="top" *ngIf="contact.isMobileUser"></i>
							<div class="text-muted text-smaller" *ngIf="contact.nmlsId">
								NMLS ID:
								<a uiSref="account.licenses.nmls.:nmlsId" [uiParams]="{ nmlsId: contact.nmlsId }" target="_blank" (click)="stopPropagation($event)">{{contact.nmlsId}}</a>
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-3 va-m">
					<sm-networks-connection
						[networksConnection]="contact.networks"
					></sm-networks-connection>
				</div>
                <ng-container [ngSwitch]="User.hasFeature(PACKAGE_FEATURE.COMPLIANCE)">
                    <ng-container *ngSwitchCase="false">
                        <div class="col-sm-2 va-m">
                            <toggle
                                [ngModel]="contact.monitoringStatus"
                                [values]="{true: 'on', false: 'off'}"
                                (toggle)="goToSubscribe()"
                                (click)="stopPropagation($event)"
                            ></toggle>
                        </div>
                        <div class="col-sm-2 va-m">
                            <toggle
                                [ngModel]="contact.monitoringStatus"
                                [values]="{true: 'on', false: 'off'}"
                                (toggle)="goToSubscribe()"
                                (click)="stopPropagation($event)"
                            ></toggle>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <div class="col-sm-2 va-m">
                            <toggle
                                [disabled]="!User.can('TPO_SMC_ORDER_MONITORING') || contact.$disabled"
                                [ngModel]="contact.monitoringStatus"
                                [values]="{true: 'on', false: 'off'}"
                                (toggle)="toggleContactMonitoring(contact)"
                                [tooltip]="User.can('TPO_SMC_ORDER_MONITORING') ? '' : 'You don\'t have permissions'"
                                [loading]="contact.$resolving"
                                (click)="stopPropagation($event)"
                            ></toggle>
                        </div>
                        <div class="col-sm-2 va-m">
                        </div>
                    </ng-container>
                </ng-container>
			</div>

			<ng-container *ngIf="contact.linksCount > 0">
				<div class="row-fluid accounts remote-resource"
					 [class.resolved]="contact.accounts?.$resolved"
					 [id]="getAccountsListId(contact)"
					 [collapse]="!isAccountsListExpanded(contact)"
				>
					<div class="rows-bordered">
						<ng-container *ngFor="let account of contact.accounts">
							<div class="row-fluid">
								<div class="col-sm-3 va-m">
									<div class="account">
										<div class="icon-container">
											<sm-icon [config]="account" [minified]="true"></sm-icon>
										</div>
										<div class="link-container">
											<ng-container *ngIf="account.networkCode !== 'RS' && account.link; then linkAnchor; else linkName"></ng-container>
											<ng-template #linkAnchor>
												<a [href]="account.link" target="_blank">{{account.name}}</a>
											</ng-template>
											<ng-template #linkName>
												{{account.name}}
											</ng-template>
										</div>
									</div>
								</div>
								<div class="col-sm-3 va-m">
									<monitoring-status [status]="account.lastConnectionStatus"></monitoring-status>
								</div>
								<div class="col-sm-2 va-m">
									<toggle
										[disabled]="!User.can('TPO_SMC_ORDER_MONITORING') || contact.$disabled || contact.$resolving"
										[ngModel]="account.monitoringStatus"
										[values]="{true: 'on', false: 'off'}"
										(toggle)="toggleAccountMonitoring(contact, account)"
										[tooltip]="User.can('TPO_SMC_ORDER_MONITORING') ? '' : 'You don\'t have permissions'"
										[loading]="account.$resolving"
									></toggle>
								</div>
								<div class="col-sm-2 va-m"></div>
							</div>
						</ng-container>
					</div>
				</div>
			</ng-container>
		</ng-container>

		<div class="row-fluid" *ngIf="!list.length">
			<div class="col-sm-12 text-center text-bigger text-muted">No individuals</div>
		</div>
	</div>
	<br>
	<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
</section>
