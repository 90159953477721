import { Injector, Injectable } from '@angular/core';

declare let apiPath: string;

@Injectable()
export class SharedUserManagementResourceService {
	userManagement: any;

	constructor(
		public injector: Injector,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const path = `${apiPath}/users`;

		this.userManagement = $resource(path, {}, {
			activate: {
				method: 'put',
				url: `${path}/:userId/active`,
				params: {
					userId: '@userId',
					notifyUser: '@notifyUser',
				},
			},
			deactivate: {
				method: 'delete',
				url: `${path}/:userId/active`,
				params: {
					userId: '@userId',
				},
			},
			unlock: {
				method: 'delete',
				url: `${path}/:userId/lock`,
				params: {
					userId: '@userId',
				},
			},
			resendUsername: {
				method: 'put',
				url: `${path}/:userId/username`,
				params: {
					userId: '@userId',
				},
			},
			resendPassword: {
				method: 'put',
				url: `${path}/:userId/password-reset`,
				params: {
					userId: '@userId',
				},
			},
			verifyPin: {
				method: 'put',
				url: `${path}/:userId/pin-verification`,
				params: {
					userId: '@userId',
				},
			},
		});
	}

	activate = (...args) => this.userManagement.activate(...args);
	deactivate = (...args) => this.userManagement.deactivate(...args);
	unlock = (...args) => this.userManagement.unlock(...args);
	resendUsername = (...args) => this.userManagement.resendUsername(...args);
	resendPassword = (...args) => this.userManagement.resendPassword(...args);
	verifyPin = (...args) => this.userManagement.verifyPin(...args);
}
