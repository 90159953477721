import { Injectable, Injector } from '@angular/core';

import { NgResource } from 'commons/interfaces';

import { AuthenticationData } from 'shared/2FA/2FA.interface';

declare let apiPath: string;

@Injectable()
export class TwoFactorAuthenticationService {
	public tfa: any;

	constructor(
		injector: Injector,
	) {
		const $resource = injector.get('$injector').get('$resource');

		this.tfa = $resource(`${apiPath}/pair`);

		this.tfa = $resource(`${apiPath}/pair`, {}, {
			pair: {
				method: 'post',
			},
			resendEmail: {
				method: 'post',
				url: `${apiPath}/resendEmail`,
			},
		});

	}

	pair(username: string, accessToken: string): NgResource<AuthenticationData> {
		return this.tfa.pair({ username, accessToken })
	}

	resendEmail(username: string, accessToken: string): NgResource {
		return this.tfa.resendEmail({ username, accessToken })
	}
}
