import { NgModule } from '@angular/core';
import { WarehousesService } from 'shared/account/information/warehouse/warehouses.service';
import { WarehousesComponent } from 'shared/account/information/warehouse/warehouses.component';
import { CommonModule } from '@angular/common';
import { WarehousesItemComponent } from 'shared/account/information/warehouse/warehouses-item/warehouses-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { TpoAccountModule } from 'tpo/account';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
        TpoAccountModule,
    ],
    declarations: [
        WarehousesComponent,
        WarehousesItemComponent,
    ],
    providers: [
        WarehousesService,
    ],
})
export class SharedWarehousesModule {
}
