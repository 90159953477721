import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

import { ExportService } from './export.service';
import { ExportListComponent } from './list';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivesModule,
		ComponentsModule,
	],
	declarations: [
		ExportListComponent,
	],
	entryComponents: [
		ExportListComponent,
	],
	providers: [
		ExportService,
	],
})
export class TPOExportModule {}
