import { Directive, Input, ɵdetectChanges } from '@angular/core';
import { allowedNetworks } from 'commons/components/sm-icons';

@Directive()
export class CommonPostComponent {
    @Input() name: string;
    @Input() profilePictureUrl: string;
    @Input() webLink: string;
    @Input() networkCode: allowedNetworks | string;
    @Input() textTruncated: any;
    @Input() attachments: any;
    @Input() previewDate: Date;
    @Input() linkPreview;
    @Input() sanitize: (params?: any) => any = () => {};
    @Input() resolved: boolean = false;

    isAttachmentsRendered: boolean = false;
    attachmentsLoaded = value => {
        this.isAttachmentsRendered = value;
        ɵdetectChanges(this);
    };
}
