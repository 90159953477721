import { Component, TemplateRef, Input, Injector, ɵmarkDirty, EventEmitter, Output } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { filter, remove, forEach, set, omit, isEqual, cloneDeep } from 'lodash';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';

import { OwnersResourceService } from 'tpo/contacts/owners-resource.service';
import { TpoAccountResourceService } from 'tpo/contacts/tpo-account-resource.service';
import { OwnershipModalComponent } from 'tpo/contacts/ownership-modal.component';
import { hasContactPermission } from 'tpo/contacts/contact-permissions';


@Component({
    selector: 'people-ownership-list',
	templateUrl: './people-ownership-list.component.html',
})
export class PeopleOwnershipListComponent extends ListComponent {
	static listName = PeopleOwnershipListComponent.addName('peopleOwnershipListComponent');
	listName = PeopleOwnershipListComponent.listName;

	owners: any = [];
	ownersFiltered: any = [];
	newOwner: object = null;
	USER = {
		LOCKED: 'L',
		ACTIVE: 'A',
		INACTIVE: 'I',
		REMOVED: 'R',
	};

	prevQueryParams = {};
	accountCompanyInfo: {
		ownership99PercentRequired: boolean;
		[key: string]: any;
	} = {
		ownership99PercentRequired: true,
	};
	modalRef: BsModalRef;
	selectedOwner: any;
	verificationSent: boolean = false;
    contactRoute = '^.contacts.:contactId.information';
	@Input() isEmbedded?: boolean;
	@Input() isWizard?: boolean;
    @Output('updateTotal') update: EventEmitter<number> = new EventEmitter<number>();

	constructor(
        public User: UserService,
        public injector: Injector,
        public OwnersResource: OwnersResourceService,
        public AccountResource: TpoAccountResourceService,
        public modalService: BsModalService,
        transitionService: TransitionService,
        stateService: StateService,
    ) {
		super(transitionService, stateService);

		this.filters = {
			previous: [
				{value: false, title: 'Current'},
				{value: true, title: 'Previous'},
			],
		};

		this.defaultFilters = {
			...this.defaultFilters,
			previous: false,
		};

		this.modalService.onHide.subscribe((reason: string) => {
			this.newOwner = null;
			this.selectedOwner = null;
			this.verificationSent = false;
		});

		this.accountCompanyInfo = this.AccountResource.get(this.getAccountCompanyParams());
        this.accountCompanyInfo.$promise
            .then((result) => {
                ɵmarkDirty(this);
            });

	}

	ngOnInit() {
		super.ngOnInit();
        if (this.isEmbedded) {
            this.contactRoute = '.:contactId.information';
        }
	}

	getAccountCompanyParams() {
		return {};
	}

	getLoadListParams(queryParams) {
		return this.getClearParams(queryParams);
	}

	ngOnDestroy() {
		if (this.modalRef) {
			this.modalRef.hide();
		}
		super.ngOnDestroy();
	}

	getOwnersFiltered = ([...items]) => ( items ? {
		true: remove(items, 'previous'),
		false: items,
		} : []
	)

	setFullAddressProperty(owners) {
		forEach(owners, (owner) => {
			if (owner.contactType === 'company') {
				const addressArray = filter([owner.address, owner.city, owner.state, owner.zip]);
				set(owner, 'fullAddress', addressArray.join(', '));
			}
		});
	}

	loadList(queryParams, forceLoad = false) {
		this.owners.$resolved = false;

		// skip data load if only previous filter changed
		const shoulLoadData = !isEqual(omit(queryParams, 'previous'), omit(this.prevQueryParams, 'previous'));
		this.prevQueryParams = cloneDeep(queryParams);

		if (!forceLoad && !shoulLoadData) {
			this.owners.$resolved = true;
			return;
		}

		return this.OwnersResource.query(this.getLoadListParams(queryParams)).$promise
			.then((result) => {
				this.setFullAddressProperty(result);
				this.owners = result;
				this.ownersFiltered = this.getOwnersFiltered(result);
				this.updateTotal(this.owners);
				this.noMatches = !result.length && (this.filtersApplied || queryParams.q);

                ɵmarkDirty(this);
			});
	}

	isSomeFiltersApplied = (queryParams) => {
		const skipParams = [ ...this.omitParams, 'previous' ];
		return !isEqual(omit(queryParams, skipParams), omit(this.defaultFilters, skipParams));
	}

	updateTotal(owners) {
        const total = owners.reduce((value, item) => {
            value += item.ownershipPercentage || 0;
            return value;
        }, 0);
        this.update.emit(total);
	}

	hasPermission(permission) {
		return hasContactPermission(this.User && this.User.profile, permission);
	}

	verifyOwner(template: TemplateRef<any>, owner) {
		this.selectedOwner = owner;
        this.selectedOwner.$resolved = true;
        this.modalRef = this.modalService.show(template, {class: 'modal-sm verify-ownership-modal modal-new'});
	}

	sendNotification() {
		this.selectedOwner.$resolved = false;
		this.OwnersResource.verify(
			{contactId: this.selectedOwner.contactId},
			{},
			(result) => {
				this.selectedOwner.$resolved = this.verificationSent = true;
			},
			() => {
				this.selectedOwner.$resolved = true;
			},
		);
	}

	showUpdateOwnership() {
		const initialState = {
			newOwners: [...filter(this.owners, {previous: false}), ...filter(this.owners, {contactType: 'company', previous: true})],
			accountCompanyInfo: this.accountCompanyInfo,
			onUpdate: () => {
				this.loadList(this.params, true);
			},
		};
		this.modalRef = this.modalService.show(OwnershipModalComponent, {
			initialState,
			class: 'modal-vlg ownership-modal modal-edit modal-new',
		});
	}
}
