import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { ListComponent } from 'commons/components/list/list.component';
import { PagedListComponent } from 'commons/components/list/paged-list.component';

import { CampaignsResourceService } from '../../campaigns-resource.service';

@Component({
	selector: 'campaign-participants',
	templateUrl: './campaign-participants.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => CampaignParticipantsComponent) },
	],
})
export class CampaignParticipantsComponent extends PagedListComponent {
	static listName = CampaignParticipantsComponent.addName('campaignParticipants');
	listName = CampaignParticipantsComponent.listName;

	list: any = [];

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		public campaignsResource: CampaignsResourceService,
	) {
		super(transitionService, stateService);

		const { campaignId } = stateService.params;

		this.defaultFilters = {
			...this.defaultFilters,
			campaignId,
		};

		this.omitParams.push('campaignId');
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.campaignsResource.campaignParticipants.get(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.list = data;
				this.noMatches = !data.length && queryParams.q;
			});
	}

	isContactExpandable = ({ hasEnrolledAccounts }): boolean => Boolean(hasEnrolledAccounts);

	getContactClassName = (contact) => this.isContactExpandable(contact) ? [ 'clickable', 'expandable' ] : [];

	getAccountsListId = ({ contactId }): string => `contact-${contactId}-accounts-list`;

	isAccountsListExpanded = ({ accounts }): boolean => Boolean(accounts);

	toggleAccountsList = (contact): void => {
		contact.accounts = contact.accounts ? null : this.fetchAccounts(contact);
	}

	fetchAccounts = (contact) => {
		const {
			contactId,
			nmlsId,
		} = contact;
		const params = {
			...this.getClearParams(this.params),
		};
		return this.campaignsResource.campaignParticipantLinks.get(
			{
				...params,
				idType: contactId ? 'CONTACTID' : 'NMLSID',
				id: contactId || nmlsId,
			},
		);
	}
}
