import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { TaskNotificationModule} from 'shared/social-compliance/task-notification';
import { SupportingDocumentsModule } from 'shared/supporting-documents';

import { SocialAccountsModule } from 'tpo/social-accounts';
import { TpoArchiveModule } from 'tpo/social-compliance/archive';

import { PostHistoryModalComponent } from './post-history-modal/post-history-modal.component';
import { AddLinkModalComponent } from './add-link-modal/add-link-modal.component';
import { FeedComponent } from './feed.component';
import { FeedService } from './feed.service';

@NgModule({
	imports: [ // TODO: check if we need all this imports
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BsDropdownModule,
		PopoverModule,
		ButtonsModule,
		PipesModule,
		DirectivesModule,
		ComponentsModule,
		TaskNotificationModule,
		SupportingDocumentsModule,
		SocialAccountsModule,
		TpoArchiveModule,
	],
	declarations: [
		FeedComponent,
		PostHistoryModalComponent,
		AddLinkModalComponent,
	],
	entryComponents: [
		FeedComponent,
		PostHistoryModalComponent,
		AddLinkModalComponent,
	],
	providers: [
		FeedService,
	],
})

export class FeedModule {}
