import { Component, Input } from '@angular/core';
import { ScorecardFindingDetailRow } from 'shared/due-diligence/scorecard/scorecard-finding.interface';


@Component({
    selector: 'scorecard-finding-detail-row',
    templateUrl: '/scorecard-finding-detail-row.component.html',
})
export class ScorecardFindingDetailRowComponent {
    @Input() detailRow: ScorecardFindingDetailRow;
}
