import { get, each } from 'lodash';
import { Component, OnInit, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { PagedListComponent } from 'commons/components/list/paged-list.component';
import { ListComponent } from 'commons/components/list/list.component';
import { NotificationModalComponent } from 'commons/components/modals';

import { SocialAccountsResourceService } from '../social-accounts-resource.service';

@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => SocialAccountsIndividualsComponent) },
	],
})
export class SocialAccountsIndividualsComponent extends PagedListComponent implements OnInit {
	static listName = SocialAccountsIndividualsComponent.addName('accountsIndividuals');
	listName = SocialAccountsIndividualsComponent.listName;

	modalRef: BsModalRef;
	User: any;
	list: any = [];

	accountInfo: any = {};

	canEditMonitoring: boolean = false;
	monitoringBillingEnabled: boolean = false;
    newMenu: boolean;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		public modalService: BsModalService,
		public socialAccountsService: SocialAccountsResourceService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;
        this.newMenu = this.User.isTpo;

		this.canEditMonitoring = this.User.isTpo && this.User.can('TPO_SMC_ORDER_MONITORING');

		this.defaultFilters = {
			...this.defaultFilters,
			nmlsId: this.User.organization.nmlsId,
			id: null,
			size: 50,
		};

		this.omitParams = [
			...this.omitParams,
			'nmlsId',
			'id',
		];
	}

	ngOnInit(): void {
		this.getAccountInfo().then(() => {
			this.socialAccountsService.company.billing.get({
				organizationId: this.accountInfo.organizationId || this.accountInfo.nmlsId,
			}, (result) => {
				this.defaultFilters.nmlsId = this.User.isTpo ? this.User.organization.nmlsId : this.accountInfo.nmlsId;
				this.monitoringBillingEnabled = result.enabled;
				super.ngOnInit();
			});
		});
	}

	loadList(queryParams): void {
		this.list.$resolved = false;

		const params = this.getListParams({ queryParams, withhNmls: true });

		return this.socialAccountsService.individuals.list.get(params)
			.$promise
			.then((data: any): void => {
				this.list = data;

				each(this.list, (contact) => {
					if (this.User.isTpo) {
						contact.contactSref = 'people.contacts.:contactId.information';
						contact.contactNmlsSref = 'account.licenses.nmls.:nmlsId';
						return;
					}

					contact.contactSref = '^.^.contacts.:contactId.information';
					contact.contactNmlsSref = '^.^.account.licenses.nmls.:nmlsId';
				});
			});
	}

	getAccountsListId = ({ contactId }): string => `${contactId}_collapse`;

	fetchAccounts = (contactId: number): any => {
		const params = this.getListParams({ queryParams: this.params });

		return this.socialAccountsService.individuals.contact.getAccounts({
			...params,
			contactId,
		});
	}

	toggleAccountsList = (contact: any): void => {
		const { contactId } = contact;
		contact.accounts = this.isAccountsListExpanded(contact) ? null : this.fetchAccounts(contactId);
	}

	isAccountsListExpanded = ({ accounts }): boolean => !!accounts;

	stopPropagation = (event): void => {
		event.stopPropagation();
	}

	getAccountInfo() {
		return new Promise<void>((resolve) => {
			if (this.User.isTpo || !this.stateService.includes('**.nmls.**')) {
				this.socialAccountsService.accountInfo.get({
					id: this.User.isTpo ? this.User.organization.id : this.stateService.params.id,
				}, (accountInfo) => {
					this.accountInfo = accountInfo;
					resolve();
				});

				return;
			}

			this.socialAccountsService.nmlsCompanyInfo.get({
				nmlsId: this.stateService.params.id,
			}, (accountInfo) => {
				this.accountInfo = accountInfo;
				resolve();
			});
		});
	}

	getListParams = ({ queryParams, withhNmls = true }) => {
		const basicParams = {
			...this.getClearParams(queryParams),
		};

		if (withhNmls === false) {
			return basicParams;
		}

		return {
			...basicParams,
			nmlsId: this.accountInfo.nmlsId,
		};
	}

	toMonitoring() {
		if (!this.monitoringBillingEnabled) {
			this.showAccessDeniedModal();
			return;
		}

		if (!this.canEditMonitoring) {
			this.showPermissionModal();
			return;
		}

		this.stateService.go('social-compliance.monitoring-settings.individuals');
	}

	showAccessDeniedModal = (): void => {
		this.modalRef = this.modalService.show(NotificationModalComponent, {
			initialState: {
				title: 'Sorry! Access Denied',
				confirmText: 'Ok',
				notification: 'Please contact Customer Service.',
				onConfirm: () => {
					this.modalRef.hide();
				},
			},
			class: 'modal-smd modal-new',
		});
	}

	showPermissionModal = (): void => {
		this.modalRef = this.modalService.show(NotificationModalComponent, {
			initialState: {
				title: 'Sorry! Access Denied',
				confirmText: 'Ok',
				notification: `<div class="denied-lock"></div>
					<p>You currently do not have the necessary permissions to access this section.</p>
					<p>If you believe this is an error, please contact your Administrator to manage your permissions.</p>
				`,
				onConfirm: () => {
					this.modalRef.hide();
				},
			},
			class: 'modal-smd modal-403',
		});
	}
}
