import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

import { GetStartedComponent } from './get-started.component';

const components = [
	GetStartedComponent,
];

@NgModule({
	imports: [
		CommonModule,
		DirectivesModule,
		ComponentsModule,
	],
	declarations: [
		...components,
	],
	entryComponents: [
		...components,
	],
})
export class GetStartedModule {}
