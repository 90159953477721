import { NgModule } from '@angular/core';

import { ComponentsModule } from 'commons/components/components.module';
import { ListingsAlertsStatusService } from 'tpo/status';

import { CompanyListingsModule } from './company';
import { ListingsComponent } from './listings.component';
import { CommonModule } from '@angular/common';
import { SocialMediaModule } from 'shared/social-media';

const components = [
	ListingsComponent,
];

@NgModule({
	imports: [
		ComponentsModule,
		CompanyListingsModule,
        CommonModule,
        SocialMediaModule,
	],
	declarations: [
		...components,
	],
	entryComponents: [
		...components,
	],
	providers: [
		ListingsAlertsStatusService,
	],
})
export class ListingsModule {}
