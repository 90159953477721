import { map, mapValues, omit } from 'lodash';
import moment from 'moment';
import { Component, forwardRef, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { first } from 'rxjs/operators';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { NotificationModalComponent } from 'commons/components/modals';
import { PagedListComponent } from 'commons/components/list';
import { ListComponent } from 'commons/components/list/list.component';
import { NgResourceArray } from 'commons/interfaces';

import { LenderQuestionnairesListService, QuestionnaireBoard } from './questionnaires.service';

@Component({
	templateUrl: './questionnaires.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => QuestionnairesComponent) },
	],
})
export class QuestionnairesComponent extends PagedListComponent {
	static listName = QuestionnairesComponent.addName('questionnaires-list');
	listName = QuestionnairesComponent.listName;

	modalRef: BsModalRef;

	isExportMode = false;
	isSelectedAll = false
	selectedQuestionnaires = {};
	selectedAmount = 0;
	downloading: boolean;

	User: UserProfile;
	list: NgResourceArray<QuestionnaireBoard> = [];
	questionnairesStatuses = {
		CN: {
			color: 'label-danger',
			id: 'CN',
			name: 'Cancelled',
		},
		C: {
			color: 'label-success',
			id: 'C',
			name: 'Completed',
		},
		IP: {
			color: 'label-warning',
			id: 'IP',
			name: 'In Progress',
		},
		NS: {
			color: 'label-danger',
			id: 'NS',
			name: 'Not Started',
		},
	};

	@ViewChild('downloadFile') downloadFile: ElementRef;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		public questionnairesService: LenderQuestionnairesListService,
		public modalService: BsModalService,
		private renderer: Renderer2,

	) {
		super(transitionService, stateService);

		this.User = userService.profile;

		this.filters = {
			status: this.questionnairesService.statuses().$promise.then((data: []) => {
				return map(data, (id) => {
					const { name } = this.questionnairesStatuses[id];
					return { id, name };
				});
			}),
			assignedAfter: null,
			assignedBefore: null,
			questionnaire: this.questionnairesService.questionnaires(),
		};

		this.defaultFilters = {
			status: [],
			...this.defaultFilters,
			assignedAfter: null,
			assignedBefore: null,
			questionnaire: null,
		};

	}

	loadList(queryParams) {
		this.list.$resolved = false;

		return this.questionnairesService.list(this.processParams(queryParams)).$promise
			.then((data: any): void => {
				this.list = data;
				this.noMatches = !data.length && (this.filtersApplied || queryParams.q);
			});
	}

	setFilter(filters, resetPage: boolean = true) {
		const pageParams = resetPage ? { page: 0 } : {};

		mapValues(filters, (value: any, key: string) => {
			if (key === 'questionnaire') {
				filters.questionnaire && (filters.questionnaire = filters.questionnaire.id);
			} else if (['assignedAfter', 'assignedBefore'].indexOf(key) === -1) {
				filters[key] = map(value || [], 'id');
			}
		});

		this.params$.next({ ...this.params$.getValue(), ...pageParams, ...filters });
	}

	processParams(params) {
		return mapValues(this.getClearParams(params), (value, key) => {
			if (['assignedAfter', 'assignedBefore'].indexOf(key) !== -1) {
				return value ? moment(value).format('YYYY-MM-DD') : value;
			} else {
				return value;
			}
		});
	}

	toggleExportMode = (value: boolean) => {
		if (value && this.params.questionnaire) {
			this.isExportMode = true;
			return;
		}

		if (value) {
			this.showNoQuestionnaireForExport();
			return;
		}

		this.isExportMode = false;
		this.resetExport();
	}

	exportQuestionnaires() {
		this.downloading = true;
		const tpoQuestionnaireIds = map(this.selectedQuestionnaires, 'tpoQuestionnaireId');
		this.questionnairesService.exportQuestionnaires(tpoQuestionnaireIds).pipe(first())
			.subscribe(({ headers, body }) => {
				const fileName = headers.get('content-disposition').split('filename=').pop();
				if (body) {
					try {
						const url = window.URL.createObjectURL(body);
						this.renderer.setAttribute(this.downloadFile.nativeElement, 'href', url);
						this.renderer.setAttribute(this.downloadFile.nativeElement, 'download', fileName);
						this.downloadFile.nativeElement.click();

						window.URL.revokeObjectURL(url);

						this.toggleExportMode(false);
						this.downloading = false;
					} catch (e) {
						// error
						this.downloading = false;
					}
				}
			});
	}

	showNoQuestionnaireForExport() {
		this.modalRef = this.modalService.show(NotificationModalComponent, {
			initialState: {
				title: 'Export Questionnaires',
				confirmText: 'Ok',
				notification: 'Questionnaires filter should be applied to conduct export.',
				onConfirm: () => {
					this.modalRef.hide();
				},
			},
			class: 'modal-smd modal-new',
		});
	}

	addQuestionnaire(questionnaire) {
		this.selectedQuestionnaires[questionnaire.tpoQuestionnaireId] = questionnaire;
		this.countAmount();
	}

	addQuestionnairesArray(questionnaires: any[]) {
		this.resetExport();
		this.isSelectedAll = true;

		questionnaires.forEach((questionnaire) => {
			this.selectedQuestionnaires[questionnaire.tpoQuestionnaireId] = questionnaire;
		});

		this.countAmount();
	}

	removeQuestionnaire(questionnaire) {
		this.isSelectedAll = false;
		delete this.selectedQuestionnaires[questionnaire.tpoQuestionnaireId];
		this.countAmount();
	}

	countAmount() {
		this.selectedAmount = Object.keys(this.selectedQuestionnaires).length;
	}

	getAllQuestionnaires() {
		this.list.$resolved = false;
		const params = this.processParams(omit(this.params, ['size', 'page']));
		this.questionnairesService.list(params).$promise
			.then((data: any): void => {
				this.addQuestionnairesArray(data);
				this.list.$resolved = true;
			});
	}

	resetExport() {
		this.isSelectedAll = false;
		this.selectedQuestionnaires = {};
		this.countAmount();
	}

	toggleQuestionnaire(input, questionnaire) {
		input.checked === true
			? this.addQuestionnaire(questionnaire)
			: this.removeQuestionnaire(questionnaire);
	}
}
