import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResource, NgResourceArray } from 'commons/interfaces';
import { LenderChannel, LenderChannelContactInfo } from './lender-channel.interface';
import { Partner } from './partners.interface';


declare let apiPath: string;

@Injectable({ providedIn: 'root' })
export class TpoPartnersService {
    private partners: any;
    private partnersCompany: any;
    private lenderChannelContactInfo: any;

	private baseParams = {
		tpoId: null,
        size: 10,
        page: 0,
	};

    private partnersUrl = `${apiPath}`;

    constructor(public injector: Injector, public user: UserService) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const Resource = injector.get('$injector').get('$resource');

		this.baseParams = {
			...this.baseParams,
            size: 10,
		};

        this.partners = PagedResource(
            this.partnersUrl,
			{ ...this.baseParams },
			{
				getLenders: {
					url: `${this.partnersUrl}/lenders`,
					method: 'GET',
					isArray: true,
				},
                getLenderChannels: {
                    url: `${this.partnersUrl}/lenders/:lenderId/channels/tpos/:channelId`,
					method: 'GET',
					isArray: true,
                },
                getApplicationHistory: {
                    url: `${this.partnersUrl}/tpo-applications-history/:lenderId`,
					method: 'GET',
					isArray: true,
                },
            }
        );
        this.partnersCompany = PagedResource(
            this.partnersUrl,
            {},
            {
                getCompanyPage: {
                    url: `${this.partnersUrl}/lenders/:lenderId/company-page-info`,
					method: 'GET',
					isArray: false,
                },
            }
        );
        this.lenderChannelContactInfo = Resource(
            `${this.partnersUrl}/lenders/:lenderId/channels/:channelId/contactinfo`,
            {
                lenderId: '@lenderId',
                channelId: '@channelId',
            },
            {}
        );
    }

    public getLenders(...args): NgResourceArray<Partner> {
        return this.partners.getLenders(...args);
    }

    public getLenderChannels(...args): NgResourceArray<LenderChannel> {
        return this.partners.getLenderChannels(...args);
    }

    public getApplicationHistory(...args): NgResourceArray<Partner> {
        return this.partners.getApplicationHistory(...args);
    }
    
    public getCompanyPage(...args): any {
        return this.partnersCompany.getCompanyPage(...args);
    }

    public getLenderChannelContactInfo(...args): NgResource<LenderChannelContactInfo> {
        return this.lenderChannelContactInfo.get(...args);
    }
}
