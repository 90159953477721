import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { cloneDeep, remove } from 'lodash';
import { get, find } from 'lodash/fp';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { RealmFormGroup, RealmFormControl } from 'commons/forms';
import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';

import { FindingResource, FindingsService } from '../../findings.service';

type Status = string;
type Statuses = Status[];

const statusesConfig: Record<Status, Statuses> = {
	Open: [ 'Open', 'Completed' ],
	Completed: [ 'Completed', 'Reopened' ],
	Reopened: [ 'Reopened', 'Completed' ],
};

@Component({
	selector: 'task',
	templateUrl: './task.component.html',
})
export class TaskComponent implements OnInit {
	bsConfig: Partial<BsDatepickerConfig> = {
		dateInputFormat: 'MM/DD/YYYY',
	};
	today = moment().startOf('day').toDate();
	modalRef: BsModalRef;
	remediation: any;
	statuses: Statuses;
	edit: boolean;
	canEdit: boolean = false;
	isAssignee: boolean = false;
	User: UserProfile;
	remediationForm = new RealmFormGroup({
		status: new RealmFormControl(
			'status',
			{ label: 'Status' },
			Validators.required,
		),
		dueDate: new RealmFormControl(
			'dueDate',
			{ label: 'Due Date' },
			Validators.required,
		),
		issues: new RealmFormControl(
			'issues',
			{
				label: 'Related Observations',
				value: [], // default value
			},
			Validators.required,
		),
		communication: new RealmFormControl(
			'communication',
			{ label: 'Communication' },
			Validators.maxLength(2000),
		),
	});
	selectedIssuesMap: any;
	resolving = false;

	@Input() finding: FindingResource;
	@Output() onCancel = new EventEmitter<void>();

	get isNew(): boolean {
		return !this.remediation;
	}

	get canNotify(): boolean {
		return (
			this.User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE') &&
			[ 'Open', 'Reopened' ].includes(this.remediation.status)
		);
	}

	constructor(
		userService: UserService,
		public stateService: StateService,
		public modalService: BsModalService,
		public findingsService: FindingsService,
	) {
		this.User = userService.profile;
		this.canEdit = this.User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE');
	}

	ngOnInit(): void {
		const { finding } = this;
		const { findingId } = this.stateService.params;
		const isExternalTransition = !finding.$resolved;

		if (
			isExternalTransition || // came from other screen
			finding.hasRemediation // came from other tab and finding already has remediation (view/edit)
		) {
			// if remediation is not set it is create mode
			this.remediation = this.findingsService.remediation.get({ findingId });
		}

		// toggleEdit requires remediation to be resolved
		(this.remediation ? this.remediation.$promise : Promise.resolve())
			.then(() => {
				this.initForm();
				this.toggleEdit(!this.remediation);
			});

		this.finding.$promise
			.then(() => {
				this.isAssignee = this.finding.contact.contactId === this.User.contactId;
			});
	}

	markComplete(): void {
		const {
			finding: { id: findingId },
		} = this;
		const makeRequest = () =>
			this.findingsService.remediation.complete({
				findingId,
			}, {}).$promise
				.then(() => {
					this.modalRef.hide();
					this.remediation.status = 'Completed';
				});

		const initialState = {
			title: 'Mark Complete',
			message: `Are you sure you want to proceed?`,
			confirmText: 'Mark Complete',
			cancelText: 'Cancel',
			onConfirm() {
				this.resolving = true;
				makeRequest()
					.finally(() => {
						this.resolving = false;
					});
			},
		};

		this.modalRef = this.modalService.show(ConfirmModalComponent, { initialState, class: 'modal-smd modal-new' });
	}

	initForm(): void {
		const {
			status: statusControl,
			communication: communicationControl,
		} = this.remediationForm.controls;
		if (this.remediation) {
			statusControl.enable();
			communicationControl.disable();
		} else {
			statusControl.disable();
			communicationControl.enable();
		}
	}

	get title(): string {
		if (this.remediation) {
			return this.edit ? 'Edit Task' : 'Task Details';
		}

		return 'Create Remediation Task';
	}

	remove = (): void => {
		const {
			finding: { id: findingId },
		} = this;
		const _remove = () =>
			this.findingsService.remediation.remove({
				findingId,
			}).$promise
				.then(() => {
					this.modalRef.hide();
					this.stateService.go('.', { tab: 'DETAILS' }, { location: 'replace' });
				});

		const initialState = {
			title: 'Delete Task',
			message: `Are you sure you want to delete Task?`,
			confirmText: 'Delete',
			cancelText: 'Cancel',
			onConfirm() {
				this.resolving = true;
				_remove()
					.finally(() => {
						this.resolving = false;
					});
			},
		};

		this.modalRef = this.modalService.show(ConfirmModalComponent, { initialState, class: 'modal-smd modal-new' });
	}

	toggleEdit = (edit: boolean = !this.edit): void => {
		const {
			remediationForm: form,
		} = this;

		this.edit = edit;

		if (edit) {
			if (this.remediation) { // edit (else create)
				const {
					status,
					dueDate,
					issues,
				} = this.remediation;

				this.statuses = statusesConfig[status];

				form.patchValue({
					status,
					dueDate: dueDate && moment(dueDate, 'MM/DD/YYYY').toDate(),
					issues,
				});

				this.selectedIssuesMap = issues.reduce((issuesAcc, { id: issueId, observations }) => ({
					...issuesAcc,
					[issueId]: observations.reduce((observationsAcc, { id: observationId }) => ({
						...observationsAcc,
						[observationId]: true,
					}), {}),
				}), {});
			}
		} else {
			form.reset();
		}
	}

	isObservationSelected = (issueId, observationId): boolean => (
		this.remediation &&
		get([ issueId, observationId ])(this.selectedIssuesMap)
	)

	onObservationChange = (e, issueId, observationId): void => {
		const { target: { checked } } = e;
		const issuesControl = this.remediationForm.get('issues');
		const issues = cloneDeep(issuesControl.value);
		const issue: any = find({ id: issueId })(issues);

		const addObservation = () => {
			const observation = { id: observationId };

			// append new observation to existing issue
			if (issue) {
				issue.observations.push(observation);

				return issues;
			}

			return [
				...issues,
				// append new issue with new observation
				{
					id: issueId,
					observations: [ observation ],
				},
			];
		};

		const removeObservation = () => {
			remove(issue.observations, { id: observationId });

			// remove issue if last observation has been remove
			if (!issue.observations.length) {
				remove(issues, issue);
			}

			return issues;
		};

		issuesControl.setValue(
			checked ? addObservation() : removeObservation(),
		);
	}

	submit = (): void => {
		const {
			findingsService,
			finding: { id: findingId },
			remediationForm: form,
		} = this;
		const {
			status,
			dueDate,
			issues,
			communication,
		} = form.value;
		const {
			create,
			update,
		} = findingsService.remediation;
		const method = this.remediation ? update : create;

		this.resolving = true;
		method(
			{
				findingId,
			},
			{
				status,
				dueDate: dueDate && moment(dueDate).format('MM/DD/YYYY'),
				issues,
				communication,
			},
		).$promise
			.then(
				(data) => {
					if (!this.remediation) { // create (else edit)
						this.finding.hasRemediation = true;
					}

					if (data.status === 'Reopened') {
						this.stateService.transitionTo(
							this.stateService.current,
							{
								tab: 'TASK',
							},
							{
								reload: true,
							},
						);
					}

					this.remediation = data;

					this.initForm();
					this.toggleEdit(false);
				},
				({ data: response }) => {
					form.setServerError(response);
				},
			)
			.finally(() => {
				this.resolving = false;
			});
	}

	cancelTaskCreation(): void {
		this.onCancel.emit();
	}
}
