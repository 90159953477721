import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

// Services
import { SCScheduleService } from 'tpo/social-compliance/schedule-old';

import { ChecklistSettingsModule } from './checklist';

// Components
import { ScSettingsComponent } from './sc-settings.component';
import { TpoStatusModule } from 'tpo/status';

@NgModule({
	imports: [
		CommonModule,
		DirectivesModule,
		ComponentsModule,
		PipesModule,
		ChecklistSettingsModule,
        TpoStatusModule,
	],
	declarations: [
		ScSettingsComponent,
	],
	providers: [
		SCScheduleService,
	],
	entryComponents: [
		ScSettingsComponent,
	],
})
export class ScSettingsModule {}
