import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PasswordPolicy } from 'shared/models/PasswordPolicy';


@Component({
    selector: 'password-requirements',
	templateUrl: './password-requirements.component.html',
})
export class PasswordRequirementsComponent {
    @Input() public config: PasswordPolicy;
    @Input() public sent: boolean = false;
    @Input() public passwordControl: AbstractControl;

    public getClass(regularExpressionString: string): string {
        const password: string = this.passwordControl?.value;
        if (password && new RegExp(regularExpressionString).test(password)) {
            return 'glyphicon glyphicon-ok-sign';
        }

        const sentClass: string = (this.sent ? 'has-error' : '');
        const result: string = `glyphicon glyphicon-minus-sign ${sentClass}`;

        return result;
    }
}
