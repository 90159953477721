import { merge, sumBy, without, find, pullAt, remove } from 'lodash';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormArray, AbstractControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserService as UService } from 'angularjs-providers/user.provider';

import { RealmFormControl } from 'commons/forms/form-control.hoc';
import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormArray } from 'commons/forms/form-array.hoc';
import { NumberValidator } from 'commons/validators';
import { PhoneValidator } from 'commons/validators/phone.validator';

import { CodeValueService as CodeValue } from 'shared/code-value.service';
import { OwnersResourceService } from 'tpo/contacts/owners-resource.service';

interface AddedContact {
	contactId: any;
	fullName: any;
	contactType: any;
}

/* Global Validator, one time usage */
const TotalOwnershipValidator = (control: RealmFormArray): {[key: string]: any} | null => {
	const sum = sumBy(control.value, 'ownershipPercentage');
	const isValid = sum >= 0 && sum <= 100;

	return isValid ? null : {
		total: {
			value: control.value,
			message: '<b>Ownership %</b> of all the owners (Company and Individual) put together cannot be greater than 100%',
		},
	};
};

const companyContactDefaults = {
	contactType: 'company',
	companyName: '',
	fax: '',
	tpoCompanyContactId: null,
	state: null,
	city: '',
	zip: '',
	phone: '',
	address: '',
	suite: '',
	businessTaxId: '',
	ownershipPercentage: 0,
	isActive: false,
};

@Component({
	templateUrl: './ownership-modal.component.html',
})

export class OwnershipModalComponent {

	@ViewChild('ownershipList', {static: false}) ownershipList: ElementRef;
	newOwners: any = [];
	nonOwnersList: any[] = [];
	contacts: any = [];
	accountCompanyInfo: {ownership99PercentRequired};
	onUpdate: (params?) => any;
	ownershipForm = new RealmFormArray([], TotalOwnershipValidator);
	showInfo: boolean = false;
	isAddingIndividual: boolean = false;
	isAddingCompany: boolean = false;
	User: any;
	states: any;
    showSSN: boolean = false;
	contactForm = new RealmFormGroup({
		contactType: new RealmFormControl(
			'contactType',
			{label: 'Contact Type'},
			Validators.required,
		),
		companyName: new RealmFormControl(
			'companyName',
			{label: 'Company Name'},
			Validators.required,
		),
		address: new RealmFormControl(
			'address',
			{label: 'Street'},
			Validators.required,
		),
		phone: new RealmFormControl(
			'phone',
			{label: 'Phone'},
			Validators.compose([Validators.required, PhoneValidator()]),
		),
		suite: new RealmFormControl(
			'suite',
			{label: 'Suite'},
		),
		fax: new RealmFormControl(
			'fax',
			{label: 'Fax'},
			PhoneValidator(),
		),
		city: new RealmFormControl(
			'city',
			{label: 'City'},
			Validators.required,
		),
		businessTaxId: new RealmFormControl(
			'businessTaxId',
			{label: 'Business Tax ID'},
			Validators.compose([
				Validators.required,
				NumberValidator('Business Tax ID should be 9 digits', 9),
			]),
		),
		state: new RealmFormControl(
			'state',
			{label: 'State'},
			Validators.required,
		),
		ownershipPercentage: new RealmFormControl(
			'ownershipPercentage',
			{label: 'Ownership %'},
			Validators.compose([Validators.required, Validators.min(0), Validators.max(100)]),
		),
		zip: new RealmFormControl(
			'city',
			{label: 'Zip'},
			Validators.required,
		),
	});
    resolved = false;

	public _addedContacts: AddedContact[] = [];

	constructor(
        protected readonly UserService: UService,
        protected readonly modalRef: BsModalRef,
        protected readonly CodeValueService: CodeValue,
        protected readonly OwnersResource: OwnersResourceService,
    ) {
		this.User = UserService.profile;
	}

	async ngOnInit() {
		const ownersArray = this.ownershipForm as FormArray;
		this.newOwners.forEach((owner) => {
			this.checkSsnRequirements(owner);
			ownersArray.push(this.getOwnerGroup(owner));
		});
        this.contacts = await this.OwnersResource.nonOwners().$promise;
        this.calcNonOwnerList();
        this.resolved = true;
	}

	checkSsnRequirements(owner) {
		owner.requestSsn = owner.contactType !== 'company'
			&& !owner.isSSNVerified
			&& owner.email === this.User.email
			&& (owner.ownershipPercentage > 0 && !owner.ssn || owner.$isNew);
	}

	async save() {
		merge(this.newOwners, this.ownershipForm.value);
        this.resolved = false;
        await this.OwnersResource.update(this.newOwners, () => {
			this.modalRef.hide();
			this.onUpdate();
		}, ({data: response}) => {
			this.ownershipForm.setServerError(response);
		}).$promise;
        this.resolved = true;
	}

	calcNonOwnerList() {
		this.nonOwnersList = without(this.contacts, ...this._addedContacts)
			.map((item) => (item.contactType !== 'company' ? {
				name: item.fullName,
				contactId: item.contactId,
			} : null));
	}

	showAddIndividual() {
		this.calcNonOwnerList();
		this.isAddingIndividual = true;
	}

	showAddCompany() {
		this.isAddingCompany = true;
		if (!this.states) {
			this.states = this.CodeValueService.get({code: 'State'});
		}
		this.contactForm.reset(companyContactDefaults);
	}

	cancelAdd() {
		this.isAddingIndividual = false;
		this.isAddingCompany = false;
	}
	selectIndividual(contactId) {
		const foundItem = find(this.contacts, {contactId}) as AddedContact;
		const item = {...foundItem, ownershipPercentage: 0, $isNew: true};
		this.checkSsnRequirements(item);
		this.isAddingIndividual = false;
		this._addedContacts.push(foundItem);
		this.newOwners.push(item);
		this.ownershipForm.push(this.getOwnerGroup(item));
		this._scrollToNew();
	}

	addCompanyContact() {
		const item = this.contactForm.value;
		this.isAddingCompany = false;
		this.newOwners.push(item);
		this.ownershipForm.push(this.getOwnerGroup(item));
		this._scrollToNew();
	}

	_scrollToNew() {
		setTimeout(() => {
			const listElement = this.ownershipList.nativeElement;
			listElement.scrollTop = listElement.scrollHeight;
		});
	}
	remove(i: number) {
		this.ownershipForm.removeAt(i);
		const [pulled]: AddedContact[] = pullAt(this.newOwners, i);
		remove(this._addedContacts, {contactId: pulled.contactId});
	}

	getOwnerGroup(owner): AbstractControl {
		// console.log(owner)

		return new RealmFormGroup({
			ownershipPercentage: new RealmFormControl(
				'ownershipPercentage',
				{label: 'Ownership %', value: owner.ownershipPercentage},
				Validators.compose([Validators.required, Validators.min(0), Validators.max(100)]),
			),
			...(owner.requestSsn ? {
				ssn: new RealmFormControl(
					'ssn',
					{label: 'your SSN to verify ownership', value: owner.ssn},
					this.accountCompanyInfo.ownership99PercentRequired ? Validators.compose([Validators.required]) : null,
				),
			} : {}),
		});
	}

	showWarningInfo() {
		this.showInfo = !this.showInfo;
	}

    toggleSSN() {
        this.showSSN = !this.showSSN;
    }
}
