import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { StateService } from '@uirouter/core';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { MaxLengthValidator, PhoneValidator } from 'commons/validators';

import { UserProfile } from 'angularjs-providers/user.provider';

import {
	UserManagementPrivilegeTypes,
	UserManagementCapabilitiesService,
} from 'commons/services/user-capabilities/user-management-capabilities.service';
import { ReadOnlyUserProfile } from 'commons/interfaces/read-only-user-profile.type';
import { UserManagementResourceService } from 'commons/services/user-management-resource.service';
import { GlobalNotification, GlobalNotificationType, GlobalNotificationsService } from 'global-elements/notication-center/notifications.service';


@Component({
	selector: 'create-read-only-user-details',
	templateUrl: './create-read-only-user-details.component.html',
})
export class CreateReadOnlyUserDetailsComponent implements OnInit {
	readonly readOnlyUserForm: FormGroup = new RealmFormGroup({
		firstName: new RealmFormControl(
			'firstName',
			{ label: 'First Name' },
			Validators.compose([ Validators.required, MaxLengthValidator(50) ]),
		),
		lastName: new RealmFormControl(
			'lastName',
			{ label: 'Last Name' },
			Validators.compose([ Validators.required, MaxLengthValidator(50) ]),
		),
		email: new RealmFormControl(
			'email',
			{ label: 'Email' },
			Validators.compose([ Validators.required, MaxLengthValidator(100), Validators.email ]),
		),
		phone: new RealmFormControl(
			'phone',
			{ label: 'Phone' },
			Validators.compose([ MaxLengthValidator(10), PhoneValidator() ]),
		),
	});

	@Input() currentUserProfile: UserProfile;
	@Input() targetUser: ReadOnlyUserProfile;
	@Input() isNew: boolean = true;

	@Output() readonly userCreated: EventEmitter<ReadOnlyUserProfile> = new EventEmitter<ReadOnlyUserProfile>();

	initiallyInEditMode: boolean = false;
	isEditing: boolean = false;
	isSaving: boolean = false;
	passwordResent: boolean = false;
	userDeactivated: boolean = false;
	usernameResent: boolean = false;

	constructor(
		private readonly globalNotificationsService: GlobalNotificationsService,
		private readonly stateService: StateService,
		private readonly userManagementResourceService: UserManagementResourceService,
		private readonly userManagementCapabilitiesService: UserManagementCapabilitiesService,
	) {
	}

	// Override
	ngOnInit(): void {
		this.initiallyInEditMode = this.isNew;

		// Set separately in edit needs to be supported.
		this.isEditing = this.initiallyInEditMode;
	}

	get shouldShowFormControls(): boolean {
		const shouldShowFormControls: boolean = (this.isEditing || !this.isNew);

		return shouldShowFormControls;
	}

	get shouldShowSaveButton(): boolean {
		const shouldShowSaveButton: boolean = this.isEditing;

		return shouldShowSaveButton;
	}

	get shouldShowCancelButton(): boolean {
		const shouldShowCancelButton: boolean = this.isEditing;

		return shouldShowCancelButton;
	}

	hasPermission(permission: (UserManagementPrivilegeTypes | string)): boolean {
		const userCan: boolean = this.userManagementCapabilitiesService.userCan(this.currentUserProfile, permission);

		return userCan;
	}

	async save(): Promise<void> {
		const readOnlyUserToBeCreated: any = this.readOnlyUserForm.value;
		try {
			this.isSaving = true;

			const createdReadOnlyUserProfile: ReadOnlyUserProfile =
				await this.userManagementResourceService.createReadOnlyUserProfile(readOnlyUserToBeCreated).toPromise();
			
			this.handleSavedUser(createdReadOnlyUserProfile);
		} finally {
			this.isSaving = false;
		}
	}

	cancel(): void {
		if (this.isNew) {
			this.stateService.go('users-management.users');
		} else {
			this.isEditing = false;
		}
	}

	// TODO: Refactor this so the create-read-only-user component is the one actually doing this
	private handleSavedUser(createdReadOnlyUserProfile: ReadOnlyUserProfile): void {
		const sref: string = this.userManagementCapabilitiesService.userManagementItemSref;
		const href: string = this.stateService.href(
			sref,
			{
				actionType: 'view',
				id: createdReadOnlyUserProfile.id,
			}
		);
		const link: string = `<a class="underlined text-success" href="${href}">View User</a>`;

		const notification: GlobalNotification = {
			type: GlobalNotificationType.POSITIVE,
			message: `User <b>${createdReadOnlyUserProfile.firstName} ${createdReadOnlyUserProfile.lastName}</b> was created successfully. ${link}`,
			timeout: 4000,
		};
		this.globalNotificationsService.add(notification);

		this.userCreated.emit(createdReadOnlyUserProfile);
	}
}
