import { cloneDeep } from 'lodash';
import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter, ɵmarkDirty, ɵdetectChanges } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ConfirmModalComponent } from 'commons/components/modals';
import { NgResourceArray } from 'commons/interfaces';

import { RelatedAccount, RelatedAccountResourceInterface } from './related-account.interface';

@Component({
	templateUrl: './related-account.component.html',
	selector: 'related-account',
})
export class RelatedAccountComponent implements OnChanges {
    account: RelatedAccount;

    @Input('account') set externalAccount(account: RelatedAccount) {
        this.account = account;
        this.relatedAccountForm.get('related').patchValue(this.account)
    }

    @Output() accountChange: EventEmitter<RelatedAccount> = new EventEmitter<RelatedAccount>();
	accountInitial: RelatedAccount;

	@Input() resolved: boolean;
	@Output() resolvedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() editing: boolean;
	@Output() editingChange = new EventEmitter<boolean>();

	@Input() heading: string = 'Related Account';
	@Input() resource: RelatedAccountResourceInterface;
	@Input() notEditableOnCreate: boolean;
	@Input() notEditableOnView: boolean;
	@Input() curtain: boolean;
	@Input() parentForm: RealmFormGroup;
	@Input() isNew: boolean;

	modalRef: BsModalRef;

	relatedAccountHints: NgResourceArray<RelatedAccount> = [];
	relatedLoading: boolean;
	relatedAccountInput: Subject<string> = new Subject<string>();

	relatedAccountForm: RealmFormGroup = new RealmFormGroup({
		related: new RealmFormControl(
			'related',
			{
				label: 'Account',
				updateOn: 'change',
			},
		),
	});

	constructor(
		public modalService: BsModalService,
		public stateService: StateService,
	) {
		this.isNew = this.isNew ?? stateService.includes('**.new.**');
		this.subscribeRelated();
	}

	ngOnChanges(changes: SimpleChanges): void {
        const { resolved } = changes;
		if (resolved?.currentValue) {
			if (this.isNew && !this.notEditableOnCreate) {
				this.parentForm.addControl('relatedAccountForm', this.relatedAccountForm);
                this.formInit();
				this.edit();
                ɵdetectChanges(this);
			} else {
				this.setInitial();
			}
		}
	}

	async save(): Promise<void> {
		const { nmlsId } = this.relatedAccountForm.get('related').value || {};
		if (nmlsId) {
			this.resolved = false;
			this.resolvedChange.emit(false);

			try {
				this.account = (
					await this.resource.update(nmlsId).$promise
				).relatedAccount;
				this.accountChange.emit(this.account);
				this.setInitial();
                ɵdetectChanges(this);
			} catch (e) {
				this.relatedAccountForm.setServerError(e.data || e);
			}

			this.resolved = true;
			this.resolvedChange.emit(true);
			this.cancelEdit();
		} else if (this.account) {
			this.remove();
		} else {
			this.cancelEdit();
		}
	}

	setEditing(editing: boolean): void {
		this.editing = editing;
		this.editingChange.emit(editing);
        ɵdetectChanges(this);
    }

	cancelEdit(): void {
		this.setEditing(false);
		this.account = cloneDeep(this.accountInitial);
		this.relatedAccountForm.reset();
		this.formInit();
	}

	edit(): void {
		this.setEditing(true);
	}

	remove(): void {
		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState: {
				title: `Delete Related Account`,
				message: `Are you sure you want to delete the Related Account?`,
				confirmText: 'Delete',
				onConfirm: async () => {
					this.modalRef.content.resolving = true;
					try {
						this.account = (await this.resource.delete().$promise).relatedAccount;
						this.accountChange.emit(this.account);
						this.setInitial();
						this.setEditing(false);
					} catch (e) {
						this.modalRef.hide();
						this.relatedAccountForm.setServerError(e.data || e);
					}
					this.modalRef.content.resolving = false;
					this.modalRef.hide();
                    ɵdetectChanges(this);
                },
			},
			class: 'modal-smd modal-new',
		});
	}

	subscribeRelated(): void {
		this.relatedAccountInput.pipe(
			distinctUntilChanged(),
			debounceTime(500),
			switchMap(async (term) => {
                this.relatedLoading = term && term.length > 2;
                if (!this.relatedLoading) return;

				this.relatedAccountForm.reset();
				try {
					const result = await this.resource.search(term).$promise;
                    ɵmarkDirty(this);
                    return result;
				} catch (e) {
					this.relatedAccountForm.setServerError(e.data || e);
					return [];
				}
			}),
		).subscribe((accounts: RelatedAccount[]) => {
			this.relatedAccountHints = accounts;
			this.relatedLoading = false;
            ɵdetectChanges(this);
		});
	}

	formInit = (): void => this.relatedAccountForm.get('related').patchValue(this.account);

	onSelect(account: RelatedAccount): void {
		this.isNew && this.setAccount(account);
		this.relatedAccountHints = [];
	}

	setInitial(): void {
		this.formInit();
		this.accountInitial = cloneDeep(this.account);
        this.relatedAccountInput.next('');
        ɵdetectChanges(this);
	}

	setAccount(account: RelatedAccount): void {
		this.account = account;
		this.accountChange.emit(account);
		this.setInitial();
        ɵdetectChanges(this);
	}
}
