import { mergeWith, isEqual, find, omit, filter, includes } from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/internal/operators';
import { from, Observable } from 'rxjs';
import { ChangeDetectorRef, Component, Injector } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { HistoryLogService } from 'angularjs-providers/history-log-service.provider';
import { UserService } from 'angularjs-providers/user.provider';

import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';
import { FormattedTextComponent } from 'commons/components/formatted-text';
import { MaxLengthValidator } from 'commons/validators';
import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import { NgResourceObject, NgResourceArray } from 'commons/interfaces';
import { DefaultNetworks, DefaultNetwork, allowedNetworks, name } from 'commons/components/sm-icons';

import { PostsResourceService } from '../../../../posts/posts-resource.service';
import {
	TemplatesService,
	ValidateResponse,
	Folder,
} from '../../../templates.service';
import { CampaignsResourceService } from '../../../../campaigns';
import { PostValidator } from '../../../../attachments/attachments.component';
import { TemplateUpdateModalComponent } from './template-update-modal';
import { DynamicField } from '../template-text-edit/utils';

@Component({
	templateUrl: './template-edit.component.html',
})
export class TemplateEditComponent {
	previewAccounts: DefaultNetwork[] = [{ networkCode: allowedNetworks.GOOGLE_MY_BUSINESS, name }];
	isNew: boolean;
	title: string;
	params: any = {};
	template: any;
	tags: any;
	folders: NgResourceArray<Folder>;
	attachment: any;
	attachmentsErrors = [];
	campaigns: any;
	commonFormControlState: any = { updateOn: 'change' };
	linkPreview: any = {
		entries: [],
		$resolved: true,
	};
	templateForm: RealmFormGroup = new RealmFormGroup(
		{
			summary: new RealmFormControl(
				'summary',
				{
					...this.commonFormControlState,
					label: 'Summary',
				},
				[
					MaxLengthValidator(2000),
					Validators.required,
				],
			),
			tags: new RealmFormControl(
				'tags',
				{
					...this.commonFormControlState,
					label: 'Tags',
				},
				[],
			),
			folder: new RealmFormControl(
				'folder',
				{
					...this.commonFormControlState,
					label: 'Folder',
					value: { id: null, name: 'None' },
				},
				[],
			),
			text: new RealmFormControl(
				'text',
				{
					...this.commonFormControlState,
					label: 'Text',
				},
				[
					MaxLengthValidator(2000),
				],
			),
			attachments: new RealmFormControl(
				'attachments',
				{
					label: 'Attachments',
				},
				[
				],
			),
			campaigns: new RealmFormControl(
				'campaigns',
				{
					...this.commonFormControlState,
					label: 'Campaigns',
				},
				[],
			),
			assetSharing: new RealmFormControl(
				'assetSharing',
				{
					...this.commonFormControlState,
					label: 'Sharing',
				},
				[
					Validators.required,
				],
			),
			targetNetworkCodes: new RealmFormControl(
				'targetNetworkCodes',
				{
					...this.commonFormControlState,
					label: 'Account Types',
                    value: ['GMB'],
				}
			),
			isEditable: new RealmFormControl(
				'isEditable',
				{
					...this.commonFormControlState,
					label: 'Disable user editing',
				},
				[],
			),
		},
		{ validators: PostValidator, ...this.commonFormControlState });
	sharingComponentReady: boolean = false;
	networksComponentReady: boolean = false;
	resolving: boolean = false;
	modalRef: BsModalRef;
	dynamicFields: NgResourceArray<DynamicField> = [];

	constructor(
		public injector: Injector,
		public state: StateService,
		public historyLogService: HistoryLogService,
		public user: UserService,
		public postsResource: PostsResourceService,
		public templatesService: TemplatesService,
		public campaignsResource: CampaignsResourceService,
		public modalService: BsModalService,
        protected readonly cd: ChangeDetectorRef,
	) {
		const { templateId } = state.params;
		if (templateId) {
			Object.assign(this.params, { templateId });
		}
		this.isNew = !templateId;
		this.title = this.isNew ? 'Create Template' : 'Edit Template';

		this.template = this.isNew ? templatesService.create() : templatesService.template(templateId);

		this.tags = {
			fetchOptions(text: string): Observable<{}> {
				const options = templatesService.tagHints(text);
				return from(options.$promise);
			},
		};

		templatesService.folders().$promise.then((folders) => {
			const folderId = parseInt(state.params.folderId);
			const root: Folder = {
				id: null,
				name: 'None',
				assetSharing: {
					sharingType: {
						id: 'NV',
						name: 'None',
					},
					shareTo: [],
				},
			};
			folders.unshift(root);
			this.folders = folders;

			const current = folderId ? find(folders, { id: folderId }) : { id: null, name: 'None' };
			this.templateForm.get('folder').patchValue(current);
            this.cd.detectChanges();
		});

		const { template, templateForm: form } = this;
		this.attachment = {
			resolving: false,
			config: {
				tpoId: user.profile.organization.id,
				template: true,
			},
			status(status: boolean): void {
				this.resolving = status;
				setTimeout((): void => {
					form.get('attachments').patchValue(template.attachments);
				});
			},
			error: (errors: [{ networkCode: string, error: string }?]): void => {
				this.attachmentsErrors = errors;

				setTimeout((): void => {
					form.get('attachments').patchValue(template.attachments); // clear errors in the sidebar
				});
			},
		};

		this.campaigns = {
			canManage: user.profile.can('TPO_PB_MANAGE_CAMPAIGNS'),
			options: null,
			fetchOptions: async (): Promise<void> => {
				const options = await campaignsResource.availableCampaigns.get().$promise;
				if (!this.isNew) {
					const { campaigns } = await template.$promise;
					for (const item of options) {
						item.disabled = !!find(campaigns, { id: item.id });
					}
				}
				this.campaigns.options = options;
			},
			init: async (): Promise<void> => {
				if (!this.campaigns.canManage) { return }

				await this.campaigns.fetchOptions();
				const { campaignId, templateId } = state.params;

				if (campaignId) {
					const currentCampaign = find(this.campaigns.options, { id: parseInt(campaignId) });
					template.campaigns = [currentCampaign];
					form.get('campaigns').patchValue([currentCampaign]);
				}

				if (templateId) {
					for (const item of template.campaigns) {
						item.disabled = true
					}
					form.get('campaigns').patchValue(template.campaigns);
				}
			},
		};

		this.campaigns.init();

		Promise.all([
			this.template.$promise,
		])
			.then((): void => {
				// Populate form
				this.templateForm.patchValue(omit(this.template, 'folder', 'targetNetworkCodes'));
				// this.selectNetworks(this.template.targetNetworkCodes);
                this.cd.detectChanges();
			});

		this.modalService.onHide.subscribe(() => {
			this.resolving = false;
		});

		this.trackTextChanges();
	}

	setSharingReady(ready): void {
		this.sharingComponentReady = !!ready;
	}

    setAssetSharing(data): void {
        this.templateForm.patchValue({ assetSharing: data });
        this.cd.detectChanges();
	}

	setNetworksReady(ready): void {
		this.networksComponentReady = !!ready;
	}

	save(): void {
		const method = this.isNew ? 'createTemplate' : 'updateTemplate';

		const params: {
			applyToScheduledPosts?: boolean,
			templateId?: number,
		} = {};

		const { templateId } = this.state.params;
		if (templateId) {
			Object.assign(params, { templateId });
		}

		const getTriggers = (): NgResourceObject<ValidateResponse> => (
			this.templatesService.validate(this.template.text)
		);

		const { selectedThumbnails } = this.template;
		const templateFormRaw = this.templateForm.getRawValue();
		templateFormRaw.selectedThumbnails = selectedThumbnails;

		const getNonPublishedPosts = (): NgResourceObject<{
			hasNonPublishedPosts: boolean,
			werePostRelatedFieldsChanged: boolean,
		}> => (
			!this.isNew && this.templatesService.additionalInfo(templateId, templateFormRaw)
		);

		mergeWith(
			this.template,
			this.templateForm.getRawValue(),
			(objValue, srcValue, key) => {
				switch (key) {
					case 'assetSharing': // do not merge options for assetSharing, since it can mix up shareTo array with different sharingType
					case 'tags':
						return srcValue;
					case 'targetNetworkCodes':
						return srcValue;
					case 'folder':
						return srcValue?.id ? srcValue : null;
				}
			},
		);

		const showTriggerModal = (text: string): void => {
			const formattedText = FormattedTextComponent.setLineBreaks(text);
			const self = this;
			const initialState = {
				title: 'Please Review',
				message: `<div class="text-muted">Your post contains terms that may create a compliance event:</div><br><div class="text-max-height">${formattedText}</div>`,
				confirmText: 'Continue',
				cancelText: 'Edit',
				onConfirm: (): void => {
					this.template.isApproved = true;
					this.modalRef.content.resolving = true;
					self.resolving = true;

					const nonPublishedPosts = getNonPublishedPosts();
					from(nonPublishedPosts ? nonPublishedPosts?.$promise : Promise.resolve(null))
						.subscribe((response: any): void => {
							if (response?.werePostRelatedFieldsChanged && response?.hasNonPublishedPosts) {
								showUpdateModal();
								self.resolving = false;
							} else {
								sendRequest(params);
							}
						});

					this.modalRef.hide();
				},
			};

			this.modalRef = this.modalService.show(ConfirmModalComponent, {
				initialState,
				class: 'social-compliance modal-smd modal-new',
			});
		};

		const sendRequest = (fparams): void => {
            this.template = this.templatesService[method](fparams, this.template).$promise
				.then(
					() => {
						this.close();
					},
					({ data }) => {
						this.templateForm.setServerError(data);
					},
				)
				.finally(() => {
					if (this.modalRef) {
						this.modalRef.hide();
					}
				});
		};

		const showUpdateModal = (): void => {
			const initialState = {
				title: 'Save Template',
				confirmText: 'Save',
				onConfirm: (value): void => {
					Object.assign(params, { applyToScheduledPosts: value });
					this.resolving = true;
					sendRequest(params);
					this.modalRef.hide();
				},
			};

			this.modalRef = this.modalService.show(TemplateUpdateModalComponent, {
				initialState,
				class: 'social-media social-media-posts template-update-modal modal-smd modal-new',
				backdrop: 'static',
			});
		};

		this.resolving = true;
		Promise.all([getTriggers()?.$promise, getNonPublishedPosts()?.$promise])
			.then((response) => {
				this.resolving = false;

				// TODO: check resource interface, why we have problems here
				const triggers = response[0] as ValidateResponse;
				const publishedPosts = response[1] as {
					hasNonPublishedPosts: boolean;
					werePostRelatedFieldsChanged: boolean;
				};

				if (triggers?.hasTriggers) {
					showTriggerModal(triggers?.text);
				} else if (publishedPosts?.werePostRelatedFieldsChanged && publishedPosts?.hasNonPublishedPosts) {
					showUpdateModal();
				} else {
					sendRequest(params);
				}
			});
	}

	// Reusable for all publisher edit components
	cancel = (): void => {
		this.state.go('^', {}, { location: 'replace' });
	};

	// Reusable for all publisher full-page components
	close = (): void => {
		const backState = this.historyLogService.findBackState([
			'**.new',
			'**.:templateId.**',
		]);

        if (backState) {
            return this.historyLogService.goBackTo(backState);
        }

		this.state.go('social-compliance.publisher.library.templates');
	};

	trackTextChanges(): void {
		this.templateForm.get('text').valueChanges.pipe(
			debounceTime(1000),
			distinctUntilChanged(isEqual),
		).subscribe((change) => {
			const request = {
				networkCodes: ['FB', 'TW', 'LI'],
				text: `${change}`,
			};

			this.linkPreview.$resolved = false;

			if (change) {
				this.postsResource.post.preview(request, ({ linkPreviews }) => {
					this.linkPreview = {
						entries: linkPreviews,
						$resolved: true,
					};
				});
			} else {
				this.linkPreview = {
					entries: [],
					$resolved: true,
				};
			}
		});
	}

	setEditable({ target }: Event): void {
		const { checked } = (target as HTMLInputElement);
		this.templateForm.get('isEditable').patchValue(!checked)
	}

	// selectNetworks(targetNetworkCodes: string[] | undefined): void {
	// 	this.templateForm.patchValue({ targetNetworkCodes });
    //
	// 	if (!targetNetworkCodes?.length) {
	// 		this.previewAccounts = DefaultNetworks;
	// 		return;
	// 	}
    //
	// 	this.previewAccounts = filter(DefaultNetworks, ({ networkCode }) => {
	// 		return includes(targetNetworkCodes, networkCode);
	// 	});
	// }

	changeText(text: string | undefined): void {
		this.templateForm.patchValue({ text });
	}

    updateDynamicFields = (dynamicFields: NgResourceArray<DynamicField>) => {
        this.dynamicFields = dynamicFields;
        this.cd.detectChanges();
    };
}
