import { Component, OnInit } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { omitBy, omit, assign } from 'lodash';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { PagedListComponent } from 'commons/components/list';

import { FindingsService } from '../../findings.service';
import { FindingAuditLogDetailsModalComponent } from '.';

@Component({
	selector: 'finding-audit-log',
	templateUrl: './finding-audit-log.component.html',
})
export class FindingAuditLogComponent extends PagedListComponent implements OnInit {
	static listName = FindingAuditLogComponent.addName('findingAuditLog');
	listName = FindingAuditLogComponent.listName;

	list: any = [];
	modalRef: BsModalRef;

	constructor(
		public User: UserService,
		public findingsService: FindingsService,
		transitionService: TransitionService,
		stateService: StateService,
		public modalService: BsModalService,
	) {
		super(transitionService, stateService);

		// NOTICE: this list is in Finding Details fullpage mode,
		// no need to use location there
		this.useLocation = false;
	}

	ngOnInit() {
		assign(
			this.defaultFilters, {
				findingId: this.stateService.params.findingId,
				size: 25,
			},
		);

		super.ngOnInit();
	}

	loadList(queryParams) {
		this.list.$resolved = false;

		return this.findingsService.getFindingHistory(this.getClearParams(queryParams)).$promise
			.then((result) => {
				this.list = result;
			});
	}

	showDetails(entry): void {
		const initialState = {
			entry,
		};
		this.modalRef = this.modalService.show(FindingAuditLogDetailsModalComponent, { initialState, class: 'modal-md modal-new' });
	}

	getClearParams(queryParams) {
		return omitBy(omit(queryParams, '#', '$inherit', 'actionType'), (value) => (!value));
	}
}
