import { omit } from 'lodash';
import { Component, Input, Output, EventEmitter, ContentChild, TemplateRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { TransitionService, StateService } from '@uirouter/core';

import { MaxLengthValidator } from 'commons/validators';
import { RealmFormGroup, RealmFormControl } from 'commons/forms';
import { PagedListComponent } from 'commons/components/list/paged-list.component';

import { CommentsResourceInterface } from './comments-resource.interface';
import { CommentsPermissionsInterface } from './comments-permissions.interface';

@Component({
	selector: 'comments',
	templateUrl: './comments.component.html',
})
export class CommentsComponent extends PagedListComponent {
	static listName = CommentsComponent.addName('comments');
	listName = CommentsComponent.listName;

	list: any = [];
	resolved: boolean = false;

	editing: boolean = false;
	submitted: boolean = false;

	@ContentChild('formTemplate', {static: true}) formTemplate: TemplateRef<any>;
	@ContentChild('commentTemplate', {static: true}) commentTemplate: TemplateRef<any>;

	// in some places comments has some other name,
	// for example 'message'
	// - endpoint expects key 'message' for comment text
	// - used for empty list row: 'No Messages'
	@Input()
	entityName: string = 'comment';

	@Input()
	resource: CommentsResourceInterface;

	// allow to skip inner loader and handle loader in parent component
	@Input()
	showLoader: boolean = true;

	@Input()
	sizes = [10, 25, 50];

	// @Input()
	// filters: any;

	// custom form for adding comment
	@Input()
	addForm: RealmFormGroup = new RealmFormGroup({
		comment: new RealmFormControl(
			'comment',
			{ label: 'Comment', updateOn: 'change' },
			Validators.compose([ Validators.required, MaxLengthValidator(2000) ]),
		),
	});

	@Input()
	permissions: CommentsPermissionsInterface = {
		canAdd: false,
	};

	@Input()
	private set defaultFiltersParams(defaultFilters) {
		this.defaultFilters = {
			...this.defaultFilters,
			...defaultFilters,
		};
	}

	// emit value for loading list start/finish
	@Output()
	onLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

	// emit value for added comment
	@Output()
	onAdd: EventEmitter<object> = new EventEmitter<object>();

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
	) {
		super(transitionService, stateService);
	}

	loadList(queryParams) {
		this.resolved = false;
		this.onLoading.emit(false);

		return this.resource.query(queryParams).$promise
			.then((result) => {
				this.list = result;
				this.resolved = true;
				this.onLoading.emit(true);
			});
	}

	add = () => {
		if (this.submitted) {
			return;
		}

		this.submitted = true;
		const data = this.mapCommentToEntityName(this.addForm.value);

		this.resource.add(this.params, data,
			/* success */
			(result) => {
				this.setEditMode(false);
				this.resetPage();
				this.resetFilters();
				this.loadList(this.params);
				this.onAdd.emit(result);
			},
			/* error */
			({ data: response }) => {
				this.submitted = false;
				this.addForm.setServerError(response);
			},
		);
	}

	setEditMode = (value: boolean) => {
		this.submitted = false;
		this.editing = value;

		if (!this.editing) {
			this.resetAddForm();
		}
	}

	resetAddForm = () => {
		this.addForm.markAsPristine();
		this.addForm.reset();
		this.addForm.serverError = '';
	}

	getFormContext() {
		const { addForm, editing, entityName, resolved, submitted, add, setEditMode, resetFormOnEmptyComment } = this;
		return {
			addForm,
			editing,
			entityName,
			resolved,
			submitted,
			methods: {
				add,
				setEditMode,
				resetFormOnEmptyComment,
			},
		};
	}

	getCommentContext(comment: object) {
		return {
			comment,
			entityName: this.entityName,
		};
	}

	resetFormOnEmptyComment = (): void => {
		const data = { ...this.addForm.value };
		if (data.comment) {
			return;
		}

		this.submitted = false;
		this.editing = false;
		this.resetAddForm();
	}

	mapCommentToEntityName(formValue) {
		const data = omit(formValue, 'comment');
		return {
			...data,
			[ this.entityName ]: formValue.comment,
		};
	}

}
