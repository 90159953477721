import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { LenderAlertsService } from 'lender/alerts/alerts.service';

import { AlertsComponent } from './list';
import { AlertsModalComponent } from './alerts-modal';
import { AlertDetailsComponent } from './alert/alert-details/alert-details.component';
import { AlertComponent } from './alert/alert.component';
import { AlertEventsComponent } from './alert/alert-events/alert-events.component';
import { AlertChannelsComponent } from './alert/alert-channels/alert-channels.component';
import { AlertIdsComponent } from './alert/alert-ids/alert-ids.component';
import { AlertHistoryComponent } from './alert/alert-history/alert-history.component';
import { AlertCommentsComponent } from './alert/alert-comments/alert-comments.component';
import { AlertCommentComponent } from './alert/alert-comments/alert-comment/alert-comment.component';
import { AlertDetailsViewRecordComponent } from 'lender/alerts/alert/alert-details/alert-details-view-record/alert-details-view-record.component';

const components = [
	AlertsComponent,
	AlertsModalComponent,
	AlertComponent,
	AlertDetailsComponent,
	AlertEventsComponent,
	AlertChannelsComponent,
	AlertIdsComponent,
	AlertHistoryComponent,
	AlertCommentsComponent,
	AlertCommentComponent,
    AlertDetailsViewRecordComponent,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BsDatepickerModule.forRoot(),
		ComponentsModule,
		DirectivesModule,
		PipesModule,
	],
	declarations: [
		...components,
	],
	providers: [
		LenderAlertsService,
	],
	entryComponents: [
		...components,
	],
})
export class AlertsModule {}
