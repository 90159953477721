import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map, mapValues } from 'lodash';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { StaticValuesService } from 'angularjs-providers/static-values.provider';

import { NgPagedResource, NgResourceArray } from 'commons/interfaces';
import { ListComponent, PagedListComponent } from 'commons/components/list';

import { License, LicenseClass } from 'shared/account/licenses/licenses.interface';
import { LicenseDetailsModalComponent } from 'shared/account/licenses/details-modal';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { SharedLicensesService } from 'shared/account/licenses/licenses.service';
import { RealmParams } from 'commons/services/http';

@Component({
    selector: 'lender-license-list',
    templateUrl: 'list.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => SharedLicenseListComponent) },
    ],
})
export class SharedLicenseListComponent<T = License> extends PagedListComponent implements OnInit {
    static listName = SharedLicenseListComponent.addName('comergenceLicensesList');
    listName = SharedLicenseListComponent.listName;

    @Input() lenderInfo: AccountInformation;
    @Input() limitedView = false;
    @Input() private externalParams: RealmParams = {};
	User: UserProfile;
	private baseParams;
	list: NgPagedResource<T> = [];

	modalRef: BsModalRef;

    resources = {
        filters: {
            status: (params) => this.resourcesService.getStatusesFilter(params),
            type: (params) => this.resourcesService.getTypesFilter(params),
        },
        list: (params) => this.resourcesService.getLicenses(
            this.getClearParams(params),
        ),
    };

	get isIndividual(): boolean {
		return this.params.class === LicenseClass.Individual;
	}

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		private staticValues: StaticValuesService,
		userService: UserService,
		public modalService: BsModalService,
        private resourcesService: SharedLicensesService,
    ) {
		super(transitionService, stateService);

		this.User = userService.profile;

        this.defaultFilters.searchNmlsId = '';
	}

	async ngOnInit(): Promise<void> {
        this.baseParams = { ...this.lenderInfo.identifier, ...this.externalParams };
		this.configureList();

		super.ngOnInit();
	}

	configureList(): void {
		Object.assign(
			this.filters,
			{
				class: [
					{
						title: 'Company',
						value: LicenseClass.Company,
					},
					{
						title: 'Individual',
						value: LicenseClass.Individual,
					},
				],
				authorization: [
					{
						name: 'Authorized',
						id: 'true',
					},
					{
						name: 'Not Authorized',
						id: 'false',
					},
				],
				state: this.staticValues.get({ code: 'State' }),
				status: this.resources.filters.status(this.baseParams),
				type: this.resources.filters.type(this.baseParams),
			},
		);

		Object.assign(
			this.defaultFilters,
			{
				...this.baseParams,
				class: LicenseClass.Company,
				// TODO: why do we need trivial values?
				authorization: undefined,
				state: [],
				status: undefined,
				type: undefined,
				size: 20,
			},
		);

		this.omitParams.push(
			...Object.keys(this.baseParams),
			'class',
		);

		this.omitResetParams.push(
			'class',
		);
	}

	setFilter(filters) {
		if (filters.class) {
			// Reset filters & sorting and set new `class`
			super.setFilter({
				...this.defaultFilters,
				...filters,
			});
		} else {
			const processedFilters = mapValues(filters, (value, key) => {
				switch (key) {
					// TODO: why do we need this?
					case 'authorization':
						return value?.id;
					case 'state':
						return map(value, 'shortName');
					default:
						return value;
				}
			});
			super.setFilter(processedFilters);
		}
	}

	async loadList(params) {
		this.list.$resolved = false;
		try {
			this.list = (await this.resources.list(params).$promise) as unknown as NgResourceArray<T>;
		} catch (e) {}
		this.list.$resolved = true;
	}

	showLocation(license: T): void {
		const initialState = {
			license,
		};
		this.modalRef = this.modalService.show(
			LicenseDetailsModalComponent,
			{
				initialState,
				class: 'modal-vlg modal-new',
			},
		);
	}
}
