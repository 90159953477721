import { Component, Input, ContentChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { remove, pull, get, findIndex, indexOf, isArray } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
	selector: 'select-checkboxes',
	templateUrl: './select-checkboxes.component.html',
})
export class SelectCheckboxesComponent {

	@Input() control: FormControl;
	@Input() items: any[] = [];
	@Input() bindValue: string;
	@Input() bindLabel: string;

	/* @Input() */ multiple?: boolean = true; // "false" is not implemented yet!
	public selected: boolean[] = [];
	public controlSubscription: Subscription;

	@Output() check: EventEmitter<any> = new EventEmitter();

	@ContentChild('labelTemplate', { read: TemplateRef, static: false }) labelTemplate: TemplateRef<any>;
	@ContentChild('additionalContentTemplate', { read: TemplateRef, static: false }) additionalContentTemplate: TemplateRef<any>;

	ngOnChanges(changes): void {
		if (this.controlSubscription) {
			this.controlSubscription.unsubscribe();
		}
		this.calculateSelection();
		this.controlSubscription = this.control.valueChanges.subscribe(() => {
			this.calculateSelection();
		});
	}

	calculateSelection(): void {
		const control = this.control;
		this.selected = [];
		if (control) {
			const controlValue: any[] = control.value;
			this.selected = (this.items || []).map((item) => {
				const value = this.getValue(item);
				return (this.bindValue ? indexOf(controlValue, value) : findIndex(controlValue, value)) >= 0;
			});
		}
	}

	getValue(item): any {
		return this.bindValue ? get(item, this.bindValue) : { ...item };
	}

	onChange(e, checkboxValue): void  {
		const { target: { checked } } = e;
		const control = this.control;
		const controlValue = isArray(control.value) ? [...control.value] : [];
		const value = this.getValue(checkboxValue);

		if (checked) {
			controlValue.push(value);
		} else {
			if (this.bindValue) {
				pull(controlValue, value);
			} else {
				remove(controlValue, value);
			}
		}

		this.control.setValue(controlValue);
		this.check.emit(checkboxValue);
	}
}
