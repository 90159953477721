<div class="manage manage-password-policy" [class.editing]="editable">
	<div class="gray-head flex-head">
		<div class="col-fit">
			<h2>
				Password Policy
			</h2>
		</div>
	</div>
	<div class="manage-page remote-resource" [class.resolved]="passwordPolicy.$resolved">
		<form [formGroup]="passwordPolicyForm" [class.preview]="!editable" id="passwordPolicyForm" (onValidSubmit)="save()">
			<h3 class="clearfix">
				<div class="pull-right" *ngIf="((User?.profile?.isCustomer && User?.profile?.can('EDIT_PASSWORD_SECURITY')) || (User?.profile?.isTpo && User?.profile?.can('TPO_SECURITY_POLICY')))">
					<div *ngIf="editable">
						<button type="submit" class="btn btn-primary">Save</button>
						<a class="btn btn-default" (click)="reset()">Cancel</a>
					</div>
					<button type="button" class="btn btn-primary ng-scope" (click)="showForm()" *ngIf="!editable">
						<span class="glyphicon glyphicon-pencil"></span> Edit
					</button>
				</div>
				Password Settings
			</h3>
			<form-errors [form]="passwordPolicyForm"></form-errors>
			<div class="row settings-holder">
				<div class="col-md-6">
					<div class="row-fluid row-space form-group" [class.has-error]="!expires && passwordPolicyForm.get('passwordExpiryPeriod').invalid && passwordPolicyForm.get('passwordExpiryPeriod').touched">
						<div [class.required]="!expires" class="col-sm-7 control-label">{{passwordPolicyForm.getFieldLabel('passwordExpiryPeriod')}}</div>
						<div class="col-sm-5 rows-holder">
							<div class="row inner-space" *ngIf="passwordPolicy?.passwordExpiryPeriod!=-1 && !editable && !expires || editable">
								<div class="col-sm-8">
										<input *ngIf="editable"
										   maxlength="3"
										   validFloat="1.0-0"
										   name="passwordExpiryPeriod"
										   formControlName="passwordExpiryPeriod"
										   [class.hide-value]="expires"
										   [attr.disabled]="expires ?'':null"
										   class="form-control">
										<span *ngIf="!editable">
											{{passwordPolicy?.passwordExpiryPeriod}}
										</span>
								</div>
								<label [class.hidden]="!editable" class="weight-normal">days</label>
							</div>
							<div class="row" [class.hidden]="!editable">
								<div class="col-sm-10 checkbox">
									<label>
										<input type="checkbox"
											   name="expires"
											   [(ngModel)]="expires"
											   [ngModelOptions]="{standalone: true}"
											   (ngModelChange)="changeExpires()"> Never Expires
									</label>
								</div>
							</div>
							<div class="checkbox no-label-padding" *ngIf="passwordPolicy.passwordExpiryPeriod == -1 && !editable">
								<span class="control-value">Never Expires</span>
							</div>
						</div>
					</div>
					<div class="row-fluid row-space">
						<div class="col-sm-7 control-label">{{passwordPolicyForm.getFieldLabel('enforcePasswordHistory')}}</div>
						<div class="col-sm-5 no-label-padding">
							<span *ngIf="!editable" class="control-value">
								{{passwordPolicy?.enforcePasswordHistory}}
							</span>

							<ng-select
								*ngIf="editable"
								formControlName="enforcePasswordHistory"
								[items]="range0To10"
								bindValue="enforcePasswordHistory"
								[clearable]="false">
							</ng-select>
						</div>
					</div>
					<div class="row-fluid row-space">
						<div class="col-sm-7 control-label">{{passwordPolicyForm.getFieldLabel('minPasswordLength')}}</div>
						<div class="col-sm-5 no-label-padding">
							<span *ngIf="!editable" class="control-value">
								{{passwordPolicy?.minPasswordLength}}
							</span>

							<ng-select
								*ngIf="editable"
								formControlName="minPasswordLength"
								[items]="range6To12"
								bindValue="minPasswordLength"
								[clearable]="false">
							</ng-select>
						</div>
					</div>
					<div class="row-fluid row-space">
						<div class="col-sm-7 control-label">{{passwordPolicyForm.getFieldLabel('maxFailedLoginAttemptsAllowed')}}</div>
						<div class="col-sm-5 no-label-padding">
							<span *ngIf="!editable" class="control-value">
								{{passwordPolicy?.maxFailedLoginAttemptsAllowed}}
							</span>

							<ng-select
								*ngIf="editable"
								formControlName="maxFailedLoginAttemptsAllowed"
								[items]="range3o10"
								bindValue="maxFailedLoginAttemptsAllowed"
								[clearable]="false">
							</ng-select>
						</div>
					</div>
				</div>

				<div class="col-md-6">
					<div class="row-fluid row-space">
						<div class="col-sm-6 control-label">Password Structure</div>
						<div class="col-sm-6 checkbox-holder">
							<div class="row">
								<div class="col-sm-2">
									<input *ngIf="editable" type="checkbox" id="specialCharRequired" formControlName="specialCharRequired" />
									<span *ngIf="!editable" [ngSwitch]="passwordPolicy?.specialCharRequired" class="control-value">
										<span *ngSwitchCase="true" class='glyphicon glyphicon-ok text-success' aria-hidden='true'></span>
										<span *ngSwitchCase="false" class='glyphicon glyphicon-remove text-danger' aria-hidden='true'></span>
									</span>
								</div>
								<label for="{{editable ? 'specialCharRequired' : ''}}" class="text-left weight-normal">{{passwordPolicyForm.getFieldLabel('specialCharRequired')}}</label>
							</div>
							<div class="row">
								<div class="col-sm-2">
									<input *ngIf="editable" type="checkbox" id="numberRequired" formControlName="numberRequired" />
									<span *ngIf="!editable" [ngSwitch]="passwordPolicy?.numberRequired" class="control-value">
										<span *ngSwitchCase="true" class='glyphicon glyphicon-ok text-success' aria-hidden='true'></span>
										<span *ngSwitchCase="false" class='glyphicon glyphicon-remove text-danger' aria-hidden='true'></span>
									</span>
								</div>
								<label for="{{editable ? 'numberRequired' : ''}}" class="text-left weight-normal">{{passwordPolicyForm.getFieldLabel('numberRequired')}}</label>
							</div>
							<div class="row">
								<div class="col-sm-2">
									<input *ngIf="editable" type="checkbox" id="upperCaseRequired" formControlName="upperCaseRequired" />
									<span *ngIf="!editable" [ngSwitch]="passwordPolicy?.upperCaseRequired" class="control-value">
										<span *ngSwitchCase="true" class='glyphicon glyphicon-ok text-success' aria-hidden='true'></span>
										<span *ngSwitchCase="false" class='glyphicon glyphicon-remove text-danger' aria-hidden='true'></span>
									</span>
								</div>
								<label for="{{editable ? 'upperCaseRequired' : ''}}" class="text-left weight-normal">{{passwordPolicyForm.getFieldLabel('upperCaseRequired')}}</label>
							</div>
							<div class="row">
								<div class="col-sm-2">
									<input *ngIf="editable" type="checkbox" id="lowerCaseRequired" formControlName="lowerCaseRequired" />
									<span *ngIf="!editable" [ngSwitch]="passwordPolicy?.lowerCaseRequired" class="control-value">
										<span *ngSwitchCase="true" class='glyphicon glyphicon-ok text-success' aria-hidden='true'></span>
										<span *ngSwitchCase="false" class='glyphicon glyphicon-remove text-danger' aria-hidden='true'></span>
									</span>
								</div>
								<label for="{{editable ? 'lowerCaseRequired' : ''}}" class="text-left weight-normal">{{passwordPolicyForm.getFieldLabel('lowerCaseRequired')}}</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
