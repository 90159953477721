<div class="rows-bordered">
    <h3 class="underlined">Channels <span class="text-light">{{channelsContact.length}}</span></h3>
    <ul class="files">
        <li *ngFor="let item of channelsContact; let i = index">
            <div class="frontface">
                <div class="upload text-light">
                    <label class="apply" *ngIf="!channelsStatus[i].isPendingRenewal &&
                           !channelsStatus[i].isActive &&
                           !channelsStatus[i].isPending &&
                           !channelsStatus[i].isSuspended &&
                           !item.screeningRequired &&
                           !item.isEligibilityDateInFuture
                           ">
                        <a class="btn btn-primary" uiSref="new-application" [uiParams]="{channelId: item.id}">Apply</a>
                    </label>
                    <label class="apply" *ngIf="!channelsStatus[i].isPendingRenewal &&
                           !channelsStatus[i].isActive &&
                           !channelsStatus[i].isPending &&
                           !channelsStatus[i].isSuspended &&
                           item.screeningRequired">
                        <button type="button" class="btn btn-primary" (click)="contactUs(item)">Contact Us</button>
                    </label>
                    <label class="pending" *ngIf="channelsStatus[i].isPendingRenewal">
                        <span class="glyphicon glyphicon-time"></span>

                        <div class="status-text">Pending Renewal</div>
                    </label>
                    <label class="pending" *ngIf="channelsStatus[i].isPending">
                        <span class="glyphicon glyphicon-time"></span>

                        <div class="status-text">Pending</div>
                    </label>
                    <label class="active" *ngIf="channelsStatus[i].isActive">
                        <span class="glyphicon glyphicon-ok-sign"></span>

                        <div class="status-text">Active<br />
                            <small>Renewal on {{item.renewalDate | date:'MM/dd/yyyy'}}</small>
                        </div>
                    </label>
                    <label class="suspend" *ngIf="channelsStatus[i].isSuspended">
                        <span class="glyphicon glyphicon-minus-sign"></span>

                        <div class="status-text">Renewal Suspended</div>
                    </label>
                </div>
                <h4><span class="glyphicon glyphicon-briefcase"></span> {{item.name}}</h4>
                <show-more class="description text-muted" [text]="item.description"></show-more>
                <div class="contact-person"
                    *ngIf="channelsStatus[i].isActive || channelsStatus[i].isPending || channelsStatus[i].isPendingRenewal">
                    <span> Assigned:</span>
                    <b>{{!item.accountExecutiveName || item.accountExecutiveName=='UNKNOWN' ? 'Unassigned' :
                        item.accountExecutiveName}}&nbsp;&nbsp;&nbsp;&nbsp;</b>
                    <span class="glyphicon glyphicon-earphone" *ngIf="item.phone"></span> <b>{{item.phone |
                        tel}}</b>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span class="glyphicon glyphicon-envelope" *ngIf="item.email"></span> <a *ngIf="item.email"
                        href="mailto:{{item.email}}"><b>{{item.email}}</b></a>
                </div>
                <div class="contact-person clearfix"
                    *ngIf="!channelsStatus[i].isPendingRenewal && !channelsStatus[i].isActive && !channelsStatus[i].isPending && !channelsStatus[i].isSuspended">
                    <div *ngIf="item.contactName" class="pull-left">
                        <span>Contact Person: </span>
                        <b>{{item.contactName}}</b>
                    </div>
                    <div *ngIf="item.phone" class="pull-left">
                        <span class="glyphicon glyphicon-earphone"></span>
                        <b>{{item.phone | tel}}</b>
                    </div>
                    <div *ngIf="item.email" class="pull-left">
                        <span class="glyphicon glyphicon-envelope"></span>
                        <a href="mailto:{{item.email}}">{{item.email}}</a>
                    </div>
                    <div *ngIf="item.webSite" class="pull-left">
                        <span class="glyphicon glyphicon-globe"></span>
                        <a href="{{item.webSite | url}}" target="_blank">{{item.webSite}}</a>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>
