import { ColumnConfig } from 'commons/mixins';

export const ccmColumnsConfig: ColumnConfig[] = [
	{
		id: 'lastLoginDate',
		name: 'Last Login Date',
		isDefault: true,
	},
	{
		id: 'email',
		name: 'Email',
	},
	{
		id: 'userName',
		name: 'User Name',
	},
	{
		id: 'createdBy',
		name: 'Created By',
	},
	{
		id: 'createdDate',
		name: 'Created Date',
	},
	{
		id: 'lastModifiedBy',
		name: 'Last Modified By',
	},
	{
		id: 'lastModifiedDate',
		name: 'Last Modified Date',
	},
];
