import { Component } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { MonitoringSettingsService } from '../monitoring-settings.service';

import { SocialMediaResourceService } from '../social-media-resource.service';
import { MonitoringListComponent } from '../common';

@Component({
	templateUrl: './list.component.html',
})
export class CompanyListComponent extends MonitoringListComponent {
	static listName = CompanyListComponent.addName('companyMonitoring');
	listName = CompanyListComponent.listName;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		modalService: BsModalService,
		monitoringSettingsService: MonitoringSettingsService,
		public socialMediaResourceService: SocialMediaResourceService,
	) {
		super(
			transitionService,
			stateService,
			userService,
			modalService,
			monitoringSettingsService,
		);

		this.defaultFilters = {
			...this.defaultFilters,
			nmlsId: this.User.organization.nmlsId,
		};

		this.omitParams = [
			...this.omitParams,
			'nmlsId',
		];
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.socialMediaResourceService.links.get(
			{...this.getClearParams(queryParams), excludeEmptyRssLinks: true},
		).$promise
			.then((data: any[]): void => {
				this.list = data;
				this.updateLinksConnectionStatus(data);
			});
	}

	toggleCompanyMonitoring = (company: any): void => {
		this.verifyMonitoringAccess()
			.then(
				(): void => {
					const { nmlsId } = this.User.organization;
					const { linkId, monitoringStatus: currentMonitoringStatus } = company;
					const monitoringStatus = currentMonitoringStatus === 'off' ? 'on' : 'off';

					company.$resolving = true;
					this.socialMediaResourceService.link.updateMonitoringStatus(
						{ nmlsId, linkId },
						{ status: monitoringStatus },
					).$promise
						.then(({ onMonitoringStatus }): void => {
							company.monitoringStatus = onMonitoringStatus;
						})
						.finally((): void => {
							company.$resolving = false;
						});
				},
				(): void => {},
			);
	}
}
