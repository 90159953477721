import { Component, Input } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { SupportingDocumentsComponent } from 'shared/supporting-documents';

import { FindingsService } from '../../../findings.service';

@Component({
	selector: 'supporting-documents',
	templateUrl: './supporting-documents.component.html',
})
export class FindingSupportingDocumentsComponent extends SupportingDocumentsComponent {
	static listName = FindingSupportingDocumentsComponent.addName('supportingDocuments');
	listName = FindingSupportingDocumentsComponent.listName;

	@Input() finding: any;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		modalService: BsModalService,
		findingsService: FindingsService,
	) {
		super(transitionService, stateService, userService, modalService, findingsService);
	}

	configureList() {
		this.omitParams = [
			...this.omitParams,
			'tpoId',
			'findingId',
		];
	}

	ngOnInit() {
		this.finding.$promise.then(() => {
			const idParams = this.getIdParams();

			this.defaultFilters = {
				...idParams,
			};

			super.ngOnInit();
		})
	}

	getIdParams() {
		const {
			findingId,
		} = this.stateService.params;
		const { tpoId } = this.finding.contact;

		return { findingId, tpoId };
	}
}
