import { Component, forwardRef, OnInit } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { mapValues } from 'lodash';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent, PagedListComponent } from 'commons/components/list';
import { NgResourceArray } from 'commons/interfaces';

import { Trigger, TriggersService } from '../triggers.service';

import { TriggerStatuses } from './trigger-statuses';

@Component({
	templateUrl: './triggers-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => TriggersListComponent) },
	],
})
export class TriggersListComponent extends PagedListComponent implements OnInit {
	static listName = TriggersListComponent.addName('triggers');
	listName = TriggersListComponent.listName;

	User: any;
	triggerStatuses = TriggerStatuses;
	helpLink = '/help/authenticated/tpo/smc/triggers';
	list: NgResourceArray<Trigger> = Object.assign([]);

	get hasViewPermissions(): boolean {
		return (
			this.User.can('TPO_SMC_VIEW_ALL') ||
			this.User.can('CCM_SMC_VIEW_ALL')
		);
	}

	get hasManagePermissions(): boolean {
		return (
			this.User.can('TPO_SMC_MANAGE_TRIGGERS') ||
			this.User.can('CCM_SMC_MANAGE_TRIGGERS')
		);
	}

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public userService: UserService,
		public triggersService: TriggersService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;
	}

	ngOnInit(): void {
		if (this.hasViewPermissions) {
			this.configureFilters();
			super.ngOnInit();
		}
	}

	configureFilters(): void {
		this.filters = {};

		this.defaultFilters = {
			...this.defaultFilters,
		};
	}

	setFilter(filter, resetPage = true): void {
		const processFilter = mapValues(filter, (value, key: string) => {
			switch (key) {
				default:
					return value;
			}
		});
		super.setFilter(processFilter, resetPage);
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.triggersService.list(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}

	preventEventBubbling(event: MouseEvent): void {
		event.preventDefault();
		event.stopPropagation();
	}
}
