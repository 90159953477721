import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { StateService } from '@uirouter/core';
import { some, find } from 'lodash';

interface Switcher {
	name: 'comment' | 'message';
	label: string;
	visible: boolean;
	help?: string;
}

@Component({
	selector: 'comments-filter',
	templateUrl: './comments-filter.component.html',
})
export class CommentsFilterComponent {
	@Input() isMobileUser: boolean;
	@Output() onChange = new EventEmitter<string>();

	User: UserProfile;
	switchers: Array<Switcher>;
	isCommentsSection: boolean;
	activeSwitcher: Switcher;

	constructor(
		{ profile: User }: UserService,
		stateService: StateService,
	) {
		this.switchers = [{
			name: 'comment',
			label: 'Internal Comments',
			visible: (User.isComergence && User.can('CCM_SMC_VIEW_ALL'))
				|| (User.isTpo && User.can('TPO_SMC_VIEW_ALL')),
		}, {
			name: 'message',
			label: 'Messages',
			// messages are not visible for comergence users
			visible: User.isTpo && User.can('TPO_SMC_VIEW_ALL')
				&& stateService.includes('**.reviews.**'),
			help: 'Communicate directly with Account Owner',
		}];

		this.isCommentsSection = some(this.switchers, {visible: true});
		this.isCommentsSection && (this.activeSwitcher = find(this.switchers, {visible: true}));
	}

	changeResource(switcher: Switcher): void {
		this.activeSwitcher = switcher;
		this.onChange.emit(switcher.name)
	}

}
