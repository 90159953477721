import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { PasswordService } from './password.service';


@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		ComponentsModule,
		DirectivesModule,
	],
	declarations: [
	],
	providers: [
		PasswordService,
	],
	entryComponents: [
	],
})

export class SharedServicesModule {}
