import { Component, OnChanges, OnInit } from '@angular/core';
import { MapMarkerClusterer } from '@angular/google-maps';

@Component({
	selector: 'custom-map-marker-clusterer',
	template: '<ng-content></ng-content>',
})
export class CustomMarkerClustererComponent extends MapMarkerClusterer implements OnChanges, OnInit {
	markerClusterer: MarkerClusterer & { onRemove: () => void };

	ngOnInit() {
		super.ngOnInit();

		this.markerClusterer.onRemove = () => {
			this.markerClusterer?.clearMarkers();
			this.markerClusterer = null;
		};
	}
}