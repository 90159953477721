<ng-template #panelTemplate>
	<div class="panel">
		<div class="panel-heading" *ngIf="tabs.length > 1">
			<div class="tabs btn-group">
				<ng-container *ngFor="let tab of tabs">
					<button type="button" class="btn"
					        [ngClass]="isActiveTab(tab) ? 'btn-primary' : 'btn-secondary'"
					        (click)="setActiveTab(tab)"
					>{{tab.name}}</button>
				</ng-container>
			</div>
		</div>
		<div class="panel-body">

			<ng-container [ngSwitch]="activeTab">

				<ng-container *ngSwitchCase="TABS.DETAILS">
					<finding-details
						[finding]="finding"
						(onClose)="close()"
						(onCreateTask)="createTask()"
					></finding-details>
					<section>
						<supporting-documents [finding]="finding"></supporting-documents>
					</section>
					<h3>Internal Comments</h3>
					<comments [resource]="commentsResource" [permissions]="commentsPermissions"></comments>
				</ng-container>

				<ng-container *ngSwitchCase="TABS.ISSUES">
					<issue-list></issue-list>
				</ng-container>

				<ng-container *ngSwitchCase="TABS.AUDIT_LOG">
					<finding-audit-log></finding-audit-log>
				</ng-container>

				<ng-container *ngSwitchCase="TABS.CHECK_LIST">
					<finding-checklist
						[finding]="finding"
					></finding-checklist>
				</ng-container>

				<ng-container *ngSwitchCase="TABS.TASK">
					<task
						[finding]="finding"
						(onCancel)="cancelTaskCreation()"
					></task>
					<ng-container *ngIf="finding.hasRemediation">
						<h3>Communication</h3>
						<comments
							[resource]="taskCommunicationResource"
							[permissions]="taskCommunicationPermissions"
							entityName="message"
						>
							<ng-template #commentTemplate let-comment="comment">
								<div>
									<div class="horizontal-flex-group">
										<div class="full-width">
											<b>{{comment.createdBy.fullName}}</b>
											<span
												tooltip="Account Owner"
												*ngIf="comment.createdBy.isAccountOwner"
												class="owner-icon c-icon c-icon-profile-image">
											</span>
										</div>
										<div class="text-light">
											{{comment.createdDate | date:'MM/dd/yyyy HH:mm'}}
										</div>
									</div>
									<p class="comment">{{comment.message}}</p>
								</div>
							</ng-template>
						</comments>
					</ng-container>
				</ng-container>

			</ng-container>

		</div>
	</div>
</ng-template>

<full-page-layout class="social-compliance-publisher-finding" (onClose)="close()">

	<ng-template #headerTemplate>
		<h4 class="page-title">Finding Details</h4>
	</ng-template>

	<ng-template #contentTemplate>
		<ng-container *ngTemplateOutlet="panelTemplate"></ng-container>
		<finding-post-preview *ngIf="finding.postKey"></finding-post-preview>
		<finding-screenshot *ngIf="!finding.postKey && finding.hasScreenshot" [finding]="finding"></finding-screenshot>
		<div class="empty-preview remote-resource" [class.resolved]="finding.id || finding.$resolved" *ngIf="!finding.postKey && !finding.hasScreenshot"></div>
	</ng-template>

</full-page-layout>
