import { Component, OnInit, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';

import { MonitoringSettingsService } from '../monitoring-settings.service';
import { MonitoringListComponent } from '../common';
import { MonitoringSettingsResourceService } from 'tpo/status/monitoring-settings-resource.service';

@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => IndividualsListComponent) },
	],
})
export class IndividualsListComponent extends MonitoringListComponent implements OnInit {
	static listName = IndividualsListComponent.addName('individualsMonitoring');
	listName = IndividualsListComponent.listName;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		modalService: BsModalService,
		monitoringSettingsService: MonitoringSettingsService,
		public monitoringSettingsResourceService: MonitoringSettingsResourceService,
	) {
		super(
			transitionService,
			stateService,
			userService,
			modalService,
			monitoringSettingsService,
		);

		this.defaultFilters = {
			...this.defaultFilters,
			size: 50,
		};
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.getMonitoringEnabledCount();
	}

	getMonitoringEnabledCount = (): void => {
		this.monitoringSettings.enabledLinks.$resolving = true;
		this.monitoringSettingsResourceService.individuals.list.getEnabledCount().$promise
			.then(({ value }): void => {
				this.monitoringSettings.enabledLinks.count = value;
			})
			.finally((): void => {
				this.monitoringSettings.enabledLinks.$resolving = false;
			});
	}

	loadList(queryParams): void {
		this.list.$resolved = false;
		return this.monitoringSettingsResourceService.individuals.list.get(
			this.getClearParams(queryParams),
		).$promise
			.then((data: any[]): void => {
				this.list = data;
			});
	}

	// contact monitoring
	toggleContactMonitoring = (contact: any): void => {
		this.verifyMonitoringAccess()
			.then(
				(): void => {
					const { contactId, monitoringStatus: currentMonitoringStatus } = contact;
					const monitoringStatus = currentMonitoringStatus === 'off' ? 'on' : 'off';

					contact.$resolving = true;
					this.monitoringSettingsResourceService.individuals.contact.updateContactMonitoringStatus(
						{ contactId },
						{ monitoringStatus },
					).$promise
						.then(({ onMonitoringStatus }): void => {
							contact.monitoringStatus = onMonitoringStatus;

							if (this.isAccountsListExpanded(contact)) {
								contact.accounts.forEach((account): void => {
									account.monitoringStatus = onMonitoringStatus;
								});
							}

							this.getMonitoringEnabledCount();
						})
						.finally((): void => {
							contact.$resolving = false;
						});
				},
				(): void => {},
			);
	}

	getContactMonitoring = (contact: any): void => {
		const { contactId } = contact;

		contact.$resolving = true;
		this.monitoringSettingsResourceService.individuals.contact.getContactMonitoringStatus({ contactId }).$promise
			.then(({ onMonitoringStatus }): void => {
				contact.monitoringStatus = onMonitoringStatus;
			})
			.finally((): void => {
				contact.$resolving = false;
			});
	}

	// contact account monitoring
	toggleAccountMonitoring = (contact: any, account: any): void => {
		this.verifyMonitoringAccess()
			.then(
				(): void => {
					const { contactId } = contact;
					const { linkId, monitoringStatus: currentMonitoringStatus } = account;
					const monitoringStatus = currentMonitoringStatus === 'off' ? 'on' : 'off';

					contact.$disabled = true;
					account.$resolving = true;
					this.monitoringSettingsResourceService.individuals.contact.updateAccountMonitoringStatus(
						{ contactId, linkId },
						{ monitoringStatus },
					).$promise
						.then(({ onMonitoringStatus }): void => {
							account.monitoringStatus = onMonitoringStatus;

							this.getContactMonitoring(contact);

							this.getMonitoringEnabledCount();
						})
						.finally((): void => {
							contact.$disabled = false;
							account.$resolving = false;
						});
				},
				(): void => {},
			);
	}

	getAccountsListId = ({ contactId }): string => `${contactId}_collapse`;

	fetchAccounts = (contactId: number): any => (
		this.monitoringSettingsResourceService.individuals.contact.getAccounts(
			{ contactId },
			this.updateLinksConnectionStatus,
		)
	)

	toggleAccountsList = (contact: any): void => {
		const { contactId } = contact;
		contact.accounts = this.isAccountsListExpanded(contact) ? null : this.fetchAccounts(contactId);
	}

	isAccountsListExpanded = ({ accounts }): boolean => !!accounts;

	stopPropagation = (event): void => {
		event.stopPropagation();
	}
}
