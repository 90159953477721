import { Component, Input, Output, OnChanges, SimpleChanges, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { UserService } from 'angularjs-providers/user.provider';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ConfirmModalComponent } from 'commons/components/modals';

import { CommonPartialScheduleComponent } from 'tpo/social-compliance/schedule/partials/common-partial.component';
import {
    MonitoringScheduleCodeValue,
    MonitoringScheduleData,
    MonitoringSchedulePrice,
    MonitoringScheduleService,
} from 'tpo/social-compliance/schedule/schedule.service';

import { GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

@Component({
    selector: 'tpo-audit-schedule',
    templateUrl: './tpo-audit-schedule.component.html',
})
export class TpoAuditScheduleComponent extends CommonPartialScheduleComponent implements OnChanges {
    @Input() price: Subject<MonitoringSchedulePrice>;
    @Input() priceLoading: boolean;

    @Input() loading: boolean;
    @Output() loadingChange = new EventEmitter<boolean>();
    isToggleEditModeEnabled: boolean;

    modalRef: BsModalRef;
    form = new RealmFormGroup({
        amount: new RealmFormControl(
            'amount',
            {
                label: 'Schedule NMLS IDs amount',
                updateOn: 'change',
            },
        ),
        amountType: new RealmFormControl(
            'amountType',
            {
                label: 'amountType',
                updateOn: 'change',
            },
        ),
        nextOrderDate: new RealmFormControl(
            'nextOrderDate',
            {
                label: 'Next Order',
            },
        ),
        frequency: new RealmFormControl(
            'frequency',
            {
                label: 'Schedule Frequency',
                updateOn: 'change',
            },
        ),
        reviewFrequency: new RealmFormControl(
            'reviewFrequency',
            {
                label: 'Exclusion Period',
                updateOn: 'change',
            },
        ),
    });
    amountSettings = {
        length: 0,
        format: '',
    };
    dateFormat: string = 'MM/dd/yyyy';
    mDateFormat: string = 'MM/DD/YYYY';
    minDate = new Date();
    initialAuditSchedule: boolean;

    values: Record<'frequency' | 'reviewFrequency' | 'amountType', Observable<MonitoringScheduleCodeValue[]>>;

    constructor(
        userService: UserService,
        public scheduleService: MonitoringScheduleService,
        public modalService: BsModalService,
        private cd: ChangeDetectorRef,
    ) {
        super(userService);
        this.values = {
            amountType: this.scheduleService.getAmountTypes(),
            frequency: this.scheduleService.getFrequencies(),
            reviewFrequency: this.scheduleService.getReviewFrequencies(),
        };
        this.form.get('amountType').valueChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.destroy$),
        ).subscribe(({ code }: MonitoringScheduleCodeValue) => {
            this.switchAmountCheck(code);
        });
        this.form.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.destroy$),
        ).subscribe((formData) => {
            const { auditSchedule: { auditScheduleSettings, ...auditScheduleRest}, ...settingsRest } = this.settings;
            this.priceUpdate.next({ auditSchedule: { auditScheduleSettings: { ...auditScheduleSettings, ...formData }, ...auditScheduleRest }, ...settingsRest });
        });
    }

    ngOnChanges({ settings = null }: SimpleChanges): void {
        if (settings?.currentValue && !settings?.previousValue) {
            this.resetForm();
            this.initialAuditSchedule = this.settings.auditSchedule.enabled;
        }
    }

    switchAmountCheck(type): void {
        const amountField = this.form.get('amount');
        const isPercent = type === 'P';

        if (isPercent) {
            this.amountSettings = { length: 4, format: '1.0-1' };
            amountField.setValidators([Validators.required, Validators.min(0.1), Validators.max(100)]);
        } else {
            this.amountSettings = { length: 9, format: '1.0-0' };
            amountField.setValidators([Validators.required, Validators.min(1), Validators.max(9999999)]);
        }
    }

    resetForm(): void {
        const { nextOrderDate: oldOrderDate, ...auditScheduleSettingsRest } = this.settings.auditSchedule.auditScheduleSettings;
        let nextOrderDate;
        if(!this.isToggleEditModeEnabled) {
            nextOrderDate = oldOrderDate && moment(oldOrderDate).toDate().getTime() > this.minDate.getTime() ? moment(oldOrderDate).toDate() : this.minDate;
        }
        else {
            nextOrderDate = null;
            this.isToggleEditModeEnabled = false;
        }
        this.form.reset({ nextOrderDate, ...auditScheduleSettingsRest });
    }

    toEdit(): void {
        // Redirect to subscription if not enabled
        if (!this.redirectIfNoCompliance()) return;

        this.editing = true;
        this.resetForm();
    }

    cancelEdit(): void {
        this.editing = false;
        this.resetForm();
    }

    async toggleSection(enabled: boolean): Promise<void> {
        // Redirect to subscription if not enabled
        if (!this.redirectIfNoCompliance()) return;

        if (enabled) {
            this.updateSettings({ auditSchedule: { ...this.settings.auditSchedule, enabled } });
            this.priceUpdate.next(this.settings);
            this.isToggleEditModeEnabled = true;
            this.toEdit();
        } else {
            this.loading = true;
            this.loadingChange.emit(this.loading);
            const settings = await this.scheduleService.update({ auditSchedule: { enabled } }).toPromise();
            this.updateSettings(settings);
            this.initialAuditSchedule = settings.auditSchedule.enabled;
            this.priceUpdate.next(this.settings);
            this.loading = false;
            this.loadingChange.emit(this.loading);
        }
    }

    async saveSchedule(startNow = false): Promise<void> {
        const auditScheduleSettings = this.form.value;
        auditScheduleSettings.nextOrderDate = moment(auditScheduleSettings.nextOrderDate).format(this.mDateFormat).toString();
        this.loading = true;
        this.cd.detectChanges();
        try {
            const settings = await this.scheduleService.update({
                ...this.settings,
                auditSchedule: { ...this.settings.auditSchedule, auditScheduleSettings },
            }, { startNow }).toPromise();
            this.updateSettings(settings)
            this.initialAuditSchedule = settings.auditSchedule.enabled;
            this.priceUpdate.next(settings);
            const formattedDate = moment(auditScheduleSettings.nextOrderDate).format(this.mDateFormat);

            if (settings.generatedOrderId) {
                this.showOrderDetailsToast(settings);
            }

            this.notifyEmitter.emit({ message: `Updated settings will be applied to the next order on ${formattedDate}` });
            this.cancelEdit();
        } catch (e) {
            this.form.setServerError(e.data || e);
        }
        this.loading = false;
    }

    confirmSchedule() {
        const isToday = this.isToday(this.form.value);
        const startNow = isToday && this.settings.auditSchedule.enabled;

        if (startNow) {
            this.showPlaceOrderModal(() => {
                this.saveSchedule(startNow);
            });
        } else {
            this.saveSchedule();
        }
    }

    showPlaceOrderModal(onConfirm: () => any) {
        this.modalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: {
                title: 'Start Schedule',
                confirmText: 'Save',
                cancelText: 'Cancel',
                message: `New Order will be placed <b>immediately</b>.`,
                onConfirm: () => {
                    this.modalRef.hide();
                    onConfirm();
                },
                onCancel: () => {
                    this.modalRef.hide();
                },
            },
            class: 'modal-smd modal-new',
        });
    }

    isToday(schedule) {
        return moment(this.minDate).format(this.mDateFormat) === moment(schedule.nextOrderDate).format(this.mDateFormat);
    }

    showOrderDetailsToast({ isLastScheduledOrderFailed, generatedOrderId }: Partial<MonitoringScheduleData>) {
        const href = `#/social-compliance/orders/${generatedOrderId}`;
        if (!isLastScheduledOrderFailed) {
            this.notifyEmitter.emit({
                message: `Order was created successfully. <a href="${href}" class="text-success underlined">View Order</a>`,
                type: GlobalNotificationType.POSITIVE,
            });
        } else {
            this.notifyEmitter.emit({
                message: `Payment failed. <a href="${href}" class="text-danger underlined">View Order</a>`,
                type: GlobalNotificationType.ERROR,
            });
        }
    }
}
