import { Component, forwardRef, OnInit } from '@angular/core';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';

import { NgResourceArray } from 'commons/interfaces';
import { ListComponent, PagedListComponent } from 'commons/components/list';

import { ClientLibraryDocument } from '../library-documents.interface';
import { ClientLibraryDocumentsService } from '../library-documents.service';

@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ClientLibraryDocumentListComponent) },
	],
})
export class ClientLibraryDocumentListComponent extends PagedListComponent implements OnInit {
	static listName = ClientLibraryDocumentListComponent.addName('clientLibraryDocumentList');
	listName = ClientLibraryDocumentListComponent.listName;
	list: NgResourceArray<ClientLibraryDocument> = [];

	constructor(
		private uiRouterGlobals: UIRouterGlobals,
		transitionService: TransitionService,
		stateService: StateService,
		private documentsService: ClientLibraryDocumentsService,
	) {
		super(transitionService, stateService);
	}

	ngOnInit() {
		this.configureList();

		super.ngOnInit();
	}

	configureList() {
		const { id: tpoId } = this.uiRouterGlobals.params;
		Object.assign(
			this.defaultFilters,
			{
				tpoId,
				size: 25,
			},
		);

		this.omitParams.push('tpoId');
	}

	async loadList(queryParams) {
		this.list.$resolved = false;
		try {
			this.list = await this.documentsService.getDocuments(
				this.getClearParams(queryParams),
			).$promise;
		} catch (e) {}
		this.list.$resolved = true;
	}

	reloadList() {
		this.loadList(this.params);
	}
}
