import { NgModule } from '@angular/core';

import pipes from 'commons/pipes';
import { AddressPipe } from 'commons/pipes/address.pipe';

@NgModule({
	imports: [],
	declarations: [
		...pipes,
		AddressPipe,
	],
	exports: [
		...pipes,
		AddressPipe,
	],
	providers: [
		...pipes,
	],
})

export class PipesModule {}
