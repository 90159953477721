import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { map, omit, omitBy, isArray } from 'lodash';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';

import { RolesResourceService as RolesResource } from 'shared/roles/roles-resource.service';
import { SharedUsersListComponent, SharedUsersResourceService as SharedUsersResource } from 'shared/user-management';

import { LenderUsersResourceService as LenderUsersResource } from '../users-resource.service';
import { LenderUserStatuses } from '../user-statuses';
import { columnsConfig } from './columns-config';

@Component({
	templateUrl: './users.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => LenderUsersListComponent) },
	],
})
export class LenderUsersListComponent extends SharedUsersListComponent {
	static listName = LenderUsersListComponent.addName('lUsers');
	listName = LenderUsersListComponent.listName;

	// ColumnsManagerMixin
	// GOTCHA: objects and arrays should have default value to properly work with mixins
	localStorageName = 'LenderUsersCols';
	columns = new Map();

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public User: UserService,
		public SharedUsersResourceService: SharedUsersResource,
		public LenderUsersResourceService: LenderUsersResource,
		public RolesResourceService: RolesResource,
	) {
		super(transitionService, stateService, User);

		this.setService();
		this.setDefaultFilters();
		this.prepareFilters();
		this.readColumnsConfig();
	}

	setService() {
		this.service = this.SharedUsersResourceService;
	}

	setDefaultFilters() {
		this.defaultFilters = {
			...this.defaultFilters,
			status: [],
			systemRole: [],
			channel: [],
		};
	}

	prepareFilters() {
		const rolesList = this.RolesResourceService.listNonCounted();
		rolesList.$promise.then((data) => {
			rolesList.data = [ ...data ];
		});

		const channels = this.getChannels();

		this.filters = {
			status: [ ...LenderUserStatuses ],
			systemRole: rolesList,
			channel: channels,
		};
	}

	getChannels() {
		if (!this.User.profile.isLender) {
			return;
		}

		const channels = this.LenderUsersResourceService.getChannels({ activeOnly: true });
		channels.$promise.then((data) => {
			channels.data = [ ...data ];
		});

		return channels;
	}

	readColumnsConfig() {
		this.prepareColumns(columnsConfig);
	}

	setFilter(filter, resetPage: boolean = true) {
		const pageParams = resetPage ? {page: 0} : {};

		if (filter.status) {
			filter.status = map(filter.status || [], 'id');
		}

		if (filter.systemRole) {
			filter.systemRole = map(filter.systemRole || [], 'id');
		}

		if (filter.channel) {
			filter.channel = map(filter.channel || [], 'id');
		}

		this.params$.next({...this.params$.getValue(), ...pageParams, ...filter});
	}

	getClearParams(queryParams) {
		const values = omitBy(omit(queryParams, '#', '$inherit'), (value) => (!value));

		values.status = values.status ? ( isArray(values.status) ? values.status : [ values.status ] ) : [];
		values.systemRole = values.systemRole ? ( isArray(values.systemRole) ? values.systemRole : [ values.systemRole ] ) : [];
		values.channel = values.channel ? ( isArray(values.channel) ? values.channel : [ values.channel ] ) : [];

		return values;
	}
}
