import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { LocalStorageService } from 'commons/services/localstorage.service';
import { RecurlyModule, RecurlyProvider } from 'commons/recurly';
import { PipesModule } from 'commons/pipes/pipes.module';

// Services
// import { TemplatesResourceService } from './templates-resource.service';
// import { CampaignsResourceService } from '../campaigns';
// Components
import { TpoNewOrderComponent, TpoPaymentOrderComponent } from './new';
import { SmOrderService } from '.';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivesModule,
		ComponentsModule,
		PipesModule,
		RecurlyModule,
		BsDropdownModule.forRoot(),
	],
	declarations: [
		TpoNewOrderComponent,
		TpoPaymentOrderComponent,
	],
	entryComponents: [
		TpoNewOrderComponent,
		TpoPaymentOrderComponent,
	],
	providers: [
		RecurlyProvider,
		SmOrderService,
		LocalStorageService,
	],
})
export class TpoOrdersModule {}
