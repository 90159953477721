import { NgModule } from '@angular/core';

import { ComponentsModule } from 'commons/components/components.module';

import { AuditsModule } from './audits/audits.module';
import { OrdersModule } from './orders/orders.module';
import { TriggersModule } from './triggers/triggers.module';
import { FeedModule } from './archive/feed/feed.module';
import { SocialComplianceComponent } from './social-compliance.component';
import { SocialMediaModule } from 'shared/social-media';
import { CommonModule } from '@angular/common';

const components = [
	SocialComplianceComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		SocialMediaModule,
	],
	declarations: [
		...components,
	],
	entryComponents: [
		...components,
	],
	exports: [
		FeedModule,
		AuditsModule,
		OrdersModule,
		TriggersModule,
	],
})
export class SharedSocialComplianceModule {}
