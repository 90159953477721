import { Injectable, Injector } from '@angular/core';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { asyncScheduler, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { Error40xComponent } from 'commons/services/error/error-40x.component';
import { HistoryLogService } from 'angularjs-providers/history-log-service.provider';
import { LocalStorageService } from 'commons/services/localstorage.service';

declare let window: Window

@Injectable()
export class ErrorModalService {
    private modalRef: BsModalRef;
    private httpException$ = new Subject();
    private updateTimeout$ = new Subject<number>();
    private timeoutCheckTimeout;
    private modalService: BsModalService;
    private readonly TIMEOUT = window.env['server_session_timeout'] * 1000;

    constructor(
        private readonly notificationsService: GlobalNotificationsService,
        private readonly injector: Injector,
        private readonly stateService: StateService,
        private readonly historyLogService: HistoryLogService,
        private readonly localStorageService: LocalStorageService,
        { start$ }: UIRouterGlobals,
    ) {
        // Hide modals on screen change
        start$.subscribe(() => this.disposeModal());
        this.setup500Handler();
        this.setupTimeoutHandler();
    }

    private setup500Handler = () => {
        this.httpException$.pipe(
            throttleTime(500, asyncScheduler, { leading: true, trailing: false }),
        ).subscribe(() => this.notificationsService.add({
            message: `<h3>Server error happened</h3>
                    <div class="text-muted">
                    You have experienced a technical error. We apologize.<br>
                    Please wait a few moments and try to continue your work.<br>
                    If page does not respond, please reload the page.
                </div>`,
            type: GlobalNotificationType.ERROR,
            timeout: 5000,
        }));
    }

    private setupTimeoutHandler = () => {
        this.updateTimeout$.pipe(
            throttleTime(500, asyncScheduler, { leading: true, trailing: false }),
        ).subscribe((timeout) => {
            if (this.timeoutCheckTimeout) {
                clearTimeout(this.timeoutCheckTimeout);
            }
            const timeoutAt = Date.now() + timeout;
            this.localStorageService.save('SESSION_TIMEOUT_AT', timeoutAt);
            this.timeoutCheckTimeout = setTimeout(() => {
                const ts = Date.now();
                const timeoutAt = this.localStorageService.get('SESSION_TIMEOUT_AT') * 1;
                const timeout = timeoutAt - ts;
                if (timeout > 0) {
                    return this.updateTimeout$.next(timeout);
                }
                this.showTimeout();
            }, Math.max(timeout, 0));
        });
    }

    private injectModalService = () => {
        if (this.modalService) {
            return;
        }

        this.modalService = this.injector.get(BsModalService);
    }

    postponeTimeout = (timeout: number = this.TIMEOUT) => {
        this.updateTimeout$.next(timeout);
    }

    handleHttpError = (status: number, navigateBack: boolean = true) => {
        if (status >= 500) {
            return this.httpException$.next();
        }

        if ([403, 404].includes(status)) {
            return this.show4xx(status, navigateBack);
        }

        if (status === 401) {
            return this.updateTimeout$.next(-1);
        }
    };

    private show4xx = (status: number, navigateBack: boolean = true) => {
        this.injectModalService();
        // replace 404 with 403 if received both
        if (this.modalRef?.content?.status > status) {
            this.disposeModal();
        }

        // Do nothing if error already shown
        if (this.modalRef) {
            return ;
        }

        const initialState: Partial<Error40xComponent> = {
            ok: () => {
                this.modalRef.hide();

                if (!!navigateBack) {
                    const previousState = this.historyLogService.findBackState();
                    if (previousState) {
                        return this.historyLogService.goBackTo(previousState);
                    }

                    if (this.stateService.get('home')) {
                        this.stateService.go('home', {}, { location: 'replace' });
                        return;
                    }
                    this.stateService.go('login', {}, { location: 'replace' });
                }
            },
            status,
        };

        this.modalRef = this.modalService.show(Error40xComponent, {
            initialState,
            class: `modal-dialog modal-smd modal-new modal-${status}`,
            backdrop: 'static',
        });
    }

    private showTimeout = () => {
        const status = 401;
        this.injectModalService();
        // replace any shown popup
        if (this.modalRef?.content?.status > status) {
            this.disposeModal();
        }

        // Do nothing if error already shown
        if (this.modalRef) {
            return ;
        }

        const initialState: Partial<Error40xComponent> = {
            ok: () => {
                location.reload();
            },
            status,
            timeoutMin: Math.round(this.TIMEOUT / 60_000),
        };

        this.modalRef = this.modalService.show(Error40xComponent, {
            initialState,
            class: `modal-dialog modal-smd modal-new modal-${status}`,
            backdrop: 'static',
        });
    }

    private disposeModal = () => {
        if (!this.modalRef)
            return;

        this.modalRef.hide();
        this.modalRef = null;
    };
}
