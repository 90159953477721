import { remove } from 'lodash';
import { Component, forwardRef, Input } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';

import { ContactsListComponent } from 'tpo/contacts/contacts-list.component';
import { ContactsResourceService } from 'tpo/contacts/contacts-resource.service';
import { LenderNMLSContactsResourceService } from 'lender/contacts/lender-nmls-contacts-resource.service';
import { RelatedAccount } from 'lender/contacts/related-account';
import { AccountInformation } from 'shared/account/company-information/account-information.service';

@Component({
	selector: 'lender-contacts-list',
	templateUrl: '../../../tpo/contacts/contacts-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => LenderContactsListComponent) },
	],
})

export class LenderContactsListComponent extends ContactsListComponent {
	static listName = LenderContactsListComponent.addName('lContacts');
	listName = LenderContactsListComponent.listName;

    public relatedAccount: RelatedAccount;

    @Input('relatedAccount') set company(company: AccountInformation) {
        if (!company) {
            this.relatedAccount = null;
            return;
        }

        const { companyName: name, nmlsId } = company;
        this.relatedAccount = { name, nmlsId };
    }

	isRegisteredTPO: boolean = false;

	constructor(public User: UserService, public TPOContactResource: ContactsResourceService, public NMLSContactResource: LenderNMLSContactsResourceService, transitionService: TransitionService, stateService: StateService) {
		super(User, TPOContactResource, transitionService, stateService);

		this.defaultFilters.lenderId = User && User.profile && User.profile.organization.id;

		this.omitParams.push('lenderId');
		this.omitParams.push('tpoId');
		this.omitParams.push('id');

		this.isRegisteredTPO = !this.stateService.includes('nmls.client.:id.**');

		if (!this.isRegisteredTPO) {
			this.ContactResource = NMLSContactResource;
		}

		if (User && User.profile && User.profile.crmEnabled) {
			if (!this.isRegisteredTPO) {
				remove(this.filters.contactType, {value: 'individual'});
			}

			this.filters.contactType.push({
				value: 'lender_individual',
				title: 'My Contacts',
			});
		}
	}

	getLoadListParams(queryParams) {
		return {
			...this.getClearParams(queryParams),
			tpoId: this.stateService.params.id,
			id: null,
		};
	}
}
