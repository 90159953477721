<div class="payments">
	<div class="gray-head">
		<h2>Payments</h2>
	</div>

	<div class="row-fluid filter-labels">
		<div class="col-sm-3">
			<div class="text-muted">Search</div>
		</div>
		<div class="col-sm-9"></div>
	</div>

	<div class="row-fluid filter-selectors">
		<div class="col-sm-3">
			<div class="form-group no-margin has-feedback left-position">
				<input
					class="form-control"
					[formControl]="searchControl"
					[placeholder]="searchControl.label"
					type="search"
					maxlength="75"
				/>
				<a
					class="form-control-feedback"
					[ngClass]="!searchControl.value ? 'c-icon c-icon-search' : 'glyphicon glyphicon-remove'"
					(click)="searchControl.setValue('')"
				></a>
			</div>
		</div>
		<div class="col-sm-9"></div>
	</div>

	<section class="payments-page">
		<div class="rows-bordered remote-resource" [class.resolved]="filteredPayments | async">
			<div class="row-fluid header">
				<div class="col-sm-4">
					<b>Partner</b>
				</div>
				<div class="col-sm-2">
					<b>Invoice Date</b>
				</div>
				<div class="col-sm-1">
					<b>Invoice Num</b>
				</div>
				<div class="col-sm-1">
					<b>Amount</b>
				</div>
				<div class="col-sm-2"></div>
			</div>
			<div class="row-fluid" *ngFor="let payment of filteredPayments | async">
				<div class="col-sm-4">
					<div class="flex-row gap-m">
						<img
							*ngIf="payment.lenderId"
							class="w140 pd5 border-gray img-rounded img-responsive"
							[src]="'/api/rest/lenders/' + payment.lenderId + '/logo'"
							[alt]="payment.clientName"
						/>

						<div>
							<h4 class="brand-title">{{payment.lenderName}}</h4>
							<span class="text-light">{{payment.channelName}}</span>
						</div>
					</div>
				</div>
				<div class="col-sm-2 va-m">
					<span>{{payment.invoiceDate | date: 'MM/dd/yyyy HH:mm'}}</span>
				</div>
				<div class="col-sm-1 va-m">
					<span>{{payment.invoiceId}}</span>
				</div>
				<div class="col-sm-1 va-m">
					<span>{{payment.amountPaid | currency}}</span>
				</div>
				<div class="col-sm-2 print va-m">
					<span class="glyphicon glyphicon-print"></span>
					<a [href]="'/api/rest/tpos/' + tpoId + '/payments/'+ payment.invoiceId" target="_blank">
						Print receipt
					</a>
				</div>
			</div>
			<div class="row-fluid" *ngIf="!(filteredPayments | async)?.length"> <!--!(payments|filter:searchPayments).length-->
				<span class="col-sm-12 text-muted text-bigger text-weak text-center va-m">
					{{searchControl.value?.length ? 'No matches found' : 'No payments'}}
				</span>
			</div>
		</div>
	</section>
</div>
