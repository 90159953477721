import { Component, forwardRef } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Mixin } from 'utils/mixin.decorator';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { ListComponent, PagedListComponent } from 'commons/components/list';

import { ConnectSmMixin, FacebookConnector, GmbConnector, SmNetworkConnector } from 'tpo/social-accounts/connect';
import { SocialConnectResourceService } from 'tpo/social-accounts/company/social-connect-resource.service';

import { CompanyListingsService } from '../company-listings.service';

@Mixin([ ConnectSmMixin ])
@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => CompanyListingGroupsComponent) },
	],
	host: {
		'(window:message)': 'onMessage($event)',
	},
})
export class CompanyListingGroupsComponent extends PagedListComponent implements ConnectSmMixin {
	static listName = CompanyListingGroupsComponent.addName('company-listings');
	listName = CompanyListingGroupsComponent.listName;

	User: UserProfile;
	list: any = [];
	networksIndication = [
		{ networkCode: 'GMB' },
		{ networkCode: 'FB' },
	];

	// IConnectMixin interface requirements
	networks;
	openConnectWindow;
	connectCommon;
	hideModal;
	connectModalState;
	connecting;
	connect;
	reconnect;
	connectFacebook;
	connectFacebookPage;
	noFacebookPagesToConnect;
	showFacebookPagesSelection;
	connectLinkedIn;
	connectLinkedInPages;
	openLinkedInPagesWindow;
	openNoLinkedInPagesWindow;
	showConnectInstagramModal;
	facebookUserShouldLogout;
	connectSelectedFacebookPages;
	showFacebookMortgageRelatedSelection;
	getStatusesForConnect;
	connectGMB;
	showGMBPages;
	finishGMBConnect;
	callbacks;
	initNetworks;
	onMessage;
	modalRef: BsModalRef;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		public modalService: BsModalService,
		public companyListingsService: CompanyListingsService,
		public socialConnectService: SocialConnectResourceService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;

		this.defaultFilters = {
			...this.defaultFilters,
			size: 100,
		};

		this.networks = {
			GMB: new GmbConnector({
				url: '/googlemb/dm-connect-flow',
				isDM: true,
				click: (entry = null) => {
					this.connectGMB(this.networks.GMB, entry);
				},
			}),
			FB: new FacebookConnector({
				click: (entry = null) => this.connectFacebookPage(entry),
			}),
		};

		const companyName = this.User.organization.name;
		this.connectModalState = {
			resolving: false,
			title: 'Company Listings',
			message: `Only select company locations that are owned by ${companyName} to be connected here.`,
			companyName,
			services: [
				this.networks.GMB,
				this.networks.FB,
			]
				.map((value: SmNetworkConnector) => ({
					...value,
					name: value instanceof GmbConnector ? value.name : companyName,
				})),
		};
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.companyListingsService.listingGroupsList(
			this.getClearParams(queryParams),
		).$promise
			.then((data: any) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}

	onConnectFinish(): void {
		this.loadList(this.params);
	}
}
