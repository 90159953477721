import { Component, Input } from '@angular/core';
import { StateService } from '@uirouter/core';

import { Partner } from 'tpo/partners/partners.interface';


@Component({
	selector: 'tpo-partners-list-item',
	templateUrl: './tpo-partners-list-item.component.html',
})
export class TpoPartnersListItemComponent {
    @Input() item: Partner;
    @Input() type: ('all' | 'my');

	constructor(
            private readonly stateService: StateService) {
	}
}
