import { Component, OnDestroy } from '@angular/core';
import { HookResult, StateDeclaration, StateService, Transition, TransitionService } from '@uirouter/angular';


interface Tab {
	state: string;
	title: string;
    params: any;
}

@Component({
	templateUrl: './partners.component.html',
})
export class TpoPartnersComponent implements OnDestroy {
    tabs: Tab[] = [
		{
			state: 'partners.all',
			title: 'All Companies',
            params: {
                type: 'all',
            },
		},
		{
			state: 'partners.my',
			title: 'My Partners',
            params: {
                type: 'my',
            },
		},
	];
	static listName: any;

    private onEnterUnregister: Function;

    public constructor(
            private readonly transitionService: TransitionService,
            private readonly stateService: StateService) {
        if (this.stateService.is('partners')) {
            this.transitionToDefaultTab();
        }

        this.onEnterUnregister = this.transitionService.onExit({}, (transition: Transition, state: StateDeclaration): HookResult => {
            const toStateDeclaration: StateDeclaration = transition.to();
            if (toStateDeclaration.name === 'partners') {
                transition.abort();
                this.transitionToDefaultTab();
            }
        });
    }

    // Override
    public ngOnDestroy(): void {
        if (this.onEnterUnregister) {
            this.onEnterUnregister();
        }
    }

    private transitionToDefaultTab(): void {
        this.stateService.transitionTo('partners.all', { /*type: 'all'*/ }, { location: 'replace', inherit: false });
    }
}
