import { Directive, OnInit, OnDestroy, ɵmarkDirty } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { isEmpty, isEqual, omit, omitBy, pick, trim, debounce, keys } from 'lodash';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, shareReplay, tap, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { shrinkListName } from 'commons/components/list/helpers/shrink-list-name';
import { addRecord, getRecord, hasRecord } from 'commons/components/list/helpers/used-names';
import { PagedData, PaginationData } from 'commons/services/http';

export type ListParams = Record<string, any>;

export type ParamProcessor = {
    encode: (param: unknown) => unknown;
    decode: (param: unknown) => unknown;
}

export const IntDateProcessor: ParamProcessor = {
    encode: (param: Date) => {
        if ( param instanceof Date ) {
            return param.getTime();
        }

        return param;
    },
    decode: (param: unknown) => {
        if ( 'number' === typeof param ) {
            return new Date(param);
        }

        if (!param) {
            return null;
        }

        return param;
    },
};


@Directive()
export abstract class NewListComponent<U, T extends U[] = U[]> implements OnInit, OnDestroy {
	//Static Name
	static _listName: string;
	static set listName(name) {
		// NOTICE: this is refer to class itself, not entity
		const className = this.name;

		if ( !name ) {
			throw new Error(`name property should be not an empty string in ${className}`);
		}

		const shortName = shrinkListName(name);
		const existingRecord = getRecord(shortName);

		if ( hasRecord(shortName) && existingRecord.className !== className ) {
			throw new Error(`Static property listName='${name}' and shortened '${shortName}' already defined in ${existingRecord.className}. Please, add another meaningful name`);
		}

		// if everything is ok, set new name(or set existing again)
		addRecord(shortName, { className, listName: name });

		this._listName = shortName;
	}

	static get listName(): string {
		return this._listName;
	}

	list: Observable<T>;
	resolving = false;
	listName: string;
	useLocation: boolean = true;
	noMatches: boolean = false;
	filtersApplied: boolean = false;
	globalParams: string[] = [];
	nonResettableParams: string[] = ['sort', 'page', 'size'];
	defaultParams: ListParams = {
		q: '',
		sort: '',
	};
	params: ListParams = {};
	params$: BehaviorSubject<ListParams> = new BehaviorSubject<ListParams>({});
        // Process params in/out of json list state params
    groupedParamProcessors: Record<string, ParamProcessor> = {};
    protected forceUpdate$ = new Subject<ListParams>();
	protected destroy$: Subject<void> = new Subject();

	protected constructor(protected router: UIRouter) {
		const staticThis = <typeof NewListComponent>this.constructor;
		this.listName = staticThis.listName;
	}

	ngOnInit(): void {
		this.setSearch = debounce(this.setSearch, 1000);

		this.router.globals.params$
			.pipe(
                takeUntil(this.destroy$),
            )
			.subscribe(params => this.onExternalParamUpdate(params));

        const filteredParams$ = merge(
            this.params$.pipe(
                debounceTime(10),
                distinctUntilChanged(isEqual),
            ),
            this.forceUpdate$.asObservable(),
        ).pipe(
            takeUntil(this.destroy$),
        );

		this.list = filteredParams$.pipe(
			switchMap(params => {
				this.resolving = true;
                const result = this.doRequest(params);
                // used not to inject ChangeDetectorRef.markForCheck()
                ɵmarkDirty(this);
				return result;
			}),
			tap((data) => {
				this.resolving = false;
				this.noMatches = !data.length && (this.filtersApplied || this.params.q);

                ɵmarkDirty(this);
			}),
			shareReplay(1),
		);

        filteredParams$.subscribe(flatParams => {
				this.params = flatParams;
				this.filtersApplied = this.hasSomeFiltersApplied(flatParams);
				if ( this.useLocation ) {
					const filteredParams = this.pickNonDefaultParams(flatParams);
					const params = {
						...this.pickGlobalParams(filteredParams),
						[this.listName]: this.encodeGroupedParams(this.pickParamsToGroup(filteredParams)),
					};
					this.router.stateService.transitionTo(this.router.globals.current.name, params, { inherit: true })
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	//When URL change
	onExternalParamUpdate(externalParams: ListParams): void {
		const params: ListParams = {
			...this.defaultParams,
			...this.pickGlobalParams(externalParams),
			...this.decodeGroupedParams(this.pickGroupedParams(externalParams)),
		}

		this.params$.next(params);
	}

	hasSomeFiltersApplied = (queryParams) => {
		return !isEqual(omit(queryParams, this.nonResettableParams), omit(this.defaultParams, this.nonResettableParams));
	}

	pickNonDefaultParams(params: ListParams): ListParams {
		return omitBy(omit(params, [ '#', '$inherit' ]), (value, key) => (isEqual(value, this.defaultParams[key])));
	}

	pickGlobalParams(params: ListParams): ListParams {
		return pick(params, this.globalParams);
	}

	pickGroupedParams(params: ListParams): ListParams {
		return params[this.listName];
	}

	pickParamsToGroup(flatParams: ListParams): ListParams | null {
		const params = omit(flatParams, this.globalParams, [ '#', '$inherit' ])
		return isEmpty(params) ? null : params;
	}

	resetFilters() {
		this.params$.next({ ...this.params, ...omit(this.defaultParams, this.nonResettableParams) });
	}

	resetSearch() {
		if ( !this.params.q ) {
			return;
		}

		const { q } = this.defaultParams;
		this.params$.next({ ...this.params, q });
	}

	setSearch($event) {
		const q = trim(`${$event.target.value}`);
		const currentParams = this.params;
		if ( currentParams.q === q ) {
			// do not search on same input
			return;
		}

		this.params$.next({ ...currentParams, q });
	}

	setFilter(filter) {
		this.params$.next({ ...this.params, ...filter });
	}

	setSort(name) {
		const { sort } = this.params;
		const [ sortName, direction ] = `${sort}`.split(',');

		// if no sorting or new sort column -> set column and asc
		if ( !sortName || name !== sortName ) {
			this.params$.next({ ...this.params, sort: `${name},asc` });
			return;
		}

		// on second click change to direction to desc
		if ( direction === 'asc' ) {
			this.params$.next({ ...this.params, sort: `${name},desc` });
			return;
		}

		// third click should reset sorting
		this.params$.next({ ...this.params, sort: '' });
	}

    updateList(params = this.params): void {
        this.forceUpdate$.next(params);
    }

    decodeGroupedParams(params: ListParams): ListParams {
        if (!params) return params;

        return Object.keys(params).reduce((acc, key) => {
            const processor = this.groupedParamProcessors[key];
            if (processor) {
                acc[key] = processor.decode(params[key]);
            } else {
                acc[key] = params[key];
            }
            return acc;
        }, {});
    }

    encodeGroupedParams(params: ListParams): ListParams {
        if (!params) return params;

        return Object.keys(params).reduce((acc, key) => {
            const processor = this.groupedParamProcessors[key];
            if (processor) {
                acc[key] = processor.encode(params[key]);
            } else {
                acc[key] = params[key];
            }
            return acc;
        }, {});
    }

	// TODO: replace hacked type to override after upgrading to TS 4.3
	protected doRequest(params): Observable<T> {
		return this.loadList(params) as Observable<T>;
	}

	// TODO: replace hacked type to override after upgrading to TS 4.3
	protected abstract loadList(params: ListParams): Observable<PagedData<T>> | Observable<T>;
}

@Directive()
export abstract class NewPagedListComponent<U, T extends U[] = U[], P extends PagedData<T> = PagedData<T>> extends NewListComponent<U, T> {
	pagination: Subject<PaginationData> = new Subject<PaginationData>();

	defaultParams: ListParams = {
		sort: '',
		q: '',
		page: 0,
		size: 10,
	}

	// TODO: replace hacked type to override after upgrading to TS 4.3
	protected doRequest(params): Observable<T> {
		const request = this.loadList(this.encodeGroupedParams(params)) as Observable<P>;
		return request.pipe(
			tap(result => this.pagination.next(result.pagination)),
			map(result => result.data),
		);
	}

	resetSearch() {
		if ( !this.params.q ) {
			return;
		}

		const { q } = this.defaultParams;
		this.params$.next({ ...this.params, page: 0, q });
	}

	resetPage() {
		const { page } = this.params;

		if ( !page ) {
			return;
		}

		this.params$.next({ ...this.params, page: 0 });
	}

	resetFilters() {
		this.params$.next({ ...this.params, ...omit(this.defaultParams, this.nonResettableParams), page: 0 });
	}

	setSearch($event) {
		const q = trim(`${$event.target.value}`);
		const currentParams = this.params;
		if ( currentParams.q === q ) {
			// do not search on same input
			return;
		}

		this.params$.next({ ...this.params, page: 0, q });
	}

	setFilter(filters: ListParams, resetPage: boolean = true) {
		const params = this.params;
		const currentFilter = pick(params, keys(filters));
		if ( isEqual(currentFilter, filters) ) {
			return;
		}

		const pageParams = resetPage ? { page: 0 } : {};
		this.params$.next({ ...params, ...pageParams, ...filters });
	}
}
