<form [formGroup]="warehouseForm"
      class="remote-resource col-md-12"
      [class.resolved]="!resolving"
      (onValidSubmit)="doSave()"
>
    <div class="row-fluid">
        <div class="col-sm-a form-group" has-error>
            <div [isEditable]="editing">
                <div view class="control-value">{{ item.companyName }}</div>
                <input edit type="text" name="companyName" formControlName="companyName" class="form-control" maxlength="98" tabindex="1" />
            </div>
        </div>
        <div class="col-sm-a form-group" has-error>
            <div [isEditable]="editing">
                <div view class="control-value">{{ item.contactName || '&ndash;'}}</div>
                <input edit type="text" name="contactName" formControlName="contactName" class="form-control" maxlength="98" />
            </div>
        </div>
        <div class="col-sm-a form-group" has-error>
            <div [isEditable]="editing">
                <div view class="control-value">{{ item.email || '&ndash;'}}</div>
                <input edit type="email" name="email" formControlName="email" class="form-control" maxlength="98"/>
            </div>
        </div>
        <div class="col-sm-a form-group" has-error>
            <div [isEditable]="editing">
                <div view class="control-value">${{ item.approvedLineAmount | number }}</div>
                <input edit type="text" name="approvedLineAmount" formControlName="approvedLineAmount" class="form-control" maxlength="15" validFloat="1.0-0"/>
            </div>
        </div>
        <div class="col-sm-a form-group" has-error>
            <div [isEditable]="editing">
                <div view class="control-value">{{ item.sellLoans ? 'Yes' : 'No' }}</div>
                <div edit class="control-value">
                    <label class="radio-inline">
                        <input type="radio" formControlName="sellLoans" [value]="true"> Yes
                    </label>
                    <label class="radio-inline">
                        <input type="radio" formControlName="sellLoans" [value]="false"> No
                    </label>
                </div>
            </div>
        </div>
        <div class="col-sm-a form-group" has-error>
            <div [isEditable]="editing">
                <div view class="control-value">
                    {{ item.relationshipStartDate ? (item.relationshipStartDate | date : 'MM/dd/yyyy') : '&ndash;' }}
                </div>
                <input edit
                       type="text"
                       name="relationshipStartDate"
                       formControlName="relationshipStartDate"
                       class="form-control"
                       bsDatepicker
                       [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
                       [placeholder]="'MM/DD/YYYY'"
                />
            </div>
        </div>
        <div class="col-sm-a form-group" has-error>
            <div [isEditable]="editing">
                <div view class="control-value">
                    {{ item.relationshipEndDate ? (item.relationshipEndDate| date : 'MM/dd/yyyy') : 'Present' }}
                </div>
                <input edit
                       type="text"
                       name="relationshipEndDate"
                       formControlName="relationshipEndDate"
                       class="form-control"
                       bsDatepicker
                       [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
                       [placeholder]="'MM/DD/YYYY'"
                />
            </div>
        </div>
        <div class="col-f-100 text-right va-m button-holder">
            <ng-container [ngSwitch]="editing">
                <ng-container *ngSwitchDefault>
                    <a *ngIf="userCanAddEdit" class="btn btn-icon btn-tertiary" (click)="doEdit()">
                        <span class="glyphicon glyphicon-pencil"></span>
                    </a>
                </ng-container>
                <ng-container *ngSwitchCase="true">
                    <button class="btn btn-icon btn-tertiary" type="submit"><span class="glyphicon glyphicon-ok"></span></button>
                    <a class="btn btn-icon btn-tertiary" (click)="doCancel()"><span class="glyphicon glyphicon-remove"></span></a>
                </ng-container>
            </ng-container>
        </div>
    </div>
</form>
