import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';

import { RealmFormControl } from 'commons/forms';

@Component({
	selector: 'new-social-media-link',
	templateUrl: './new-social-media-link.component.html',
})
export class NewSocialMediaLinkComponent implements OnInit, OnDestroy {
	unsubscribe$: Subject<void> = new Subject();

	@Input('formControlRef') formControl: RealmFormControl;
	@Input() show$: BehaviorSubject<boolean>;

	ngOnInit(): void {
		this.show$
			.pipe(
				takeUntil(this.unsubscribe$),
			)
			.subscribe((show) => {
				if (show) {
					this.formControl.enable();
					this.formControl.reset();
				} else {
					this.formControl.disable();
				}
			});
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	get show(): boolean {
		return this.show$?.getValue();
	}

	toggleShow(show = !this.show): void {
		this.show$.next(show);
	}
}
