<div class="social-media publisher-requests">
	<div class="gray-head flex-head">
		<back-bread class="col-fit" [opts]="{headerClass: 'title-label'}">
			<span>Publisher Requests</span>
		</back-bread>
	</div>

	<section class="requests">

		<h3>Requests</h3>

		<div class="remote-resource" [class.resolved]="list.$resolved">
			<div class="alert alert-info flex-row gap" *ngFor="let request of list">

				<div class="flex flex-row gap">
					<sm-icon [config]="request"></sm-icon>
					<div class="flex text-overflow">
						<div>
							<a uiSref="people.contacts.:contactId.information" [uiParams]="{ contactId: request.requestorTpoIndividualContactId }" target="_blank">{{ request.requestorName }}</a> requests access to post your social media account
						</div>
						<div>
							<a class="url-holder" target="_blank"  [href]="request.link">{{ request.linkName }}</a>
						</div>
					</div>
				</div>
				<div>
					<a class="btn btn-primary" (click)="confirmAccess(request, true)">Accept</a>
					<a class="btn btn-default" (click)="confirmAccess(request, false)">Decline</a>
				</div>
			</div>

			<div class="text-muted text-bigger text-center" *ngIf="list.$resolved && !list.length">No Requests</div>
		</div>

	</section>
</div>
