<section>
	<h2>
		Findings
		<div class="pull-right" *ngIf="User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE')">
			<button type="button" class="btn btn-primary" uiSref=".new-finding">
				<span class="glyphicon glyphicon-plus small-icon"></span>
				Add Finding
			</button>
		</div>
	</h2>
	<div class="remote-resource" [class.resolved]="list.$resolved">
		<div class="rows-bordered">
			<div class="row-fluid header">
				<sortable-header class="col-sm-1" columnName="id">ID</sortable-header>
				<sortable-header class="col-sm-3" columnName="name">Account</sortable-header>
				<sortable-header class="col-sm-a" columnName="status">Status</sortable-header>
				<sortable-header class="col-sm-a" columnName="statusDate">Status Date</sortable-header>
				<sortable-header class="col-sm-a" columnName="source">Source</sortable-header>
			</div>
			<a
				*ngFor="let item of list"
				class="row-fluid clickable"
				uiSref="social-compliance.findings.:actionType.:findingId"
				[uiParams]="{ actionType: 'edit', findingId: item.id }"
			>
				<div class="col-sm-1 va-m">
					{{item.id}}
				</div>
				<div class="col-sm-3 va-m">
					<div class="flex-row gap">
						<sm-icon [config]="item.mediaLink" [minified]="true"></sm-icon>
						<span class="flex text-overflow">{{item.mediaLink.name}}</span>
					</div>
				</div>
				<div class="col-sm-a va-m text-bigger">
					<span class="label" [ngClass]="item.isRemoved || item.condition === 'Closed' ? 'label-success' : 'label-warning'">
						{{item.isRemoved ? 'Removed' : item.status}}
					</span>
				</div>
				<div class="col-sm-a va-m">{{item.statusDate | date:'MM/dd/yyyy'}}</div>
				<div class="col-sm-a va-m">
					<finding-source class="flex-row gap" [source]="item.source"></finding-source>
				</div>
			</a>
			<div class="row-fluid" *ngIf="list.$resolved && !list.length">
				<div class="col-sm-12 text-center text-light text-bigger">No findings</div>
			</div>
			<br>
			<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[5,10,25]"></new-paging>
		</div>
	</div>
</section>
