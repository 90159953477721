<ng-container [ngSwitch]="status">
	<span *ngSwitchCase="'CONNECTED'">
		<i class="dot dot-success block"></i>&nbsp;
		Connected
	</span>
	<span *ngSwitchCase="'DISCONNECTED'">
		<i class="dot dot-danger block"></i>&nbsp;
		Disconnected
	</span>
</ng-container>
