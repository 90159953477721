import { animate, state, style, transition, trigger } from '@angular/animations';

export const SimpleShowAnimation = trigger('showSimple', [
    state('void', style({
        height: '0',
        opacity: '0',
        overflow: 'hidden',
    })),
    state('*', style({
        height: '*',
    })),
    transition(':enter', [animate('0.3s linear')]),
    transition(':leave', [animate('0.3s 20ms linear')]),
]);

export const BoxShowAnimation = trigger('showBox', [
    state('void', style({
        height: '0',
        paddingTop: '0',
        paddingBottom: '0',
        marginTop: '0',
        marginBottom: '0',
        opacity: '0',
        overflow: 'hidden',
    })),
    state('*', style({
        height: '*',
        overflow: 'hidden',
    })),
    transition(':enter', [animate('0.3s linear')]),
    transition(':leave', [animate('0.3s 20ms linear')]),
]);
