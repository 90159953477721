<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
    <h4 class="modal-title">
        {{ applicationSummary.clientName}} - <span>NMLS ID: {{ applicationSummary.nmlsId }}</span>
    </h4>
</div>
<form [formGroup]="form" (onValidSubmit)="save()">
    <div class="modal-body remote-resource" [class.resolved]="!resolving">
        <div class="alert alert-danger" *ngIf="docRequired">
            There are outstanding documents for this application.
            <a class="alert-danger-link" uiSref="client.:id.documents" [uiParams]="{id: applicationSummary?.tpoId}" target="_blank">View Documents</a>
        </div>
        <div class="alert alert-danger" *ngIf="warning" [innerHTML]="warning"></div>
        <form-errors [form]="form"></form-errors>

        <div class="form-group row" has-error>
            <label class="col-sm-4 control-label">{{ form.getFieldLabel('optionalStatus') }}</label>
            <div class="col-sm-8">
                <ng-select
                    [items]="values.optionalStatus"
                    bindLabel="name"
                    [clearable]="false"
                    placeholder="Choose option"
                    formControlName="optionalStatus"
                ></ng-select>
            </div>
        </div>
        <div class="form-group row" has-error *ngIf="isApproval">
            <label class="col-sm-4 control-label required">{{ form.getFieldLabel('renewalDate') }}</label>
            <div class="col-sm-8">
                <input class="form-control"
                       bsDatepicker
                       [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
                       [minDate]="minDate"
                       [maxDate]="maxDate"
                       formControlName="renewalDate"
                       placeholder="mm/dd/yyyy"
                >
            </div>
        </div>
        <div class="form-group row" has-error>
            <label class="col-sm-4 control-label">{{ form.getFieldLabel('accountExecutive') }}</label>
            <div class="col-sm-8">
                <ng-select
                    [items]="values.assignee"
                    bindLabel="fullName"
                    [clearable]="false"
                    placeholder="Select user"
                    formControlName="accountExecutive"
                ></ng-select>
            </div>
        </div>
        <div class="form-group" [class.no-margin]="!notify">
            <label class="checkbox-inline text-muted">
                <input type="checkbox" formControlName="notify">
                {{ form.get('notify')?.label }}
            </label>
        </div>
        <ng-container *ngIf="notify">
            <div class="form-group" has-error>
                <label class="control-label required">{{ form.getFieldLabel('notificationUserIds') }}</label>
                <div>
                    <ng-select
                        [items]="values.user"
                        bindValue="id"
                        bindLabel="fullName"
                        [clearable]="false"
                        [multiple]="true"
                        placeholder="Select users"
                        formControlName="notificationUserIds"
                    ></ng-select>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label">{{ form.getFieldLabel('ccRealmUserIds') }}</label>
                <div>
                    <ng-select
                        [items]="values.ccUser"
                        bindValue="id"
                        bindLabel="fullName"
                        [multiple]="true"
                        placeholder="Select users"
                        formControlName="ccRealmUserIds"
                    ></ng-select>
                </div>
            </div>
            <div class="form-group no-margin" has-error>
                <label for="message" class="control-label required">
                    {{ form?.getFieldLabel('notificationMessage') }}
                </label>
                <div>
                    <textarea
                        id="message"
                        class="form-control resize-vertical"
                        formControlName="notificationMessage"
                        [rows]="5"
                        [maxlength]="messageCharsAmount"
                        [tabindex]="4"
                        placeholder="Enter message"
                    ></textarea>
                </div>
                <div class="characters text-muted text-smaller">Symbols: {{getCharsCount()}}</div>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer" [class.curtain]="resolving">
        <div class="text-right" [ngSwitch]="statusCode">
            <button *ngSwitchCase="APPLICATION_STATUS.APPROVED" type="submit" class="btn btn-primary">Approve</button>
            <button *ngSwitchCase="APPLICATION_STATUS.CANCELLED" type="submit" class="btn btn-danger">Cancel Application</button>
            <button *ngSwitchCase="APPLICATION_STATUS.DECLINED" type="submit" class="btn btn-danger">Decline</button>
            <button *ngSwitchCase="APPLICATION_STATUS.DOC_REQUIRED" type="submit" class="btn btn-primary">Request</button>
            <button type="button" (click)="modalRef.hide()" class="btn btn-default">Exit</button>
        </div>
    </div>
</form>
