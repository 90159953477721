import { Component } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';
import { NgResourceArray } from 'commons/interfaces';

import { RolesResourceService } from '../roles-resource.service';
import { Role } from './role.interface';

@Component({
	selector: 'roles-list',
	templateUrl: './roles-list.component.html',
})
export class RolesListComponent extends ListComponent {
	static listName = RolesListComponent.addName('roles');
	listName = RolesListComponent.listName;

	roles: NgResourceArray<Role> = [];
	createdRole: any = null;

	constructor(
            public userService: UserService,
            public rolesResourceService: RolesResourceService,
            transitionService: TransitionService,
            stateService: StateService) {
		super(transitionService, stateService);

		this.createdRole = this.stateService.params.createdRole || {};
	}

    // Override
	async loadList(queryParams) {
		this.roles.$resolved = false;

        try {
            this.roles = await this.rolesResourceService.list(this.getClearParams(queryParams)).$promise;
            this.noMatches = (!this.roles.length && (this.filtersApplied || queryParams.q));
        } finally {
            this.roles.$resolved = true;
        }
	}

	hasManagePermission() {
		const userProfile = this.userService && this.userService.profile;

		if (!userProfile) {
			return false;
		}

		if (userProfile.isTpo) {
			return userProfile.can('TPO_ROLES_AND_PERMISSIONS');
		}

		if (userProfile.isLender || userProfile.isCustomer) {
			return userProfile.can('MANAGE_ROLES');
		}

		if (userProfile.isComergence) {
			return userProfile.can('CCM_MANAGE_CCM_USERS');
		}

		return false;
	}
}
