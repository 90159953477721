<div class="modal-header">
    <span class="modal-title">Contact Information</span>
    <button type="button" class="close" (click)="hide()">×</button>
</div>
<div class="modal-body">
    <div class="lender-contact-information remote-resource" [class.resolved]="lenderChannelContactInfo.$resolved">
        <div class="row-fluid" *ngIf="lenderChannelContactInfo.name">
            <div class="col-sm-3 control-label text-right">Contact Name:</div>
            <div>{{ lenderChannelContactInfo.name }}</div>
        </div>
        <div class="row-fluid" *ngIf="lenderChannelContactInfo.phone">
            <div class="col-sm-3 control-label text-right">Phone:</div>
            <div>{{ lenderChannelContactInfo.phone | tel }}</div>
        </div>
        <div class="row-fluid" *ngIf="lenderChannelContactInfo.email">
            <div class="col-sm-3 control-label text-right">Email:</div>
            <div>
                <a *ngIf="lenderChannelContactInfo.email" href="mailto:{{ lenderChannelContactInfo.email }}">{{ lenderChannelContactInfo.email }}</a>
            </div>
        </div>
        <div class="row-fluid" *ngIf="lenderChannelContactInfo.webSite">
            <div class="col-sm-3 control-label text-right">Web-site:</div>
            <div>
                <a target="_blank" *ngIf="lenderChannelContactInfo.webSite" href="{{ lenderChannelContactInfo.webSite | url }}">
                    {{ lenderChannelContactInfo.webSite }}
                </a>
            </div>
        </div>
        <p class="description">{{ lenderChannelContactInfo.description }}</p>
    </div>
</div>
