import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';

import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';
import { ShortHandEditComponent } from '../short-hand-edit.component';


@Component({
    selector: '[popover-edit]',
    templateUrl: 'popover-edit.component.html',
})
export class PopoverEditComponent<I = (number | string)> extends ShortHandEditComponent<I> {
    @Input() externalContainerElementId: string;
    @Input() relativePosition: AvailbleBSPositions = 'bottom';
    @Input() adaptivePosition: boolean = true;
    @Input() title?: string;
    @Input() inputType: ('text' | 'number' | 'textarea') = 'text';
    @Input() maxlength!: number;
    @Input() popoverContainerClasses: string = 'row';
    @Input() popoverContentClasses: string = 'col-sm-10';

    @ViewChild(PopoverDirective, { static: false }) popover: PopoverDirective;

    public popoverContainerId: string;
    public containerId: string;
    public useTextArea: boolean = false;

    constructor(
        private readonly hostElement: ElementRef,
        private readonly renderer: Renderer2,
    ) {
        super();

        this.renderer.addClass(this.hostElement.nativeElement, 'popover-edit-container');
    }

    // Override
    ngOnInit(): void {
        this.popoverContainerId = `${this.fieldName}_${this.uniqueBaseId.replace('-', '')}`;
        if (!this.externalContainerElementId) {
            this.containerId = this.popoverContainerId;
        } else {
            this.containerId = this.externalContainerElementId;
        }

        if (this.inputType === 'textarea') {
            this.useTextArea = true;
        }

        super.ngOnInit();
    }

    // Override
    protected handleStartEditingUI(): void {
        this.popover.show();
    }

    // Override
    protected handleCancelEditingUI(): void {
        this.popover.hide();
    }
}
