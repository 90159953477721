import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { Warehouse, WarehousesService } from 'shared/account/information/warehouse/warehouses.service';
import { UserService } from 'angularjs-providers/user.provider';
import { SectionReviewComponent } from 'tpo/account/section-review/section-review.component';
import { PROFILE_SECTION } from 'tpo/account/section-review/section-review.service';

@Component({
    selector: 'warehouse-section',
    templateUrl: 'warehouses.component.html',
})
export class WarehousesComponent implements OnInit {
    @Input() public lenderInfo: AccountInformation;
    @ViewChild(SectionReviewComponent, { static: true }) sectionReview: SectionReviewComponent;
    public readonly sectionType = PROFILE_SECTION.WAREHOUSE;
    resolved = false;
    list: Warehouse[];
    userCanAddEdit: boolean = false;

    constructor(
        private warehousesService: WarehousesService,
        private readonly userService: UserService,
    ) {
        this.userCanAddEdit = this.userService.profile.isTpo;
    }

    async ngOnInit(): Promise<void> {
        this.list = await this.warehousesService.getWarehouse(this.lenderInfo.identifier.tpoId).toPromise();
        this.resolved = true;
    }

    cancelNewItem(item: Warehouse): void {
        if(item.id) {
            return;
        }

        const itemIndex: number = this.list.indexOf(item);
        if (itemIndex != -1) {
            this.list.splice(itemIndex, 1);
        }
    }

    addItem() {
        this.list.push({
            approvedLineAmount: null,
            companyName: null,
            contactName: null,
            email: null,
            id: null,
            phone: null,
            relationshipEndDate: null,
            relationshipStartDate: null,
            sellLoans: null,
        });
    }
}
