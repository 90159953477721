// tslint:disable:origin-ordered-imports
import '@angular/compiler';
// import './polyfills';
import './globals';
import 'js-marker-clusterer';
import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowser } from '@angular/platform-browser';
import { UIRouter, UrlService } from '@uirouter/core';
import { UIRouterRx } from '@uirouter/rx';
// tslint:enable:origin-ordered-imports

import { RealmNewApp } from './realm.app';
import { intdate, jsonWithDate, jsonWithIntdate } from './custom-url-params';
import { RelativeParentFinder } from 'commons/services/parent-finder.plugin';
import { environment } from 'environments/environment';

declare let window: Window;
window.apiPath = '/api/rest';

if (environment.production) {
	enableProdMode();
}

platformBrowser().bootstrapModule(RealmNewApp).then((platformRef) => {
	// Intialize the Angular Module
	// get() the UIRouter instance from DI to initialize the router
	const urlService: UrlService = platformRef.injector.get(UrlService);
    const uiRouter: UIRouter = platformRef.injector.get(UIRouter);

	// Instruct UIRouter to listen to URL changes
	function startUIRouter(): void {
		urlService.config.type(intdate.name, intdate.config);
		urlService.config.type(jsonWithDate.name, jsonWithDate.config);
		urlService.config.type(jsonWithIntdate.name, jsonWithIntdate.config);
        uiRouter.plugin(UIRouterRx);
        uiRouter.plugin(RelativeParentFinder);
    }

	platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
});
