import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import commonDirectives from 'commons/directives';

@NgModule({
	imports: [
		BrowserModule,
		HttpClientModule,
	],
	declarations: [
		...commonDirectives,
	],
	exports: [
		...commonDirectives,
	],
	providers: [],
	entryComponents: [],
})

export class DirectivesModule {}
