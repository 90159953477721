<div class="TFA remote-resource" [class.resolved]="!loading">
	<div class="logout-btn">
		<button type="button" class="btn btn-tertiary" (click)="goBackToLogin()">
			{{loginParams.twoFAType === 'TOTP' ? 'Log Out' : 'Cancel'}}
		</button>
	</div>

	<div class="step-1" *ngIf="step === 1">

		<h1 class="text-center">Enable Two-Factor Authentication</h1>
		<p class="text-bigger">
			At Black Knight, cyber security is a high priority. This application now requires Two-Factor Authentication,
			and you may notice a small change to the login process.
		</p>

		<h3 class="font-bold">What Is Two-Factor Authentication?</h3>
		<p class="text-bigger">
			Two-Factor Authentication (also known as 2FA) is an additional layer of security for your account.
			Passwords can be compromised - especially if you use the same password for multiple sites.
			Adding Two-Factor Authentication means that even if your password gets stolen, your account will remain secure.
		</p>

		<h3 class="font-bold">How does it work?</h3>
		<p class="text-bigger mb30">
			Two-Factor Authentication uses your phone based on the authentication app you’ve chosen to provide an extra layer
			of security for your username. After you log in, you will be asked to enter the verification code found in your
			authenticator app.
		</p>

		<div class="text-center">
			<button type="button" class="btn btn-primary" (click)="goToQRCodeStep()">Get Started</button>
		</div>
	</div>

	<div class="step-2 row" *ngIf="step === 2 && secret && qrCodeImg">
		<div class="col-md-7 text-bigger">
			<p class="mb15">
				You will need to add your account to your chosen <br>
				authenticator app by either scanning the QR code or entering <br>
				the number below.
			</p>
			<div class="mb15">Scan QR Code:</div>
			<p><img [src]="qrCodeImg" alt="QR Code"></p>
			<span>Or enter the number below in your authenticator:</span>
			<h2 class="mb30">{{secret}}</h2>
			<button type="button" class="btn btn-primary" (click)="goToOTPStep()">Next</button>
		</div>
		<div class="col-md-offset-1 col-md-4">
			<div class="prompt text-muted">
				If you don't currently use a Two-Factor Authentication app, download one of the Two-Factor apps in the list provided.
				<ul>
					<li>Google Authenticator</li>
					<li>Lastpass</li>
					<li>Microsoft Authenticator</li>
					<li>Authy by Twilio</li>
					<li>2FA Authenticator</li>
					<li>Duo Mobile</li>
					<li>Aegis Authenticator</li>
				</ul>
			</div>
		</div>
	</div>

	<div class="step-3 flex-column center align-middle" *ngIf="step === 3">
		<h2>Enter the one-time {{otpLength}}-digit code</h2>
		<p class="text-big text-muted mb30" [ngSwitch]="loginParams.twoFAType">
			<ng-container *ngSwitchCase="'TOTP'">
				Open your authenticator app and enter the {{otpLength}}-digit code.
			</ng-container>
			<ng-container *ngSwitchCase="'EMAIL'">
				Please enter the one-time {{otpLength}}-digit passcode sent to the email <br>
				associated with this account. This code is only valid for {{validityTime | date:'m'}} minutes
			</ng-container>
		</p>

		<div class="flex-column align-middle text-center">
			<form [formGroup]="otpForm" (onValidSubmit)="submitOtp()">
				<form-errors [form]="otpForm"></form-errors>
				<div class="otp-input-wrapper">
					<div>
						<input
							#otpElement
							formControlName="otpControl"
							class="otp-input"
							type="text"
							[maxLength]="otpLength"
							(input)="onInput(otpElement)"
						/>
					</div>
				</div>
				<br>
				<input
					class="btn btn-primary"
					[disabled]="otpElement.value.length!==otpLength"
					type="submit"
					value="Log In"
				>
				<br><br>

				<ng-container *ngIf="errorCode === 'OTP_REQUIRED' && loginParams.twoFAType === 'EMAIL'">
					<input
						class="btn btn-secondary mb10"
						[disabled]="isResending || isNotificationSet"
						(click)="resendEmail()"
						type="button"
						value="Resend Code"
					>
					<p *ngIf="isNotificationSet" class="text-muted">Your verification code has been sent to your inbox.</p>
				</ng-container>
				<a
					*ngIf="errorCode === 'NOT_PAIRED'"
					class="btn btn-tertiary text-link"
					(click)="goBackToQRCodeStep()"
				>Go Back</a>
			</form>
		</div>
	</div>
</div>
