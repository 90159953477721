import { mapValues } from 'lodash';
import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { PagedListComponent } from 'commons/components/list';
import { ServerErrorResponse } from 'commons/forms/form-group.hoc';
import { NgPagedResource } from 'commons/interfaces';
import { ListComponent } from 'commons/components/list/list.component';

import { Question } from 'lender/questionnaires/manage/questionnaires.interface';
import { LenderManageQuestionnaireService } from 'lender/questionnaires/manage/questionnaires.service';

import { RemoveBankQuestionModalComponent } from 'lender/questionnaires/bank/modals/remove-question-modal.component';
import { BankQuestionUsageModalComponent } from 'lender/questionnaires/bank/modals/usage-question-modal.component';

@Component({
	templateUrl: './bank.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => QuestionBankComponent) },
	],
})
export class QuestionBankComponent extends PagedListComponent {
	static listName = QuestionBankComponent.addName('question-bank-list');
	listName = QuestionBankComponent.listName;

	editId: Question['id'] = null;
	isCreateFormOpened = false;
	questionIsSaving = false;
	updateError: ServerErrorResponse;

	modalRef: BsModalRef;

	tabs = [
		{
			state: 'questionnaires',
			title: 'Questionnaires',
		},
		{
			state: 'question-bank',
			title: 'Question Bank',
		},
	];

	User: UserProfile;
	list: NgPagedResource<Question> = [];

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		protected questionnairesService: LenderManageQuestionnaireService,
		public modalService: BsModalService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;

		this.filters = {
			categoryId: this.questionnairesService.bankCategories(),
		};

		this.defaultFilters = {
			categoryId: null,
			...this.defaultFilters,
		};
	}

	loadList(queryParams) {
		this.list.$resolved = false;

		if (!queryParams.categoryId) {
			this.list = [];
			return;
		}

		return this.questionnairesService.bankQuestions(queryParams)
			.$promise
			.then((data: NgPagedResource<Question>): void => {
				this.list = data;
				this.noMatches = !data.length && this.filtersApplied;
			});
	}

	setFilter(filters, resetPage: boolean = true): void {
		const pageParams = resetPage ? { page: 0 } : {};

		mapValues(filters, (value: any, key: string) => {
			if (key === 'categoryId') {
				filters[key] = value?.id;
			}
		});

		this.params$.next({ ...this.params$.getValue(), ...pageParams, ...filters });
	}

	toggleEdit = (item: Question, edit?: boolean): void => {
		if (edit === undefined) {
			edit = this.editId !== item.id;
		}

		this.editId = edit ? item.id : null;
		this.updateError = undefined;
	};

	update = async (item: Question, update: Partial<Question>): Promise<void> => {
		if (this.questionIsSaving) {
			return;
		}

		this.questionIsSaving = true;
		this.list.$resolved = false;

		try {
			await this.questionnairesService.updateBankQuestion({
				categoryId: this.params.categoryId,
			}, {
				...item,
				...update,
			}).$promise;

			this.updateError = undefined;
			this.loadList(this.params);
			this.editId = null;
		} catch (error) {
			this.updateError = error.data;
			this.list.$resolved = true;
		} finally {
			this.questionIsSaving = false;
		}
	};

	create = async (item: Partial<Question>, update: Partial<Question>): Promise<void> => {
		if (this.questionIsSaving) {
			return;
		}

		this.questionIsSaving = true;
		this.list.$resolved = false;

		try {
			await this.questionnairesService.addBankQuestion({
				categoryId: this.params.categoryId,
			}, {
				...item,
				...update,
			}).$promise;

			this.loadList(this.params);
			this.isCreateFormOpened = false;
		} catch (error) {
			this.list.$resolved = true;
		} finally {
			this.questionIsSaving = false;
		}
	};

	remove = (item: Question): void => {
		const initialState = {
			questionId: item.id,
			questionName: item.text,
			onCancel: () => {
				this.modalRef.hide();
			},
			onConfirm: () => {
				this.loadList(this.params);
			},
		};

		this.modalRef = this.modalService.show(RemoveBankQuestionModalComponent, {
			initialState,
			class: 'modal-md',
			backdrop: 'static',
		});
	}

	showUsage = (item: Question): void => {
		const initialState = {
			questionId: item.id,
			onCancel: () => {
				this.modalRef.hide();
			},
		};

		this.modalRef = this.modalService.show(BankQuestionUsageModalComponent, {
			initialState,
			class: 'modal-md',
			backdrop: 'static',
		});
	}

	getNewQuestion = (): Partial<Question> => {
		return {
			categoryId: this.params.categoryId,
		}
	}
}
