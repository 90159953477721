<div class="company-page">
    <div class="row-fluid">
        <div class="col-sm-3">
            <div class="logo">
                <img src="{{ '/api/rest/lenders/' + stateService.params.id + '/logo' }}">
            </div>
        </div>
        <div class="col-sm-9">
            <div class="form-group">
                <h3>{{companyPage.displayName}}</h3>
            </div>
            <div class="form-group no-margin">
                <span>{{companyPage.description}}</span>
            </div>
        </div>
    </div>
    <div class="row-fluid prop-blocks">
        <div class="col-sm-4 prop-block" *ngIf="companyPage.contactName">
            <div class="text-block">
                <h5>{{companyPage.contactName}}</h5>
                <label class="control-label">Contact name</label>
                <span class="glyphicon glyphicon-user text-light"></span>
            </div>
        </div>
        <div class="col-sm-4 prop-block form-group" *ngIf="companyPage.webSite">
            <div class="text-block">
                <h5 class="text-bigger"><a href="{{companyPage.webSite | url}}" target="_blank">Open Website</a>
                </h5>
                <label class="control-label">{{(companyPage.webSite | url)}}</label>
                <span class="glyphicon glyphicon-globe text-light"></span>
            </div>
        </div>
        <div class="col-sm-4 prop-block form-group" *ngIf="companyPage.phone">
            <div class="text-block">
                <h5>{{companyPage.phone || '' | tel}}</h5>
                <label class="control-label">Phone</label>
                <span class="glyphicon glyphicon-earphone text-light"></span>
            </div>
        </div>
    </div>
</div>
