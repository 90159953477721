import { Injectable } from '@angular/core';

import { UserProfile } from 'angularjs-providers/user.provider';


export type CreateUserConfig = {
	userCanCreateUsers: boolean;
	createUserSref?: string;
	createUserSrefParams?: any;
	createUserSrefUIOptions?: any;
};

export type UserManagementPrivilegeTypes = (
	'MANAGE_USERS' |
	'UNLOCK_USERS' |
	'VIEW_ROLES' |
	'EDIT_USERS'
);

/**
 * Playing with the idea of keeping the srefs in a type of configuration (even though they are setup outside of Angular DI).
 */
@Injectable()
export class UserManagementCapabilitiesService {
	static readonly ALL_USERS_AUTOACTIONS: string[] = [ 'view', 'edit' ];
	static readonly DESKTOP_SUPPORT_USERS_ACTIONS: string[] = [ 'view' ];

    constructor(
    ) {
    }

    // Static Config (TODO: Make it an actual config file)

	get userManagementRootSref(): string {
		return 'users-management';
	}

	get userManagementUsersSref(): string {
		return `${this.userManagementRootSref}.users`;
	}

	get userManagementActionSref(): string {
		return `${this.userManagementUsersSref}.:actionType`;
	}

    get userManagementItemSref(): string {
        return `${this.userManagementUsersSref}.:actionType.:id`;
    }

	get desktopSupportRootSref(): string {
		return `${this.userManagementRootSref}.desktop-support`;
	}

	get desktopSupportNewSref(): string {
		return `${this.desktopSupportRootSref}.new`;
	}

    // Rules

    userCanManageCCMUsers(userProfile: UserProfile): boolean {
        // Explicit guard in case more rules are added later
        if (!userProfile) {
            return false;
        }

        const canManageCCMUsers: boolean = userProfile.can('CCM_MANAGE_CCM_USERS');

        return canManageCCMUsers;
    }

    userIsDesktopSupport(userProfile: UserProfile): boolean {
        // Explicit guard in case more rules are added later
        if (!userProfile) {
            return false;
        }

        const isDesktopSupport: boolean = userProfile.can('CCM_DESKTOP_SUPPORT');

        return isDesktopSupport;
    }

	userCan(userProfile: UserProfile, permission: (UserManagementPrivilegeTypes | string)): boolean {
        // Explicit guard in case more rules are added later
        if (!userProfile) {
            return false;
        }

		const isComergence = userProfile.isComergence;
		const isCustomer = userProfile.isCustomer;

		const userCanManageCCMUsers: boolean = userProfile.can('CCM_MANAGE_CCM_USERS');
		const userCanManageUsers: boolean = userProfile.can('MANAGE_USERS');
		const userIsDesktopSupportUser: boolean = userProfile.can('CCM_DESKTOP_SUPPORT');

		switch (permission) {
			case 'MANAGE_USERS':
				return (
					(isComergence && userCanManageCCMUsers) ||
					(isComergence && userIsDesktopSupportUser) ||
					(isCustomer && userCanManageUsers)
				);
			case 'UNLOCK_USERS':
				return (
					isComergence ||
					(isCustomer && userCanManageUsers)
				);
			case 'VIEW_ROLES':
				return (
					isCustomer &&
					userProfile.can('VIEW_ROLES')
				);
            case 'EDIT_USERS':
                return (
					(isComergence && userCanManageCCMUsers) ||
					(isCustomer && userCanManageUsers)
				);
			default:
				return false;
		}
	}

    // Actions

	/**
	 * @param userProfile Assumed to be defined and valid.
	 * @returns 
	 */
    determineCreateUserConfig(userProfile: UserProfile): CreateUserConfig {
		const canManageCCMUsers: boolean = this.userCanManageCCMUsers(userProfile);
		const isDesktopSupport: boolean = this.userIsDesktopSupport(userProfile);
		
		const userCanCreateUsers: boolean = (canManageCCMUsers || isDesktopSupport);

        const createUserConfig: CreateUserConfig = {
            userCanCreateUsers,
            createUserSrefUIOptions: { inherit: false, reload: true },
        };

		if (canManageCCMUsers) {
			createUserConfig.createUserSref = this.userManagementItemSref;
			createUserConfig.createUserSrefParams = { actionType: 'new', id: null };
		} else if (isDesktopSupport) {
			createUserConfig.createUserSref = this.desktopSupportNewSref;
			createUserConfig.createUserSrefParams = {};
		}

        return createUserConfig;
    }
}
