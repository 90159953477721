import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { ChannelApplicationExpirationSettingsComponent } from './application-expiration';

const components = [
	ChannelApplicationExpirationSettingsComponent,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ComponentsModule,
		DirectivesModule,
		PipesModule,
	],
	declarations: [
		...components,
	],
	entryComponents: [
		...components,
	],
})
export class ChannelSettingsModule {}
