import { Injector, Injectable } from '@angular/core';

declare let apiPath: string;

@Injectable()
export class PasswordPolicyService {
	resource: any;

	constructor(public injector: Injector) {
		const $resource = injector.get('$injector').get('$resource');
		const path = `${apiPath}/password-policy`;

		this.resource = $resource(path, {}, {
			get: {method: 'get'},
			update: {method: 'put'},
		});
	}

	get = (...args) => this.resource.get(...args);
	update = (...args) => this.resource.update(...args);
}
