import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { SharedDocumentsModule } from 'shared/documents';

import { TpoDocumentsService } from './documents.service';
import { TpoDocumentsComponent } from './documents.component';
import {
	TpoDocumentListComponent,
	TpoDocumentListItemComponent,
	TpoDocumentUploadFromLibraryModalComponent,
} from './list';
import {
	TpoDocumentComponent,
	TpoDocumentHistoryComponent,
} from './document';
import {
	TpoLibraryDocumentListComponent,
	TpoLibraryDocumentListItemComponent,
	TpoLibraryDocumentRequestsModalComponent,
	TpoLibraryDocumentComponent,
	TpoLibraryDocumentHistoryComponent,
} from './library';

const components = [
	TpoDocumentsComponent,
	TpoDocumentListComponent,
	TpoDocumentComponent,
	TpoDocumentUploadFromLibraryModalComponent,
	TpoDocumentHistoryComponent,
	TpoLibraryDocumentListComponent,
	TpoLibraryDocumentComponent,
	TpoLibraryDocumentRequestsModalComponent,
	TpoLibraryDocumentHistoryComponent,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DirectivesModule,
		ComponentsModule,
		SharedDocumentsModule,
	],
	declarations: [
		...components,
		TpoDocumentListItemComponent,
		TpoLibraryDocumentListItemComponent,
	],
	entryComponents: [
		...components,
	],
	providers: [
		TpoDocumentsService,
	],
	exports: [
		TpoDocumentListItemComponent,
		TpoLibraryDocumentListComponent,
	],
})
export class TpoDocumentsModule {}
