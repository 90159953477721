import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { PostsResourceService } from 'tpo/social-compliance/publisher/posts/posts-resource.service';
import { CampaignsResourceService } from 'tpo/social-compliance/publisher/campaigns';
import { AttachmentsModule } from 'tpo/social-compliance/publisher/attachments';

import { PublisherPostPreviewModule } from '../posts-preview/post-preview.module';
import { PostViewComponent } from './post-view.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivesModule,
		ComponentsModule,
		AttachmentsModule,
		PipesModule,
		PublisherPostPreviewModule,
	],
	declarations: [
		PostViewComponent,
	],
	entryComponents: [
		PostViewComponent,
	],
	exports: [
		PostViewComponent,
	],
	providers: [
		PostsResourceService,
		CampaignsResourceService,
	],
})
export class PublisherPostViewModule {}
