import { map, toPairs, isUndefined } from 'lodash';
import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserService as UService } from 'angularjs-providers/user.provider';
import { StaticValuesService } from 'angularjs-providers/static-values.provider';
import { StateService as $stateProvider } from 'angularjs-providers/state.provider';

import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';
import { NotificationModalComponent } from 'commons/components/modals/notification-modal.component';

import { RolesResourceService } from 'shared/roles/roles-resource.service';
import { SharedUserManagementResourceService } from 'shared/user-management/user-management-resource.service';

import { LenderUserDetailsForm } from './details.form';
import { LenderUserStatuses } from './user-statuses';
import { LenderUsersResourceService } from 'lender/user-management/users-resource.service';
import { TableauReportingGroup } from 'shared/new-contacts/contacts.interface';
import { NgResourceArray } from 'commons/interfaces';
import { UserManagementCapabilitiesService } from 'commons/services/user-capabilities/user-management-capabilities.service';

@Component({
	templateUrl: './details.component.html',
	selector: 'lender-user-details',
})
export class LenderUserDetailsComponent {
	@Input()
	user: any = {};

	@Input()
	isNew: boolean = false;

	@Input()
	editable: boolean = false;

	@Input()
	settingsView: boolean = false;

	@Input()
	linkToRole: boolean = false;

	roles: any = [];
	states: any = [];
    reportingGroups!: TableauReportingGroup[];

	managers: NgResourceArray<any> = [];
	managersMap: any = {};

	channels: any = [];
	channelsData: any = [];

	usernameResent: boolean = false;
	passwordResent: boolean = false;
	doingaction: boolean = false;
	pageNotification: any = null;
	initialData: any = {};

	userStatus = LenderUserStatuses;
	userForm = LenderUserDetailsForm;

	modalRef: BsModalRef;

	constructor(
		public UserService: UService,
		public RolesResource: RolesResourceService,
		public StaticValues: StaticValuesService,
		public LenderUsersService: LenderUsersResourceService,
		public $state: $stateProvider,
		public modalService: BsModalService,
		public UserManagement: SharedUserManagementResourceService,
		private readonly userManagementCapabilitiesService: UserManagementCapabilitiesService,
	) {}

	ngOnInit() {
		this.initCommon();

		if (this.user.$promise) {
			this.user.$promise.then(() => {
				if (this.UserService.profile.isLender) {
					this.filterOutUser();
				}

				this.setFullVisibility();

				this.userForm.patchValue(this.user);
				this.initialData = {...this.user};
			});
		}

		if (this.UserService.profile.isLender) {
			this.loadManagers();
			this.loadChannels();
		}
	}

	async initCommon() {
        this.resetForm();
		this.StaticValues.get({ code: 'State' }, (data) => {
			this.states = data;
		});
        this.loadRoles();
        this.reportingGroups = await this.LenderUsersService.getReportingGroups().toPromise();
	}

	resetForm() {
		this.userForm.markAsPristine();
		this.userForm.reset();
		this.userForm.serverError = '';
		const userNameMethod = (this.settingsView || this.isNew) ? 'disable' : 'enable';
		this.userForm.get('userName')[userNameMethod]();
	}

	loadRoles() {
		this.roles = this.RolesResource.listNonCounted();
	}

	getLoadManagersParams() {
		return {};
	}

	loadChannels() {
		this.channels = this.LenderUsersService.getChannels({ activeOnly: true }, (data) => {
			this.channelsData = data.map((channel) => (channel));
		});
	}

	loadManagers() {
		this.managersMap = {};

		this.managers = this.LenderUsersService.getReportingManagers(this.getLoadManagersParams(), () => {
			this.managers.unshift({ id: null, personalName: 'None' });
			this.managers.forEach((manager) => {
				this.managersMap[`${manager.id}`] = manager.personalName;
			});

			this.filterOutUser();
		});
	}

	filterOutUser = () => {
		if (this.user?.id && this.UserService.profile.isLender && this.managers.$resolved) {
            this.managers = this.managers.filter((manager) => {
                return manager.id !== this.user.id;
            });
			// gotcha: after filtering it is not a resource, so add $resolved again
			this.managers.$resolved = true;
		}

		if (this.user && this.user.state === '--') {
			delete this.user.state;
		}
	}

	getRoleById(id) {
		if (!id) {
			return '-';
		}

		return this.roles.reduce((memo, role) => {
			return (role.id === id) ? role.name : memo;
		}, '-');
	}

	toggleAssignedChannels(id) {
		if (!this.editable) {
			return;
		}

		const idx = this.user.channelsAssignedIds.indexOf(id);

		if (idx > -1) {
			// is currently selected
			this.user.channelsAssignedIds.splice(idx, 1);

			if (!this.user.channelsAssignedIds.length) {
				this.userForm.serverError = 'User should be assigned to at least one channel';
			}

			return;
		}

		// is newly selected
		this.user.channelsAssignedIds.push(id);
		this.userForm.serverError = '';
	}

	toggleReportingManager() {
		if (!this.editable) {
			return;
		}

		const field = this.userForm.get('isReportingManager');
		field.setValue(!field.value);
		this.setFullVisibility();
	}

	toggleAccountAssignment() {
		if (!this.editable) {
			return;
		}

		const field = this.userForm.get('isAccountAssigned');
		field.setValue(!field.value);
		this.setFullVisibility();
	}

	isFullVisibilityAllowedDisabled() {
		// in non-edit mode return model values
		if (!this.editable) {
			return !this.UserService.profile.crmEnabled || (!this.user.isAccountAssigned && !this.user.isReportingManager);
		}

		// in edit mode check form values to enable/disable isFullVisibilityAllowed field
		const disabled = !this.UserService.profile.crmEnabled || (
			!this.userForm.get('isAccountAssigned').value && !this.userForm.get('isReportingManager').value
		);

		const field = this.userForm.get('isFullVisibilityAllowed');
		const method = disabled ? 'disable' : 'enable';
		// trigger method only if value is changed
		(field.disabled !== disabled) && field[method]();

		return disabled;
	}

	toggleFullVisibilityAllowed() {
		if (!this.editable || this.isFullVisibilityAllowedDisabled()) {
			return;
		}

		const field = this.userForm.get('isFullVisibilityAllowed');
		field.setValue(!field.value);
	}

	setFullVisibility = () => {
		if (this.UserService.profile.isComergence) {
			return;
		}

		const shouldPreserveValue = this.userForm.get('isReportingManager').value
			|| this.userForm.get('isAccountAssigned').value
			|| !this.UserService.profile.crmEnabled;

		if (shouldPreserveValue) {
			return;
		}

		const field = this.userForm.get('isFullVisibilityAllowed');
		field.setValue(false);
	}

	isAssignedChannelsValid() {
		if (!this.UserService.profile.isLender) {
			return true;
		}

		return !!this.user.channelsAssignedIds.length;
	}

	async save() {
		const data = { ...this.userForm.value };

		// this is hack since disabled form value does not get value
		if (!this.UserService.profile.isComergence && isUndefined(data.isFullVisibilityAllowed)) {
			data.isFullVisibilityAllowed = false;
		}

		if (!this.UserService.profile.isComergence && !this.isAssignedChannelsValid()) {
			this.userForm.serverError = 'User should be assigned to at least one channel';
			return;
		}

		map(toPairs(data), ([fieldName, fieldValue]) => (this.user[fieldName] = fieldValue));

		if (this.isNew) {
            try {
                this.user.$resolved = false;
                this.user = await this.LenderUsersService.user.save(this.user).$promise;
                this.saveSuccessHandler(this.user);
            } catch (e) {
                this.saveErrorHandler(e);
            }
			return this.user;
		}

        if (!this.UserService.profile.isLender) {
            const { id } = this.user;
            try {
                this.user.$resolved = false;
                this.user = await this.LenderUsersService.user.update({ userId: id },this.user).$promise;
                this.$state.go('users-management.users.:actionType.:id', { id, actionType: 'view' });
            } catch (e) {
                this.saveErrorHandler(e);
            }
            return this.user;
        }

		const assignmentVerification = (): void => {
            this.user.$resolved = false;
            this.LenderUsersService.user.assignmentVerification(
                { userId: this.user.id },
                this.user,
                response => {
                    this.user = response;
                    return this.saveSuccessHandler(response);
                },
                this.saveErrorHandler
            ).$promise.finally(() => {
                this.user.$resolved = true;
            });
		}

		if (this.user.reportingGroups?.length && !this.initialData.reportingGroups?.length) {
			this.showReportsActivationModal(assignmentVerification);
			return;
		}

		assignmentVerification();
	}

	assignmentVerification = () => {
		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState: {
				title: 'Setting Change Confirmation',
				message: 'The user is currently assigned to branch locations. ' +
						'Changing this setting, will remove this user from all locations. ' +
						'Are you sure you would like to make this change?',
				onConfirm: () => {
					this.modalRef.content.resolving = true;
                    this.LenderUsersService.user.update(
                        { userId: this.user.id },
                        this.user,
						() => {
							this.UserService.getUser();

							this.$state.go('users-management.users.:actionType.:id', {
								id: this.user.id,
								actionType: 'view',
							});
						},
						(response) => {
							this.modalRef.content.resolving = false;
							return this.saveErrorHandler(response.data);
						},
					);
				},
			},
		});
	}

	saveSuccessHandler = (response) => {

		if (this.isNew) {
			this.UserService.getUser();
			const href = this.$state.href('users-management.users.:actionType.:id', { id: this.user.id, actionType: 'view' });
			const link = `<a class="underlined text-success" href="${href}">View User</a>`;

			this.$state.notify('users-management.users', {
				notification: {
					type: 'alert-success',
					message: `User <b>${this.user.firstName} ${this.user.lastName}</b> was created successfully. ${link}`,
					timeout: 4000,
				},
			});
			return;
		}

		if (response.isLocationAssigned) {
			return this.assignmentVerification();
		}

        this.LenderUsersService.user.update(
            { userId: this.user.id },
            this.user,
			() => {
				this.UserService.getUser();

				this.$state.go('users-management.users.:actionType.:id', {
					id: this.user.id,
					actionType: 'view',
				});
			},
			this.saveErrorHandler,
		);
	}

	saveErrorHandler = ({ data: response }) => {
        this.user.$resolved = true;
		this.userForm.setServerError(response);
	}

	sendUsername() {
		this.UserManagement.resendUsername({ userId: this.user.id });
		this.usernameResent = true;
	}

	resetPassword() {
		this.UserManagement.resendPassword({ userId: this.user.id });
		this.passwordResent = true;
	}

	unlockUser() {
		this.doingaction = false;
		this.UserManagement.unlock({ userId: this.user.id }).$promise
			.then(this.refreshUser, this.unlockErrorHandler);
	}

    refreshUser = async () => {
        this.doingaction = false;
        try {
            this.user = this.LenderUsersService.user.get({ userId: this.user.id }, this.filterOutUser)

        } catch (e) {
        }
    };

	deactivate() {
		this.doingaction = true;
		this.UserManagement.deactivate({ userId: this.user.id, ignoreValidation: true }).$promise
			.then(this.refreshUser);
	}

	commonErrorHandler = ({ response, title }) => {
		let notification = null;
		try {
			notification = response?.data?.message?.replace(/\n/g, '<br/>');
		} catch (e) {
			notification = response?.data?.message;
		}

		this.doingaction = false;

		this.modalRef = this.modalService.show(NotificationModalComponent, {
			initialState: { title, notification },
		});
	}

	unlockErrorHandler = (response) => {
		this.commonErrorHandler({ response, title: 'Can\'t Unlock' });
	}

	activate() {
		this.pageNotification = null;

		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState: {
				title: 'Send Email',
				message: 'Do you want to Resend Username and Password to this User?',
				confirmText: 'Yes',
				cancelText: 'No',
				onConfirm: () => {
					this.modalRef.content.resolving = true;
					this.activateHandler(true);
				},
				onCancel: () => {
					this.modalRef.content.resolving = true;
					this.activateHandler(false);
				},
			},
		});
	}

	activateHandler = (notify) => {
		const finalHandler = () => {
			this.modalRef.content.resolving = false;
			this.modalRef.hide();
		};

		this.UserManagement.activate({ userId: this.user.id, notifyUser: notify },
			() => {
				this.refreshUser();
				finalHandler();
			},
			(error) => {
				this.pageNotification = {
					type: 'alert-danger',
					message: error.data.message,
				};
				finalHandler();
			},
		);
	}

	hasError(fieldName) {
		return this.editable && this.userForm.get(fieldName).invalid && this.userForm.get(fieldName).touched;
	}

	getLabel(fieldName) {
		const field = this.userForm.get(fieldName);
		return field && (field as any).label;
	}

	hasPermission(permission) {
		if (!this.UserService || !this.UserService.profile) {
			return false;
		}

		const userCan: boolean = this.userManagementCapabilitiesService.userCan(this.UserService.profile, permission);

		return userCan;
	}

	showReportsActivationModal(saveContact: Function): void {
		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState: {
				title: 'Acknowledgement',
				message: 'The user acknowledges, understands, and agrees by turning reports “on”, ' +
					'the user’s financial institution will be charged $25.00 per month for this feature.',
				confirmText: 'I Agree',
				cancelText: 'Cancel',
				onConfirm: () => {
					this.modalRef.hide();
					saveContact();
				},
				onCancel: () => {
					this.modalRef.hide();
				},
			},
            class: 'modal-new',
		});
	}
}
