<div class="documents remote-resource" [class.resolved]="list.$resolved">
	<ng-template #applicationNotification>
		<ng-container [ngSwitch]="application.submitted">
			<ng-container *ngSwitchCase="true">
				<div class="alert alert-success text-center text-bigger">
					<ng-container [ngSwitch]="!!list.length">
						<ng-container *ngSwitchCase="true">
							<span class="glyphicon glyphicon-ok"></span>
							Your Application has been sent to Lender. Upload required documents to complete application process.
						</ng-container>
						<ng-container *ngSwitchCase="false">
							<span class="glyphicon glyphicon-ok"></span>
							Your application has been sent to Lender. Documents are not required for this application. Click the Finish button.
						</ng-container>
					</ng-container>
				</div>
			</ng-container>
			<ng-container *ngSwitchCase="false">
				<div class="alert well text-center text-bigger">
					<ng-container [ngSwitch]="!!list.length">
						<ng-container *ngSwitchCase="true">
							Your application has not been submitted. You may upload required documents and click the back button at any time to return to the application form and submit your application.
						</ng-container>
						<ng-container *ngSwitchCase="false">
							Your application has not been submitted. Documents are not required for this application. Click the Back button to return to the application form and submit your application.
						</ng-container>
					</ng-container>
				</div>
			</ng-container>
		</ng-container>
	</ng-template>

	<div class="rows-bordered">
		<div class="row-fluid no-border" *ngFor="let item of list">
			<tpo-document-list-item
				[document]="item"
			></tpo-document-list-item>
		</div>
	</div>

	<ng-container *ngIf="list.$resolved">
		<div class="text-right btn-group-lg bottom-toolbar">
			<button type="button"  class="btn btn-default" (click)="back()">Back</button>
			<button type="button"  class="btn btn-primary" (click)="finish()">Finish</button>
		</div>
	</ng-container>
</div>
