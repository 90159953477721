import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';

// Services
import { PublisherAttachmentsPreviewService } from './publisher-attachments-preview/publisher-attachments-previews.service';

// Components
import { PostsPreviewComponent } from './posts-preview.component';
import { PostComponent } from './post.component';
import { FacebookPostComponent } from './facebook-post/facebook-post.component';
import { InstagramPostComponent } from './instagram-post/instagram-post.component';
import { LinkedInPostComponent } from './linkedin-post/linkedin-post.component';
import { TwitterPostComponent } from './twitter-post/twitter-post.component';
import { PublisherAttachmentsPreviewComponent } from './publisher-attachments-preview/publisher-attachments-previews.component';
import { GmbPostComponent } from './gmb-post/gmb-post.component';

const components = [
	PostsPreviewComponent,
	PostComponent,
	FacebookPostComponent,
	InstagramPostComponent,
	LinkedInPostComponent,
	TwitterPostComponent,
	GmbPostComponent,
	PublisherAttachmentsPreviewComponent,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivesModule,
		ComponentsModule,
		PipesModule,
	],
	declarations: [
		...components,
	],
	entryComponents: [
		...components,
	],
	exports: [
		...components,
	],
	providers: [PublisherAttachmentsPreviewService],
})
export class PublisherPostPreviewModule {}
