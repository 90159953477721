<div class="rows-bordered remote-resource" [class.resolved]="list.$resolved">
	<div class="row-fluid header">
		<div class="col-sm-3"><b>Listing</b></div>
		<div class="col-sm-a"><b>Field</b></div>
		<div class="col-sm-a"><b>Alert Value</b></div>
		<div class="col-sm-a"><b>Confirmed Value</b></div>
		<div class="col-sm-a"><b>Confirmed By</b></div>
		<div class="col-sm-a"><b>Confirmed Date</b></div>
	</div>

	<div class="row-fluid" *ngFor="let item of list">
		<div class="col-sm-3 va-m">
			<div class="flex-row gap">
				<sm-network-icon
					*ngIf="item.listingNetworkCode"
					[config]="{ networkCode: item.listingNetworkCode }"
					[minified]="true"
				></sm-network-icon>&nbsp;
				<a
					uiSref="listings.company.:listingGroupId.:listingDetailsId"
					[uiParams]="{ listingGroupId: item.listingGroupId, listingDetailsId: item.listingId }"
					class="flex"
				>{{item.listingName}}</a>
			</div>
		</div>
		<div class="col-sm-a va-m">{{item.alertField || '&ndash;'}}</div>
		<div class="col-sm-a va-m">{{item.alertValue || '&ndash;'}}</div>
		<div class="col-sm-a va-m">{{item.confirmedValue || '&ndash;'}}</div>
		<div class="col-sm-a va-m">{{item.confirmedBy}}</div>
		<div class="col-sm-a va-m">{{item.confirmedDate | date:'MM/dd/yyyy'}}</div>
	</div>

	<div class="row-fluid" *ngIf="list.$resolved && !list.length">
		<div class="col-sm-12 text-center va-m text-bigger text-muted">No alerts</div>
	</div>
</div>

<br/>

<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
