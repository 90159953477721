<div class="password-requirements">
	<h5>Password requirements:</h5>
	<ul class="list-unstyled">
		<li *ngIf="config.minPasswordLength > 0">
			<span class="{{ getClass('.{' + config.minPasswordLength + '}') }}"></span>
			<span class="small-left-padding">Minimum {{ config.minPasswordLength }} characters</span>
		</li>
		<li *ngIf="config.specialCharRequired">
			<span class="{{ getClass('[^a-zA-Z0-9 ]') }}"></span>
			<span class="small-left-padding">Special character required</span>
		</li>
		<li *ngIf="config.numberRequired">
			<span class="{{ getClass('[0-9]') }}"></span>
			<span class="small-left-padding">Number required</span>
		</li>
		<li *ngIf="config.upperCaseRequired">
			<span class="{{ getClass('[A-Z]') }}"></span>
			<span class="small-left-padding">Upper case required</span>
		</li>
		<li *ngIf="config.lowerCaseRequired">
			<span class="{{ getClass('[a-z]') }}"></span>
			<span class="small-left-padding">Lower case required</span>
		</li>
	</ul>
</div>
