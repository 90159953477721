<div *ngIf="form" class="form-group has-feedback" [formGroup]="form">
    <label class="col-sm-5 control-label required">
        <span *ngIf="displayEnter" class="enter-word"></span>
        {{ inputLabel }}
    </label>
    <div class="col-sm-6">
        <input [type]="fieldType" [formControlName]="controlName" [maxlength]="maxlength" [tabindex]="tabindex" class="form-control">
        <span class="c-icon c-icon-eye form-control-feedback" (click)="toggleType()"></span>
    </div>
</div>
