import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { OrganizationType } from 'angularjs-providers/user.provider';
import { RealmStateDeclaration } from 'routes/sharedRoutes';
import DEFAULT_ROUTES from 'routes/defaultRoutes';
import TPO_ROUTES from 'routes/tpoRoutes';
import TPO_LIMITED_ROUTES from 'routes/tpoLimitedRoutes';
import LENDER_ROUTES from 'routes/lenderRoutes';
import COMERGENCE_ROUTES from 'routes/comergenceRoutes';
import { findClosestParent } from 'commons/services/parent-finder.plugin';
import { InvalidTokenComponent } from 'login/invalid-token/invalid-token.component';

const INVALID_TOKEN = [{
    name: 'public',
    url: `^/public/{accessToken:string}`,
    component: InvalidTokenComponent,
    class: 'center-vertically',
    free: true,
}];

@Injectable({
    providedIn: 'root',
})
export class RouteManagerService {
    constructor(
        private readonly router: UIRouter,
    ) {
    }

    loadStates(role: OrganizationType | 'INVALID_TOKEN' = null) {
        switch (role) {
            case 'COMERGENCE':
                return this.addRoutes(COMERGENCE_ROUTES as RealmStateDeclaration[], role);
            case 'CUSTOMER':
                return this.addRoutes(LENDER_ROUTES as RealmStateDeclaration[], role);
            case 'TPO':
                return this.addRoutes(TPO_ROUTES as RealmStateDeclaration[], role);
            case 'TPO_LIMITED':
                return this.addRoutes([...DEFAULT_ROUTES, ...TPO_LIMITED_ROUTES] as RealmStateDeclaration[], role);
            case 'INVALID_TOKEN':
                return this.addRoutes(INVALID_TOKEN as RealmStateDeclaration[], role);
            case null:
                return this.addRoutes(DEFAULT_ROUTES, role);
            default:
                console.warn(`[Router] Unknown role ${role || 'DEFAULT'}`);
                break;
        }
    }

    private addRoutes(routes: RealmStateDeclaration[], role: OrganizationType | 'INVALID_TOKEN' = null) {
        this.router.urlService.listen(false);
        this.enhanceRoutes(routes).forEach(state => {
            if (state.controller || state.templateUrl) {
                console.error(`Legacy route declaration for "${state.name}"`);
            }
            this.router.stateRegistry.register(state);
        });
        if (!role || role == 'TPO_LIMITED') {
            this.router.urlService.rules.otherwise(() => {
                this.router.locationService.url;
                const url = this.router.locationService.url();
                return `/login${(url ? '?back=' + encodeURIComponent(url) : '')}`;
            });
        } else {
            this.router.urlService.rules.otherwise('/home');
            const { back } = this.router.globals.params;
            if (back) {
                this.router.locationService.url(back);
            }
        }
        this.router.urlService.listen(true);
        this.router.urlService.sync();
    }

    removeDefaultRoutes() {
        this.enhanceRoutes(DEFAULT_ROUTES).forEach(state => {
            try {
                this.router.stateRegistry.deregister(state.name);
            } catch(e) {
                // ignore deregister errors;
            }
        });
    }

    private flattenRoutes(children: RealmStateDeclaration[], parent: RealmStateDeclaration = null) {
        return children.reduce(
            (result: RealmStateDeclaration[], { name, children = [], ...stateRest }): RealmStateDeclaration[] => {
                if (parent && name[0] === '.') {
                    name = `${parent.name}${name}`;
                }
                const state = this.enhanceRoute({ name, ...stateRest });
                // console.log(`Flatten name: ${name}`)
                return result.concat([state]).concat(children.length ? this.flattenRoutes(children, state) : []);
            },
            [],
        );
    }

    private enhanceRoutes(routes: RealmStateDeclaration[]): RealmStateDeclaration[] {
        const resultingRoutes = this.flattenRoutes(routes);
        return resultingRoutes;
    }

    private enhanceRoute = (route: RealmStateDeclaration): RealmStateDeclaration => {
        let { name, url = null, views, ...routeRest } = route;
        if (typeof route !=='object' || !name) {
            throw new Error(`Incorrect route declaration: ${route}`);
        }
        const nameArray = name.split('?');
        name = nameArray.shift();
        const qs = nameArray.length ? `?${nameArray.join('?')}` : '';
        const partArray = name.split('.');

        if (!url) {
            url = `/${partArray[partArray.length - 1]}${qs}`;
        }

        if (views) {
            const origViews = views;
            views = Object.fromEntries(Object.entries(views).map(([viewName, viewConfig]) => {
                if (viewName[0] === '@') {
                    return [viewName, viewConfig];
                }

                const stateParts = name.split('.');
                stateParts.pop();
                if (viewName === '.') {
                    stateParts.pop();
                    return [`@${stateParts.join('.')}`, viewConfig];
                }

                //resolving parent state if ^stateName found
                // const viewParts = viewName.split('.');
                const viewParts = findClosestParent(viewName, name).split('.');

                //try to parse, available format ^.^.xxx.yyy where ^ - means go up a level
                while (viewParts.length) {
                    if (viewParts[0] === '^') {
                        stateParts.pop();
                    } else if (viewParts[0]) {
                        stateParts.push(viewParts[0]);
                    }
                    viewParts.shift();
                }

                return [`@${stateParts.join('.')}`, viewConfig];
            }));
        }

        return { name, url, views, ...routeRest };
    }
}
