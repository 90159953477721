<div class="market-map-new remote-resource" [class.resolved]="mapResolved">
    <div class="header-buttons">
        <filters-toggle
            *ngIf="filtersAvailable"
            [opened]="filtersVisible"
            (toggle)="toggleFilters()"
        ></filters-toggle>&nbsp;
        <a
            class="btn btn-primary"
            uiSref="crm.market.{{this.locationSrefParams ? 'companies' : 'msa'}}"
            [uiParams]="locationSrefParams"
        >
            Close Map
        </a>
    </div>

    <div class="map-filters" *ngIf="filtersAvailable && filtersVisible">
        <market-filters [locationParams]="locationParams" [resetPage]="false"></market-filters>
    </div>

    <div class="market-map-container remote-resource" [class.resolved]="markersLoaded">
        <ng-container *ngIf="mapResolved">
            <google-map
                width="100%"
                height="100%"
                #googleMap
                [options]="mapOptions"
                [center]="usCenter"
                [zoom]="4">
                <custom-map-marker-clusterer [styles]="clusterOptions" [options]="{ maxZoom: 18 }" #markerClusterer>
                    <map-marker #marker="mapMarker"
                        *ngFor="let markerLocation of list | async"
                        [position]="markerLocation.position"
                        [icon]="markerLocation.icon"
                        (mapClick)="openInfoWindow(marker, markerLocation)"
                    >
                        <map-info-window
                            class="remote-resource map-info-window"
                            [class.resolved]="currentMarkerInfo | async"
                        >
                            <ng-container *ngIf="currentMarkerInfo | async as locationInfo">
                                <div class="info-window-header">
                                    <h1 *ngIf="locationInfo.companyName">
                                        <div class="logo" *ngIf="locationInfo.isRegistered"></div>
                                        <a
                                            *ngIf="locationInfo.tpoId"
                                            uiSref="client.:id.account.information" [uiParams]="{ id: locationInfo.tpoId }"
                                            [textContent]="locationInfo.companyName"
                                            target="_blank"
                                            tabindex="-1"
                                        ></a>
                                        <a
                                            *ngIf="!locationInfo.tpoId"
                                            uiSref="nmls.client.:id.account.information" [uiParams]="{ id: locationInfo.companyNmlsId }"
                                            [textContent]="locationInfo.companyName"
                                            target="_blank"
                                            tabindex="-1"
                                        ></a>
                                    </h1>
                                    <div class="nmls-id" *ngIf="locationInfo.companyNmlsId">
                                        NMLS ID:
                                        <span [textContent]="locationInfo.companyNmlsId"></span>
                                    </div>
                                </div>
                                <div class="info-window-body">
                                    <h2 *ngIf="locationInfo.branchName">
                                        <span [textContent]="locationInfo.branchName"></span>
                                    </h2>
                                    <div *ngIf="locationInfo.locationAddress">
                                        <span [textContent]="locationInfo.locationAddress"></span>
                                    </div>
                                    <div *ngIf="locationInfo.companyPhone">
                                        <span [textContent]="locationInfo.companyPhone | tel"></span>
                                    </div>
                                    <div class="nmls-id" *ngIf="locationInfo.locationNmlsId">
                                        NMLS ID:
                                        <span [textContent]="locationInfo.locationNmlsId"></span>
                                    </div>
                                    <prospecting-status
                                            [canAdd]="hasProspectPermission"
                                            [prospectInfo]="locationInfo"
                                            (prospectInfoChange)="reloadMap()"
                                    ></prospecting-status>
                                </div>
                            </ng-container>
                        </map-info-window>
                    </map-marker>
                </custom-map-marker-clusterer>

                <div class="map-legend">
                    <div class="panel panel-default">
                        <div class="panel-heading"
                             [class.collapsed]="!showLegend"
                             (click)="showLegend = !showLegend"
                        >
                            <b>Legend</b>
                            <div class="pull-right">
                                <span class="caret"></span>
                            </div>
                        </div>
                        <div class="panel-body" *ngIf="showLegend">
                            <ul>
                                <li class="relationships">
                                    <i class="icon"></i>
                                    Relationships
                                </li>
                                <li class="prospect">
                                    <i class="icon"></i>
                                    Prospect
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </google-map>
        </ng-container>
    </div>
</div>
