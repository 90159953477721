<section class="social-compliance-publisher-finding-details">
	<form [formGroup]="findingDetailsForm" class="form-vertical remote-resource" [class.resolved]="finding.$resolved" (onValidSubmit)="submit()">
		<h3 class="flex-row gap">
			<span class="flex">
				Finding ID {{finding.id}}
			</span>
			<ng-container *ngIf="canEdit && !edit">
				<button type="button" class="btn btn-primary" (click)="createTask()" *ngIf="showCreateTask">
					<span class="glyphicon glyphicon-plus small-icon"></span>
					Task
				</button>
				<button type="button" class="btn btn-secondary btn-icon" (click)="toggleEdit(true)">
					<span class="glyphicon glyphicon-pencil"></span>
				</button>
			</ng-container>
			<ng-container *ngIf="edit">
				<button type="button" class="btn btn-secondary btn-icon" (click)="remove()" *ngIf="!finding.isRemoved">
					<span class="glyphicon glyphicon-trash"></span>
				</button>
				<button type="submit" class="btn btn-primary">
					Save
				</button>
				<button type="button" class="btn btn-secondary" (click)="toggleEdit(false)">
					Cancel
				</button>
			</ng-container>
		</h3>

		<form-errors [form]="findingDetailsForm"></form-errors>

		<div class="row form-group">
			<div class="col-sm-6">
				<label class="control-label">Finding Status</label>
				<span class="control-value text-bigger">
					<ng-container *ngIf="finding.status; then findingStatus; else noFindingStatus"></ng-container>
					<ng-template #findingStatus>
						<span class="label" [ngClass]="{ 'label-warning': !finding.isRemoved && finding.status.correspondingCondition !== 'Closed', 'label-success': finding.isRemoved || finding.status.correspondingCondition === 'Closed' }">
							{{finding.isRemoved ? 'Removed' : finding.status.status}}
						</span>
						<ng-container *ngIf="canEdit && User.isTpo && !edit">
							<a class="edit-link" (click)="editStatus()">Edit</a>
						</ng-container>
					</ng-template>
					<ng-template #noFindingStatus>&ndash;</ng-template>
				</span>
			</div>
			<div class="col-sm-6">
				<label class="control-label">Finding Status Date</label>
				<span class="control-value">{{finding.statusDate | date: 'MM/dd/yyyy'}}</span>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-sm-6">
				<label class="control-label">
					{{findingDetailsForm.getFieldLabel('originalPostDate')}}
				</label>
				<editable-component [view]="(finding.originalPostDate | date: 'MM/dd/yyyy') || '&ndash;'" [editable]="edit">
					<ng-template #editTemplate>
						<input type="text" class="form-control"
							   formControlName="originalPostDate"
							   bsDatepicker
							   placement="bottom left"
							   [bsConfig]="bsDatepickerConfig"
							   placeholder="mm/dd/yy"
						/>
					</ng-template>
				</editable-component>
			</div>
			<div class="col-sm-6">
				<label class="control-label">
					{{findingDetailsForm.getFieldLabel('modifiedPostDate')}}
				</label>
				<editable-component [view]="(finding.modifiedPostDate | date: 'MM/dd/yyyy') || '&ndash;'" [editable]="edit">
					<ng-template #editTemplate>
						<input type="text" class="form-control"
							   formControlName="modifiedPostDate"
							   bsDatepicker
							   placement="bottom left"
							   [bsConfig]="bsDatepickerConfig"
							   placeholder="mm/dd/yy"
						/>
					</ng-template>
				</editable-component>
			</div>		</div>
		<div class="row form-group">
			<div class="col-sm-6">
				<label class="control-label">Name</label>
				<span class="control-value">
					<ng-container *ngIf="finding.contact?.contactId; then contactLink; else contactName"></ng-container>
					<ng-template #contactLink>
						<contact-link [data]="finding.contact"></contact-link>
					</ng-template>
					<ng-template #contactName>
						{{finding.contact?.contactName || '&ndash;'}}
					</ng-template>
				</span>
			</div>
			<div class="col-sm-6">
				<label class="control-label">Audit ID</label>
				<span class="control-value">
					<ng-container *ngIf="finding.reviewId; then auditLink; else noAudit"></ng-container>
					<ng-template #auditLink>
						<a uiSref="social-compliance.orders.:id.reviews.:reviewId.information" [uiParams]="{ id: finding.orderId, reviewId: finding.reviewId }">{{finding.reviewId}}</a>
					</ng-template>
					<ng-template #noAudit>&ndash;</ng-template>
				</span>
			</div>
		</div>
		<div class="row form-group" [class.has-error]="findingDetailsForm.showFieldError('mediaLink')">
			<div class="col-sm-12">
				<label class="control-label required">
					{{findingDetailsForm.getFieldLabel('mediaLink')}}
				</label>
				<editable-component [editable]="edit">
					<ng-template #viewTemplate>
						<div class="control-value horizontal-flex-group">
							<div *ngIf="finding.mediaLink" class="icon-container">
								<sm-icon [config]="finding.mediaLink"></sm-icon>
							</div>
							<div class="link-container text-overflow-ellipsis">
								<ng-container *ngIf="finding.mediaLink?.networkCode !== 'RS'; then linkAnchor; else linkName"></ng-container>
								<ng-template #linkAnchor>
									<a [href]="finding.mediaLink?.link | hrefReady" target="_blank">{{finding.mediaLink?.name}}</a>
								</ng-template>
								<ng-template #linkName>
									{{finding.mediaLink?.name || '&ndash;'}}
								</ng-template>
							</div>
						</div>
					</ng-template>
					<ng-template #editTemplate>
						<ng-select
							formControlName="mediaLink"
							[items]="mediaLinks.$promise | async"
							[loading]="!mediaLinks.$resolved"
							bindLabel="title"
						>
							<ng-template ng-label-tmp ng-option-tmp let-item="item">
								<div class="flex-row gap">
									<sm-icon
										[config]="item"
										[minified]="true"
										[showTooltip]="false"
									></sm-icon>
									<div class="flex text-overflow ng-option-label">{{item.name}}</div>
								</div>
							</ng-template>
						</ng-select>
					</ng-template>
				</editable-component>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-sm-12">
				<label for="additionalInformation" class="control-label">
					{{findingDetailsForm.getFieldLabel('additionalInformation')}}
				</label>
				<editable-component [view]="finding.additionalInformation || '&ndash;'" [editable]="edit">
					<ng-template #editTemplate>
						<textarea class="form-control resize-vertical" id="additionalInformation" formControlName="additionalInformation" rows="3"></textarea>
					</ng-template>
				</editable-component>
			</div>
		</div>
	</form>
</section>
