import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { SideMenuContainerComponent } from 'commons/components/navigation/menu/side/side-menu-container.component';
import { UIRouterGlobals, StateService } from '@uirouter/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { combineLatest, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { combineAlerts } from 'commons/components/navigation/menu/side/side-menu.component';
import { TpoIssuesStatusService } from 'tpo/status';
import {  PACKAGE_FEATURE } from 'tpo/social-media/sm-package.service';
import { OnClickLink, RawLink } from 'commons/components/navigation/menu/generic-menu.component';
import { NotificationModalComponent } from 'commons/components/modals';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'tpo-social-media-menu',
    templateUrl: '../../../commons/components/navigation/menu/side/side-menu-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialMediaMenuComponent extends SideMenuContainerComponent {
    protected user$: Observable<UserProfile>;
    private profile: UserProfile;
    private modalRef: BsModalRef;

    constructor(
        private readonly tpoIssuesStatusService: TpoIssuesStatusService,
        private stateService: StateService,
        private modalService: BsModalService,
        { profile$, profile }: UserService,
        changeDetector: ChangeDetectorRef,
        router: UIRouterGlobals,
    ) {
        super(changeDetector, router);
        this.user$ = profile$.asObservable();
        this.profile = profile;
        this.menuUpdate$ = combineLatest([profile$, router.success$]).pipe(
            takeUntil(this.destroyed$),
        );
    }

    ngOnInit() {
        super.ngOnInit();
        this.populateMenu();
    }

    populateMenu = () => {
        const SCRoute = 'social-compliance';
        // const ListingsRoute = 'listings';
        const SARoute = 'social-accounts';
        const { socialMedia: { socialCompliance, listings } } = this.tpoIssuesStatusService;
        const { profile } = this;
        const notEnabledLink: OnClickLink = {
            click: () => {
                if (profile.package.invoicing)
                    return this.showNotEnabledModal();

                this.goToSubscribe();
            },
        };
        const socialCloudLink: RawLink = { href: 'https://optimalbluesocial.cldportal.com/login', target: '_blank' };
        // const hasListingsEnabled = profile.hasFeature(PACKAGE_FEATURE.LISTINGS);

        this.menuItems = [
            {
                title: 'Content Library',
                link: `${SCRoute}.publisher.library`,
                items: [
                    { title: 'Curated Content', link: `${SCRoute}.publisher.library.content` },
                    { title: 'Group Sharing', link: `${SCRoute}.publisher.groups` },
                    { title: 'Templates', link: `${SCRoute}.publisher.library.templates` },
                ]
                    .map(({ link, ...rest }) => ({
                        ...rest,
                        link: (profile.hasFeature(PACKAGE_FEATURE.MARKETING) ? link : notEnabledLink),
                    })),
            },
            {
                title: 'Reputation',
                link: `${SCRoute}.reputation`,
                items: [
                    { title: 'Reviews ', link: 'reviews.list' },
                    { title: 'Settings', link: 'reviews.settings.accounts', activeRoutes: ['reviews.settings.**'] },
                    { title: 'Widgets', link: 'reviews.widgets' },
                ]
                    .map(({ link, ...rest }) => ({
                        ...rest,
                        link: (profile.hasFeature(PACKAGE_FEATURE.REPUTATION_MANAGEMENT) ? link : notEnabledLink),
                    })),
            },
            // {
            //     title: 'SEO Listings',
            //     link: `${ListingsRoute}`,
            //     badge: 'Beta',
            //     items: [
            //         {
            //             title: 'Company Listings',
            //             link: `${ListingsRoute}.company`,
            //             hasAlert: hasListingsEnabled ? listings.groups.get(true) : undefined,
            //         },
            //         {
            //             title: 'Alerts',
            //             link: `${ListingsRoute}.alerts`,
            //             hasAlert: hasListingsEnabled ? listings.alerts.get() : undefined,
            //         },
            //     ]
            //         .map(({ link, ...rest }) => ({
            //             ...rest,
            //             link: (hasListingsEnabled ? link : notEnabledLink),
            //         })),
            // },
            {
                title: 'Compliance',
                link: `${SCRoute}.get-started`,
                items: [
                    { title: 'Archive', link: profile.hasFeature(PACKAGE_FEATURE.COMPLIANCE) ? `${SCRoute}.archive` : notEnabledLink },
                    { title: 'Audits', link: `${SCRoute}.reviews`, activeRoutes: ['**.orders.**.reviews.**'] },
                    { title: 'Tasks', link: `${SCRoute}.tasks` },
                    { title: 'Findings', link: `${SCRoute}.monitoring-board` },
                    {
                        title: 'Settings',
                        link: `${SCRoute}.settings`,
                        activeRoutes: [
                            '**.settings',
                            '**.compliance-schedule',
                            '**.monitoring-settings.**',
                            '**.triggers.**',
                            '**.audit-checklist.**',
                            { sref: `${SCRoute}.orders`, exclude: ['**.reviews.**'] },
                        ],
                        hasAlert: combineAlerts([
                            socialCompliance.schedule.get(),
                            this.tpoIssuesStatusService.socialComplianceOrderPaymentFailedIssueStatus.get(false),
                        ]).pipe(
                            takeUntil(this.destroyed$),
                        ),
                    },
                ],
            },
            {
                title: 'Social Accounts',
                link: `${SARoute}`,
                items: [
                    {
                        title: 'Company',
                        link: `${SARoute}.company`,
                    },
                    {
                        title: 'Individuals',
                        link: `${SARoute}.individuals`,
                    },
                    {
                        title: 'Inventory',
                        link: profile.package.isInventoryVisible ? `${SARoute}.inventory` : notEnabledLink,
                    },
                ],
            },
            ...(profile.hasFeature(PACKAGE_FEATURE.MARKETING) ? [{
                title: 'Social Cloud',
                link: socialCloudLink,
            }] : []),
            ...(!profile.package?.invoicing ? [{
                title: 'Subscription',
                link: `${SCRoute}.subscription`,
                hasAlert: combineAlerts([ this.tpoIssuesStatusService.socialMedia.subscription.hasPastDueInvoice.get(true) ]).pipe(
                    takeUntil(this.destroyed$),
                ),
            }] : []),
        ];
        this.changeDetector.markForCheck();
    };

    public goToSubscribe(): void {
        this.stateService.go('social-compliance.subscription');
    }

    public showNotEnabledModal(): void {
        this.modalRef = this.modalService.show(
            NotificationModalComponent,
            {
                initialState: {
                    title: 'No Access',
                    notification: 'To activate this service, please contact us at 720-709-4353 for more information.',
                    confirmText: 'Ok',
                    onConfirm: () => {
                        this.modalRef.hide();
                    },
                },
                class: 'modal-smd modal-new',
            },
        );
    }
}
