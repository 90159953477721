<div class="rows-bordered gap-l">
    <div class="row-fluid header">
        <div class="col-sm-5">
            <b>Account Information</b>
        </div>
        <div class="col-sm-5"><b>Status</b></div>
        <div class="col-sm-2"><b>Last Updated</b></div>
    </div>
    <div class="row-fluid">
        <application-summary-profile-review
            [section]="summary.profileReview?.AF"
            [sectionLabel]="'Affiliates'">
        </application-summary-profile-review>
    </div>
    <div class="row-fluid">
        <application-summary-profile-review
            [section]="summary.profileReview?.AG"
            [sectionLabel]="'Agency'">
        </application-summary-profile-review>
    </div>
    <div class="row-fluid">
        <application-summary-profile-review
            [section]="summary.profileReview?.IB"
            [sectionLabel]="'Insurance and Bonds'">
        </application-summary-profile-review>
    </div>
    <div class="row-fluid">
        <application-summary-profile-review
            [section]="summary.profileReview?.IV"
            [sectionLabel]="'Investors'">
        </application-summary-profile-review>
    </div>
    <div class="row-fluid">
        <application-summary-profile-review
            [section]="summary.profileReview?.TO"
            [sectionLabel]="'Technology & Operations'">
        </application-summary-profile-review>
    </div>
    <div class="row-fluid">
        <application-summary-profile-review
            [section]="summary.profileReview?.UW"
            [sectionLabel]="'Underwriting'">
        </application-summary-profile-review>
    </div>
    <div class="row-fluid">
        <application-summary-profile-review
            [section]="summary.profileReview?.WH"
            [sectionLabel]="'Warehouse'">
        </application-summary-profile-review>
    </div>
</div>
