<section class="remote-resource" [class.resolved]="list?.$resolved">
	<div class="filters-rows">
		<div class="row-fluid filter-labels">
			<div class="col-sm-3">
				<span class="text-muted">Search</span>
			</div>
			<div class="col-sm-9"></div>
		</div>
		<div class="row-fluid filter-selectors">
			<div class="col-sm-3">
				<search-list placeholder="Search Name or NMLS ID"></search-list>
			</div>
			<div class="col-sm-9"></div>
		</div>
	</div>

	<div class="rows-bordered">
		<div class="row-fluid header">
			<div class="col-sm-5" [ngClass]="getSortClass('fullName')" (click)="setSort('fullName')">
				<b>Name</b>
			</div>
			<div class="col-sm-7">
				<b>Accounts</b>
			</div>
		</div>

		<ng-container *ngFor="let contact of list">
			<div
				class="row-fluid"
				[ngClass]="getContactClassName(contact)"
				[attr.aria-expanded]="isAccountsListExpanded(contact)"
				[attr.aria-controls]="getAccountsListId(contact)"
				(click)="isContactExpandable(contact) && toggleAccountsList(contact)"
			>
				<div class="col-sm-5 va-m">
					<div class="contact flex-row">
						<span class="expandable-icon" *ngIf="isContactExpandable(contact)"></span>
						<div>
							<ng-container *ngIf="contact.contactId; then contactAnchor; else companyAnchor"></ng-container>
							<ng-template #contactAnchor>
								<a
									uiSref="people.contacts.:contactId.information"
									[uiParams]="{ contactId: contact.contactId }"
									target="_blank"
									(click)="$event.stopPropagation()"
								>{{contact.fullName}}</a>
							</ng-template>
							<ng-template #companyAnchor>
								<a
									uiSref="account.information"
									target="_blank"
									(click)="$event.stopPropagation()"
								>{{contact.fullName}}</a>
							</ng-template>

							<span
								class="c-icon c-icon-phone"
								tooltip="Mobile User"
								placement="top"
								*ngIf="contact.isMobileUser"
							></span>

							<div class="text-muted text-smaller" *ngIf="contact.nmlsId">
								NMLS ID:
								<ng-container *ngIf="contact.contactId; then contactNmlsAnchor; else companyNmlsAnchor"></ng-container>
								<ng-template #contactNmlsAnchor>
									<a
										uiSref="account.licenses.nmls.:nmlsId"
										[uiParams]="{ nmlsId: contact.nmlsId }"
										target="_blank"
										(click)="$event.stopPropagation()"
									>{{contact.nmlsId}}</a>
								</ng-template>
								<ng-template #companyNmlsAnchor>
									<a
										uiSref="account.information"
										target="_blank"
										(click)="$event.stopPropagation()"
									>{{contact.nmlsId}}</a>
								</ng-template>
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-7 va-m">
					<sm-networks-connection
						[networksConnection]="contact.networks"
					></sm-networks-connection>
				</div>
			</div>

			<ng-container *ngIf="isContactExpandable(contact)">
				<div
					class="row-fluid accounts-list remote-resource"
					[class.resolved]="contact.accounts?.$resolved"
					[id]="getAccountsListId(contact)"
					[collapse]="!isAccountsListExpanded(contact)"
				>
					<div class="rows-bordered">
						<ng-container *ngFor="let account of contact.accounts">
							<div class="row-fluid">
								<div class="col-sm-5 va-m">
									<div class="account flex-row gap">
										<sm-icon [config]="account" [minified]="true"></sm-icon>

										<ng-container *ngIf="account.networkCode !== 'RS' && account.link; then accountAnchor; else accountName"></ng-container>
										<ng-template #accountAnchor>
											<a [href]="account.link" target="_blank">{{account.name}}</a>
										</ng-template>
										<ng-template #accountName>
											<span>{{account.name}}</span>
										</ng-template>
									</div>
								</div>
								<div class="col-sm-7 va-m">
									<company-monitoring-status
										[status]="account.lastConnectionStatus"
									></company-monitoring-status>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</ng-container>
		</ng-container>

		<div class="row-fluid" *ngIf="noMatches">
			<div class="col-sm-12 va-m text-center text-bigger text-muted">No matches found</div>
		</div>

		<div class="row-fluid" *ngIf="!list?.length && !noMatches">
			<div class="col-sm-12 va-m text-center text-bigger text-muted">No participants</div>
		</div>

		<br/>

		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</section>
