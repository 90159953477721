import { cloneDeep, map } from 'lodash';
import { Component, Input, OnInit, ɵdetectChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { shareReplay, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { PhoneValidator } from 'commons/validators';
import { NgResourceObject } from 'commons/interfaces';

import { CommonValue, CommonValuesService } from 'shared/services/common-values.service';
import { CustomFieldsElement, LayoutElements } from 'shared/fields/custom-fields.service';

import { CcmCustomerDeactivateModalComponent } from 'comergence/customers/customer-information/ccm-customer-deactivate-modal/ccm-customer-deactivate-modal.component';
import { CustomerInformation } from 'comergence/customers/services/customer-list/customer-information.service.interface';
import { CustomerService } from 'comergence/customers/services/customer-list/customer.service';

import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

@Component({
    templateUrl: './ccm-customer-information.component.html',
    selector: 'ccm-customer-information',
})
export class CcmCustomerInformationComponent implements OnInit {
    private organizationID: number;
    private customerType: string;
    customer: CustomerInformation;
    private user: UserProfile;

    groupedFields: NgResourceObject<LayoutElements>;
    numOnly: RegExp = new RegExp('^[0-9]+$');
    resolving: boolean;
    isEditing: boolean;
    isAdditionalFieldsEditing: boolean;

    canManageCustomers: boolean;
    canManageAdditionalFields: boolean;

    states: Observable<CommonValue[]>;
    loadingValue = {
        states: true,
    };

    @Input()
    isNew: boolean = false;

    informationForm = new RealmFormGroup({
        organizationName: new RealmFormControl(
            'organizationName',
            {
                label: 'Company Name',
            },
            Validators.required,
        ),
        companyNmlsId: new RealmFormControl(
            'companyNlmsId',
            {
                label: 'Company NMLS ID',
            },
            [
                Validators.maxLength(9),
                Validators.pattern(this.numOnly),
            ]
        ),
        organizationPhoneNumber: new RealmFormControl(
            'organizationPhoneNumber',
            {
                label: 'Phone Number',
            },
            PhoneValidator(),
        ),
        businessTaxId: new RealmFormControl(
            'businessTaxId',
            {
                label: 'Business Tax ID',
            },
            [
                Validators.maxLength(9),
                Validators.pattern(this.numOnly),
            ]
        ),
        address1: new RealmFormControl(
            'address1',
            {
                label: 'Address 1',
            },
            Validators.required,
        ),
        city: new RealmFormControl(
            'city',
            {
                label: 'City',
            },
            Validators.required,
        ),
        address2: new RealmFormControl(
            'address2',
            {
                label: 'Address 2',
            },
        ),
        state: new RealmFormControl(
            'state',
            {
                label: 'State',
            },
            Validators.required,
        ),
        suite: new RealmFormControl(
            'suite',
            {
                label: 'Suite #',
            },
        ),
        zip: new RealmFormControl(
            'zip',
            {
                label: 'Zip',
            },
            Validators.required,
        ),
    });

    constructor(
        private customerService: CustomerService,
        private routerGlobals: UIRouterGlobals,
        public userService: UserService,
        public modalService: BsModalService,
        private commonValues: CommonValuesService,
        public stateService: StateService,
        private readonly globalNotificationsService: GlobalNotificationsService,
    ) {
        this.customerService = customerService;
        this.user = this.userService.profile;
        this.canManageCustomers = this.user.can('CCM_MANAGE_CUSTOMERS');
        this.canManageAdditionalFields = this.user.can('CCM_MANAGE_CUSTOM_FIELDS');
        this.states = commonValues.getStates()
            .pipe(
                tap(() => this.loadingValue.states = false),
                shareReplay(1),
            );
    }

    ngOnInit(): void {
        this.customerType = this.routerGlobals.params.customerType;
        this.isEditing = this.isNew;
        if (!this.isNew) {
            this.organizationID = this.routerGlobals.params.organizationId;
            this.loadCustomer();
        }
    }

    private async loadCustomer(): Promise<void> {
        this.resolving = true;

        try {
            this.customer = await this.customerService.getCustomer(this.customerType, this.organizationID).toPromise();
        } catch ({ error: { message }, message: httpError }) {
            this.informationForm.setServerError({ message: message });
        } finally {
            this.resolving = false;
        }
    }

    setEditing(value: boolean) {
        this.informationForm.patchValue(this.customer);
        this.isEditing = value;
    }

    cancel() {
        if (this.isNew) {
            this.stateService.go('customers');
        } else {
            this.isEditing = false;
            this.informationForm.reset();
        }
    }

    save() {
        if (this.isNew) {
            const { additionalFieldsForm, ...customer } = this.informationForm.value;
            const allFields = cloneDeep(this.groupedFields.fields);
            for (const group of this.groupedFields.groups) {
                allFields.push(...group.fields);
            }
            const customFieldValues = map(allFields, this.getFieldElementValues)
            this.addNewCustomer({ ...customer, customFieldValues });
        } else {
            const result = this.informationForm.value;
            result.statusCode = this.customer.statusCode;
            this.saveCustomerChanges(result);
        }
    }

    async saveCustomerChanges(changedCustomer: CustomerInformation) {
        this.resolving = true;

        try {
            await this.customerService.updateCustomer(this.customerType, this.organizationID, changedCustomer).toPromise();

            this.customer = changedCustomer;
        } catch ({ error: { message }, message: httpError }) {
            this.informationForm.setServerError({ message: message });
        } finally {
            this.resolving = false;
            this.isEditing = false;
        }
    }

    getFieldElementValues = (field: CustomFieldsElement): CustomFieldsElement => {
        const { value, ...customField } = field;
        return { customField, value } as CustomFieldsElement;
    }

	detectChanges = () => ɵdetectChanges(this);

    async addNewCustomer(newCustomer: CustomerInformation) {
        this.resolving = true;
        try {
            newCustomer.customerType = this.customerType;
            const returnedCustomer: CustomerInformation = await this.customerService.addCustomer(this.customerType, newCustomer).toPromise();

            this.goToNewlyAddedCustomer(this.customerType, returnedCustomer);
        } catch ({ error: { message }, message: httpError }) {
            this.informationForm.setServerError({ message: message});
        } finally {
            this.resolving = false;
        }
    }

    async setStatus(status: string): Promise<void> {
        this.resolving = true;
        const statusCode = { statusCode: status };

        try {
            await this.customerService.updateStatus(this.customerType, this.organizationID, statusCode).toPromise();

            this.customer.statusCode = status;
        } catch ({ error: { message }, message: httpError }) {
            this.informationForm.setServerError({ message: message });
        } finally {
            this.resolving = false;
        }
    }

    openDeactivateModal() {
        const initialState = {
            organizationName: this.customer.organizationName,
            setStatus: async () => {
                await this.setStatus('INACTIVE');
            },
        }
        this.modalService.show(
            CcmCustomerDeactivateModalComponent,
            {
                initialState,
                class: 'modal-md modal-new',
                keyboard: false,
            });
    }

    goToNewlyAddedCustomer(customerType, newCustomer) {
        this.resolving = false;
        this.globalNotificationsService.add({
            type: GlobalNotificationType.POSITIVE,
            message: `<b>${newCustomer.organizationName}</b> was created successfully`,
        });
        this.stateService.go('customers.:customerType.:organizationId.information', {
            customerType: customerType,
            organizationId: newCustomer.organizationId,
        });
    }
}
