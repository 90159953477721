import { map, indexOf } from 'lodash';
import { Component,  Injector, forwardRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TransitionService, StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import { LocalStorageService as LSService } from 'commons/services/localstorage.service';
import { ListComponent } from 'commons/components/list/list.component';
import { PagedListComponent } from 'commons/components/list/paged-list.component';

import { CodeValueService } from 'shared/code-value.service';

import { SmOrderService as SmOrder } from 'tpo/social-compliance/orders/sm-order.service';

@Component({
	templateUrl: './new.component.html',
	preserveWhitespaces: true,
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => TpoNewOrderComponent) },
	],
})
export class TpoNewOrderComponent extends PagedListComponent {
	static listName = TpoNewOrderComponent.addName('newOrder');
	listName = TpoNewOrderComponent.listName;

	helpLink = '/help/authenticated/tpo/smc/orders-board';
	contacts: any = [];
	selectedAll: boolean = false;
	selectedAmount: number = 0;
	selectedContacts: any = {};
	dateFormat = new DatePipe('en-Us');
	$scope: any;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public SmOrderService: SmOrder,
		public LocalStorageService: LSService,
		public injector: Injector,
		public User: UserService,
		public CodeValue: CodeValueService,

	) {
		super(transitionService, stateService);
		this.$scope = this.injector.get('$scope');
		this.omitParams.push('notification');

		if (!this.User.profile.can('TPO_SMC_VIEW_ALL')) {
			this.$scope.$emit('USER:403');
			return;
		}

		if (!this.User.profile.smEnabled) {
			// this.$scope.showAccessDeniedModal(true);
			return;
		}

		this.filters = {
			employment: this.CodeValue.get({ code: 'Contact OrganizationLink ContactStatus' }),
			state: this.CodeValue.get({ code: 'State' }),
			branchId: [],
		};

		this.defaultFilters = {
			...this.defaultFilters,
			employment: 'C',
		};

		this.omitParams = [
			...this.omitParams,
			'employment',
		];
	}

	setFilter(filters, resetPage: boolean = true) {
		const pageParams = resetPage ? { page: 0 } : {};
		this.selectedAll = false;

		if (filters.branchId) {
			filters.branchId = map(filters.branchId, 'id');
		}

		if (filters.employment) {
			filters.employment = filters.employment.shortName;
		}

		if (filters.state) {
			filters.state = map(filters.state, 'shortName');
			if (filters.state.length) {
				this.getBranches(filters.state);
			} else {
				filters.state = null;
				filters.branchId = null;
			}
		}

		this.params$.next({ ...this.params$.getValue(), ...pageParams, ...filters });
	}

	getBranches(state) {
		this.filters.branchId = this.SmOrderService.getBranches({state});
	}

	getAllContacts(): void {
		const appliedFilters = {
			employment: this.params.employment,
			q: this.params.q,
			state: this.params.state,
			branchId: this.params.branchId,
		};

		this.contacts.$resolved = false;
		this.SmOrderService.contacts(this.getClearParams(appliedFilters)).$promise
			.then((data) => {
				this.addContactsArray(data);
				this.contacts.$resolved = true;
			});
	}

	loadList(queryParams) {
		this.contacts.$resolved = false;

		return this.SmOrderService.contacts(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.contacts = data;
				this.noMatches = !data.length && (this.filtersApplied || queryParams.q);
			});
	}

	getDataRange(arr) {
		return map(arr, (item: any) => {
			const startDate = this.dateFormat.transform(item.startDate, 'dd/MM/yyyy');
			const endDate = this.dateFormat.transform(item.endDate, 'dd/MM/yyyy') || 'Present';

			return `${startDate} &ndash;  ${endDate}`;
		}).join(',<br/>').replace(/,\s*$/, '');
	}

	countAmount() {
		this.selectedAmount = Object.keys(this.selectedContacts).length;
	}

	addContact(contact) {
		this.selectedContacts[contact.contactId] = contact;
		this.countAmount();
	}

	addContactsArray(contacts: any[]) {
		this.resetContacts();
		this.selectedAll = true;

		contacts.forEach((element) => {
			this.selectedContacts[element.contactId] = element;
		});

		this.countAmount();
	}

	removeContact(contact) {
		this.selectedAll = false;
		delete this.selectedContacts[contact.contactId];
		this.countAmount();
	}

	resetContacts() {
		this.selectedAll = false;
		this.selectedContacts = {};
		this.countAmount();
	}

	cancelOrder() {
		const fromState = this.LocalStorageService.get('tpoNewOrderFromState');

		if (!fromState || indexOf(['orders', 'reviews', 'get-started'], fromState) < 0) {
			this.stateService.go('social-compliance.orders');
			return;
		}

		this.stateService.go('social-compliance.' + fromState);
	}

	back() {
		window.history.back();
	}

	toggleContact(input, item) {
		input.checked === true
			? this.addContact(item)
			: this.removeContact(item);
	}

	setSelectedAllToFalse = () => {
		this.selectedAll = false;
	}
}
