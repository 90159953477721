import { equals } from '@uirouter/core/lib/common/common';
import { isEqual, isObject, isDate, reduce, isString, isNumber } from 'lodash';
import * as moment from 'moment';

const intdate = {
	name: 'intdate',
	config: {
		encode: (date: any) => (date ? date.getTime() : null),
		decode: (str) => (str ? new Date(parseInt(str, 10)) : null),
		is: (val) => (typeof (val) === 'object' && val instanceof Date),
		equals: (a, b) => (isEqual(a, b)),
	},
};

export { intdate };

const urlDateFormat = 'YYYY-MM-DD';
const dateCapture = /([0-9]{4})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/;
const jsonWithDate = {
	name: 'jsonWithDate',
	config: {
		normalizeEncodeData: (data: any) => {
			return reduce(data, (acc, value, key) => {
				if (isDate(value)) {
					const date = moment(value).format(urlDateFormat);
					return { ...acc, [key]: date };
				}

				return { ...acc, [key]: value };
			}, {});
		},
		normalizeDecodeData: (data: any) => {
			return reduce(data, (acc, value, key) => {
				if (!isString(value)) {
					return { ...acc, [key]: value };
				}

				const momentDate = moment(value, urlDateFormat);
				const match = dateCapture.exec(value);
				if (momentDate.isValid() && match) {
					const date = new Date(momentDate.valueOf());
					return { ...acc, [key]: date };
				}

				return { ...acc, [key]: value };
			}, {});
		},
		encode: (data: any) => {
			const normalizedData = jsonWithDate.config.normalizeEncodeData(data);

			return JSON.stringify(normalizedData);
		},
		decode: (str: string) => {
			const data = JSON.parse(str);

			return jsonWithDate.config.normalizeDecodeData(data);
		},
		is: isObject,
		equals,
	},
};

export { jsonWithDate };

const jsonWithIntdate = {
	name: 'jsonWithIntdate',
	config: {
		normalizeEncodeData: (data: any) => {
			return reduce(data, (acc, value, key) => {
				if (isDate(value)) {
					return { ...acc, [key]: value.getTime() };
				}

				return { ...acc, [key]: value };
			}, {});
		},
		normalizeDecodeData: (data: any) => {
			return reduce(data, (acc, value, key) => {
				if (!isString(value) && !isNumber(value)) {
					return { ...acc, [key]: value };
				}

				const expectedDateInMs = parseInt(`${value}`, 10);
				const expectedDate = moment(value, 'x');
				// TODO: find better check on timestamp
				// let's check if number of ms greater than Tuesday, January 1, 1980 12:00:00 AM
				// and it is valid date for moment
				// and no milliseconds added
				if (expectedDateInMs > 315532800000 && expectedDate.isValid() && (expectedDateInMs % 1000 === 0)) {
					const date = new Date(expectedDateInMs);
					return { ...acc, [key]: date };
				}

				return { ...acc, [key]: value };
			}, {});
		},
		encode: (data: any) => {
			const normalizedData = jsonWithIntdate.config.normalizeEncodeData(data);

			return JSON.stringify(normalizedData);
		},
		decode: (str: string) => {
			const data = JSON.parse(str);

			return jsonWithIntdate.config.normalizeDecodeData(data);
		},
		is: isObject,
		equals,
	},
};

export { jsonWithIntdate };
