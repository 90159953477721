import { toPairs, map } from 'lodash';
import { Component } from '@angular/core';
import { Validators } from '@angular/forms';

import { UserService } from 'angularjs-providers/user.provider';

import { createRealmFormControl, RealmFormControl } from 'commons/forms/form-control.hoc';
import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { PhoneValidator, MaxLengthValidator } from 'commons/validators';

declare let angular: any;

import { CompanyPageService } from './company-page-resource.service';
import { CompanyPage as CompanyPageModel } from './company-page';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LenderCompanyPagesChangeLogoModalComponent } from './change-logo-modal/lender-company-pages-change-logo-modal.component';

@Component({
	templateUrl: './company-pages.component.html',
})
export class CompanyPagesComponent {
	editing: boolean = false;
	submitted: boolean = false;
	maxDescriptionLength: number = 1000;
	imageUpdate: number = 0;

	companyPageData: any = {};
	companyPageResource: any = {};

	$scope: any = {};

	companyPageForm = new RealmFormGroup({
		...createRealmFormControl('displayName', {label: 'Company display name'}, Validators.compose([
			Validators.required,
			MaxLengthValidator(100),
		])),
		...createRealmFormControl('description', {label: 'Company Description'}),
		...createRealmFormControl('contactName', {label: 'Contact name'}, MaxLengthValidator(100)),
		...createRealmFormControl('webSite', {label: 'Website'}, MaxLengthValidator(100)),
		...createRealmFormControl('phone', {label: 'Phone'}, Validators.compose([
			MaxLengthValidator(10),
			PhoneValidator(),
		])),
	});

	constructor(
		public User: UserService,
		public CompanyPage: CompanyPageService,
        public readonly modalService: BsModalService,
	) {}

	ngOnInit() {
		this.imageUpdate = Date.now();
		this.CompanyPage.get().$promise
			.then((result) => {
				this.companyPageResource = result;
				this.companyPageData = this.getDataModelFromResource();
				this.companyPageForm.reset({ ...this.companyPageData });
			});
	}

	showUploadModal(files: FileList) {
		const reader = new FileReader();

		reader.onload = (e: any) => {
			const initialState = {
                selectedImage: e.target.result,
                saveCallback: () => this.imageUpdate = Date.now(),
            };
			this.modalService.show(
				LenderCompanyPagesChangeLogoModalComponent,
				{
					initialState,
					class: 'modal-smd modal-new confirm-file-upload-modal',
					backdrop: 'static',
				},
			);
		};

		reader.readAsDataURL(files[0]);
	}

	save() {
		this.submitted = true;

		map(toPairs(this.companyPageForm.value), ([fieldName, fieldValue]) => {
			this.companyPageResource[fieldName] = fieldValue;
		});

        this.companyPageResource.$resolved = false;
        this.CompanyPage.save(this.companyPageResource, (result) => {
			// this.companyPageResource = result;
			this.companyPageData = this.getDataModelFromResource();
            this.companyPageResource.$resolved = true;
			this.editing = false;
			this.submitted = false;
		}, ({data: response}) => {
            this.companyPageResource.$resolved = true;
			this.submitted = false;
			this.companyPageForm.setServerError(response);
		});
	}

	reset() {
		if (this.submitted) {
			return;
		}

		this.editing = false;
		this.companyPageForm.reset({...this.companyPageData});
	}

	showForm() {
		this.editing = true;
	}

	charsLeft() {
		const field = this.companyPageForm.get('description') as RealmFormControl;
		const value = field._pendingValue !== undefined ? field._pendingValue : field.value;
		const usedChars = value ? value.length : 0;
		const result = this.maxDescriptionLength - usedChars;
		return Math.max(result, 0);
	}

	getImageURL() {
		if (!this.User) {
			return '';
		}

		return `/api/rest/lenders/${this.User.profile.organization.id}/logo?${this.imageUpdate}`;
	}

	public getDataModelFromResource() {
		const { contactName, description, displayName, phone, webSite } = this.companyPageResource;
		return new CompanyPageModel(contactName, description, displayName, phone, webSite);
	}
}
