import { Component, forwardRef } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { Validators } from '@angular/forms';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { NgResourceArray } from 'commons/interfaces';
import { RealmFormGroup, RealmFormControl } from 'commons/forms';
import { ListComponent, PagedListComponent } from 'commons/components/list';
import { ListingsAlertsStatusService } from 'tpo/status';

import {
	CompanyListingsService,
	ListingGroupOpenAlert,
} from '../../../../company-listings.service';
import { GroupAlertsStatusService } from '../../group-alerts-status.service';

@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => CompanyListingGroupOpenAlertsComponent) },
	],
})
export class CompanyListingGroupOpenAlertsComponent extends PagedListComponent {
	static listName = CompanyListingGroupOpenAlertsComponent.addName('company-listing-group-open-alerts');
	listName = CompanyListingGroupOpenAlertsComponent.listName;
	list: NgResourceArray<ListingGroupOpenAlert> = [];
	forms: RealmFormGroup[];
	User: UserProfile;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		{ profile }: UserService,
		private listingsAlertsStatusService: ListingsAlertsStatusService,
		private companyListingsService: CompanyListingsService,
		private groupAlertsStatusService: GroupAlertsStatusService,
	) {
		super(transitionService, stateService);

		this.User = profile;

		this.configureList();
	}

	private configureList(): void {
		const { listingGroupId } = this.stateService.params;
		Object.assign(
			this.defaultFilters,
			{
				listingGroupId,
				size: 25,
			},
		);

		this.omitParams.push('listingGroupId');
	}

	loadList(params) {
		this.listingsAlertsStatusService.refresh();
		this.groupAlertsStatusService.refresh();

		this.list.$resolved = false;
		return this.companyListingsService.listingGroupOpenAlerts(
			this.getClearParams(params),
		).$promise
			.then((data) => {
				this.list = data;

				this.forms = data.map(() => (
					new RealmFormGroup({
						resolveType: new RealmFormControl(
							'resolveType',
							{
								value: 'GROUP',
								updateOn: 'change',
							},
							Validators.required,
						),
					})
				));
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}

	reloadList(): void {
		this.loadList(this.params);
	}

	confirm(
		alert: ListingGroupOpenAlert,
		form: RealmFormGroup,
	): void {
		const { listingGroupId } = this.stateService.params;
		const { id: alertId } = alert;
		const { resolveType } = form.value;

		this.forms
			.filter((item) => item !== form)
			.forEach((item) => {
				item.reset({ resolveType: 'GROUP' });
			});

		this.list.$resolved = false;
		return this.companyListingsService.resolveListingGroupAlert({
			listingGroupId,
			alertId,
			resolveType,
		}, null).$promise
			.then(
				() => {
					this.reloadList();
				},
				({ data }) => {
					form.reset({ resolveType: 'GROUP' });
					form.setServerError(data);
					this.list.$resolved = true;
				},
			);
	}
}
