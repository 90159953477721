import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';

// Custom Modules
import { PublisherPostEditModule } from './$postType/$postId/edit/post-edit.module';
import { PublisherPostViewModule } from './$postType/$postId/view/post-view.module';
import { PublisherPostPreviewModule } from './$postType/$postId/posts-preview/post-preview.module';
import { PostsTypeListModule } from '../posts-type-list';

// Services
import { PostsResourceService } from './posts-resource.service';
import { PostsService } from './posts.service';
import { CampaignsResourceService } from '../campaigns';
import { PostTypeResourceService } from './$postType';

// Components
import { PostTypeComponent } from './$postType';
import { PostEditPageComponent } from './post-edit-page.component';
import { PostViewPageComponent } from './post-view-page.component';
import { PostsComponent } from './posts.component';
import { AttachmentsModule } from '../attachments';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivesModule,
		ComponentsModule,
		AttachmentsModule,
		PostsTypeListModule,
		PipesModule,
		PublisherPostEditModule,
		PublisherPostViewModule,
		PublisherPostPreviewModule,
	],
	declarations: [
		PostsComponent,
		PostViewPageComponent,
		PostEditPageComponent,
		PostTypeComponent,
	],
	entryComponents: [
		PostsComponent,
		PostViewPageComponent,
		PostEditPageComponent,
		PostTypeComponent,
	],
	providers: [
		PostsResourceService,
		PostsService,
		CampaignsResourceService,
		PostTypeResourceService,
	],
})
export class PostsModule {}
