<div class="company-logo-modal remote-resource" [ngClass]="{ resolved: !isSaving }" tabindex="-1" role="dialog">
    <div #modalHeader class="modal-header">
        <button type="button" class="close" (click)="hide();">&times;</button>
        <h4 class="modal-title">Upload Logo</h4>
    </div>
    <div class="modal-body clearfix">
        <div class="well text-center">Drag and resize selection to crop your image</div>

        <div class="image-zone upload">
            <div class="crop-zone"
                    [ngStyle]="{
                        width: (zone.size + 'px'),
                        height: (zone.size + 'px'),
                        left: (zone.x + 'px'),
                        top: (zone.y + 'px')
                    }"
                    (mousedown)="cropZoneMouseDown($event)">
                <div class="size-drag" (mousedown)="sizeDragMouseDown($event)"></div>
            </div>
            <div class="image-zone-inner">
                <img *ngIf="imagePos"
                        src="{{ selectedImage }}"
                        class="crop-image"
                        [ngStyle]="{
                            clip: 'rect(' + (zone.y - imagePos.y) + 'px,' + (zone.x - imagePos.x + zone.size) + 'px,' + (zone.y - imagePos.y + zone.size) + 'px,' + (zone.x - imagePos.x) + 'px)'
                        }">
                <img #wholeImage src="{{ selectedImage }}" class="whole-image"/>
            </div>
        </div>

        <div class="cropped-image-zone">
            <canvas #croppedImage id="croppedImage" width="{{ resultSize }}" height="{{ resultSize }}"></canvas>
            <div class="crop-description text-smaller text-muted">This is how your logo will look like in our system</div>
        </div>
    </div>
    <div class="modal-footer text-right">
        <div class="btn-group-lg">
            <a class="btn btn-primary" (click)="save()">Save</a>
            <a class="btn btn-default" (click)="hide()">Cancel</a>
        </div>
    </div>
</div>
