<div
	[class.resolved]="!targetUser && !isSaving"
	[class.preview]="!isEditing"
	class="remote-resource"
>
	<ng-container *ngIf="!!isEditing">
		<form id="readOnlyUserForm" [formGroup]="readOnlyUserForm" (onValidSubmit)="save()">
			<form-errors [form]="readOnlyUserForm"></form-errors>

			<div class="row-fluid">
				<div *ngIf="shouldShowFormControls" class="pull-right">
					<input *ngIf="shouldShowSaveButton" type="submit" tabindex="16" value="Save" class="btn btn-primary">

					<a *ngIf="shouldShowCancelButton" (click)="cancel()" class="btn btn-default">
						Cancel
					</a>
				</div>
			</div>

			<div *ngIf="!settingsView && (hasPermission('MANAGE_USERS') || (!isNew && hasPermission('UNLOCK_USERS')))" class="row-fluid">
				<div *ngIf="!isNew && (hasPermission('MANAGE_USERS') || hasPermission('UNLOCK_USERS'))" class="col-md-12">
					<button *ngIf="hasPermission('MANAGE_USERS') && (targetUser?.status?.id === userStatus.ACTIVE)"
						type="button"
						(click)="sendUsername()"
						[disabled]="usernameResent || isEditing"
						class="btn btn-default"
					>
						Resend Username
					</button>

					<button
						*ngIf="hasPermission('MANAGE_USERS') && (targetUser?.status?.id === userStatus.ACTIVE)"
						type="button"
						(click)="resetPassword()"
						[disabled]="passwordResent || isEditing"
						class="btn btn-default"
					>
						Reset Password
					</button>

					<button
						*ngIf="hasPermission('UNLOCK_USERS') && (targetUser?.status?.id === userStatus.LOCKED)"
						type="button"
						(click)="unlockUser()"
						[disabled]="isEditing"
						class="btn btn-default"
					>
						Unlock User
					</button>

					<button
						*ngIf="hasPermission('MANAGE_USERS') && (targetUser?.status?.id === userStatus.ACTIVE || targetUser?.status?.id === userStatus.LOCKED)"
						type="button"
						(click)="deactivate()"
						[disabled]="userDeactivated || isEditing"
						class="btn btn-danger-wired"
					>
						Deactivate User
					</button>

					<button
						*ngIf="hasPermission('MANAGE_USERS') && (targetUser?.status?.id === userStatus.INACTIVE)"
						type="button"
						(click)="activate()"
						[disabled]="isEditing"
						class="btn btn-success-wired"
					>
						Activate User
					</button>
				</div>
			</div>

			<div class="mb20">
				<h3>User Information</h3>
			</div>

			<div class="rows-striped">
				<div class="row-fluid">
					<div class="col-md-6">
						<div has-error class="form-group row">
							<label [class.required]="isEditing" class="col-sm-3 control-label">First Name</label>
							<div class="col-sm-8">
								<div [isEditable]="isEditing">
									<span view class="control-value">
										<div class="control-value">
											{{ targetUser?.firstName || '&ndash;' }}
										</div>
									</span>
									<input edit type="text" name="firstName" formControlName="firstName" maxlength="50" tabindex="4" class="form-control" />
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div has-error class="form-group row">
							<label [class.required]="isEditing" class="col-sm-3 control-label">Email</label>
							<div [class.col-sm-6]="!isEditing" [class.col-sm-8]="isEditing">
								<span [class.control-value]="!isEditing">
									<div [isEditable]="isEditing">
										<span view class="control-value">
											<a *ngIf="targetUser?.email && !targetUser?.isEmailBounced"
												href="mailto:{{ targetUser?.email }}"
											>
												{{ targetUser?.email || '&ndash;' }}
											</a>
											<span *ngIf="!targetUser?.email || targetUser?.isEmailBounced">{{ targetUser?.email || '&ndash;' }}</span>
											<span *ngIf="targetUser?.isEmailBounced">
												&nbsp;<span
													tooltip="Bounce Email"
													placement="top-right"
													class='text-danger glyphicon glyphicon-exclamation-sign'
												></span>
											</span>
										</span>
										<input edit
											type="email"
											formControlName="email"
											maxlength="100"
											tabindex="6"
											autocomplete="off"
											class="form-control"
										/>
									</div>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row-fluid">
					<div class="col-md-6">
						<div has-error class="form-group row">
							<label [class.required]="isEditing" class="col-sm-3 control-label">Last Name</label>
							<div class="col-sm-8">
								<div [isEditable]="isEditing">
									<span view class="control-value">
										<div class="control-value">
											{{ targetUser?.lastName || '&ndash;' }}
										</div>
									</span>
									<input edit type="text" name="lastName" formControlName="lastName" maxlength="50" tabindex="5" class="form-control" />
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div has-error class="form-group row">
							<label class="col-sm-3 control-label">Phone</label>
							<div class="col-sm-8">
								<div [isEditable]="isEditing">
									<span view class="control-value">
										<div class="control-value">
											{{ ( targetUser?.phone || '&ndash;') | tel }}
										</div>
									</span>
									<span edit>
										<input phoneFormatter type="text" name="phone" formControlName="phone" tabindex="7" class="form-control" />
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</ng-container>
</div>
