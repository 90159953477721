import { Component, forwardRef } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';

import { NgResourceArray } from 'commons/interfaces';
import { ListComponent, PagedListComponent } from 'commons/components/list';

import {
	CompanyListingsService,
	AlertedListing,
} from '../company-listings.service';

@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => CompanyAlertedListingsComponent) },
	],
})
export class CompanyAlertedListingsComponent extends PagedListComponent {
	static listName = CompanyAlertedListingsComponent.addName('company-alerted-listings');
	listName = CompanyAlertedListingsComponent.listName;
	list: NgResourceArray<AlertedListing> = [];

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		public companyListingsService: CompanyListingsService,
	) {
		super(transitionService, stateService);

		this.configureList();
	}

	private configureList(): void {}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.companyListingsService.alertedListings(
			this.getClearParams(queryParams),
		).$promise
			.then((data: any) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}
}
