import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';

import { NgResourceArray } from 'commons/interfaces';
import { ListComponent, PagedListComponent } from 'commons/components/list';

import { TpoLibraryDocument } from '../../documents.interface';
import { TpoDocumentsService } from '../../documents.service';

@Component({
	selector: 'tpo-library-document-list',
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => TpoLibraryDocumentListComponent) },
	],
})
export class TpoLibraryDocumentListComponent extends PagedListComponent {
	static listName = TpoLibraryDocumentListComponent.addName('tpoLibraryDocumentList');
	listName = TpoLibraryDocumentListComponent.listName;
	list: NgResourceArray<TpoLibraryDocument> = [];

	@Input() filters: { activeOnly?: boolean } = {};
	@Output() loaded: EventEmitter<boolean> = new EventEmitter();

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		private documentsService: TpoDocumentsService,
	) {
		super(transitionService, stateService);
	}

	ngOnInit() {
		this.configureList();

		super.ngOnInit();
	}

	private configureList(): void {
		Object.assign(
			this.defaultFilters,
			{
				...this.filters,
				size: 25,
			},
		);

		this.omitParams.push(
			Object.keys(this.filters),
		);
	}

	async loadList(queryParams) {
		this.loaded.emit(false);
		this.list.$resolved = false;
        this.list = await this.documentsService.getLibraryDocuments(
            this.getClearParams(queryParams),
        ).$promise;
		this.loaded.emit(true);
		this.list.$resolved = true;
	}

	reloadList(): void {
		this.loadList(this.params);
	}
}
