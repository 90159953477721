import { Component, forwardRef } from '@angular/core';

import { ListComponent } from 'commons/components/list';

import { TpoPartnersListComponent } from '.';


@Component({
	templateUrl: './tpo-partners-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => TpoMyPartnersListComponent) },
	],
})
export class TpoMyPartnersListComponent extends TpoPartnersListComponent {
	static listName = TpoMyPartnersListComponent.addName('TpoMyPartnersListComponent');
	listName = TpoMyPartnersListComponent.listName;

    protected type: ('all' | 'my') = 'my';
}
