import { Component } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { Validators } from '@angular/forms';

import { UserService } from 'angularjs-providers/user.provider';

import { PagedListComponent } from 'commons/components/list/paged-list.component';
import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';

import { GroupsResourceService } from '../../../groups-resource.service';

@Component({
	selector: 'group-sharing-templates',
	templateUrl: './group-sharing-templates.component.html',
})
export class GroupSharingTemplatesComponentComponent extends PagedListComponent {
	static listName = GroupSharingTemplatesComponentComponent.addName('groupSharingtemplates');
	listName = GroupSharingTemplatesComponentComponent.listName;

	list: any = [];
	removeTemplateId: number;
	tpoId: number;
	User: any;
	showAddTemplates: any;
	addTemplatesCandidates: any = [];
	addTemplatesForm: RealmFormGroup = new RealmFormGroup({
		templates: new RealmFormControl(
			'templates',
			{
				label: 'Templates',
			},
			[
				Validators.required,
			],
		),
	});

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public groupsResource: GroupsResourceService,
		public userService: UserService,
	) {
		super(transitionService, stateService);

		const { groupId } = stateService.params;

		this.tpoId = this.userService.profile.organization.id;

		this.defaultFilters = {
			...this.defaultFilters,
			size: 5,
			groupId,
		};

		this.omitParams = [
			...this.omitParams,
			'groupId',
		];

		this.User = userService.profile;
	}

	toggleAddTemplates(state): void {
		// skip no change
		if (this.showAddTemplates === state) {
			return;
		}

		this.showAddTemplates = state;

		if (this.showAddTemplates) {
			// reset remove
			this.resetRemoveTemplate();

			// refresh select options
			const { groupId } = this.stateService.params;
			const params = {
				groupId,
			};
			this.addTemplatesCandidates = this.groupsResource.groupSharedTemplates.getTemplatesCandidates(params);
		} else {
			this.addTemplatesForm.reset();
		}
	}

	submitAddTemplates(): void {
		const { groupId } = this.stateService.params;
		const params = {
			groupId,
		};
		const request = this.addTemplatesForm.value.templates;

		this.list.$resolved = false;
		this.groupsResource.groupSharedTemplates.add(
			params,
			request,
		).$promise
			.then(
				() => {
					this.toggleAddTemplates(false);
					this.loadList(this.params);
				},
				({ data }) => {
					this.list.$resolved = true;
					this.addTemplatesForm.setServerError(data);
				},
			);
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.groupsResource.groupSharedTemplates.get(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.list = data;
				this.showAddTemplates = false;
				this.addTemplatesForm.reset();
			});
	}

	removeTemplate(templateId): void {
		// reset add
		this.toggleAddTemplates(false);

		this.removeTemplateId = templateId;
	}

	resetRemoveTemplate(): void {
		this.removeTemplateId = null;
	}

	confirmRemoveTemplate(): void {
		const { groupId } = this.stateService.params;
		const { removeTemplateId: templateId } = this;

		const params = {
			groupId,
			templateId,
		} as any;

		this.list.$resolved = false;
		this.groupsResource.groupSharedTemplates.remove(params).$promise
			.then(() => {
				this.list = this.list.filter(({ id }): boolean => id !== templateId);
				if (this.list.length === 0 && this.params.page > 0) {
					this.params.page = this.params.page - 1;
				}
				this.loadList(this.params);
			})
			.finally(this.resetRemoveTemplate);
	}
}
