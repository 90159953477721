import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InvestorsSelfReportedInformation } from 'tpo/account/investors/investors.interface';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { PhoneValidator } from 'commons/validators';
import * as moment from 'moment';
import { InvestorsService } from 'tpo/account/investors/investors.service';
import { AccountInformation } from 'shared/account/company-information/account-information.service';


@Component({
    selector: 'investor-item',
    templateUrl: 'investor-item.component.html',
})
export class InvestorItemComponent implements OnInit {
    @Input() public item: InvestorsSelfReportedInformation;
    @Input() public accountInformation: AccountInformation;
    @Input() public userCanAddEdit: boolean;
    @Output() public onNewItemCancel: EventEmitter<InvestorsSelfReportedInformation> = new EventEmitter<InvestorsSelfReportedInformation>();
    @Output() public afterSave: EventEmitter<void> = new EventEmitter<void>();

    isEditing: boolean = false;
    resolving: boolean;
    isValid: boolean;
    labelDateFormat: string = 'MM/dd/YYYY';
    dateFormat: string = 'MM/DD/YYYY';

    investorInformationForm = new RealmFormGroup({
        investorName: new RealmFormControl(
            'investorName',
            {
                label: 'Investor Name',
            },
            Validators.required,
        ),
        phone: new RealmFormControl(
            'phone',
            {
                label: 'Phone',
            },
            [
                Validators.required,
                PhoneValidator(),
                Validators.maxLength(10),
            ],
        ),
        contactName: new RealmFormControl(
            'contactName',
            {
                label: 'Contact',
            },
            Validators.required,
        ),
        relationshipStartDate: new RealmFormControl(
            'relationshipStartDate',
            {
                label: 'Relationship Start Date',
            },
            Validators.required,
        ),
        relationshipEndDate: new RealmFormControl(
            'relationshipEndDate',
            {
                label: 'Relationship End Date',
                value: null,
            },
        ),
    },
        { validators: this.relationshipDatesValidator }
    );

    relationshipDatesValidator(formGroup: FormGroup): ValidatorFn {
        if(formGroup.controls['relationshipEndDate'].value && formGroup.controls['relationshipEndDate'].value < formGroup.controls['relationshipStartDate'].value) {
            formGroup.controls['relationshipEndDate'].setErrors({ 'error' : true });
        }
        return;
    }

   constructor(private investorsService: InvestorsService) {}

    ngOnInit() {
        if (!this.item.id) {
            return this.isEditing = true;
        }
    }

    doCancel() {
        this.isEditing = false;
        if (!this.item.id) {
            this.onNewItemCancel.next(this.item);
        }
    }

    doEdit() {
        const formValue = ({ ...this.item } as any);

        this.investorInformationForm.patchValue(formValue);
        this.investorInformationForm.patchValue({relationshipStartDate: this.toDate(this.item.relationshipStartDate)});
        this.investorInformationForm.patchValue({relationshipEndDate: this.toDate(this.item.relationshipEndDate)});

        this.isEditing = true;
    }

    async doSave() {
        const result: InvestorsSelfReportedInformation = this.investorInformationForm.value;
        if(!this.item.id) {
            this.addNewInvestor(result);
        }
        else {
            this.saveChangedInvestor(result);
        }
    }

    async addNewInvestor(result: InvestorsSelfReportedInformation) {
       result.productionSoldPercent = 0;
        try {
            this.resolving = true;
            const newlyAddedInvestor: InvestorsSelfReportedInformation =  await this.investorsService.addNewInvestor(this.accountInformation.identifier.tpoId, result).toPromise();
            this.item = newlyAddedInvestor;
            this.afterSave.emit();
            this.isEditing = false;
        }
        catch(e) {
            this.investorInformationForm.setServerError(e);
        }
        finally {
            this.resolving = false;
        }
    }

    async saveChangedInvestor(result: InvestorsSelfReportedInformation) {
        result.productionSoldPercent = this.item.productionSoldPercent;
        try {
            this.resolving = true;
            const changedInvestor: InvestorsSelfReportedInformation =  await this.investorsService.updateInvestor(this.accountInformation.identifier.tpoId, this.item.id, result).toPromise();
            this.item = changedInvestor;
            this.afterSave.emit();
            this.isEditing = false;
        }
        catch(e) {
            this.investorInformationForm.setServerError(e);
        }
        finally {
            this.resolving = false;
        }
    }

    toDate(dateString) {
        if(dateString) {
            return moment(dateString).toDate();
        }
        return null;
    }
}
