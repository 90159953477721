<div class="rows-bordered  remote-resource" [class.resolved]="list.$resolved">
	<div class="filters-rows partner-filters-row">
		<div class="row-fluid filter-labels">
			<div class="col-sm-3">
				<div class="text-muted">Search</div>
			</div>
			<div class="col-sm-3"></div>
		</div>
		<div class="row-fluid filter-selectors">
			<div class="col-sm-3">
				<div class="has-feedback">
					<search-list placeholder="Name, description, location"></search-list>
				</div>
			</div>
			<div class="col-sm-9"></div>
		</div>
	</div>
	<div class="row-fluid header partner-header">Partner</div>
	<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
		<div class="col-sm-12 va-m text-center">
			<span>Filters are applied. </span>
			<a (click)="resetFilters()">Reset filters</a>
			<span> to see all results</span>
		</div>
	</div>
	<div class="row-fluid" *ngIf="!list.length">
		<span class="col-sm-12 text-muted text-center text-bigger text-weak">No matches found</span>
	</div>
	<div class="row-fluid list" *ngFor="let item of list">
		<tpo-partners-list-item [item]="item" [type]="type"></tpo-partners-list-item>
	</div>
	<div class="paging-section" *ngIf="list.length">
		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>

</div>