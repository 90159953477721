import { Component, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { ApplicationStatus, ApplicationStatusTransitions, LenderApplication, LenderApplicationsResourceService } from '../applications.service';
import { ApplicationSummary } from 'lender/applications/application-summary.interface';
import { NgResource } from 'commons/interfaces/resource.interface';
import { ApplicationChangeStatusModalComponent } from 'lender/applications/app-status-modal/app-status.modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

enum ApplicationStatusColors {
	AP = 'success',
	CN = 'danger',
	DE = 'danger',
	WD = 'danger',
}

@Component({
	templateUrl: './application-summary.component.html',
})
export class ApplicationSummaryComponent implements OnInit {
	user: UserProfile;
	applicationStatusColors = ApplicationStatusColors;
	summary: NgResource<ApplicationSummary>;
	fullApplicationLink: string;
	showDocuments = false;
    hasStatusPermission: boolean = false;
    applicationId: number;
    appStatuses: ApplicationStatusTransitions;
    modalRef: BsModalRef;
    resolving: boolean;
    hasPrintPackagePermission: boolean;
    lenderId: number;


    constructor(
        public stateService: StateService,
        userService: UserService,
        public routerGlobals: UIRouterGlobals,
        public modalService: BsModalService,
        public resourceService: LenderApplicationsResourceService,
    ) {
        this.user = userService.profile;
    }

    ngOnInit() {
        this.applicationId = this.routerGlobals.params.applicationId;
        this.loadApplicationSummary();
        this.loadAppStatuses();
        this.hasStatusPermission = this.user.can('EDIT_STATUS');
        this.hasPrintPackagePermission = this.user.can('PRINT_PACKAGE');
        if (this.user.isLender) {
            this.lenderId = this.user.organization.id;
        }
    }

	toggleDocuments = (showDocuments = !this.showDocuments): void => {
		this.showDocuments = showDocuments;
	}

    async loadAppStatuses() {
        this.appStatuses = await this.resourceService.getApplicationStatusTransitions().toPromise();
    }

    setAppStatus(application: ApplicationSummary, status: ApplicationStatus) {
        const { id: statusCode } = status;
        application.tpoId = application.tpo.id;
        application.clientName = application.tpo.name;
        application.nmlsId = application.companyNmlsId;
        const initialState = {
            applicationSummary: application,
            statusCode,
            updateApplication: (newApplication: LenderApplication) => {
                if(newApplication.applicationStatus.id == 'CN' || newApplication.applicationStatus.id == 'AP' || newApplication.applicationStatus.id == 'DE') {
                    this.stateService.go('applications');
                }
                else {
                   this.loadApplicationSummary();
                }
            },
        };

        this.modalRef = this.modalService.show(ApplicationChangeStatusModalComponent, {
            initialState,
            class: 'modal-smd modal-new',
        });
    }

    private async loadApplicationSummary() {
        this.resolving = true;
        try {
            this.summary = await this.resourceService.getApplicationSummary(this.applicationId).toPromise();
            this.summary.tpoId = this.summary.tpo.id;
        }
        finally {
            this.resolving = false;
        }
    }
}
