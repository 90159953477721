import { Component, ɵdetectChanges } from '@angular/core';
import { StateService } from '@uirouter/core';


interface Tab {
	title: string;
	url: string;
	params?: {};
	permission?: string;
}

@Component({
	templateUrl: './activity.component.html',
})
export class ActivityComponent {

	tabs: Tab[] = [
		{
			title: 'Details',
			url: '.details',
		},
		{
			title: 'History',
			url: '.history',
		},
	];
	title: string;

	constructor(public stateService: StateService) {}

	getCurrentTab(tab): boolean {
		const isCurrent = this.stateService?.current?.name.indexOf(tab.url) > -1;
		// const { activityInitial } = this.view?._componentRef?.instance || {};
        // CRAP!!!
		// isCurrent && (this.title = tab.title === 'Details' ? activityInitial?.name : tab.title);
		return isCurrent;
	}

    setTitle(title: string) {
        this.title = title;
        ɵdetectChanges(this);
    }

	getParams({ params }: Tab): any {
		return {
			...params,
			q: null,
			page: null,
			sort: null,
			size: null,
		};
	}
}
