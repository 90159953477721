import { Component, forwardRef } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list';
import { TpoIssuesStatusService } from 'tpo/status';

import {
	ListingGroup,
	ListingGroupListing,
	CompanyListingsService,
} from '../../../../company-listings.service';
import { MoveListingModalComponent } from '../common';

@Component({
	selector: 'listing-group-listings',
	templateUrl: './group-listings.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ListingGroupListingsComponent) },
	],
})
export class ListingGroupListingsComponent extends ListComponent {
	static listName = ListingGroupListingsComponent.addName('company-listing-group-listings');
	listName = ListingGroupListingsComponent.listName;
	list: any = [];
	User: UserProfile;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		{ profile }: UserService,
		public modalService: BsModalService,
		public companyListingsService: CompanyListingsService,
		private tpoIssuesStatusService: TpoIssuesStatusService,
	) {
		super(transitionService, stateService);

		this.User = profile;

		this.defaultFilters.listingGroupId = stateService.params.listingGroupId;
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.companyListingsService.listingGroupListingsList(
			this.getClearParams(queryParams),
		).$promise
			.then((data: any) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}

	update() {
		this.loadList(this.params);
		this.tpoIssuesStatusService.socialMedia.listings.groups.update();
	}

	goToListings(): void {
		this.tpoIssuesStatusService.socialMedia.listings.groups.update();
		this.stateService.go('listings.company');
	}

	cancelBubble(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		return false;
	}

	moveListing(
		listing: ListingGroupListing,
	): void {
		const { listingGroupId } = this.stateService.params;
		const {
			mediaLinkId,
		} = listing;
		const params = {
			listingGroupId,
			mediaLinkId,
		};

		const initialState = {
			listing,
			listingGroupsList: this.companyListingsService.listingMoveList(params),
			move: (
				targetGroup: ListingGroup,
			): Promise<void> => (
				this.companyListingsService.moveListing(
					params,
					targetGroup,
				).$promise
					.then(
						({ listingGroupRemoved }) => {
							if (listingGroupRemoved) {
								this.goToListings();
							} else {
								this.update();
							}
						},
						() => {
							this.update();
						},
					)
			),
			create: (
				newGroup: ListingGroup,
			): Promise<void> => (
				this.companyListingsService.moveListingToNewGroup(
					params,
					newGroup,
				).$promise
					.then(
						({ listingGroupRemoved }) => {
							if (listingGroupRemoved) {
								this.goToListings();
							} else {
								this.update();
							}
						},
						() => {
							this.update();
						},
					)
			),
		};

		this.modalService.show(
			MoveListingModalComponent,
			{
				initialState,
				class: 'modal-smd modal-new',
			},
		);
	}
}
