import { Component, forwardRef } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';

import { ListComponent } from 'commons/components/list';
import { HistoryDocumentListComponent } from 'shared/documents';

import { TpoLibraryHistoryDocument } from 'tpo/documents/documents.interface';
import { TpoDocumentsService } from 'tpo/documents/documents.service';

@Component({
	templateUrl: '../../../../../shared/documents/document-history/document-history.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => TpoLibraryDocumentHistoryComponent) },
	],
})
export class TpoLibraryDocumentHistoryComponent extends HistoryDocumentListComponent<TpoLibraryHistoryDocument> {
	static listName = TpoLibraryDocumentHistoryComponent.addName('tpoLibraryDocumentHistory');
	listName = TpoLibraryDocumentHistoryComponent.listName;

	listResource = this.documentsService.getLibraryDocumentHistory;

	configureList = (): void => {
		this.globalParams.push('libraryId');
		this.omitParams.push('libraryId');
	};

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		private documentsService: TpoDocumentsService,
	) {
		super(transitionService, stateService);
	}
}
