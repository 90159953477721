import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService as UService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';
import { PagedListComponent } from 'commons/components/list/paged-list.component';
import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';

import { GroupNameModalComponent } from '../../group-name-modal.component';
import { GroupService } from '../group.service';
import { GroupsResourceService } from '../../groups-resource.service';

@Component({
	templateUrl: './group-contacts.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => GroupContactsComponent) },
	],
})
export class GroupContactsComponent extends PagedListComponent {
	static listName = GroupContactsComponent.addName('groupContacts');
	listName = GroupContactsComponent.listName;

	group: any = {};
	contacts: any = [];
	removeContactId: number;
	User: any;
	modalRef: BsModalRef;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public modalService: BsModalService,
		public groupService: GroupService,
		public groupsResource: GroupsResourceService,
		public UserService: UService,
	) {
		super(transitionService, stateService);

		const { groupId } = stateService.params;
		this.group = groupService.get(groupId);

		this.defaultFilters = {
			...this.defaultFilters,
			size: 10,
			groupId,
		};

		this.omitParams = [
			...this.omitParams,
			'groupId',
		];

		this.User = UserService.profile;
	}

	loadList(queryParams) {
		this.contacts.$resolved = false;
		return this.groupsResource.groupContacts.get(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.contacts = data;
				this.noMatches = !data.length && (this.filtersApplied || queryParams.q);
			});
	}

	renameGroup(): void {
		const { id: groupId } = this.group;
		const initialState = {
			title: 'Rename Group',
			onSubmit: (data) => {
				const resource = this.groupsResource.group.rename({ groupId }, data);
				resource.$promise
					.then(({ name }) => {
						this.group.name = name;
						this.stateService.go('.', {}, { reload: true }); // refresh parent component state workaround
					});
				// return resource $promise to process error
				return resource.$promise;
			},
		};
		this.modalService.show(GroupNameModalComponent, { initialState, class: 'modal-sm' });
	}

	removeGroup(): void {
		const { id: groupId } = this.group;
		const { stateService, groupsResource } = this;
		const initialState = {
			title: 'Remove Group',
			confirmText: 'Remove',
			cancelText: 'Cancel',
			message: 'Are you sure you want to remove the group?',
			onConfirm(): void {
				this.resolving = true;
				groupsResource.group.remove({ groupId }).$promise
					.then(
						() => {
							this.modalRef.hide();
							stateService.go('social-compliance.publisher.groups', {}, { reload: true });
						},
						({ data }) => {
							this.resolving = false;
							this.errorText = data;
						},
					);
			},
		};

		this.modalService.show(ConfirmModalComponent, { initialState, class: 'modal-smd' });
	}

	removeContact(contactId): void {
		this.removeContactId = contactId;
	}

	confirmRemoveContact(forceUpdate = false): void {
		const { id: groupId } = this.group;
		const { removeContactId: contactId } = this;

		const params = { groupId, contactId } as any;
		if (forceUpdate) {
			params.forceUnenroll = true;
		}

		this.contacts.$resolved = false;
		this.groupsResource.groupContacts.remove(params).$promise
			.then(() => {
				this.loadList(this.params);
			}).catch((e) => {
				if (e.status !== 400) {
					return;
				}

				this.confirmForceRemoval(e.data && e.data.message);
			});
	}

	confirmForceRemoval(message) {
		this.contacts.$resolved = true;
		const initialState = {
			message,
			onCancel: () => {
				this.modalRef.hide();
				this.cancelRemoveContact();
			},
			onConfirm: () => {
				this.contacts.$resolved = false;
				this.modalRef.hide();
				this.confirmRemoveContact(true);
			},
		};

		this.modalRef = this.modalService.show(ConfirmModalComponent, { initialState, class: 'modal-smd' });
	}

	cancelRemoveContact(): void {
		this.removeContactId = undefined;
	}
}
