import { Component } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';

import { ListComponent } from 'commons/components/list/list.component';

import { ReviewsService } from '../../reviews.service';

@Component({
	templateUrl: './audit-log.component.html',
})
export class ReviewAuditLogComponent extends ListComponent {
	static listName = ReviewAuditLogComponent.addName('reviewAuditLog');
	listName = ReviewAuditLogComponent.listName;

	list: any = [];

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		public reviewService: ReviewsService,
	) {
		super(transitionService, stateService);

		this.defaultFilters = {
			...this.defaultFilters,
			reviewId: stateService.params.reviewId,
		};
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.reviewService.getAuditLog(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}
}
