import { setTheme } from 'ngx-bootstrap/utils';
import { ApplicationRef, DoBootstrap, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { GlobalLogoComponent } from './global-elements/logo/logo.component';

import { services } from './commons/services';
import { RealmModules } from './realm.modules';

import { TpoStatusModule } from 'tpo/status';
import { LenderStatusModule } from 'lender/status';
import { httpInterceptorProviders } from 'commons/services/http/http-interceptors.provider';
import { GlobalErrorHandlerService } from 'commons/services/error/global-error-handler.service';
import { FooterComponent } from 'global-elements/footer/footer.component';
import { GlobalNavbarComponent } from 'global-elements/global-navbar/global-navbar.component';
import { NavigationModule } from 'commons/components/navigation';
import { GlobalSearchComponent } from 'global-elements/global-search/global-search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CompatibilityInjectorService } from 'commons/services/compatibility/compat-injector.service';
import { UIRouterModule } from '@uirouter/angular';
import { AngularJSServiceProviders } from 'angularjs-providers';
import { RealmApp } from 'realm-app.component';
import { ErrorModalsModule } from 'commons/services/error/error-modals.module';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        // UpgradeModule,
        // for basic directives, like ngIf
        CommonModule,
        HttpClientModule,
        TpoStatusModule,
        LenderStatusModule,
        NavigationModule,
        ReactiveFormsModule,
        ErrorModalsModule,
        // import all modules
        ...RealmModules,

        UIRouterModule.forRoot({
            useHash: true,
        }),
    ],

	providers: [
        { provide: '$injector', useClass: CompatibilityInjectorService },
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        ...AngularJSServiceProviders,
		...services,
        httpInterceptorProviders,
	],

	declarations: [
		// ...CommonDirectives,
		// ...CommonComponents,
		GlobalLogoComponent,
        GlobalNavbarComponent,
        GlobalSearchComponent,
        RealmApp,
        FooterComponent,
    ],
})
export class RealmNewApp implements DoBootstrap {
	constructor( ) {
		setTheme('bs3');
	}

    ngDoBootstrap(appRef: ApplicationRef) {
        appRef.bootstrap(RealmApp);
    }
}
