/* eslint-disable comma-dangle */
// import { ExampleComponentsComponent } from 'commons/__components';

import { TwoFactorAuthentication } from 'shared/2FA/2FA.component';
import { RegistrationComponent } from 'login/registration/registration.component';
import { recaptchaResolver } from 'commons/recaptcha/recaptcha.resolver';
import { LoginComponent } from 'login/itself/login.component';
import { SetupPasswordComponent } from 'login/setup-password/setup-password.component';
import { RealmStateDeclaration } from 'routes/sharedRoutes';

export default [
	{
		name: 'login',
		free: true,
		class: 'generic-login',
		url: '/login?back&host_url',
		component: LoginComponent,
	},
    {
        name: 'registration',
        free: true,
        component: RegistrationComponent,
        resolve: [
            recaptchaResolver,
        ],
    },
	{
		name: '2FA',
		url: null, // prevent changing url, so you cannot refresh to this state
		free: true,
		params: {
			credentials: { type: 'json' },
			data: { type: 'json' },
			isSuccess2FA: { type: 'bool', value: null },
		},
		dynamic: true,
		component: TwoFactorAuthentication,
	},
    {
		name: 'setPassword',
		url: '/set-password/:token',
        component: SetupPasswordComponent,
		free: true,
		class: 'home-blue'
	},
] as RealmStateDeclaration[];
