import { Component, OnInit } from '@angular/core';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { APPLICATION_STATUS, LenderApplication, LenderApplicationSummary, LenderApplicationsResourceService } from 'lender/applications/applications.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ChannelsService, Person, TextEntity } from 'lender/clients/$id/channels/channels.service';
import { Validators } from '@angular/forms';
import { DropdownRequiredValidator } from 'commons/validators';
import { UserService } from 'angularjs-providers/user.provider';
import { requestMessageCharsAmount } from 'lender/clients/$id/documents/common/request-document-modal/request-document-modal.form';
import { DatePipe } from '@angular/common';

@Component({
    templateUrl: './app-status.modal.html',
})
export class ApplicationChangeStatusModalComponent implements OnInit {
    public isApproval = false;
    public statusCode: APPLICATION_STATUS;
    public notify = false;
    public resolving = true;
    public applicationSummary!: LenderApplicationSummary;
    public application: LenderApplication;
    public docRequired = false;
    public warning: string | null = null;
    public minDate = new Date();
    public maxDate: Date;
    public values: Partial<{
        optionalStatus: TextEntity[],
        assignee: Person[],
        user: Person[],
        ccUser: Person[],
    }> = {};
    public readonly APPLICATION_STATUS = APPLICATION_STATUS;
    public messageCharsAmount = 4000;
    public form = new RealmFormGroup({
        optionalStatus: new RealmFormControl('optionalStatus', { label: 'Optional Status' }),
        renewalDate: new RealmFormControl('renewalDate', { label: 'Renewal Date', disabled: true }, Validators.required),
        accountExecutive: new RealmFormControl('accountExecutive', { label: 'Assigned' }, Validators.required),
        notify: new RealmFormControl('notify', { label: 'Notify Client', updateOn: 'change' }),
        notificationUserIds: new RealmFormControl('notificationUserIds', { label: 'To: Client', value: [] }, DropdownRequiredValidator),
        ccRealmUserIds: new RealmFormControl('ccRealmUserIds', { label: 'CC: Internal', value: [] }),
        notificationMessage: new RealmFormControl('notificationMessage', { label: 'Message', updateOn: 'change' }, Validators.required),
    });
    public updateApplication: (application: LenderApplication) => void;

    get applicationId(): number {
        return this.applicationSummary?.applicationId;
    }

    //DI
    constructor(
        protected readonly applicationService: LenderApplicationsResourceService,
        protected readonly channelsService: ChannelsService,
        protected readonly userService: UserService,
        public readonly modalRef: BsModalRef,
    ) {
    }

    async ngOnInit() {
        this.isApproval = this.statusCode == APPLICATION_STATUS.APPROVED;
        const messageTpl = (str: TemplateStringsArray) => `You are about to change the status of this application to <b>${str[0]}</b>.<br/>`
            + `<span class="text-danger">This action <b>CANNOT BE UNDONE</b></span>`;
        switch (this.statusCode) {
            case APPLICATION_STATUS.CANCELLED:
                this.warning = messageTpl`Cancelled`;
                break;
            case APPLICATION_STATUS.DECLINED:
                this.warning = messageTpl`Declined`;
                break;
        }
        try {
            [
                this.application,
                this.values.optionalStatus,
                this.values.assignee,
                this.values.user,
                this.values.ccUser,
                this.docRequired,
            ] = await Promise.all([
                this.applicationService.getApplication(this.applicationId).toPromise(),
                this.applicationService.filters.optionalStatus().$promise,
                this.channelsService.getAccountExecutives(this.applicationSummary.channel.id).toPromise(),
                this.applicationService.getNotificationUsers(this.applicationSummary.tpoId).toPromise(),
                this.applicationService.getApplicationUsers().toPromise(),
                this.isApproval ? this.applicationService.getRequestedDocuments(this.applicationId).toPromise() : Promise.resolve(false),
            ]);
        } catch (e) {
            this.modalRef.hide();
            throw e;
        }
        this.resolving = false;
        this.initForm();
    }

    private initForm() {
        const { accountExecutive: { id, name: fullName }, optionalStatus, nextRenewalDate, maxRenewalDate } = this.application;
        this.maxDate = new Date(maxRenewalDate);
        const renewalDate = new Date(nextRenewalDate);
        const accountExecutive = { id, fullName } as Person;
        const notificationMessage = this.getMessage();
        this.form.get('notify').valueChanges.subscribe( this.changeNotify);
        if (this.isApproval) {
            this.form.get('renewalDate').enable();
        }
        this.form.reset({
            optionalStatus,
            renewalDate,
            accountExecutive,
            notify: false,
            notificationUserIds: [],
            ccRealmUserIds: [],
            notificationMessage,
        });
    }

    private getMessage() {
        const { organization: { name } } = this.userService.profile;
        const ending = ` If you have any questions, please contact your Account Executive.\n\nThank you,\n${name}`;
        switch (this.statusCode) {
            case APPLICATION_STATUS.APPROVED:
                return `${name} has approved your application.${ending}`;
            case APPLICATION_STATUS.CANCELLED:
                return `${name} has cancelled your application.${ending}`;
            case APPLICATION_STATUS.DECLINED:
                return `${name} has declined your application.${ending}`;
            case APPLICATION_STATUS.DOC_REQUIRED:
                return `${name} has requested an update to your document requirement(s). Please log into your account at http://www.comergence.com and refer to your Home page for any outstanding items.${ending}`;
            default:
                return '';
        }
    }

    private changeNotify = (notify: boolean) => {
        const fields: string[] = ['notificationUserIds', 'ccRealmUserIds', 'notificationMessage'];
        this.notify = notify;
        if (notify) {
            this.getFields(fields).forEach(field => field.enable());
        } else {
            this.getFields(fields).forEach(field => field.disable());
        }
    }

    getCharsCount = (): number => Math.max(0, (requestMessageCharsAmount - (this.form.value?.notificationMessage?.length ?? 0)));

    getFields = (names: string[]): RealmFormControl[] => names.map(name => this.form.get(name)) as RealmFormControl[];

    async save() {
        this.resolving = true;
        const { renewalDate: inputRenewalDate, ...form } = this.form.value
        const renewalDate = inputRenewalDate ? new DatePipe('en-US').transform(inputRenewalDate, 'MM/dd/yyyy') : null;
        try {
            this.application = await this.applicationService.saveApplication(this.applicationId, {
                ...this.application,
                ...form,
                applicationStatus: { id: this.statusCode },
                renewalDate,
            }).toPromise();
            this.updateApplication(this.application);
            this.modalRef.hide();
        } catch ({error}) {
            this.form.setServerError(error);
        }
        this.resolving = false;
   }
}
