<div class="review-header-wrapper flex-row gap-m">
    <h2>Warehouse</h2>
    <section-review  [section]="sectionType" class="flex-grow"></section-review>
</div>
<div class="rows-bordered remote-resource" [class.resolved]="resolved">
    <div class="row-fluid header bordered">
        <div class="col-sm-a">Company</div>
        <div class="col-sm-a">Contact Name</div>
        <div class="col-sm-a">Email</div>
        <div class="col-sm-a">Line Amount, $</div>
        <div class="col-sm-a">Sell Loans</div>
        <div class="col-sm-a">Start Date</div>
        <div class="col-sm-a">End Date</div>
        <div class="col-f-100 text-right button-holder">
            <button *ngIf="userCanAddEdit" type="button" class="btn btn-primary" (click)="addItem()"><span class="glyphicon glyphicon-plus small-icon"></span>Add</button>
        </div>
    </div>
    <warehouses-item class="row-fluid"
                   *ngFor="let item of list"
                   [item]="item"
                   [accountInformation]="lenderInfo"
                   (onNewItemCancel)="cancelNewItem($event)"
                   [userCanAddEdit]="userCanAddEdit"
                   (afterSave)="sectionReview.updateReview()"
    ></warehouses-item>
    <div class="row-fluid" *ngIf="!list?.length">
        <div class="col-md-12 text-bigger text-center text-muted">No record added</div>
    </div>
</div>

