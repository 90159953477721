import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { Observable } from 'rxjs';

import { AccountExecutiveDetails, ChannelsSummary } from './channels-summary.interface';
import { TPOChannelEligibilityDateUpdate } from 'lender/clients/channels/tpo-channel-update.interface';
import { AccountIdentifier, AccountInformationService } from 'shared/account/company-information/account-information.service';
import { RealmHttpClient } from 'commons/services/http';
import { Transitions } from 'lender/clients/clients.interface';
import { Person } from 'lender/clients/$id/channels/channels.service';
import { tap } from 'rxjs/operators';

export type EstablishRSData = {
    accountExecutiveId: number;
    approvalRenewalDate: string
    approvalStatus: Person;
};

declare let apiPath: string;

@Injectable()
export class ChannelCardService {
    constructor(
        private readonly http: RealmHttpClient,
        private readonly accountInformationService :AccountInformationService,
    ) {
    }

    getChannels<T extends ChannelsSummary = ChannelsSummary>(params: AccountIdentifier, investorId: number): Observable<T[]> {
        return this.http.request<T[]>(
            'GET',
            `${apiPath}/lenders/${investorId}/tpo-channel-details`,
            { nmlsId: params.nmlsId, tpoId: params.tpoId },
        );
    }

    getChannel<T extends ChannelsSummary = ChannelsSummary>(
        params: AccountIdentifier,
        investorId: number,
        channelId: number,
    ): Observable<T> {
        return this.http.request(
            'GET',
            `${apiPath}/investors/${investorId}/tpo-channel-details/${channelId}`,
            params,
        );
    }

    getAccountExecutives(channelId: number): Observable<AccountExecutiveDetails[]> {
        return this.http.request<any>(
            'GET',
            `${apiPath}/organization-channels/${channelId}/account-executives`,
        );
    }

    getStatusTransitions(): Observable<Transitions> {
        return this.http.request<any>(
            'GET',
            `${apiPath}/applications/approval-statuses/transitions`,
        );
    }

    saveChannelFields<T extends ChannelsSummary>(
        channelId: number,
        tpoId: number,
        investorId: number,
        fieldName: string,
        fieldValue: string
    ): Observable<T> {
        let params = [
            {
                op: 'replace',
                path: `/${fieldName}`,
                value: fieldValue,
            }
        ];
        return this.http.request<T>(
            'PATCH',
            `${apiPath}/investors/${investorId}/tpos/${tpoId}/tpo-channel-details/${channelId}`,
            null,
            params,
        );
    }

    updateEligibilityDate(
        investorId: number,
        tpoId: number,
        channelId: number,
        rawEligibilityDate: string
    ): Observable<ChannelsSummary> {
        const eligibilityDateUpdate: TPOChannelEligibilityDateUpdate = {
            rawEligibilityDate,
        };

        return this.http.request<ChannelsSummary>(
            'PUT',
            `${apiPath}/investors/${investorId}/tpos/${tpoId}/tpo-channel-details/${channelId}/eligibility-date`,
            null,
            eligibilityDateUpdate
        );
    }

    establishRelationship (
        investorId: number,
        tpoId: number,
        channelId: number,
        relationshipData: EstablishRSData,
    ): Observable<ChannelsSummary> {
        return this.http.request<ChannelsSummary>(
            'POST',
            `${apiPath}/investors/${investorId}/tpos/${tpoId}/tpo-channel-details/${channelId}/establish-relationship`,
            null,
            relationshipData
        ).pipe(
            tap(() => {
                // Update badges after channel save
                this.accountInformationService.updateBadges();
            })
        );
    }

    saveAccountExecutiveForAllLocations(
        assigned: AccountExecutiveDetails,
        channelId: number,
        investorId: number,
        tpoId: number
    ) {
        return this.http.request(
            'PUT',
            `${apiPath}/lenders/${investorId}/tpos/${tpoId}/channels/${channelId}/location-statuses/assigned`,
            null,
            assigned,
        )
    }

    saveStatusReason<StatusReason>(
        channelId: number,
        tpoId: number,
        statusReason: StatusReason,
    ): Observable<StatusReason> {
        return this.http.request(
            'PUT',
            `${apiPath}/organization-channels/${channelId}/tpos/${tpoId}/status-reason`,
            null,
            statusReason
        );
    }

    // Utilities

    formatDate(inputRenewalDate: Date): string {
        return new DatePipe('en-US').transform(inputRenewalDate, 'MM/dd/yyyy')
    }
}
