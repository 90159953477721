import { find, merge, pick } from 'lodash';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, Optional } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UIRouterGlobals } from '@uirouter/core';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';
import { PinValidator } from 'commons/validators';

import { RolesResourceService as RResourceService } from 'shared/roles/roles-resource.service';
import { UsersResourceService as UResourceService } from 'shared/users/users-resource.service';
import { ChangeOwnPasswordModalComponent } from 'commons/components/modals/change-own-password-modal/change-own-password-modal.component';
import { hasContactPermission } from 'tpo/contacts/contact-permissions';
import { TpoContactDetailsComponent } from 'tpo/people/contacts/details/tpo-contact-details.component';
import { CcmContactDetailsComponent } from 'comergence/new-contacts/details/ccm-contact-details.component';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { SharedContactDetailsComponent } from 'shared/new-contacts/shared-contact-details.component';
import { FullTpoContact } from 'shared/new-contacts/contacts.interface';
import { GrantAccessModal } from 'shared/new-contacts/settings/grant-access.modal';

@Component({
    templateUrl: './contact-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SharedContactSettingsComponent implements OnDestroy {
    StatusColors = {
        L: 'label-warning',
        A: 'label-success',
        I: 'label-default',
        R: 'label-danger',
    };
    USER = {
        LOCKED: 'L',
        ACTIVE: 'A',
        INACTIVE: 'I',
        REMOVED: 'R',
    };
    contact!: FullTpoContact;
    organizationId: number;
    editing: boolean;
    initialData: object = {};
    User: UserProfile;
    settingsForm: RealmFormGroup = new RealmFormGroup({});
    private confirmDialogModalRef: BsModalRef;
    private changeOwnPasswordModalRef: BsModalRef;
    roles: any;
    userParams: { id };
    isOwnContact: boolean;
    showPin: boolean = false;
    saving: boolean = false;
    noUser: boolean = false;
    testPin: string;
    pinUser;
    currentUser;
    currentRole;
    destroyed$: Subject<void> = new Subject<void>();

    // TODO: is it an error?
    // we have no isNew option on component,
    // but use it in template
    isNew: boolean;

    public Dialogs = {
        deactivate: {
            title: 'Deactivate User',
            message: 'Are you sure you want to deactivate the user?',
            confirmText: 'Deactivate',
            onConfirm: async () => {
                this.confirmDialogModalRef.content.resolving = true;
                try {
                    await this.usersResourceService.deactivate(this.userParams, () => {
                        this.confirmDialogModalRef.hide();
                        this.contact.userStatus = {
                            id: this.USER.INACTIVE,
                            name: 'Inactive',
                        };
                    }, ({ data: response }) => {
                        this.confirmDialogModalRef.content.errorText = response.message;
                        this.confirmDialogModalRef.content.resolving = false;
                    }).$promise;
                } catch (e) {
                }
                this._cd.markForCheck();
            },
        },
        activate: {
            title: 'Activate User',
            message: 'Are you sure you want to activate the user?',
            confirmText: 'Activate',
            onConfirm: async () => {
                this.confirmDialogModalRef.content.resolving = true;
                try {
                    await this.usersResourceService.activate(this.userParams, () => {
                        this.confirmDialogModalRef.hide();
                        this.contact.userStatus = {
                            id: this.USER.ACTIVE,
                            name: 'Active',
                        };
                    }, ({ data: response }) => {
                        this.confirmDialogModalRef.content.errorText = response.message;
                        this.confirmDialogModalRef.content.resolving = false;
                    }).$promise;
                } catch (e) {
                }
                this._cd.markForCheck();
            },
        },
        username: {
            title: 'Resend Username',
            message: 'Are you sure you want to resend username?',
            confirmText: 'Resend',
            onConfirm: async () => {
                this.confirmDialogModalRef.content.resolving = true;
                try {
                    await this.usersResourceService.resendUsername(this.userParams, () => {
                        this.confirmDialogModalRef.hide();
                    }, ({ data: response }) => {
                        this.confirmDialogModalRef.content.errorText = response.message;
                        this.confirmDialogModalRef.content.resolving = false;
                    }).$promise;
                } catch (e) {
                }
                this._cd.markForCheck();
            },
        },
        password: {
            title: 'Reset Password',
            message: 'Are you sure you want to reset password?',
            confirmText: 'Reset',
            onConfirm: async () => {
                this.confirmDialogModalRef.content.resolving = true;
                try {
                    await this.usersResourceService.resetPassword(this.userParams, () => {
                        this.confirmDialogModalRef.hide();
                    }, ({ data: response }) => {
                        this.confirmDialogModalRef.content.errorText = response.message;
                        this.confirmDialogModalRef.content.resolving = false;
                    }).$promise;
                } catch (e) {
                }
                this._cd.markForCheck();
            },
        },
        unlock: {
            title: 'Unlock User',
            message: 'Are you about to unlock user <b>%NAME</b>?',
            confirmText: 'Unlock',
            onConfirm: async () => {
                this.confirmDialogModalRef.content.resolving = true;
                try {
                    await this.usersResourceService.unlock(this.userParams, () => {
                        this.contact.userStatus = {
                            id: this.USER.ACTIVE,
                            name: 'Active',
                        };
                        this.confirmDialogModalRef.hide();
                    }, ({ data: response }) => {
                        this.confirmDialogModalRef.content.errorText = response.message;
                        this.confirmDialogModalRef.content.resolving = false;
                    }).$promise;
                } catch (e) {
                }
                this._cd.markForCheck();
            },
        },
    };
    contactDetails: SharedContactDetailsComponent;

    constructor(
        @Optional() tpoContactDetails: TpoContactDetailsComponent,
        @Optional() ccmContactDetails: CcmContactDetailsComponent,
        private globals: UIRouterGlobals,
        private userService: UserService,
        private usersResourceService: UResourceService,
        private rolesResourceService: RResourceService,
        private modalService: BsModalService,
        private _cd: ChangeDetectorRef,
    ) {
        this.User = userService.profile;
        const contactDetails = this.contactDetails = (tpoContactDetails || ccmContactDetails) as SharedContactDetailsComponent;
        combineLatest([contactDetails.contact, contactDetails.company]).pipe(
            takeUntil(this.destroyed$),
        ).subscribe(([contact, { organizationId }]) => {
            this.organizationId = organizationId;
            this.contact = contact;
            this.initContact();
        });
    }

    hasPermission = (permission) => hasContactPermission(this.User, permission);

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    updatePermissions = async (updateUser = false) => {
        const { organizationId, currentUser: { role: { id: roleId } } } = this;
        this.currentRole = await this.rolesResourceService.get({ roleId, organizationId });
        await this.currentRole.$promise;
        if (updateUser) {
            const profile = await this.userService.getUser().$promise;
            this.User = profile;
        }
        this._cd.detectChanges();
    };

    loadRoles(): void {
        const { organizationId, contact: { contactId } } = this;
        if (this.hasPermission('MANAGE_USER')) {
            this.roles = this.rolesResourceService.listNonCounted({
                activeOnly: true,
                contactId,
                organizationId,
            });
        } else {
            this.roles = [];
            this.roles.$resolved = true;
            Object.defineProperty(this.roles, '$promise', { writable: true, enumerable: false, value: Promise.resolve(this.roles) });
        }

    }

    initContact = async () => {
        const { contact: { realmUserId: id } } = this;
        this.noUser = !id;
        if (id) {
            this.isOwnContact = this.User.id === id;
            this.userParams = { id };
            this.loadRoles();
            this.currentUser = this.usersResourceService.get(this.userParams);
            await Promise.all([this.currentUser.$promise, this.roles.$promise]);
            this.updatePermissions();
        }
        this._cd.markForCheck();
    };

    cancelEdit() {
        this.editing = false;
        if (this.pinUser) {
            delete this.pinUser.valid;
        }
    }

    initEditForm() {
        this.initialData = { ...this.currentUser };
        this.editing = true;
        const form = this.settingsForm = new RealmFormGroup({});
        const { role: { id }, userName, pin } = this.currentUser;

        form.addControl('id', new RealmFormControl(
            'id',
            { label: 'Role', value: id },
            Validators.required,
        ));

        if (this.isOwnContact) {
            form.addControl('userName', new RealmFormControl(
                'userName',
                { label: 'Username', value: userName },
                Validators.required,
            ));

            form.addControl('pin', new RealmFormControl(
                'pin',
                { label: 'Security Pin Code', value: pin },
                Validators.compose([
                    Validators.required,
                    PinValidator(),
                ]),
            ));
        }
    }

    public confirmDialog(type): void {
        const initialState = { ...this.Dialogs[type] };
        initialState.message = initialState.message.replace('%NAME', this.contact.fullName);
        this.confirmDialogModalRef = this.modalService.show(ConfirmModalComponent, { initialState, class: 'modal-smd modal-edit modal-new' });
    }

    async triggerSave() {
        if (this.saving) {
            return;
        }

        this.saving = true;
        // pick {id, name} from role
        merge(this.currentUser.role, pick(find(this.roles, { id: this.settingsForm.value.id }), ['id', 'name']));

        if (this.isOwnContact) {
            this.currentUser.userName = this.settingsForm.value.userName;
            this.currentUser.pin = this.settingsForm.value.pin;
        }

        this.currentUser.$resolved = false;

        const method = this.isOwnContact ? 'save' : 'setRole';
        const data = this.isOwnContact ? this.currentUser : this.currentUser.role;

        await this.usersResourceService[method](this.userParams, data, () => {
            this.editing = false;
            this.saving = false;
            this.showPin = false;
            this.initialData = { ...this.currentUser };
            this.currentUser.$resolved = true;
            this.updatePermissions(this.isOwnContact);
        }, ({ data: response }) => {
            this.settingsForm.setServerError(response);
            merge(this.currentUser, this.initialData);
            this.currentUser.$resolved = true;
            this.saving = false;
        }).$promise;
        this._cd.markForCheck();
    }

    togglePin() {
        this.showPin = !this.showPin;
    }

    checkPin(value) {
        if (this.pinUser) {
            delete this.pinUser.valid;
        }
        if (value.length < 4) {
            return;
        } // skip check while inputting


        this.pinUser = this.usersResourceService.verifyPin(this.userParams, { securityPinCode: value }, () => {
            this.pinUser.valid = 'true';
            this._cd.markForCheck();
        }, () => {
            this.pinUser.valid = 'false';
            this._cd.markForCheck();
        });
    }

    changeOwnPasswordModal() {
        const initialState = { userProfile: this.currentUser };
        this.changeOwnPasswordModalRef = this.modalService.show(ChangeOwnPasswordModalComponent, {
            initialState,
            class: 'modal-md modal-edit modal-new',
        });
    }

    get tpoId(): number {
        const { isTpo, organization: { id } } = this.User;
        return isTpo ? id : this.globals.params.id;
    }

    async grantAccess() {
        if (!this.roles) {
            this.loadRoles();
        }
        const { roles, contact, contactDetails, tpoId } = this;
        const initialState = { roles, contact, contactDetails, tpoId };
        this.changeOwnPasswordModalRef = this.modalService.show(GrantAccessModal, {
            initialState,
            class: 'modal-smd modal-new',
        });
    }
}
