import { Component, forwardRef } from '@angular/core';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';

import { ListComponent } from 'commons/components/list';
import { HistoryDocumentListComponent } from 'shared/documents';

import { ComergenceHistoryDocument } from '../../comergence-documents.interface';
import { ComergenceDocumentsService } from '../../comergence-documents.service';


@Component({
	templateUrl: '../../../../shared/documents/document-history/document-history.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ComergenceDocumentHistoryComponent) },
	],
})
export class ComergenceDocumentHistoryComponent extends HistoryDocumentListComponent<ComergenceHistoryDocument> {
	static listName = ComergenceDocumentHistoryComponent.addName('comergenceDocumentHistory');
	listName = ComergenceDocumentHistoryComponent.listName;

    // Override
	listResource;

	constructor(
            transitionService: TransitionService,
            stateService: StateService,
            private readonly uiRouterGlobals: UIRouterGlobals,
            private readonly documentsService: ComergenceDocumentsService) {
		super(transitionService, stateService);

        if (this.uiRouterGlobals.params.documentId) {
            this.listResource = this.documentsService.getLibraryDocumentHistory;
        } else {
            this.listResource = this.documentsService.getFromClientLibraryDocumentHistory;
        }
	}

    // Override
	configureList = (): void => {
		if (this.uiRouterGlobals.params.documentId) {
			this.globalParams.push('documentId');
			this.omitParams.push('documentId');
		} else {
			this.globalParams.push('libraryId');
			this.omitParams.push('libraryId');
		}
	};

    Override
	async loadList(queryParams) {
        await super.loadList(queryParams);

        this.list.forEach((historyDocument: ComergenceHistoryDocument) => {
            historyDocument.downloadLink = `/api/rest/v2/library-document/${historyDocument.documentId}/history/${historyDocument.libraryId}/uploadedDocument`;
        });
    }
}
