<div class="company-alerted-listings">
	<div class="gray-head">
		<h2>Alerts</h2>
	</div>

	<div class="remote-resource" [class.resolved]="list.$resolved">
		<div class="rows-bordered">
			<div class="row-fluid header">
				<div class="col-sm-7">Listing Name</div>
				<div class="col-sm-3">Alerted Date</div>
				<div class="col-sm-2"></div>
			</div>

			<a
				*ngFor="let item of list"
				class="row-fluid clickable"
				uiSref="^.company.:listingGroupId.alerts"
				[uiParams]="{ listingGroupId: item.groupId }"
			>
				<div class="col-sm-7 va-m">
					<div class="flex-row gap-m">
						<div class="list-item-image" [bgImage]="item.coverImage"></div>
						<div class="flex">
							<div>{{item.businessName}}</div>
							<div class="text-muted">{{item | address}}</div>
							<div class="text-muted" *ngIf="item.phoneNumber">{{item.phoneNumber | tel}}</div>
							<div class="text-muted" *ngIf="item.website">{{item.website}}</div>
						</div>
					</div>
				</div>
				<div class="col-sm-3 va-m">
					{{item.alertedDate | date:'MM/dd/yyyy'}}
				</div>
				<div class="col-sm-2 text-right va-m">
					<sm-network-icon [config]="item"></sm-network-icon>
				</div>
			</a>

			<div class="row-fluid" *ngIf="!list.length">
				<div class="col-sm-12 text-center va-m text-bigger text-muted">No alerts</div>
			</div>
		</div>

		<br/>

		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</div>
