import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';
import { FormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { DropdownRequiredValidator } from 'commons/validators';

import { TaskNotificationService } from '../task-notification.service';

const CcEmailsValidator = (control: RealmFormControl): { [key: string]: any } | null => (
	(
		control.value
			?.some((email) => (
				Validators.email(
					new FormControl(email)
				)
			))
	) ?
		{
			ccEmails: {
				value: control.value,
				message: 'Please enter valid emails',
			},
		} :
		null
);

@Component({
	templateUrl: './task-notification-modal.component.html',
})
export class TaskNotificationModalComponent implements OnInit {
	User: UserProfile
	form: RealmFormGroup;
	emails: any;
	resolved = true;
	isAdding: boolean = false;

	// Inputs
	tpoId: number;
	nmlsId: number;
	contactId: number;
	contactName: string;

	@ViewChild('emailInput') emailInput: ElementRef;

	get userName(): string {
		return `${this.User.firstName} ${this.User.lastName}`;
	}

	get userCompany(): string {
		return this.User.organization.name;
	}

	get toEmailsControl(): RealmFormControl {
		return this.form.get('toEmails') as RealmFormControl;
	}

	get ccEmailsControl(): RealmFormControl {
		return this.form.get('ccEmails') as RealmFormControl;
	}

	get messageControl(): RealmFormControl {
		return this.form.get('message') as RealmFormControl;
	}

	constructor(
		{ profile: User }: UserService,
		public stateService: StateService,
		public taskNotificationService: TaskNotificationService,
		public modalRef: BsModalRef,
	) {
		this.User = User;
	}

	ngOnInit(): void {
		const {
			tpoId,
			contactId,
		} = this;
		this.emails = this.taskNotificationService.emails({
			tpoId,
			contactId,
		});

		this.initForm();
	}

	initForm(): void {
		this.form = new RealmFormGroup({
			toEmails: new RealmFormControl(
				'toEmails',
				{
					label: 'Email',
					updateOn: 'change',
				},
				DropdownRequiredValidator,
			),
			ccEmails: new RealmFormControl(
				'ccEmails',
				{
					label: 'Cc',
					updateOn: 'change',
				},
				CcEmailsValidator,
			),
			message: new RealmFormControl(
				'message',
				{
					label: 'Message',
					updateOn: 'change',
				},
				Validators.maxLength(2000),
			),
		});
	}

	toggleAddEmail(): void {
		const toEmails = this.form.get('toEmails')
		this.isAdding = !this.isAdding;
		toEmails.setValidators(
			this.isAdding ?
				[
					Validators.email,
					Validators.required,
				] :
				DropdownRequiredValidator
		);
		toEmails.reset(this.isAdding ? '' : []);
		if (this.isAdding) {
			setTimeout(() => {
				this.emailInput.nativeElement.focus();
			}, 1);
		}
	}

	submit(): void {
		const {
			tpoId,
			contactId,
		} = this;
		const {
			toEmails,
			ccEmails,
			message,
		} = this.form.value;

		this.resolved = false;
		this.taskNotificationService.notify(
			{
				tpoId,
				contactId,
			},
			{
				toEmails: (
					this.isAdding ?
						toEmails :
						toEmails.join(',')
				),
				ccEmails: ccEmails?.join(',') || null,
				message: message || null,
			}
		).$promise
			.then(
				() => {
					this.close();
				},
				({ data }) => {
					this.form.setServerError(data);
				}
			)
			.finally(() => {
				this.resolved = true;
			})
	}

	close(): void {
		this.modalRef.hide();
	}
}
