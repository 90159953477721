import { Component, OnDestroy } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ListComponent } from 'commons/components/list';
import { SmConfirmModalComponent } from 'commons/components/modals';

import {
	PublishingPermissionsResourceService,
	IPermissionsResourceService,
} from 'tpo/social-compliance/publishing-permissions';

@Component({
	templateUrl: './publisher-requests.component.html',
})
export class PublisherRequestsComponent extends ListComponent implements OnDestroy {
	static listName = PublisherRequestsComponent.addName('publisherRequests');
	listName = PublisherRequestsComponent.listName;
	permissionsResource: IPermissionsResourceService;
	modalRef: BsModalRef;
	list: any = [];

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		publishingPermissionsResourceService: PublishingPermissionsResourceService,
		public modalService: BsModalService,
	) {
		super(
			transitionService,
			stateService,
		);
		this.permissionsResource = publishingPermissionsResourceService.individual;
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
		this.modalRef?.hide();
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.permissionsResource.requests.list(this.getClearParams(queryParams)).$promise
			.then((result) => {
				this.list = result;
			});
	}

	confirmAccess(
		account,
		positive: boolean = false
	): void {
		const {
			id,
			linkName,
			requestorName,
		} = account;
		const action = positive ? 'accept' : 'decline';
		const initialState = {
			title: `${action.toUpperCase()} Request`,
			message: positive
				? `By accepting this request you are granting <strong>${requestorName}</strong>? to post on your behalf for this account. You can revoke access at any time.`
				: `Are you sure you want to decline this Publisher Request for <strong>${requestorName}</strong> for this account?`,
			confirmText: action.toUpperCase(),
			account: {
				...account,
				name: linkName,
			},
			onConfirm: (): void => {
				const modalScope = this.modalRef.content;
				modalScope.resolving = true;
				modalScope.errorText = null;
				this.permissionsResource.requests[action]({ id }).$promise
					.then(
						() => {
							this.modalRef.hide();
							this.loadList(this.getClearParams(this.params));
						},
						({ status, data }) => {
							// if (status === 404) {
							// 	this.modalRef.hide();
							// }

							modalScope.resolving = false;
							modalScope.errorText = data.message;
						}
					);
			},
		};

		this.modalRef = this.modalService.show(SmConfirmModalComponent, { initialState, class: 'modal-smd modal-new' });
	}
}
