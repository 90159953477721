<div class="section-header flex-head">
	<h2 class="col-fit">Templates</h2>
	<div class="col-min" *ngIf="canManageCampaigns">
		<button type="button" class="btn btn-primary" (click)="addTemplate.toggle()" [disabled]="!templates.$resolved || addTemplate.show">
			<span class="glyphicon glyphicon-plus small-icon"></span>
			Add
		</button>
	</div>
</div>

<notification></notification>

<form-errors [form]="addTemplate.form"></form-errors>

<form-errors [form]="removeTemplate.form"></form-errors>

<section class="rows-bordered" [ngClass]="{ 'remote-resource': templates, 'resolved': templates.$resolved }">
	<div class="row-fluid header">
		<div class="col-md-5">
			<search-list placeholder="Search"></search-list>
		</div>
		<div class="col-md-a" [ngClass]="getSortClass('updatedDate')" (click)="setSort('updatedDate')"><b class="two-liners">Last Updated Date</b></div>
		<div class="col-md-a" [ngClass]="getSortClass('updatedBy')" (click)="setSort('updatedBy')"><b class="two-liners">Updated By</b></div>
		<div class="col-md-2"></div>
	</div>

	<form class="row-fluid highlighted no-border remote-resource" *ngIf="addTemplate.show"
		  [ngClass]="{ 'resolved': addTemplate.resolved}"
		  [formGroup]="addTemplate.form"
		  (onValidSubmit)="addTemplate.submit()"
	>
		<div class="col-md-5 va-m" [ngClass]="{ 'remote-resource': addTemplate.templates, 'resolved': addTemplate.templates.$resolved }">
			<ng-select
				*ngIf="addTemplate.templates.$resolved"
				formControlName="templateIds"
				[items]="addTemplate.templates"
				bindValue="id"
				bindLabel="summary"
				[searchable]="true"
				[multiple]="true"
				[closeOnSelect]="false"
				placeholder="Select Templates">

				<ng-template ng-footer-tmp *ngIf="canManageTemplates">
					<div class="ng-dropdown-footer-items">
						<div class="ng-option" (click)="addTemplate.new()">
							<span class="text-big text-light">+</span>
							Create Template
						</div>
					</div>
				</ng-template>

			</ng-select>
		</div>
		<div class="col-md-7 text-right">
			<button type="submit" class="btn btn-primary" [disabled]="!addTemplate.form.valid">Save</button>
			<button type="button" class="btn btn-default" (click)="addTemplate.toggle()">Cancel</button>
		</div>
	</form>

	<div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
		<div class="col-md-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</div>
	</div>

	<div class="row-fluid" [ngClass]="{ 'remote-resource': item.id === removeTemplate.id, 'resolved': removeTemplate.resolved }" *ngFor="let item of templates">
		<div class="col-md-12" *ngIf="!(item.id === removeTemplate.id && removeTemplate.message)">
			<div class="row-fluid">
				<div class="col-md-5 va-m">
					<a uiSref="social-compliance.publisher.library.templates.:templateId" [uiParams]="{ templateId: item.id }">{{item.summary}}</a>
				</div>
				<div class="col-md-a va-m">{{(item.updatedDate | date : 'MM/dd/yyyy h:mm a') || '&ndash;'}}</div>
				<div class="col-md-a va-m">{{item.updatedBy || '&ndash;'}}</div>
				<div class="col-md-2 text-right">
					<button type="button" class="btn btn-primary" (click)="removeTemplate.request(item.id)" *ngIf="canManageCampaigns">Remove</button>
				</div>
			</div>
		</div>
		<div class="col-md-12" *ngIf="item.id === removeTemplate.id && removeTemplate.message">
			<form class="row-fluid"
				  [formGroup]="removeTemplate.form"
				  (onValidSubmit)="removeTemplate.submit()"
			>
				<div class="col-md-8 va-m">
					{{removeTemplate.message}}
				</div>
				<div class="col-md-4 text-right">
					<button type="submit" class="btn btn-primary" [disabled]="!removeTemplate.allowed">Remove</button>
					<button type="button" class="btn btn-default" (click)="removeTemplate.reset()">Cancel</button>
				</div>
			</form>
		</div>
	</div>

	<div class="row-fluid" *ngIf="noMatches">
		<div class="col-md-12 text-center text-bigger text-muted">No matches found</div>
	</div>

	<div class="row-fluid" *ngIf="!templates.length && !noMatches">
		<div class="col-md-12 text-center text-bigger text-muted">No templates</div>
	</div>

	<br />

	<new-paging class="no-border" [(list)]="templates" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
</section>
