import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { AuditChecklistComponent } from './checklist.component';
import { RegulationsModalComponent } from './modals';
import { ShowRegulationsIconComponent } from './show-regulations-icon';

import { AuditChecklistService } from './checklist.service';

@NgModule({
	imports: [
		CommonModule,
		DirectivesModule,
		ComponentsModule,
	],
	declarations: [
		AuditChecklistComponent,
		RegulationsModalComponent,
		ShowRegulationsIconComponent,
	],
	entryComponents: [
		AuditChecklistComponent,
		RegulationsModalComponent,
		ShowRegulationsIconComponent,
	],
	exports: [
		ShowRegulationsIconComponent,
	],
	providers: [
		AuditChecklistService,
	],
})
export class AuditChecklistModule {}
