import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

// Services
import { GroupsResourceService } from './groups-resource.service';

// Components
import { GroupsListComponent } from './list';
import {
	GroupComponent,
	GroupContactsComponent,
	GroupContactsManageComponent,
	GroupService,
	GroupSharingComponent,
	GroupSharingCampaignsComponent,
	GroupSharingTemplatesComponentComponent,
} from './$groupId';
import { GroupNameModalComponent } from './group-name-modal.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BsDropdownModule.forRoot(),
		DirectivesModule,
		ComponentsModule,
	],
	declarations: [
		GroupsListComponent,
		GroupComponent,
		GroupContactsComponent,
		GroupNameModalComponent,
		GroupContactsManageComponent,
		GroupSharingComponent,
		GroupSharingCampaignsComponent,
		GroupSharingTemplatesComponentComponent,
	],
	entryComponents: [
		GroupsListComponent,
		GroupComponent,
		GroupContactsComponent,
		GroupNameModalComponent,
		GroupContactsManageComponent,
		GroupSharingComponent,
		GroupSharingCampaignsComponent,
		GroupSharingTemplatesComponentComponent,
	],
	providers: [
		GroupsResourceService,
		GroupService,
	],
})
export class GroupsModule {}
