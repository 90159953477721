import { Component, forwardRef, OnInit } from '@angular/core';
import { StateService, TransitionService, UIRouter } from '@uirouter/core';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray, NgResourceObject } from 'commons/interfaces';
import { ListComponent, PagedListComponent } from 'commons/components/list';

import { TpoDocument } from 'tpo/documents/documents.interface';
import { TpoDocumentsService } from 'tpo/documents/documents.service';
import { TpoApplicationService } from '../../application.service';

interface Params {
	channelId: number;
}

@Component({
	selector: 'application-documents',
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ApplicationDocumentListComponent) },
	],
})
export class ApplicationDocumentListComponent extends PagedListComponent implements OnInit {
	static listName = ApplicationDocumentListComponent.addName('applicationDocuments');
	listName = ApplicationDocumentListComponent.listName;
	list: NgResourceArray<TpoDocument> = [];
	params: Params;
	user: UserProfile;
	application: NgResourceObject<any> = {};

	constructor(
		uiRouter: UIRouter,
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		private applicationService: TpoApplicationService,
		private documentsService: TpoDocumentsService,
	) {
		super(transitionService, stateService);

		const {
			id: channelId,
		} = uiRouter.globals.params;
		this.params = {
			channelId,
		};
		this.user = userService.profile;
	}

	ngOnInit() {
		// TODO
		// this.fetchApplication();
		this.configureList();

		super.ngOnInit();
	}

	async fetchApplication(): Promise<void> {
		this.application.$resolved = false;
		try {
			const { channelId } = this.params;
			this.application = await this.applicationService.getApplication({
				id: channelId,
			}).$promise;
		} catch (e) {}
		this.application.$resolved = true;
	}

	configureList(): void {
		const { channelId } = this.params;

		Object.assign(
			this.defaultFilters,
			{
				channelId,
				activeOnly: true,
				size: 50,
			},
		);

		this.omitParams.push('channelId', 'activeOnly');
	}

	async loadList(queryParams): Promise<void> {
		this.list.$resolved = false;
		try {
			this.list = await this.documentsService.getDocuments(
				this.getClearParams(queryParams),
			).$promise;
		} catch (e) {}
		this.list.$resolved = true;
	}

	back() {
		const { channelId } = this.params;
		this.stateService.go('application.:id.form', { id: channelId });
	}

	finish() {
		this.stateService.go('home');
	}
}
