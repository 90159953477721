import { Component, forwardRef } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { ComergenceLibraryHistoryDocument } from 'comergence/documents/comergence-documents.interface';
import { ComergenceDocumentsService } from 'comergence/documents/comergence-documents.service';

import { ListComponent } from 'commons/components/list';
import { HistoryDocumentListComponent } from 'shared/documents';


@Component({
	templateUrl: '../../../../../shared/documents/document-history/document-history.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ComergenceLibraryDocumentHistoryComponent) },
	],
})
export class ComergenceLibraryDocumentHistoryComponent extends HistoryDocumentListComponent<ComergenceLibraryHistoryDocument> {
	static listName = ComergenceLibraryDocumentHistoryComponent.addName('comergenceLibraryDocumentHistory');
	listName = ComergenceLibraryDocumentHistoryComponent.listName;

	listResource = this.documentsService.getClientLibraryDocumentLibraryHistory;

	configureList = (): void => {
		this.globalParams.push('libraryId');
		this.omitParams.push('libraryId');
	};

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		private documentsService: ComergenceDocumentsService,
	) {
		super(transitionService, stateService);
	}
}
