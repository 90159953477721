import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LenderChannel, LenderChannelContactInfo } from 'tpo/partners/lender-channel.interface';
import { NgResource } from 'commons/interfaces';

@Component({
	templateUrl: './tpo-info-modal.component.html',
})
export class TpoInfoModalComponent {
    lenderChannelContactInfo: NgResource<LenderChannelContactInfo>;
    lenderChannel: LenderChannel;

	public constructor(private readonly modalRef: BsModalRef) {
	}

    public hide(): void {
        this.modalRef.hide();
    }
}
