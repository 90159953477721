<ng-container *ngIf="canAdd">
	<ng-container *ngIf="add.show; then addFormTemplateRef; else addButtonTemplateRef"></ng-container>
	<ng-template #addFormTemplateRef>
		<form *ngIf="add.show" [formGroup]="add.form" (onValidSubmit)="add.submit()">

			<form-errors [form]="add.form"></form-errors>

			<div class="rows-striped remote-resource" [class.resolved]="!add.loading">
				<div class="row-fluid">
					<label class="col-sm-2 control-label required">Add to List</label>
					<div class="col-sm-6">
						<ng-select
							formControlName="listIds"
							[items]="add.availableLists$ | async"
							[loading]="!add.availableLists.$resolved"
							bindValue="id"
							bindLabel="name"
							[multiple]="true"
							[closeOnSelect]="false"
							placeholder="Select Lists"
						>
						</ng-select>
					</div>
					<div class="col-sm-4 text-right">
						<button class="btn btn-primary" type="submit">Add</button>
						<button class="btn btn-default" type="button" (click)="add.toggle()">Cancel</button>
					</div>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #addButtonTemplateRef>
		<div class="row-fluid" *ngIf="!add.show">
			<div class="col-sm-12 text-right">
				<a class="btn btn-primary" (click)="add.toggle()" [aDisabled]="!(contact.$resolved && lists.$resolved)">Add to List</a>
			</div>
		</div>
	</ng-template>

	<br>
</ng-container>

<div class="rows-bordered remote-resource" [class.resolved]="contact.$resolved && lists.$resolved">
	<div class="row-fluid header">
		<div class="col-sm-1" [ngClass]="getSortClass('id')" (click)="setSort('id')"><b>Id</b></div>
		<div class="col-sm-3" [ngClass]="getSortClass('name')" (click)="setSort('name')"><b class="two-liners">List Name</b></div>
		<div class="col-sm-2" [ngClass]="getSortClass('contacts')" (click)="setSort('contacts')"><b>Contacts</b></div>
		<div class="col-sm-2" [ngClass]="getSortClass('createdDate')" (click)="setSort('createdDate')"><b>Created</b></div>
		<div class="col-sm-a"></div>
	</div>

	<div class="row-fluid" *ngFor="let item of lists">
		<div class="col-sm-1 va-m">{{item.id}}</div>
		<div class="col-sm-3 va-m">{{item.name}}</div>
		<div class="col-sm-2 va-m">{{item.contacts | number}}</div>
		<div class="col-sm-2 va-m">{{item.createdDate | date}}</div>
		<div class="col-sm-a text-right">
			<a class="btn btn-sm btn-default"
			   (click)="remove(item)"
			   *ngIf="canRemove"
			>Remove</a>
		</div>
	</div>

	<div class="row-fluid" *ngIf="!lists.length">
		<div class="col-sm-12 text-center text-bigger text-muted">No lists</div>
	</div>

	<br />

	<new-paging class="no-border" [(list)]="lists" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
</div>
