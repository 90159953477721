import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';

import { NgResourceArray } from 'commons/interfaces';
import { ListComponent, PagedListComponent } from 'commons/components/list';

import { ComergenceDocument } from '../comergence-documents.interface';
import { ComergenceDocumentsService } from '../comergence-documents.service';
import { Subject } from 'rxjs';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { takeUntil } from 'rxjs/operators';


@Component({
	templateUrl: './comergence-document-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ComergenceDocumentListComponent) },
	],
})
export class ComergenceDocumentListComponent extends PagedListComponent implements OnInit, OnDestroy {
	static listName = ComergenceDocumentListComponent.addName('comergenceDocumentList');
	listName = ComergenceDocumentListComponent.listName;
	list: NgResourceArray<ComergenceDocument> = [];

    private readonly userProfileUnsubscribe$: Subject<void> = new Subject();
    public userProfile: UserProfile;

	constructor(
            transitionService: TransitionService,
            stateService: StateService,
            private readonly documentsService: ComergenceDocumentsService,
            private readonly userService: UserService) {
		super(transitionService, stateService);
	}

    // Override
	ngOnInit() {
		super.ngOnInit();

        this.userService.profile$
            .pipe(takeUntil(this.userProfileUnsubscribe$))
            .subscribe((userProfile: UserProfile) => this.userProfile = userProfile);
	}

    // Override
    public ngOnDestroy(): void {
        this.userProfileUnsubscribe$.next();
        this.userProfileUnsubscribe$.complete();
    }

    // Override
	async loadList(queryParams) {
		this.list.$resolved = false;
		try {
			this.list = await this.documentsService.getLibraryDocuments(
				this.getClearParams(queryParams),
			).$promise;
		} catch (e) {}
		this.list.$resolved = true;
	}
}
