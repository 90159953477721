import { Component, forwardRef } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { NgResourceArray } from 'commons/interfaces';
import { ListComponent, PagedListComponent } from 'commons/components/list';
import { ListingsAlertsStatusService } from 'tpo/status';

import {
	CompanyListingsService,
	ListingGroupResolvedAlert,
} from '../../../../company-listings.service';
import { GroupAlertsStatusService } from '../../group-alerts-status.service';

@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => CompanyListingGroupResolvedAlertsComponent) },
	],
})
export class CompanyListingGroupResolvedAlertsComponent extends PagedListComponent {
	static listName = CompanyListingGroupResolvedAlertsComponent.addName('company-listing-group-resolved-alerts');
	listName = CompanyListingGroupResolvedAlertsComponent.listName;
	list: NgResourceArray<ListingGroupResolvedAlert> = [];
	User: UserProfile;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		{ profile }: UserService,
		private listingsAlertsStatusService: ListingsAlertsStatusService,
		private companyListingsService: CompanyListingsService,
		private groupAlertsStatusService: GroupAlertsStatusService,
	) {
		super(transitionService, stateService);

		this.User = profile;

		this.configureList();
	}

	private configureList(): void {
		const { listingGroupId } = this.stateService.params;
		Object.assign(
			this.defaultFilters,
			{
				listingGroupId,
				size: 25,
			},
		);

		this.omitParams.push('listingGroupId');
	}

	loadList(params) {
		this.listingsAlertsStatusService.refresh();
		this.groupAlertsStatusService.refresh();

		this.list.$resolved = false;
		return this.companyListingsService.listingGroupResolvedAlerts(
			this.getClearParams(params),
		).$promise
			.then((data: ListingGroupResolvedAlert[]) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}
}
