<div class="review-header-wrapper flex-row gap-m">
    <h2>Investors</h2>
    <section-review  [section]="sectionType" class="flex-grow"></section-review>
</div>

<div class="remote-resource" [class.resolved]="resolving">
    <h3 class="col-md-a">Self Reported Information</h3>
    <div class="rows-bordered">
        <div class="row-fluid header bordered gap-m">
            <div class="col-md-a">Investor Name</div>
            <div class="col-md-a">Phone</div>
            <div class="col-md-a">Contact</div>
            <div class="col-f-280">
                <div class="row-fluid">
                    <div class="col-md-a">Start Date</div>
                    <div class="col-md-a">End Date</div>
                </div>
            </div>
            <div class="col-f-100 text-right button-holder">
                <button *ngIf="userCanAddEdit" type="button" class="btn btn-primary" (click)="addItem()"><span class="glyphicon glyphicon-plus small-icon"></span>Add</button>
            </div>
        </div>
        <investor-item class="row-fluid"
            *ngFor="let item of investorsList"
            [item]="item"
            [accountInformation]="lenderInfo"
            (onNewItemCancel)="cancelNewItem($event)"
            [userCanAddEdit]="userCanAddEdit"
            (afterSave)="sectionReview.updateReview()"
        ></investor-item>
        <div class="row-fluid" *ngIf="!investorsList?.length">
            <div class="col-md-12 text-bigger text-center text-muted">No records added</div>
        </div>
    </div>
</div>
<br>
<investor-information-table
    [lenderInfo]="lenderInfo"
></investor-information-table>
