import { Component, OnInit, Input } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { extend } from 'lodash';

import { UserActionsService } from '../../users/user-actions.service';
import { ActionLogComponent } from './action-log.component';

@Component({
	selector: 'user-login-log',
	templateUrl: './login-log.component.html',
})
export class LoginLogComponent extends ActionLogComponent implements OnInit {
	static listName = LoginLogComponent.addName('loginLog');
	listName = LoginLogComponent.listName;

	constructor(
		Transition: TransitionService,
		State: StateService,
		service: UserActionsService,
	) {
		super(Transition, State, service);
	}

	ngOnInit() {
		const {userId, type} = this.loadParams;
		super.ngOnInit();
		extend(this.defaultFilters, {userId, action: 'login-history'});
	}
}
