<div class="modal-dialog modal-login clearfix">
    <form [formGroup]="loginForm" novalidate autocomplete="off" (onValidSubmit)="submit()">
        <div class="modal-content nmls">
            <div class="modal-content-container remote-resource" [class.resolved]="resolved">
                <div class="modal-body">
                    <form-errors [form]="loginForm"></form-errors>
                    <div class="form-group input-group" has-error>
                        <span class="input-group-addon">
                            <img src="/static/images/login/username.svg" width="13"/>
                        </span>
                        <input type="text" class="form-control" formControlName="username" placeholder="Username"/>
                    </div>
                    <div class="form-group input-group" has-error>
                        <span class="input-group-addon">
                            <img src="/static/images/login/password.svg" width="14"/>
                        </span>
                        <input type="password" class="form-control" formControlName="password" placeholder="Password" (blur)="onPasswordBlur()" maxlength="20"/>
                    </div>
                    <div class="form-group login-button-holder">
                        <button type="submit" [disabled]="!cookieEnabled" class="btn btn-primary btn-lg">Log In</button>
                    </div>
                    <div class="restore clearfix">
                        <button type="button" [disabled]="!cookieEnabled" class="btn btn-link btn-sm pull-left" (click)="forgotUserName()">
                            Forgot Username?
                        </button>
                        <button type="button" [disabled]="!cookieEnabled" class="btn btn-link btn-sm pull-right" (click)="forgotPassword()">
                            Forgot Password?
                        </button>
                    </div>
                    <div class="form-group">
                        <button type="button" [disabled]="!cookieEnabled" class="btn btn-white btn-lg" uiSref="registration">Register</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
