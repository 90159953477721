import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { RejectType, StateRegistry, UIRouter, UIRouterGlobals } from '@uirouter/core';
import { Subject } from 'rxjs';
import { delay, first, skip, takeUntil, tap } from 'rxjs/operators';
import { RouteManagerService } from 'commons/services/route-manager.service';
import { OrganizationType, UserService } from 'angularjs-providers/user.provider';
import { RealmStateDeclaration } from 'routes/sharedRoutes';
import { HistoryLogService } from 'angularjs-providers/history-log-service.provider';
import { Location } from '@angular/common';
import { InspectletService } from 'commons/services/inspectlet.service';
import { AppInsightsStatisticsService } from 'commons/services/3rd-party/app-insights/app-insights-stats.service';
import { RealmParams } from 'commons/services/http';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorModalService } from 'commons/services/error/error-modal.service';
import { Transition } from '@uirouter/angular';

declare let window: Window;

@Component({
    selector: 'realm-app',
    templateUrl: 'realm-app.component.html',
})
export class RealmApp implements OnDestroy {
    public isFullPageView = false;
    public state: string = 'login';
    protected destroy$ = new Subject<void>();
    public className: string[] = [];
    public finishedLoading = false;
    public loadingResolves = false;

    constructor(
        private readonly routeManagerService: RouteManagerService,
        private readonly userService: UserService,
        private readonly stateRegistry: StateRegistry,
        private readonly historyLogService: HistoryLogService,
        private readonly inspectletService: InspectletService,
        private readonly appInsightsStatisticsService: AppInsightsStatisticsService,
        private readonly cd: ChangeDetectorRef,
        private readonly location: Location,
        private readonly errorModalService: ErrorModalService,
        { transitionService, stateService }: UIRouter,
        { success$, start$, params }: UIRouterGlobals,
    ) {
        //manage content classes
        success$.pipe(
            takeUntil(this.destroy$),
        ).subscribe(this.handleContentClass);

        // global loading response
        success$.pipe(
            first(),
            tap(() => this.finishedLoading = true),
            delay(300),
        ).subscribe(this.hideLoader);

        // responding to logout
        this.userService.logout$
            .pipe(
                first(),
            ).subscribe(type => this.logout());

        const accessTokenMatch = this.location.path().match(/^\/public\/(\w+)/);
        window.accessToken = accessTokenMatch && accessTokenMatch[1] || null;

        // trying to restore session
        this.userService.getUser(
            ({ organization: { type } }) => {
                this.loadStates(type);
            },
            () => {
                //settings up one-time login response
                this.userService.login$
                    .pipe(
                        first(),
                    ).subscribe(type => {
                        // navigate to helpdesk one logged in
                        if (params.host_url) {
                            return window.location.href = '/help';
                        }

                        // clearing default states (they no longer needed)
                        this.routeManagerService.removeDefaultRoutes();
                        this.loadStates(type);
                    },
                );
                window.accessToken ? this.loadStates('INVALID_TOKEN') : this.loadStates();
            },
        );

        // Global resolves handling
        start$.pipe(
            takeUntil(this.destroy$),
        ).subscribe(t => {
            const state = t.targetState().state() as RealmStateDeclaration;
            const params = t.params('to');

            //track state change
            this.appInsightsStatisticsService.trackState({
                name: state.name,
                url: t.router.stateService.href(state.name, params as RealmParams),
            });

            const nameParts = state.name.split('.');
            const isLoadingResolvers = nameParts.some((v, i, parts) => {
                const stateName = parts.slice(0, i + 1).join('.')
                return !!this.stateRegistry.get(stateName)?.resolve;
            });
            this.loadingResolves = isLoadingResolvers;
        });

        // Refresh user on state change
        start$.pipe(
            skip(1),
            takeUntil(this.destroy$),
        ).subscribe(() => {
            this.userService.getUser();
        });

        // Set user context when user is loaded
        this.userService.profile$.pipe(
            first(),
        ).subscribe(user => this.appInsightsStatisticsService.setUser(user));

        // Handle transition errors
        transitionService.onError({}, (t) => {
            const { type, detail }: { type: RejectType, detail: HttpErrorResponse } = t.error();

            // Init is wan't initialized
            if (!this.finishedLoading) {
                this.handleContentClass(t);
                this.hideLoader();
            }

            if (type === RejectType.ERROR && [401, 403, 404, 500].includes(detail.status)) {
                this.errorModalService.handleHttpError(detail.status);
                return this.cd.detectChanges();
            }
        });

        stateService.defaultErrorHandler((error) => {
            // ignore http errors
            if (error.type === RejectType.ERROR && error.detail instanceof HttpErrorResponse) {
                return;
            }

            return error;
        });
    }

    private loadStates(role: OrganizationType | 'INVALID_TOKEN' = null) {
        this.routeManagerService.loadStates(role);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    handleContentClass = (t: Transition) => {
        const state = t.targetState().state() as RealmStateDeclaration;
        this.loadingResolves = false;
        this.state = state.name;
        this.isFullPageView = state.isFullPageView;
        this.className = [
            `state-${state.name}`,
            ...state.class ? [state.class] : [],
            ...state.isFullPageView ? ['full-page-view'] : [],
        ];
        this.cd.detectChanges();
    };

    hideLoader = () => {
        this.finishedLoading = true;
        document.getElementById('rotate-loader').classList.add('out');
        this.cd.detectChanges();
    }

    private logout() {
        const content = document.getElementById('content-holder');
        content.classList.add('logout');
        window.location.reload();
    }
}
