import { Component, Input } from '@angular/core';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';

@Component({
    selector: 'tpo-partners-application-history',
    templateUrl: './tpo-partners-application-history.component.html',
})
export class TpoPartnersApplicationHistoryComponent {
    @Input() appHistory;
    @Input() stateService;

    theOrder: any = {
        actionDate: 'asc',
    };
    reverse = true;

    sortByOrder(order): void {
        const isAsc = this.theOrder[order] === 'asc';
        this.theOrder = {
            [order] : isAsc ? 'desc': 'asc',
        };
        this.reverse = !this.reverse;
    }

    sortByClass(order): string {
        if (Object.keys(this.theOrder)[0] == order) {
            return this.reverse ? 'sort-down' : 'sort-up';
        }
    }
}
