<div class="application-history">
    <h3 class="underlined">Application History <span class="text-light">{{appHistory.length}}</span></h3>
    <section>
        <div class="rows-bordered">
            <div class="row-fluid header">
                <span class="col-sm-2">
                    <b><a (click)="sortByOrder('channel.name')" [ngClass]="sortByClass('channel.name')">Channel</a></b>
                </span>
                <span class="col-sm-2">
                    <b><a (click)="sortByOrder('applicationStatus.name')"
                            [ngClass]="sortByClass('applicationStatus.name')">Application Status</a></b>

                </span>
                <span class="col-sm-2">
                    <b><a (click)="sortByOrder('actionDate')" [ngClass]="sortByClass('actionDate')">Status
                            Date</a></b>
                </span>
                <span class="col-sm-2">
                    <b><a (click)="sortByOrder('applicationType')" [ngClass]="sortByClass('applicationType')">App
                            Type</a></b>
                </span>
                <span class="col-sm-2">
                    <b><a (click)="sortByOrder('actionBy.firstName')"
                            [ngClass]="sortByClass('actionBy.firstName')">Actioned By</a></b>
                </span>
                <span class="col-sm-2"></span>
            </div>
            <div class="row-fluid" *ngFor="let item of appHistory | orderBy:theOrder">
                <span class="col-sm-2 break-word">
                    <span>
                        <b>{{item.channel.name}}</b>
                    </span><br>
                </span>
                <span class="col-sm-2 break-word" [ngClass]="item.applicationStatus.id == 'A' ? 'text-success' : ''">
                    {{item.applicationStatus.name}}
                </span>
                <span class="col-sm-2">
                    {{item.actionDate | date : 'MM/dd/yyyy'}}
                </span>
                <span class="col-sm-2">
                    {{item.applicationType == 'NEW' ? 'New' : 'Renewal'}}
                </span>
                <span class="col-sm-2 break-word">
                    {{item.actionBy.firstName}} {{item.actionBy.lastName ? item.actionBy.lastName : ''}}
                </span>
                <span class="col-sm-2 break-word actions">
                    <a class="btn btn-primary ng-scope" *ngIf="item.editable" uiSref="new-application"
                        [uiParams]="{channelId: item.channel.id}">
                        <span class="glyphicon glyphicon-pencil"></span> Edit
                    </a>
                    <a class="view" *ngIf="item.viewable"
                        href="tpo-applications/channels/{{item.channel.id}}/applications/{{item.applicationId}}/print"
                        target="_blank">
                        View
                    </a>
                    <div *ngIf="!item.editable && !item.viewable">&ndash;</div>
                </span>
            </div>
            <div class="row-fluid" *ngIf="appHistory.length == 0 ">
                <span class="col-sm-12 text-muted text-center text-bigger text-weak">No Application History</span>
            </div>
        </div>
    </section>
</div>