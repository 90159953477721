import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { ComergenceApplicationsListComponent } from './applications-list.component';
import { ComergenceApplicationsService } from 'comergence/applications/applications.service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		PipesModule,
		ComponentsModule,
		DirectivesModule,
		BsDropdownModule.forRoot(),
	],
	declarations: [
		ComergenceApplicationsListComponent,
	],
	providers: [
		ComergenceApplicationsService,
	],
	entryComponents: [
		ComergenceApplicationsListComponent,
	],
})
export class ComergenceApplicationsModule {
}
