import { FormGroup, ValidatorFn, AbstractControlOptions, AsyncValidatorFn } from '@angular/forms';

import { RealmFormControl } from './form-control.hoc';
import { RealmFormArray } from './form-array.hoc';

interface ServerError {
	name?: string;
	description?: string;
}

export interface ServerErrorResponse {
	message?: string;
	errorDetails?: ServerError[];
}

export type RealmFormFamily = RealmFormControl | RealmFormArray | RealmFormGroup;

// TODO: add recurly from server-validation.js
class RealmFormGroup extends FormGroup {
    label: string;
    serverError: string = '';

	get showError() {
		return this.touched && this.invalid;
	}

	constructor(
        controls: Record<string, RealmFormFamily>,
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
    ) {
		super(controls, validatorOrOpts, asyncValidator);
	}

	reset(value?: any, options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
		super.reset(value, options);
		this.serverError = '';
	}

	setServerError(response: ServerErrorResponse) {
		if (response.message) {
			if (response.errorDetails && response.errorDetails.length > 0) {
				for (const i in response.errorDetails) {
					if (response.errorDetails.hasOwnProperty(i)) {
						const fieldName = response.errorDetails[i].name;
						const field = this.get(fieldName) as RealmFormControl;

						if (!field) {
							// this is strange, no?
							this.serverError = response.message;
							continue;
						}

						field.serverError = response.errorDetails[i].description;
						field.patchValue(field.value);
						field.markAsTouched();
						field.updateValueAndValidity();
					}
				}
				return;
			}

			this.serverError = response.message;
			return;
		}

		for (const fieldName in response) {
			if (response.hasOwnProperty(fieldName)) {
				const field = this.controls[fieldName] as RealmFormControl;

				if (!field) {
					continue;
				}

				field.serverError = response[fieldName];
				field.patchValue(field.value);
				field.markAsTouched();
				field.updateValueAndValidity();
			}
		}
	}

	getFieldLabel = (fieldName) => {
		const field = this.get(fieldName);
		return field && (field as any).label;
	}

	setEnabled = (fieldName, enabled) => {
		const field = this.get(fieldName);
        if (!field) {
            return
        }
        if (enabled) {
            return field.enable();
        }

        return field.disable();
	}

	showFieldError = (fieldName) => {
		const field = this.get(fieldName);
		return field && (field as any).showError;
	}

    get = (path: Array<string | number> | string): RealmFormFamily | null => <RealmFormFamily>super.get(path);
}

export { RealmFormGroup };
