<footer class="global-footer clearfix flex-row">
    <div class="copyright text-left flex-grow">
        &#169; Comergence Compliance Monitoring, LLC. All rights reserved.
    </div><!--end:copyright-->

    <nav>
        <ul>
            <li><a target="_blank" href="/help">Help</a></li>
            <li><a target="_blank" href="https://o.comergencesupport.com/solution/articles/8000063692-terms-of-service">Terms of Use</a></li>
            <li><a target="_blank" href="https://www2.optimalblue.com/privacy-policy/">Privacy Notice</a></li>
            <li><a target="_blank" href="https://o.comergencesupport.com/solution/articles/8000067646-download-security">Download Security</a></li>
            <li *ngIf="isFree"><a target="_blank" href="/help/browser-requirements">Site Requirements</a></li>
        </ul>
    </nav>
</footer>
