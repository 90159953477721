import { Component, forwardRef, OnInit } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent, PagedListComponent } from 'commons/components/list';

import { ContactService } from '../../contact.service';

@Component({
	selector: 'contact-compliance-findings',
	templateUrl: './findings-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ContactComplianceFindingsListComponent) },
	],
})
export class ContactComplianceFindingsListComponent extends PagedListComponent implements OnInit {
	static listName = ContactComplianceFindingsListComponent.addName('contactComplianceFindings');
	listName = ContactComplianceFindingsListComponent.listName;

	User: any;
	list: any = [];

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public userService: UserService,
		public contactService: ContactService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;
	}

	ngOnInit(): void {
		this.configureList();

		super.ngOnInit();
	}

	configureList(): void {
		const { id, contactId } = this.stateService.params;

		Object.assign(this.defaultFilters, {
			size: 25,
			tpoId: this.User.isTpo ? this.User.organization.id : id,
			contactId,
		});
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.contactService.compliance.findings.get(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}
}
