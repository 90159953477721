import { Component, Input } from '@angular/core';
import { SectionReview } from 'lender/applications/application-summary.interface';


@Component({
    selector: 'application-summary-profile-review',
    templateUrl: 'application-summary-profile-review.component.html',
})
export class ApplicationSummaryProfileReviewComponent {
    @Input() section: SectionReview;
    @Input() sectionLabel: string;

    constructor() {}
}
