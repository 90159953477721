import * as moment from 'moment';
import { map, mapValues, reduce } from 'lodash';
import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';
import { PagedListComponent } from 'commons/components/list/paged-list.component';

import { ExportService } from '../export.service';
import { ExportTaskInterface } from '../export-task.interface';
import { ExportTypeInterface } from '../export-type.interface';

@Component({
	templateUrl: './export-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ExportListComponent) },
	],
})
export class ExportListComponent extends PagedListComponent {
	static listName = ExportListComponent.addName('export');
	listName = ExportListComponent.listName;

	User: any;
	resolved: boolean = false;
	list: ExportTaskInterface[] = [];
	exportTypes = {};

	bsConfig: Partial<BsDatepickerConfig> = {
		dateInputFormat: 'MM/DD/YYYY',
	};

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public userService: UserService,
		public service: ExportService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;

		this.configureFilters();
	}

	configureFilters() {
		this.defaultFilters = {
			...this.defaultFilters,
			exportType: [],
			requestFrom: null,
			requestTo: null,
		};

		const types = this.service.export.types();
		types.$promise.then((result: ExportTypeInterface[]) => {
			this.exportTypes = reduce(result, (memo, { name, id }) => {
				return { ...memo, [id]: name };
			}, {});
		});

		this.filters = {
			exportType: types,
		};
	}

	setFilter(filter, resetPage = true) {
		const processedFilter = mapValues(filter, (value: any, key: string) => {
			switch (key) {
				case 'exportType':
					return map(value || [], 'id');
				default:
					return value;
			}
		});

		super.setFilter(processedFilter, resetPage);
	}

	processParams(params) {
		return mapValues(
			this.getClearParams(params),
			(value, key) => (
				['requestFrom', 'requestTo'].includes(key) ?
					(value && moment(value).startOf('day').format('x')) :
					value
			),
		);
	}

	loadList(queryParams) {
		this.resolved = false;

		return this.service.export.list(
			this.processParams(queryParams),
		).$promise
			.then((tasks: ExportTaskInterface[]) => {
				this.list = tasks;
			})
			.finally(() => {
				this.resolved = true;
			});
	}

	download(item: ExportTaskInterface) {
		if (item.status !== 'COMPLETED') {
			return;
		}

		this.resolved = false;

		this.service.export.download({ exportTaskId: item.exportTaskId }).$promise.finally(() => {
			this.loadList(this.params);
		});
	}
}
