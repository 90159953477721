import { Injectable } from '@angular/core';
import { RealmHttpClient, RealmParams } from 'commons/services/http';
import { Observable } from 'rxjs';

declare let apiPath;

export interface Warehouse {
    approvedLineAmount: number;
    companyName: string;
    contactName: string;
    email: string;
    id: number;
    phone: string;
    relationshipEndDate: number;
    relationshipStartDate: number;
    sellLoans: boolean;
}

@Injectable()
export class WarehousesService {

    constructor(private http: RealmHttpClient) {}

    getWarehouse(tpoId): Observable<Warehouse []> {
        const tpoIdParam: RealmParams = {tpoId: tpoId};
        return this.http.request(
            'GET',
            `${apiPath}/warehouses`,
            tpoIdParam,
            null
        );
    }

    updateWarehouse(tpoId, id: number, updatedWarehouse): Observable<Warehouse> {
        const tpoIdParam: RealmParams = {tpoId: tpoId};
        return  this.http.request<Warehouse>(
            'PUT',
            `${apiPath}/warehouses/${id}`,
            tpoIdParam,
            updatedWarehouse
        );
    }

    addNewWarehouse(tpoId, newWarehouse): Observable<Warehouse> {
        const tpoIdParam: RealmParams = {tpoId: tpoId};
        return this.http.request<Warehouse>(
            'POST',
            `${apiPath}/warehouses`,
            tpoIdParam,
            newWarehouse
        );
    }

}
