import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import html2canvas from 'html2canvas';

import { SocialMediaPostCommentsComponent } from 'commons/components/sm-post';
import { NgResource } from 'commons/interfaces';

import { FindingDetail } from 'shared/findings/$findingId/finding.interface';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { FindingPost } from '../../../findings.interface';
import { FindingsService } from '../../../findings.service';
import { FindingModelService } from '../../finding-model.service';

declare let apiPath: string;

@Component({
	selector: 'finding-post',
	templateUrl: './post.component.html',
})
export class PostComponent implements OnInit, OnChanges {
	User: UserProfile;
	params: any;
	post: NgResource<FindingPost>;
	expandAllCommentsSubject: Subject<boolean> = new Subject();
	screenshotLoading: boolean;
	screenshotUrl: string;
	canManage: boolean = false;

	@ViewChild('postContent') postContent!: ElementRef<HTMLImageElement>;
	@ViewChild('postComments') postComments: SocialMediaPostCommentsComponent;

	@Input() title: string;
	@Input() finding: NgResource<FindingDetail>;
	@Input() current: boolean = false;

	constructor(
		public findingsService: FindingsService,
		public findingModelService: FindingModelService,
		{ profile }: UserService,
	) {
		this.User = profile;
		this.canManage = (
			(this.User.isComergence && this.User.can('CCM_SMC_VIEW_ALL'))
			||
			this.User.isTpo && this.User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE')
		);

		this.findingModelService.onImageLoaded$.pipe(first()).subscribe(() => {
			this.screenshotLoading = false;
		})
	}

	ngOnInit(): void {
		if (this.finding?.hasScreenshot) {
			this.screenshotLoading = true;
			const { isTpo, isPublicAccess, organization: { id: tpoId } } = this.User;
			const authParam = isPublicAccess ? `?realmLimitedAccessToken=${window.accessToken}` : '';
			this.screenshotUrl = `${apiPath}${isTpo || isPublicAccess ? `/tpos/${tpoId}` : ``}/social-media-compliance/finding/${this.finding.id}/screen${authParam}`;
		}
	}

	ngOnChanges({ finding }: SimpleChanges): void {
		const { postKey, reviewId, id: findingId } = finding?.currentValue || {};
		if (postKey) {
			const { smAccountId, type, id, version } = postKey;
			this.params = { smAccountId, type, id, version, reviewId };
			const params = {
				...this.params,
				...(this.current && {
					version: 'current',
					diffVersion: version,
				}),
				findingId,
			};
            this.post = this.findingsService.getPost(params);
            this.post.$promise.catch(() => this.post.$resolved = true);

		}
	}

	async createPostImg(): Promise<void> {
		this.screenshotLoading = true;
		if (!this.post.isAllCommentsVisible && this.post.comments?.totalElements) {
			this.postComments.onMoreComments.pipe(first()).subscribe(() => {
				setTimeout(() => {
					this.htmlToImg();
				}, 500);
			});
			this.expandAllCommentsSubject.next();
		} else {
			this.htmlToImg();
		}
	}

	async htmlToImg(): Promise<void> {
		const canvas = await html2canvas(this.postContent.nativeElement, {
			allowTaint: true,
			proxy: '/api/rest/social-media-compliance/archive/proxy',
		});

		try	{
			this.screenshotUrl = canvas.toDataURL('image/jpeg');
			this.screenshotLoading = false;
		} catch (e) {
			this.screenshotLoading = false;
			// error
		}

		canvas.toBlob((blob) => {
			try	{
				this.findingsService.findingScreenshot.create({ findingId: this.finding.id }, blob);
			} catch (e) {
				this.screenshotUrl = null;
				// error
			}
		});
	}
}
