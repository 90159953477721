import { range } from 'lodash';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { UserService } from 'angularjs-providers/user.provider';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';

import { PasswordPolicyService } from './password-policy.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'password-policy',
	templateUrl: './password-policy.component.html',
})

export class PasswordPolicyComponent implements OnDestroy, OnInit {
	$scope: any = {};
	passwordPolicy: any = {};
	editable: boolean = false;
	expires: boolean;
	range0To10: number[] = range(0, 11);
	range6To12: number[] = range(6, 13);
	range3o10: number[] = range(3, 11);

	passwordPolicyForm: RealmFormGroup = new RealmFormGroup({});
    destroy$ = new Subject<void>();

	constructor(
		public User: UserService,
		public PasswordPolicy: PasswordPolicyService,
		public injector: Injector,
	) {
		this.$scope = this.injector.get('$scope');
	}

    ngOnInit() {
		// FIXME: #RM-18302 temp fix: secure at back-end
        this.User.profile$
            .pipe(
                takeUntil(this.destroy$),
            )
            .subscribe((user) => {
            if (user && ((user.isTpo && !user.can('TPO_SECURITY_POLICY')) || (user.isLender && !user.can('EDIT_PASSWORD_SECURITY')))) {
                this.$scope.$emit('USER:403');
            }
        });


		this.passwordPolicy = this.PasswordPolicy.get(() => this.setExpires());
		this.initForm();
	}

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

	save() {
		this.passwordPolicy.$resolved = false;
		this.PasswordPolicy.update({...this.passwordPolicy, ...this.passwordPolicyForm.getRawValue()}, (response) => {
			this.passwordPolicy = response;
			this.editable = false;
		}, (response) => {
			this.passwordPolicy.$resolved = true;
			this.passwordPolicyForm.setServerError(response);
		});
	}

	initForm() {
		const form = this.passwordPolicyForm;

		form.addControl('passwordExpiryPeriod', new RealmFormControl(
			'passwordExpiryPeriod',
			{
				label: 'Password Expiration Period',
				updateOn: 'blur',
			},
			Validators.compose([
				Validators.required,
				Validators.min(1),
				Validators.max(999),
				Validators.pattern('^[0-9]*$'),
			]),
		));

		form.addControl('enforcePasswordHistory', new RealmFormControl(
			'enforcePasswordHistory',
			{label: 'Enforce Password History'},
			Validators.required,
		));

		form.addControl('minPasswordLength', new RealmFormControl(
			'minPasswordLength',
			{label: 'Minimum Password Length'},
			Validators.required,
		));

		form.addControl('maxFailedLoginAttemptsAllowed', new RealmFormControl(
			'maxFailedLoginAttemptsAllowed',
			{label: 'Maximum Failed Login Attempts Allowed'},
			Validators.required,
		));

		form.addControl('specialCharRequired', new RealmFormControl(
			'specialCharRequired',
			{label: 'Special Character Required'},
		));

		form.addControl('numberRequired', new RealmFormControl(
			'numberRequired',
			{label: 'Number Required'},
		));

		form.addControl('upperCaseRequired', new RealmFormControl(
			'upperCaseRequired',
			{label: 'Upper Case Required'},
		));

		form.addControl('lowerCaseRequired', new RealmFormControl(
			'lowerCaseRequired',
			{label: 'Lower Case Required'},
		));

		this.passwordPolicy.$promise.then((response) => {
			form.patchValue(response);
		});
	}

	showForm() {
		this.editable = true;
	}

	reset() {
		this.editable = false;
		this.passwordPolicyForm.reset({...this.passwordPolicy});
		this.setExpires();
	}

	changeExpires() {
		if (this.passwordPolicyForm.get('passwordExpiryPeriod').value === -1) {
			const period = this.passwordPolicy.passwordExpiryPeriod === -1 ? '' : this.passwordPolicy.passwordExpiryPeriod;
			this.passwordPolicyForm.get('passwordExpiryPeriod').setValue(period);
		} else {
			this.passwordPolicyForm.get('passwordExpiryPeriod').setValue(-1);
		}

		this.toggleExpirationFieldValidation();
	}

	toggleExpirationFieldValidation() {
		this.passwordPolicyForm.get('passwordExpiryPeriod')[this.expires ? 'disable' : 'enable']();
	}

	setExpires() {
		this.expires = this.passwordPolicy.passwordExpiryPeriod === -1 ? true : false;
		this.toggleExpirationFieldValidation();
	}
}
